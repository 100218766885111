import React from 'react';
import { useRouter } from 'next/router';
import { useCompaniesInformation } from '@repository/companies/useCompanies';
import FinanceInfoGraph from './financeInfoGraph';
import InfoListItem from '../infoListItem';
import classnames from 'classnames/bind';
import styles from './financeInfo.module.scss';
const cx = classnames.bind(styles);

const FinanceInfo = () => {
  const router = useRouter();
  const { sn } = router.query;
  const { data: companyInfoData } = useCompaniesInformation(Number(sn));

  const isNotData =
    !companyInfoData?.companyDetail?.businessSize &&
    !companyInfoData?.companyDetail?.revenue &&
    !companyInfoData?.companyDetail?.profit;

  if (isNotData) return null;

  return (
    // 고용 안정성 영역
    <div className={cx('snInner', 'noBorder', 'noPadding')}>
      {/* 영역 제목 */}
      <p className={cx('sectionTitle')}>
        재무 정보
        <span className={cx('updateDate')}>{companyInfoData?.lastModifiedDateText}</span>
      </p>

      {/* 안정성 카드 */}
      <ul className={cx('companyInfoList')}>
        {/* 기업규모 */}
        <InfoListItem type={'enterprise'} title={companyInfoData?.companyDetail.businessSizeText ?? '-'} />
        {/* 매출액 */}
        <InfoListItem type={'take'} title={companyInfoData?.companyDetail.revenueText ?? '-'} />
        {/* 영업이익 */}
        <InfoListItem type={'profit'} title={companyInfoData?.companyDetail.profitText ?? '-'} />
      </ul>

      <FinanceInfoGraph />
    </div>
  );
};

export default FinanceInfo;
