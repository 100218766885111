import React, { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useCompanyAdvantage } from '@repository/companies/useCompanies';
import { ReviewTypeEnum } from '@domain/constant/company/sn/reviewType';
import useModals from '@utils/hooks/useModals';
import classnames from 'classnames/bind';
import styles from './advantages.module.scss';

const cx = classnames.bind(styles);

const Advantages = () => {
  const router = useRouter();
  const { sn } = router.query;
  const { data: advantageData } = useCompanyAdvantage(Number(sn));
  const { isVisible, openModal } = useModals(advantageData?.reviewTypeList ?? []);

  const tabItemEls = () => {
    return advantageData?.reviewTypeList?.map((item, index) => {
      return (
        <a className={cx('btnMenu', { on: isVisible(item) })} role="button" onClick={() => openModal(item)} key={index}>
          {ReviewTypeEnum[item]}
        </a>
      );
    });
  };

  const progressItemEls = () => {
    const currentTabData = advantageData?.cultureList?.find((item) => isVisible(item.type));
    return currentTabData?.answerData?.map((item, index) => {
      return (
        <div className={cx('progressArea')} key={index}>
          <div className={cx('progressBarWrap')}>
            <div
              className={cx('progressBar', { overHalf: item.avergageScore ?? 0 >= 50 })}
              style={{ width: `${item.avergageScore}%` }}
            />
            <div className={cx('progressContent')}>
              <span className={cx('progressDesc', { overHalf: item.avergageScore ?? 0 >= 50 })}>
                <img className={cx('logo')} src={item.iconUrl} alt="logoImage" />
                {item?.answer}
              </span>
              <span className={cx('progressText')}>{item?.avergageScore}%</span>
            </div>
          </div>
        </div>
      );
    });
  };

  useEffect(() => {
    if (!advantageData?.reviewTypeList) return;
    openModal(advantageData?.reviewTypeList?.[0]);
  }, [advantageData]);

  if (!advantageData?.cultureList) return null;

  return (
    // 장점 퍼센테이지 영역
    <div className={cx('snInner', 'advantageInner')}>
      {/* 영역 제목 */}
      <p className={cx('sectionTitle')}>입사 선배가 알려주는 장점</p>

      {/* 태그 버튼 리스트 */}
      <div className={cx('advantagesBtn')}>
        {/* [D] 선택된 요소인 경우 'on' 클래스 추가 */}
        {tabItemEls()}
      </div>

      {/* 장점 순위 리스트 */}
      <div className={cx('rankingList')}>
        {/* 스크롤 영역 */}
        <div className={cx('scrollArea')}>
          {/* 프로그래스바 영역 */}
          {progressItemEls()}
        </div>
      </div>
    </div>
  );
};

export default Advantages;
