import React, { FC } from 'react';
import { JOBDA_SUPPORT_EMAIL } from '@domain/constant/contact';

import classnames from 'classnames/bind';
import styles from './emptyCuration.module.scss';
const cx = classnames.bind(styles);

export type EmptyInfoType = 'ERROR' | 'FIND_POSITION' | 'EMPTY_POSITION' | 'INPUT_POSITION';

const ErrorArea = () => {
  return (
    <div className={cx('emptyCurationInner', 'error')}>
      <em className={cx('title')}>추천 포지션을 가지고 오는데 실패했어요.</em>
      <p className={cx('desc')}>해결을 원하시는 경우 아래의 E-mail(잡다 헬프데스크)로 문의해 주세요.</p>
      <a href={`mailto:${JOBDA_SUPPORT_EMAIL}`} className={cx('link')}>
        {JOBDA_SUPPORT_EMAIL}
      </a>
    </div>
  );
};

const FindPositionArea = () => {
  return (
    <div className={cx('emptyCurationInner')}>
      <em className={cx('title')}>회원님과 적합한 포지션을 찾는 중입니다.</em>
      <p className={cx('desc')}>
        분석 결과를 확인하시려면 잠시 후<br />
        새로 고침(F5)을 눌러주세요.
      </p>
    </div>
  );
};

const NoPositionDataArea = () => {
  return (
    <div className={cx('emptyCurationInner')}>
      <em className={cx('title')}>아쉽게도 나와 맞는 포지션이 없네요.</em>
      <p className={cx('desc')}>
        프로필 강화, 선호 조건 조정을 통해
        <br />더 많은 포지션을 추천받아 보세요.
      </p>
    </div>
  );
};

const InputPositionArea = () => {
  return (
    <div className={cx('emptyCurationInner')}>
      <em className={cx('title')}>
        포지션 정보를 입력하고 있어요.
        <br />
        잠시만 기다려주세요.
      </em>
    </div>
  );
};

const getEmptyArea = (type: EmptyInfoType) => {
  switch (type) {
    // 포지션 로드 실패(에러)
    case 'ERROR':
      return <ErrorArea />;
    // 포지션 찾는중
    case 'FIND_POSITION':
      return <FindPositionArea />;
    // 포지션 없음
    case 'EMPTY_POSITION':
      return <NoPositionDataArea />;
    // 포지션 정보 입력중
    case 'INPUT_POSITION':
      return <InputPositionArea />;
  }

  return null;
};

interface IEmptyCurationProps {
  type: EmptyInfoType;
}

const EmptyCuration: FC<IEmptyCurationProps> = ({ type }) => {
  const contentsEl = getEmptyArea(type);

  return <div className={cx('emptyCurationArea')}>{contentsEl}</div>;
};

export default EmptyCuration;
