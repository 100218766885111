import React from 'react';
import Modal from '@common/modal';
import Button from '@components/common/button';
import { JOBDA_SUPPORT_EMAIL } from '@domain/constant/contact';
import classnames from 'classnames/bind';
import styles from './fileAttachmentErrorModal.module.scss';

const cx = classnames.bind(styles);

export type FileAttachmentType =
  | 'FILE_COUNT_ERROR'
  | 'FILE_FORMAT_ERROR'
  | 'WRONG_FILE_ERROR'
  | 'SERVICE_ERROR'
  | 'FILE_CAPACITY_ERROR'
  | 'END_POSITION_ERROR'
  | 'DELETE_POSITION_ERROR';

// 파일 개수 에러
const FileCountError = () => {
  return (
    <>
      <strong className={cx('title')}>파일 개수가 초과되었습니다.</strong>
      <p className={cx('desc')}>
        파일은 총 10개를 넘을 수 없습니다.
        <br />더 많은 파일을 업로드 하시려면
        <br />
        여러 개의 파일을 압축하신 후 업로드해 주세요.
      </p>
    </>
  );
};

// 파일 형식 에러
const FileFormatError = () => {
  return (
    <>
      <strong className={cx('title')}>첨부할 수 없는 파일입니다.</strong>
      <p className={cx('desc')}>업로드 가능한 파일 형식을 확인해 주세요.</p>
      <ul className={cx('fileList')}>
        <li className={cx('listItem')}>- 문서: doc,docx,hwp,pdf,ppt,pptx,rtf,txt,xls,xlsx</li>
        <li className={cx('listItem')}>- 영상/음성: avi,m4v,mov,mp3,mp4,web,wmv</li>
        <li className={cx('listItem')}>- 사진/그림: ai,gif,jiffy,jpeg,jpg,png,tif,tiff</li>
        <li className={cx('listItem')}>- 압축파일: egg, zip</li>
      </ul>
    </>
  );
};

// 손상된 파일이거나 비어있는 파일
const WrongFileError = () => {
  return (
    <>
      <strong className={cx('title')}>파일을 다시 확인해 주세요.</strong>
      <p className={cx('desc')}>
        손상된 파일이거나 비어있는 파일입니다.
        <br />
        확인 후 다시 업로드해 주세요.
      </p>
    </>
  );
};

// 서비스 에러
const ServiceError = () => {
  return (
    <>
      <strong className={cx('title')}>현재 서비스가 원활하지 않습니다.</strong>
      <p className={cx('desc')}>
        일시적인 장애이거나 네트워크 문제일 수 있습니다.
        <br />
        오류가 지속될 경우 아래의 E-mail (잡다 헬프데스크)로
        <br />
        문의 해주세요.
      </p>
      <a className={cx('link')} href={`mailto:${JOBDA_SUPPORT_EMAIL}`}>
        {JOBDA_SUPPORT_EMAIL}
      </a>
    </>
  );
};

// 파일 용량 에러
const FileCapacityError = () => {
  return (
    <>
      <strong className={cx('title')}>파일 용량이 초과되었습니다.</strong>
      <p className={cx('desc')}>
        파일 용량은 총 50MB를 넘을 수 없습니다.
        <br />
        용량을 줄이신 후 다시 업로드해 주세요.
      </p>
    </>
  );
};

// 종료된 포지션
const EndPosition = () => {
  return (
    <>
      <strong className={cx('title')}>종료된 포지션입니다.</strong>
      <p className={cx('desc')}>포지션이 종료되어 파일 업로드가 불가능합니다.</p>
    </>
  );
};

// 삭제된 포지션
const DeletePosition = () => {
  return (
    <>
      <strong className={cx('title')}>삭제된 포지션입니다.</strong>
      <p className={cx('desc')}>포지션이 삭제되어 파일 업로드가 불가능합니다.</p>
    </>
  );
};

interface IFileAttachmentErrorLayerProps {
  type: FileAttachmentType;
}

const FileAttachmentLayer = ({ type }: IFileAttachmentErrorLayerProps) => {
  switch (type) {
    // 파일 개수 에러
    case 'FILE_COUNT_ERROR':
      return <FileCountError />;

    // 파일 형식 에러
    case 'FILE_FORMAT_ERROR':
      return <FileFormatError />;

    // 손상된 파일이거나 비어있는 파일
    case 'WRONG_FILE_ERROR':
      return <WrongFileError />;

    // 서비스 에러
    case 'SERVICE_ERROR':
      return <ServiceError />;

    // 파일 용량 에러
    case 'FILE_CAPACITY_ERROR':
      return <FileCapacityError />;

    // 종료된 포지션
    case 'END_POSITION_ERROR':
      return <EndPosition />;

    // 삭제된 포지션
    case 'DELETE_POSITION_ERROR':
      return <DeletePosition />;
  }
};

interface FileAttachmentErrorModalProps {
  visible: boolean;
  type: FileAttachmentType;
  onClose: () => void;
}

const FileAttachmentErrorModal = ({ visible, type, onClose }: FileAttachmentErrorModalProps) => {
  return (
    <Modal visible={visible} isUseScrollHidden={false}>
      <div className={cx('layer')}>
        <FileAttachmentLayer type={type} />
        <Button className={cx('btnConfirm')} onClick={onClose}>
          확인
        </Button>
      </div>
    </Modal>
  );
};

export default FileAttachmentErrorModal;
