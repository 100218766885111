import React, { FC } from 'react';
import Percentage from './percentage';
import FourQuadrants from './fourQuadrants';
import { MatchingReportScoreVO } from '@domain/vo/match/report/MatchingReportVO';
import classnames from 'classnames/bind';
import styles from './graph.module.scss';
const cx = classnames.bind(styles);

export interface GraphProps {
  data?: MatchingReportScoreVO;
}

const Graph: FC<GraphProps> = ({ data }) => {
  if (!data) return null;

  return (
    <div className={cx('graphInner')}>
      <Percentage data={data} />
      <FourQuadrants data={data} />
    </div>
  );
};

export default Graph;
