import React from 'react';
import classnames from 'classnames/bind';
import styles from './companyDescList.module.scss';
const cx = classnames.bind(styles);

interface companyDescProps {
  title: string;
  first?: string | null;
  second?: string | null;
  third?: string | null;
}

const CompanyDescList = ({ title, first, second, third }: companyDescProps) => {
  if (!first && !second && !third) return null;

  return (
    <div className={cx('companyItem')}>
      <div className={cx('descTitle')}>{title}</div>
      <div className={cx('descInner')}>
        <div className={cx('descList')}>{first}</div>
        <div className={cx('descList', { hidden: !second })}>{second}</div>
        <div className={cx('descList', { hidden: !third })}>{third}</div>
      </div>
    </div>
  );
};

export default CompanyDescList;
