import React from 'react';
import Text from '@components/common/text';
import FileList from '../../../common/FileList';
import { AttachFileDto } from '@domain/rs/match/message/MatchingMessageListRs';
import { FontStyle } from '@domain/constant/fontStyle';
import Colors from '@domain/constant/colors';
import DateFormat from '@utils/date/format';
import classnames from 'classnames/bind';
import styles from './messageContents.module.scss';

const cx = classnames.bind(styles);

interface MessageContentsProps {
  title: string;
  message: string;
  decisionDueDateTimeOriginal?: string | null;
  attachFiles: AttachFileDto[];
  positionSn: number | null;
}

const MessageContents = ({
  title,
  message,
  decisionDueDateTimeOriginal,
  attachFiles,
  positionSn,
}: MessageContentsProps) => {
  return (
    <>
      <strong className={cx('title')}>{title}</strong>
      <div className={cx('message')}>{message}</div>
      <FileList positionSn={positionSn} attachFiles={attachFiles} />

      {!!decisionDueDateTimeOriginal && (
        <>
          <Text fontStyle={FontStyle.B2P_B} color={Colors.C_COOL_GRAY_90} styles={{ marginTop: 16 }}>
            응답 기한
            <span className={cx('date')}>
              {DateFormat.format(new Date(decisionDueDateTimeOriginal), 'yyyy.MM.dd (week)')}
            </span>
          </Text>
          <Text fontStyle={FontStyle.B3P_M} color={Colors.C_COOL_GRAY_60} styles={{ marginTop: 4 }}>
            정해진 유효기간까지 제출하지 않을 경우 제출이 불가능합니다.
          </Text>
        </>
      )}
    </>
  );
};

export default MessageContents;
