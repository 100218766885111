import React, { FC } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { RouteUrl } from '@utils/routeUtil';
import classnames from 'classnames/bind';
import styles from './emptyPage.module.scss';

const cx = classnames.bind(styles);

export type ErrorType = 'NOT_REGISTER_COMPANY' | 'NOT_EXIST_COMPANY' | null;

const NotRegisterCompany = () => {
  const { back } = useRouter();
  //이전 페이지 존재 유무
  const isExistBackPage = !!document?.referrer || document.referrer !== '';

  const buttonEl = isExistBackPage ? (
    <a className={cx('btn')} role={'button'} onClick={back}>
      뒤로가기
    </a>
  ) : (
    <Link href={RouteUrl.INDEX} legacyBehavior>
      <a className={cx('btn')} role="button">
        메인으로 돌아가기
      </a>
    </Link>
  );

  return (
    <div className={cx('contentArea')}>
      <div className={cx('contentInner', 'type2')}>
        <strong className={cx('title')}>기업 전용 페이지를 준비하고 있어요.</strong>
        <p className={cx('desc')}>
          기업 소개부터 인원, 재무 등 다양한 정보를 준비하고 있으니
          <br />
          조금만 기다려주세요.
        </p>
        {buttonEl}
      </div>
    </div>
  );
};

interface EmptyPageProps {
  type: ErrorType;
}

const EmptyPage: FC<EmptyPageProps> = ({ type }) => {
  if ('NOT_REGISTER_COMPANY' === type) return <NotRegisterCompany />;

  return (
    <div className={cx('contentArea')}>
      <div className={cx('contentInner')}>
        <strong className={cx('title')}>요청하신 페이지를 찾을 수 없어요</strong>
        <p className={cx('desc')}>
          기업 서비스를 준비하고 있거나 사용할 수 없는 페이지입니다.
          <br />
          입력하신 주소가 정확한지 다시 한번 확인해 주세요.
        </p>
        <Link href={RouteUrl.INDEX} legacyBehavior>
          <a className={cx('btn')} role="button">
            메인으로 돌아가기
          </a>
        </Link>
      </div>
    </div>
  );
};

export default EmptyPage;
