import React, { FC, useRef, useState } from 'react';
import { useFormContext, useController } from 'react-hook-form';
import Icon from '@common/assets';
import { useJobGroupsWithTitles } from '@repository/codes/useCodes';
import PositionListFilterVO from '@domain/vo/position/list/PositionListFilterVO';
import { JobGroupWithTitlesDto } from '@domain/rs/code/JobGroupsWithTitlesRs';
import JobTitleRs from '@domain/rs/code/JobTitleRs';
import useClickAway from '@utils/hooks/useClickOutside';

import TextFormat from '@utils/text/format';
import classnames from 'classnames/bind';
import styles from '../positionFilter.module.scss';
const cx = classnames.bind(styles);

const fieldName = 'jobTitles';

interface IJobGroupAreaProps {
  vo: JobGroupWithTitlesDto;
  selectedList: number[];
  onSelect: (selected: JobTitleRs, checked: boolean) => void;
}

const JobGroupArea: FC<IJobGroupAreaProps> = ({ vo, selectedList, onSelect }) => {
  const { name, jobTitles } = vo;

  const JobTitlesEl = jobTitles.map((item, idx) => {
    const isChecked = selectedList.includes(item.code);

    return (
      <label key={idx} className={cx('label')}>
        <input
          type="checkbox"
          checked={isChecked}
          className={cx('hidden', 'input')}
          onChange={(e) => onSelect(item, e.target.checked)}
        />
        <span className={cx('checkbox')} />
        {item.name}
      </label>
    );
  });

  return (
    <div className={cx('selectOptionWrap')}>
      <em className={cx('optionTitle')}>{name}</em>
      <div className={cx('optionArea')}>{JobTitlesEl}</div>
    </div>
  );
};

const JobTitlesFilter = () => {
  const [selectedList, setSelectedList] = useState<JobTitleRs[]>([]);

  const { control } = useFormContext<PositionListFilterVO>();
  const { field: jobTitlesField } = useController({
    control,
    name: fieldName,
    defaultValue: [],
  });
  const checkedList = jobTitlesField.value;
  const btnName = checkedList.length
    ? TextFormat.formatSummaryListWithCount(checkedList.map((item: JobTitleRs) => item.name))
    : '직군·직무';

  const [selectOptionAreaVisible, setSelectOptionAreaVisible] = useState(false);
  const jobFilterRef = useRef<HTMLDivElement>(null);
  const { data } = useJobGroupsWithTitles();
  const isOn = checkedList.length;

  const openCloseSelectOptionArea = () => {
    //직무 Option 열릴 때 적용 버튼을 통해 초기화된 값으로 셋팅
    if (!selectOptionAreaVisible) {
      setSelectedList(jobTitlesField.value);
    }

    setSelectOptionAreaVisible(!selectOptionAreaVisible);
  };

  const onInitSelectedList = () => {
    jobTitlesField.onChange([]);
    setSelectedList([]);
  };

  const onSaveSelectedList = () => {
    jobTitlesField.onChange(selectedList);
    setSelectOptionAreaVisible(false);
  };

  const onSelectedJobTitles = (selected: JobTitleRs, checked: boolean) => {
    if (!checked) {
      const filteredList = selectedList.filter((itme) => itme.code !== selected.code);
      setSelectedList(filteredList);
    } else {
      const tempList = [...selectedList, selected];
      //정렬 이유 : 직군·직무 버튼 표시시 제일 상단에 있는 직무 값 먼저 표시하기 위함
      tempList.sort((a, b) => a.code - b.code);
      setSelectedList(tempList);
    }
  };

  const JobGroupEl = data?.map((item, idx) => {
    return (
      <JobGroupArea key={idx} vo={item} selectedList={selectedList.map((d) => d.code)} onSelect={onSelectedJobTitles} />
    );
  });

  useClickAway(() => setSelectOptionAreaVisible(false), jobFilterRef);

  return (
    <div className={cx('selectWrap')} ref={jobFilterRef}>
      <a className={cx('btnSelect', { on: isOn })} onClick={() => openCloseSelectOptionArea()} role="button">
        {btnName}
        {selectOptionAreaVisible && <Icon name="arrowTopLight" width={24} height={24} />}
        {!selectOptionAreaVisible && <Icon name="arrowBottomLight" width={24} height={24} />}
      </a>
      {selectOptionAreaVisible && (
        <div className={cx('selectOptionArea', 'job')}>
          <div className={cx('scrollArea')}>
            <div className={cx('selectOptionInner')}>{JobGroupEl}</div>
          </div>

          <div className={cx('btnArea')}>
            <a className={cx('btnReset')} role="button" onClick={onInitSelectedList}>
              선택 초기화
            </a>
            <a className={cx('btnConfirm')} role="button" onClick={onSaveSelectedList}>
              적용
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

export default JobTitlesFilter;
