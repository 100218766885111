import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useRouter } from 'next/router';
import Link from 'next/link';
import Icon from '@common/assets';
import { useCompaniesPositions } from '@repository/companies/useCompanies';
import { LogTypeEnum } from '@/src/logging/LogType';
import CompanyPositionFilterVO from '@domain/vo/company/sn/CompanyPositionFilterVO';
import { V2Route } from '@utils/routeUtil';
import classnames from 'classnames/bind';
import styles from './positionList.module.scss';

const cx = classnames.bind(styles);

const NoPositionData = () => {
  return (
    <div className={cx('emptyArea')}>
      <strong className={cx('title')}>공고를 기다리고 있어요.</strong>
      <p className={cx('text')}>기업에서 채용을 시작하면 채용 공고를 확인할 수 있어요.</p>
    </div>
  );
};

const PositionList = () => {
  const router = useRouter();
  const { sn } = router.query;
  const { watch } = useFormContext<CompanyPositionFilterVO>();
  const watchFilter = watch();
  const { data, fetchNextPage } = useCompaniesPositions(Number(sn), watchFilter);
  const moreBtnVisible = data?.pages[data.pages.length - 1].isExistNextData;

  //리스트 없는 경우
  if (data?.pages[0] && data?.pages[0].positions.length < 1) return <NoPositionData />;

  const positionItemEls = data?.pages.map(({ positions }) => {
    return positions.map((item) => {
      return (
        <Link
          className={cx('positionList')}
          href={V2Route.getPositionJDPage(item.positionSn)}
          key={item.positionSn}
          id={LogTypeEnum.log_company_position}
          target="_blank"
        >
          <>
            <span className={cx('recruitTitle')}>{item.positionName}</span>
            <div className={cx('recruitInfo')}>
              <span className={cx('date')}>{item.closingDateText}</span>
              <span className={cx('dDay')}>{item.closingDateDdayText}</span>
            </div>
          </>
        </Link>
      );
    });
  });

  return (
    <div className={cx('positionBox')}>
      {/* 공고 리스트 */}
      <div className={cx('positionInner')}>{positionItemEls}</div>

      {/* 더보기 버튼 */}
      {moreBtnVisible && (
        <a className={cx('btnMore')} role="button" onClick={() => fetchNextPage()}>
          포지션 더보기
          <Icon name="addLight" width={24} height={24} />
        </a>
      )}
    </div>
  );
};

export default PositionList;
