import { AxiosResponse } from 'axios';
import { axios } from '@repository/RequestClient';
import RecommendKeywordListRs from '@domain/rs/search/RecommendKeywordListRs';
import SimilarKeywordListRs from '@domain/rs/search/SimilarKeywordListRs';
import CompanyListRs from '@domain/rs/search/CompanyListRs';

const globalSearchPrefix = '/search';

export default interface SearchRepo {
  //추천 키워드 조회
  fetchRecommendKeywordList(viewBenchmark: number, size: number): Promise<AxiosResponse<RecommendKeywordListRs>>;

  //유사 키워드 조회
  fetchSimilarKeywordList(keyword: string, size: number): Promise<AxiosResponse<SimilarKeywordListRs>>;

  //기업 목록 조회
  fetchSearchCompanyList(keyword: string): Promise<AxiosResponse<CompanyListRs>>;
}

export class RemoteSearchRepo implements SearchRepo {
  fetchRecommendKeywordList(viewBenchmark = 30, size = 4) {
    return axios.get<RecommendKeywordListRs>(`${globalSearchPrefix}/recommend/keyword`, {
      params: {
        viewBenchmark,
        size,
      },
    });
  }

  fetchSimilarKeywordList(keyword: string, positionSize = 6, companySize = 6) {
    return axios.get<SimilarKeywordListRs>(`${globalSearchPrefix}/v2/similar/keyword`, {
      params: {
        keyword,
        positionSize,
        companySize,
      },
    });
  }

  fetchSearchCompanyList(keyword: string) {
    return axios.get<CompanyListRs>(`/companies/search`, {
      params: {
        keyword,
      },
    });
  }
}
