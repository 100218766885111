import React, { FC } from 'react';
import TermLabel from './TermLabel';
import TermsVO from '@domain/vo/terms/TermsVO';
import classnames from 'classnames/bind';

import styles from './fileAttachmentTermsModal.module.scss';

const cx = classnames.bind(styles);

interface TermProps extends TermsVO {
  isChecked: boolean;
  onClick: () => void;
  isOpen: boolean;
  onOpen: () => void;
}

const Term: FC<TermProps> = ({ title, contents, isChecked, onClick, isOpen, onOpen }) => {
  return (
    <>
      <div className={cx('labelWrap')}>
        <TermLabel isChecked={isChecked} onClick={onClick}>
          {title}
        </TermLabel>
        <a className={cx('btnOpen', { open: isOpen })} role="button" onClick={onOpen} />
      </div>
      <div className={cx('agreementTextArea', { open: isOpen })}>
        <div
          className={cx('agreementText')}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: contents,
          }}
        />
      </div>
    </>
  );
};

export default Term;
