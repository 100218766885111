import React, { FC, useState } from 'react';
import Modal from '@common/modal';
import CloseButton from '@common/closeButton';
import Button from '@components/common/button';
import { usePositionAgreeInformation, useTerms } from '@repository/terms/useTerms';
import Term from './Term';
import TermsVO from '@domain/vo/terms/TermsVO';
import { positionTermsSortMap } from '@domain/constant/v1/termsType';
import useDebounce from '@utils/hooks/useDebounce';
import classnames from 'classnames/bind';
import styles from './fileAttachmentTermsModal.module.scss';

const cx = classnames.bind(styles);

const getInitialState = (status = false) => {
  return Array.from({ length: Object.keys(positionTermsSortMap).length }, () => status);
};

interface TermListProps {
  termList: TermsVO[];
  checkStatus: boolean[];
  onCheck: (args: { idx: number }) => void;
  openStatus: boolean[];
  onOpen: (args: { idx: number }) => void;
}

const TermList: FC<TermListProps> = ({ termList, checkStatus, onCheck, openStatus, onOpen }) => {
  return (
    <>
      {termList.map((term, idx) => (
        <div key={term.sn} className={cx('agreementWrap')}>
          <Term
            isChecked={checkStatus[idx]}
            {...term}
            onClick={() => onCheck({ idx })}
            isOpen={openStatus[idx]}
            onOpen={() => onOpen({ idx })}
          />
        </div>
      ))}
    </>
  );
};

interface FileAttachmentTermsModal {
  visible: boolean;
  onClose: () => void;
  onAgreeTerms: () => void;
}

const FileAttachmentTermsModal = ({ visible, onClose, onAgreeTerms }: FileAttachmentTermsModal) => {
  const { data: positionAgreeInformationData } = usePositionAgreeInformation();
  const { data: positionPrivacyPolicyTermsData } = useTerms({
    termType: 'POSITION_PRIVACY_POLICY',
    termRequired: true,
    options: {
      enabled: positionAgreeInformationData?.privacyPolicyYn === false,
    },
  });
  const { data: positionPrivacyProvisionTermsData } = useTerms({
    termType: 'POSITION_PRIVACY_PROVISION',
    termRequired: true,
    options: {
      enabled: positionAgreeInformationData?.privacyProvisionYn === false,
    },
  });

  const [checkStatus, setCheckStatus] = useState(getInitialState());

  const getCheckStatus = (status: boolean[]) => status.every((state) => state);

  const onSubmit = useDebounce(async () => {
    if (!getCheckStatus(checkStatus)) return;
    onAgreeTerms();
  }, 300);

  const onClickAll = () => {
    setCheckStatus(getInitialState(!getCheckStatus(checkStatus)));
  };

  const onCheck = ({ idx }: { idx: number }) => {
    setCheckStatus((prevCheckStatus) => {
      const postCheckStatus = prevCheckStatus;
      postCheckStatus[idx] = !prevCheckStatus[idx];
      return [...postCheckStatus];
    });
  };

  // 약관 open/close 관련 작업
  const [openStatus, setOpenStatus] = useState(getInitialState());
  const getOpenStatus = (status: boolean[]) => status.every(() => false);
  const onOpen = ({ idx }: { idx: number }) => {
    const postOpenStatus = getInitialState(getOpenStatus(openStatus));
    postOpenStatus[idx] = !openStatus[idx];
    setOpenStatus(postOpenStatus);
  };

  return (
    <Modal visible={visible} isUseScrollHidden={false}>
      <div className={cx('layerTerms')}>
        <CloseButton className={cx('btnClose')} onClick={onClose} />

        <strong className={cx('title')}>
          파일 제출을 위해
          <br />
          아래의 항목에 동의해 주세요.
        </strong>

        <div className={cx('agreementArea')}>
          <div className={cx('agreementWrap', 'all')}>
            <div className={cx('labelWrap')}>
              <label className={cx('label')}>
                <input
                  type="checkbox"
                  className={cx('input', 'hidden')}
                  checked={getCheckStatus(checkStatus)}
                  onChange={onClickAll}
                />
                <span className={cx('mark')} />
                아래의 사항에 대해 전체 동의합니다.
              </label>
            </div>
          </div>

          <TermList
            termList={[...(positionPrivacyPolicyTermsData ?? []), ...(positionPrivacyProvisionTermsData ?? [])]}
            checkStatus={checkStatus}
            onCheck={onCheck}
            openStatus={openStatus}
            onOpen={onOpen}
          />
        </div>

        <Button className={cx('btnAgreement')} onClick={onSubmit} disabled={!getCheckStatus(checkStatus)}>
          동의하고 제출하기
        </Button>
      </div>
    </Modal>
  );
};

export default FileAttachmentTermsModal;
