import React, { useCallback, useEffect } from 'react';
import { useAccordion } from '.';

import classnames from 'classnames/bind';
import styles from './index.module.scss';
const cx = classnames.bind(styles);

interface Props {
  children: React.ReactElement;
  duration?: number;
  className?: string;
  id: string;
}

const AnimationAccordion = ({ children, duration = 300, className, id }: Props) => {
  const { registerAccordion, accordions } = useAccordion();
  const contentRef = React.useRef<HTMLDivElement>(null);
  const [maxHeight, setMaxHeight] = React.useState(0);
  const visible = accordions[id]?.visible;

  const childrenElement = React.cloneElement(children as React.ReactElement, { contentRef, duration, id });

  const handleHeight = useCallback(() => {
    if (!contentRef.current) {
      setMaxHeight(0);
      return;
    }

    const totalHeight = Object.values(accordions).reduce((acc, cur) => {
      if (!cur.visible) return acc;
      return acc + (cur?.height || 0);
    }, 0);
    setMaxHeight(visible ? totalHeight : 0);
  }, [accordions, visible]);

  useEffect(() => {
    requestAnimationFrame(handleHeight);
  }, [visible, handleHeight]);

  useEffect(() => {
    if (!contentRef.current) {
      return;
    }

    registerAccordion(id, contentRef.current.clientHeight);
  }, [id, registerAccordion]);

  return (
    <div className={cx('animate', className)} style={{ maxHeight, transition: `max-height ${duration}ms ease-out` }}>
      {childrenElement}
    </div>
  );
};

export default AnimationAccordion;
