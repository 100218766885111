import React, { FC } from 'react';
import Icon from '@components/common/assets';
import Tooltip from '@components/common/tooltip';
import { useUserInfo } from '@repository/auth/useUserInfo';
import GraphInteraction from './graphInteraction';
import { GraphProps } from '..';
import Colors from '@domain/constant/colors';
import classnames from 'classnames/bind';
import styles from './percentage.module.scss';
const cx = classnames.bind(styles);

const Percentage: FC<GraphProps> = ({ data }) => {
  const { data: userInfo } = useUserInfo();
  if (!data) return null;
  return (
    <div className={cx('percentage')}>
      <span className={cx('percentageTitle')}>
        내 프로필과
        <br />
        역량검사 결과 기반으로 분석한
      </span>
      <span className={cx('percentageDesc')}>
        {userInfo?.name}님과
        <br />
        <span className={cx('infoArea')}>
          <span>우리가 잘 맞을 확률</span>
          <Tooltip
            content={
              <span>
                {userInfo?.name}님의 JOBDA 프로필 및 역량검사 정보로
                <br />
                기업의 선호 매칭 인재상과 얼마나 부합하는지
                <br />
                점수로 환산한 수치입니다.
              </span>
            }
            label={
              <a className={cx('icon')}>
                <Icon name="informationLight" width={20} height={20} fill={Colors.C_COOL_GRAY_70} />
              </a>
            }
            placement="bottom-left"
            theme="gray"
            left={-30}
            iconLeft={35}
          />
        </span>
      </span>

      {/* 퍼센트 + 그래프 */}
      <GraphInteraction />
      <span className={cx('percentageNumber')}>
        {data.matchingTotalScore.toFixed(1)}
        <span className={cx('percentageUnit')}>%</span>
      </span>
    </div>
  );
};

export default Percentage;
