import React from 'react';
import Upload, { BeforeUpload } from '@common/upload';
import Icon from '@common/assets';
import Text from '@components/common/text';
import { usePositionAgreeInformation, useTerms } from '@repository/terms/useTerms';
import FileRow from './FileRow';
import { FileAttachmentModalType } from '../fileAttachmentModals';
import { AttachFileDto } from '@domain/rs/match/message/MatchingMessageListRs';
import { allFileAllowedExtensions } from '@domain/constant/fileExtention';
import { FontStyle } from '@domain/constant/fontStyle';
import Colors from '@domain/constant/colors';
import FileUtil from '@utils/fileUtil';

import classnames from 'classnames/bind';
import styles from './fileTable.module.scss';
const cx = classnames.bind(styles);

interface FileTableProps {
  positionSn: number | null;
  filesState: File[];
  setFilesState: (filesState: File[] | ((filesState: File[]) => File[])) => void;
  isCompleted: boolean;
  attachFiles: AttachFileDto[];
  setFileAttachmentModalState: (fileAttachmentModalState: FileAttachmentModalType | null) => void;
  openYn: boolean;
  deleteYn: boolean;
}

const FileTable = ({
  positionSn,
  filesState,
  setFilesState,
  isCompleted,
  attachFiles,
  setFileAttachmentModalState,
  openYn,
  deleteYn,
}: FileTableProps) => {
  const { data: positionAgreeInformationData } = usePositionAgreeInformation();
  const { data: positionPrivacyPolicyTermsData } = useTerms({
    termType: 'POSITION_PRIVACY_POLICY',
    termRequired: true,
    options: {
      enabled: positionAgreeInformationData?.privacyPolicyYn === false,
    },
  });
  const { data: positionPrivacyProvisionTermsData } = useTerms({
    termType: 'POSITION_PRIVACY_PROVISION',
    termRequired: true,
    options: {
      enabled: positionAgreeInformationData?.privacyProvisionYn === false,
    },
  });

  const isTerms =
    (positionAgreeInformationData?.privacyPolicyYn === false ||
      positionAgreeInformationData?.privacyProvisionYn === false) &&
    (positionPrivacyPolicyTermsData?.length ?? 0) + (positionPrivacyProvisionTermsData?.length ?? 0) > 0;

  const isDisabled = isCompleted || !openYn || deleteYn;

  const handleUpload = (e: React.MouseEvent) => {
    if (isDisabled) return;

    // [D] 종료된 포지션
    if (!openYn) {
      e.preventDefault();
      setFileAttachmentModalState('END_POSITION_ERROR');
      return;
    }

    if (deleteYn) {
      e.preventDefault();
      setFileAttachmentModalState('DELETE_POSITION_ERROR');
    }

    // [D] 약관 동의 필요
    if (isTerms) {
      e.preventDefault();
      setFileAttachmentModalState('FILE_TERMS');
      return;
    }
  };

  const fileBeforeUpload: BeforeUpload = (file, files) => {
    return checkFileBeforeUpload(file, files, allFileAllowedExtensions, 50, 'MB');
  };

  const checkFileBeforeUpload = (
    file: File,
    files: File[],
    allowedExtensions: string[],
    limitSize: number,
    limitSizeUnit: 'KB' | 'MB',
  ) => {
    const filesStateSize = filesState.reduce((prev, file) => prev + file.size, 0);
    const fileSize = file.size;
    const isInvalidCount = filesState.length + files.length > 10;
    const isInvalidExt = !FileUtil.checkFileExtArr(
      { ...file, name: file.name.toLowerCase() } as File,
      allFileAllowedExtensions,
      true,
    );
    const isInValidSize = !FileUtil.checkFileSize(filesStateSize + fileSize, limitSize, limitSizeUnit);

    // 파일 개수 에러
    if (isInvalidCount) {
      setFileAttachmentModalState('FILE_COUNT_ERROR');
      return false;
    }

    // 파일 형식 에러
    if (isInvalidExt) {
      setFileAttachmentModalState('FILE_FORMAT_ERROR');
      return false;
    }

    // 빈 파일, 손상된 파일 에러
    if (!fileSize) {
      setFileAttachmentModalState('WRONG_FILE_ERROR');
      return false;
    }

    // 파일 용량 에러 (총 용량)
    if (isInValidSize) {
      setFileAttachmentModalState('FILE_CAPACITY_ERROR');
      return false;
    }

    // 추가 서류는 한번에 묶어서 제출
    setFilesState((prev: File[]) => [...prev, file]);
    return false;
  };

  // [D] 제출 완료한 파일 목록
  const submittedFileRows = attachFiles?.map((attachFileDto, index) => {
    const { fileName, fileUid } = attachFileDto;
    return (
      <FileRow
        key={index}
        index={index}
        positionSn={positionSn}
        isCompleted={true}
        setFilesState={setFilesState}
        setFileAttachmentModalState={setFileAttachmentModalState}
        fileName={fileName}
        fileUid={fileUid}
      />
    );
  });

  // [D] 제출전 파일 목록
  const filesRows = filesState.map((file, index) => {
    const { name } = file;
    return (
      <FileRow
        key={index}
        index={index}
        positionSn={positionSn}
        isCompleted={false}
        setFilesState={setFilesState}
        setFileAttachmentModalState={setFileAttachmentModalState}
        file={file}
        fileName={name}
      />
    );
  });

  return (
    <div className={cx('fileArea')}>
      <Text fontStyle={FontStyle.B2P_B} color={Colors.C_COOL_GRAY_90}>
        추가서류 업로드
      </Text>
      {isCompleted ? submittedFileRows : filesRows}
      <Upload
        name={'companyMessageFile'}
        value={[]}
        beforeUpload={fileBeforeUpload}
        onClick={handleUpload}
        disabled={isDisabled}
        multiple
      >
        <div className={cx('btnArea', { disabled: isDisabled })}>
          <Icon name="addLight" width={24} height={24} />
          <Text fontStyle={FontStyle.B3_B} color={Colors.C_COOL_GRAY_90}>
            파일 추가
          </Text>
        </div>
      </Upload>
      <Text fontStyle={FontStyle.B3P_M} color={Colors.C_COOL_GRAY_60} styles={{ marginTop: 8 }}>
        업로드 시 각 파일 당 50MB, 총 10개까지 업로드 가능합니다.
      </Text>
    </div>
  );
};
export default FileTable;
