import React from 'react';
import { MatchTalkMessageOptionDto } from '@domain/rs/match/message/MatchingMessageListRs';
import DecidedSchedules from './decidedSchedules';
import Schedules from './schedules';

export interface ScheduleProps extends Pick<MatchTalkMessageOptionDto, 'sn' | 'title' | 'disabled'> {
  decisionSn?: string;
  isChecked?: boolean;
  onClick?: (args: { sn: string; date: string }) => void;
}

interface SchedulesProps {
  interviewSn: string | null;
  decisionDueDateTime: string | null;
  options: ScheduleProps[];
  decision?: MatchTalkMessageOptionDto | null;
  onClick: (args: { sn: string; date: string }) => void;
  openModal: (type: string) => void;
}

const InterviewSchedules = ({
  decisionDueDateTime,
  options,
  decision,
  interviewSn,
  onClick,
  openModal,
}: SchedulesProps) => {
  // [D] 결정된 일정
  if (decision) return <DecidedSchedules interviewSn={interviewSn} decision={decision} options={options} />;

  // [D] 결정전 일정
  return (
    <Schedules
      interviewSn={interviewSn}
      options={options}
      onClick={onClick}
      openModal={openModal}
      decisionDueDateTime={decisionDueDateTime}
    />
  );
};

export default InterviewSchedules;
