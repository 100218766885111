import React, { FC, useEffect, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useRouter } from 'next/router';
import PageTopButton from '@components/common/pageTopButton';
import CurationFilter, { defaultCurationFilter } from '@components/position/list/curationFilter';
import PositionFilter from '@components/position/list/positionFilter';
import PositionList from '@components/position/list/positionList';
import CurationPositionList from '@components/position/list/positionList/CurationPositionList';
import RecommendPositionList from '@components/position/list/positionList/RecommendPositionList';
import CurationTitle from '@components/position/list/curationTitle';
import PositionListFilterVO from '@domain/vo/position/list/PositionListFilterVO';
import CurationFilterListVO from '@domain/vo/position/list/CurationFilterListVO';
import useUpdateEffect from '@utils/hooks/useUpdateEffect';
import SessionStorageUtil from '@utils/storage/sessionStorage';
import classnames from 'classnames/bind';
import styles from './positionListPage.module.scss';

const cx = classnames.bind(styles);

export const FILTER_STORAGE_KEY = 'positionListFilter';

interface ICurationContentsProps {
  vo: CurationFilterListVO;
}

const CurationContents: FC<ICurationContentsProps> = ({ vo }) => {
  const listEl =
    vo.type === 'RECOMMEND' ? (
      <RecommendPositionList />
    ) : (
      <>
        <PositionFilter isCuration={true} curationFilterSn={vo.sn} />
        <CurationPositionList vo={vo} />
      </>
    );

  return (
    <>
      <CurationTitle vo={vo} />
      {listEl}
    </>
  );
};

const AllPositionContents: FC = () => {
  return (
    <>
      <PositionFilter isCuration={false} />
      <PositionList />
    </>
  );
};

const Position: FC = () => {
  const router = useRouter();
  const [selectedCuration, setSelectedCuration] = useState<CurationFilterListVO>(defaultCurationFilter);
  const methods = useForm<PositionListFilterVO>({
    defaultValues: new PositionListFilterVO(),
  });
  const watchFilter = methods.watch();

  useUpdateEffect(() => {
    if (router.query.reload) return;
    SessionStorageUtil.setItem(FILTER_STORAGE_KEY, watchFilter);
  }, [watchFilter]);

  //reload query가 있을 경우 필터 초기화
  useEffect(() => {
    if (!router.query.reload) return;
    const filter = SessionStorageUtil.getItem<PositionListFilterVO>(FILTER_STORAGE_KEY);
    if (filter)
      //바뀌는 값만 넣어주면 다른 값들은 초기화되지 않음
      methods.reset({ ...new PositionListFilterVO(), ...filter });

    router.replace(
      {
        pathname: router.pathname,
      },
      undefined,
      { shallow: true },
    );
  }, [router]);

  //포지션 상세에서 나온 경우 필터 값 유지
  useEffect(() => {
    const filter = SessionStorageUtil.getItem<PositionListFilterVO>(FILTER_STORAGE_KEY);
    if (filter) {
      methods.reset({ ...new PositionListFilterVO(), ...filter });
    }
    router.replace(
      {
        pathname: router.pathname,
      },
      undefined,
      { shallow: true },
    );
  }, []);

  const contentsEl =
    selectedCuration.type === 'ALL' ? <AllPositionContents /> : <CurationContents vo={selectedCuration} />;

  return (
    <>
      <FormProvider {...methods}>
        <div className={cx('positionListArea')}>
          {/* [D] 큐레이션 필터 영역 */}
          <CurationFilter selectedCuration={selectedCuration} setSelectedCuration={setSelectedCuration} />
          {contentsEl}
        </div>
      </FormProvider>
      <PageTopButton />
    </>
  );
};

export default Position;
