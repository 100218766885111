import React, { FC } from 'react';
import Icon from '@components/common/assets';
import { VisionDto } from '@domain/rs/match/report/MatchingReportCompanyRs';
import ObjectUtil from '@utils/object/util';
import classnames from 'classnames/bind';
import styles from './vision.module.scss';
const cx = classnames.bind(styles);

interface VisionProps {
  data?: VisionDto;
}
const Vision: FC<VisionProps> = ({ data }) => {
  if (!data || ObjectUtil.isKeysValueAllEmpty(data, ['visionTitle', 'visionDetails'])) return null;

  return (
    <div className={cx('vision')}>
      <div className={cx('title')}>
        <Icon name="doubleQuotation" width={20} height={12} />
        <span>{data.visionTitle}</span>
      </div>
      <div className={cx('desc')}>{data.visionDetails}</div>
    </div>
  );
};

export default Vision;
