import React from 'react';
import { useRouter } from 'next/router';
import { useCompaniesEmployeeStories } from '@repository/companies/useCompanies';
import classnames from 'classnames/bind';
import styles from './cheerMessage.module.scss';
const cx = classnames.bind(styles);

const IMG_PATH = '/img/v2/components/company/sn/reviewBanner';

const BG_LENGTH = 12;

const getReviewBannerPng = (sn: number) => {
  const number = sn % BG_LENGTH;
  return `${IMG_PATH}/reviewBanner${number}.png`;
};

const CheerMessage = () => {
  const router = useRouter();
  const { sn } = router.query;
  const { data } = useCompaniesEmployeeStories(Number(sn));

  if (!data?.stories.length) return null;

  const isScroll = data?.stories.length > 3;
  const messageEls = data?.stories.map((story, idx) => {
    return (
      <li key={idx} className={cx('cheerMessageList')}>
        <p className={cx('message')}>{story.experience}</p>
        <div className={cx('writer')}>
          <span className={cx('jobDesc')}>{story.reviewText}</span>
        </div>
      </li>
    );
  });

  return (
    // 현직자이야기
    <div className={cx('snInner')}>
      {/* 영역 제목 */}
      <p className={cx('sectionTitle')}>현직자 이야기</p>

      {/* 응원 메세지 썸네일 */}
      <div className={cx('cheerMessageWrap')}>
        {/* [D] 기본 이미지 N개중 랜덤 노출 */}

        <img className={cx('randomImg')} src={getReviewBannerPng(Number(sn))} alt="Banner" />

        <p className={cx('title')}>함께할 동료가 말해주는 우리 회사 이야기</p>
      </div>

      {/* 응원 메세지 모음 */}
      <div className={cx('cheerMessageArea')}>
        <ul className={cx('scrollArea', { isScroll })}>{messageEls}</ul>
      </div>
    </div>
  );
};

export default CheerMessage;
