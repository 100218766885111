import { useQuery } from 'react-query';
import { RemoteAccRepo } from '@repository/acc/AccRepo';
import { AccProgressCompleteListVO } from '@domain/vo/acca/test/AccProgressCompleteListVO';
const accRepo = new RemoteAccRepo();

export const keys = ['acc', 'results', 'progress-complete'];

const fetchAccResultProgressComplete = async () => {
  const { data } = await accRepo.fetchAccResultsProgressComplete();

  return new AccProgressCompleteListVO(data);
};

export function useAccResultProgressComplete() {
  return useQuery(keys, fetchAccResultProgressComplete);
}
