import DateFormat from './../../../../utils/date/format/index';
import MatchingOfferPositionRs, {
  MatchingOfferPositionDto,
  MessageOption,
} from '@domain/rs/match/report/MatchingOfferPositionRs';
import { MatchingResponseTypeTextEnum } from '@domain/constant/match/report/ResponseType';
import DateUtil from '@utils/date/util';

export default class MatchingOfferPositionListVO {
  positions: MatchingOfferPositionVO[];

  constructor(rs: MatchingOfferPositionRs) {
    this.positions = rs.positions.map((position) => new MatchingOfferPositionVO(position));
  }
}

export class MatchingOfferPositionVO {
  sn: number; //포지션 순번
  name: string; //포지션 이름
  isToday: boolean; //오늘 마감 여부
  isAccepted: boolean; //수락 여부
  responseTypeText: string; //매칭 응답 타입 //null은 답변대기
  decisionDueDateTime: Date; //답변 마감일
  decisionDueDateTimeText: string; //답변 마감일
  options: MessageOptionVO[]; //매칭 제안 메세지 옵션 목록

  constructor(dto: MatchingOfferPositionDto) {
    this.sn = dto.sn;
    this.name = dto.name;
    this.isToday =
      !DateUtil.isPastDate(new Date(dto.decisionDueDateTime)) && DateUtil.isToday(new Date(dto.decisionDueDateTime));
    this.isAccepted = dto.responseType === 'ACCEPTED';
    this.responseTypeText = dto.responseType ? MatchingResponseTypeTextEnum[dto.responseType] : '답변 대기중';
    this.decisionDueDateTime = new Date(dto.decisionDueDateTime);
    this.decisionDueDateTimeText = DateFormat.formatPositionDDayText(new Date(dto.decisionDueDateTime));
    this.options = dto.options?.map((option) => new MessageOptionVO(option));
  }
}

export class MessageOptionVO {
  optionSn: number;
  optionCommand: 'ACCEPT' | 'REFUSE';

  constructor(dto: MessageOption) {
    this.optionSn = dto.optionSn;
    this.optionCommand = dto.optionCommand;
  }
}
