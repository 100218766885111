import TermsRs from '@domain/rs/terms/v1/TermsRs';

export default class TermsVO {
  contents: string;
  sn: number;
  title: string;
  version: number;
  isRequired: boolean;

  constructor(rs: TermsRs, isRequired?: boolean) {
    this.sn = rs.sn;
    this.title = isRequired ? (!rs.title.endsWith('(필수)') ? `${rs.title}(필수)` : rs.title) : `${rs.title}(선택)`;
    this.contents = rs.contents;
    this.version = rs.version;
    this.isRequired = isRequired || false;
  }
}
