import { useQuery } from 'react-query';
import { RemotePhsPaccRepo } from '@repository/phs/pacc/PhsPaccRepo';
import { PaccProgressCompleteListVO } from '@domain/vo/phs/pacc/results/PaccProgressCompleteListVO';

export const keys = ['phs', 'pacc', 'results', 'progress-complete'];

const remotePhsPaccRepo = new RemotePhsPaccRepo();

const fetchPHSPACCResultProgressComplete = async () => {
  const { data } = await remotePhsPaccRepo.fetchPHSPACCResultsProgressComplete();
  return new PaccProgressCompleteListVO(data);
};

export function usePHSPACCResultProgressComplete() {
  return useQuery(keys, fetchPHSPACCResultProgressComplete);
}
