import React, { FC } from 'react';
import classnames from 'classnames/bind';
import styles from './positionList.module.scss';
const cx = classnames.bind(styles);

const NoPositionData: FC = () => {
  return (
    <div className={cx('emptyArea')}>
      <strong className={cx('title')}>해당 조건의 포지션을 찾을 수 없어요.</strong>
      <p className={cx('text')}>필터 조건을 수정해보시는건 어때요?</p>
    </div>
  );
};

export default NoPositionData;
