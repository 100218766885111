import { MutableRefObject, useEffect, useState } from 'react';
import { ChartData, EDGE_SPACE, LineChartColorsType, LineChartSizeType, LineColors } from './data';
import { createScaler } from '@utils/chartUtil';
import classnames from 'classnames/bind';
import styles from './lineChart.module.scss';

const cx = classnames.bind(styles);

interface LineProps {
  data: ChartData[];
  type: LineChartSizeType;
  colors: LineChartColorsType;
  rows: string[];
  height: number;
  tableRef: MutableRefObject<HTMLDivElement | null>;
  trigger: boolean;
  axisMin: number;
  axisMax: number;
}

const Line = ({ data, type, colors, rows, height, tableRef, trigger, axisMin, axisMax }: LineProps) => {
  const getRowWidth = () => {
    if (tableRef.current) {
      return tableRef.current?.clientWidth / (rows.length - 1 + EDGE_SPACE[type] * 2);
    }
    return 0;
  };

  const scaleY = createScaler(0, height, axisMin, axisMax);
  const getX = (value: number | null) => {
    return EDGE_SPACE[type] * getRowWidth() + (value ?? 0) * getRowWidth();
  };
  const getY = (value: number | null) => {
    return height - scaleY(value ?? 0);
  };
  const getChar = (value: number) => (value === data.length - 1 ? '' : 'L');
  const getPoint = (x: number | null, y: number | null) => `${getX(x)} ${getY(y)}`;
  const stroke = LineColors[colors][type];
  const [lineData, setLineData] = useState('');

  useEffect(() => {
    const updateSize = () => {
      setLineData(getLineData(data));
    };
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, [data, tableRef.current?.clientWidth]);

  const getLineData = (data: ChartData[]) => {
    if (!data.length) return '';
    return data.reduce(
      (prev, { column }, i) => (column === null ? prev : `${prev} ${getPoint(i, column)} ${getChar(i)}`),
      'M',
    );
  };

  const getDataLineEl = () => {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" fill="none">
        <path d={lineData} stroke={stroke} />
      </svg>
    );
  };

  return <div className={cx('lineArea', { animation: trigger })}>{getDataLineEl()}</div>;
};

export default Line;
