import React, { ReactElement, useRef } from 'react';
import { CompanyInfoTitleType, CompanyInfoTitleTypeTextMap } from '@domain/constant/companyInfo';
import classnames from 'classnames/bind';
import styles from './infoListItem.module.scss';
const cx = classnames.bind(styles);

interface InfoItemProps {
  type: CompanyInfoTitleType;
  labelText?: string;
  title: ReactElement | string;
}

const InfoListItem = ({ type, labelText, title }: InfoItemProps) => {
  const titleRef = useRef<HTMLDivElement | null>(null);

  return (
    <li className={cx('listItem', `${type}`)}>
      <span className={cx('itemText')}>{labelText ?? CompanyInfoTitleTypeTextMap[type]}</span>
      <span ref={titleRef} className={cx('itemTitle')}>
        {title}
      </span>
    </li>
  );
};

export default InfoListItem;
