import React, { ChangeEvent, FC, useEffect, useRef, useState } from 'react';
import Icon from '@components/common/assets';
import useSimilarKeyword from '@components/search/query/useSimilarKeyword';
import { SimilarKeywordDto } from '@domain/rs/search/SimilarKeywordListRs';
import { V2Route } from '@utils/routeUtil';
import useReactQueryDebounce from '@utils/hooks/useReactQueryDebounce';
import useClickAway from '@utils/hooks/useClickOutside';

import { PATTERN } from '@utils/validators/pattern';
import classnames from 'classnames/bind';
import styles from './searchPage.module.scss';
const cx = classnames.bind(styles);

interface ICompanySimilarKeywordProps {
  keyword: string;
  similarKeyword: string;
  isHighLight: boolean;
}

const CompanySimilarKeyword: FC<ICompanySimilarKeywordProps> = ({ keyword, similarKeyword, isHighLight }) => {
  const handleKeywordSymbol = keyword.replace(PATTERN.SEARCH_SPECIAL_SYMBOL, '\\$&');
  const regex = new RegExp(`${handleKeywordSymbol}`, 'g');
  const emphasisKeyword = similarKeyword.replace(regex, `<span class=${cx('emphasis')}>${keyword}</span>`);

  return (
    <li className={cx('listItem')}>
      <a
        href={V2Route.getSearchPage(similarKeyword)}
        className={cx('link', 'company', { isHighLight })}
        dangerouslySetInnerHTML={{
          __html: emphasisKeyword,
        }}
        rel="noreferrer"
      />
    </li>
  );
};

interface IPositionSimilarKeywordProps {
  keyword: string;
  similarKeyword: SimilarKeywordDto;
  isHighLight: boolean;
}

const PositionSimilarKeyword: FC<IPositionSimilarKeywordProps> = ({ keyword, similarKeyword, isHighLight }) => {
  const handleKeywordSymbol = keyword.replace(PATTERN.SEARCH_SPECIAL_SYMBOL, '\\$&');
  const regex = new RegExp(`${handleKeywordSymbol}`, 'g');
  const { positionName } = similarKeyword;
  const emphasisKeyword = positionName.replace(regex, `<span class=${cx('emphasis')}>${keyword}</span>`);

  return (
    <li className={cx('listItem')}>
      <a
        href={V2Route.getSearchPage(positionName)}
        className={cx('link', { isHighLight })}
        dangerouslySetInnerHTML={{
          __html: emphasisKeyword,
        }}
        rel="noreferrer"
      />
    </li>
  );
};

interface IKeywordFilterProps {
  queryKeyword?: string;
  autoVisible?: boolean;
  shouldFocusOnMount?: boolean;
}

const HIGHLIGHT_INDEX_DEFAULT = -1;

const KeywordFilter: FC<IKeywordFilterProps> = ({
  queryKeyword = '',
  autoVisible = false,
  shouldFocusOnMount = true,
}) => {
  const [keyword, setKeyword] = useState(queryKeyword);
  const [currentKeyword, setCurrentKeyword] = useState(keyword);
  const [highlightIndex, setHighlightIndex] = useState(HIGHLIGHT_INDEX_DEFAULT);
  const debouncedSearchQuery = useReactQueryDebounce(keyword, 500);

  const [autoCompleteListVisible, setAutoCompleteListVisible] = useState(false);
  const keywordAreaRef = useRef<HTMLDivElement>(null);
  const keywordInputRef = useRef<HTMLInputElement>(null);

  const { data, isFetched } = useSimilarKeyword(debouncedSearchQuery);

  const positionKeywordListEl =
    data?.positionKeywords.map((item, idx) => {
      const isHighLight = idx + (data?.companyKeywords.length || 0) === highlightIndex;
      return <PositionSimilarKeyword key={idx} keyword={keyword} similarKeyword={item} isHighLight={isHighLight} />;
    }) ?? [];
  const companyKeywordListEl =
    data?.companyKeywords.map((item, idx) => {
      const isHighLight = idx === highlightIndex;
      return <CompanySimilarKeyword key={idx} keyword={keyword} similarKeyword={item} isHighLight={isHighLight} />;
    }) ?? [];

  const getHighlightKeyword = (index: number) => {
    if (!data) return keyword;

    const { positionKeywords, companyKeywords } = data;

    if (highlightIndex < companyKeywords.length) {
      return companyKeywords[index];
    } else {
      return positionKeywords[index - companyKeywords.length]?.positionName;
    }
  };

  const onCheckKeyDown = (e: React.KeyboardEvent) => {
    if (!data) return null;

    const enterKey = ['NumpadEnter', 'Enter'];
    const arrowKey = ['ArrowUp', 'ArrowDown'];
    const { positionKeywords, companyKeywords } = data;
    const keywordListLength = positionKeywords.length + companyKeywords.length;

    const highlightKeyword = getHighlightKeyword(highlightIndex) ?? keyword;

    // 한글 입력시 composing상태가 되어 마지막에 composing이 종료되며 한 번더 keyDown 이벤트가 발생해 막아주기 위해 추가.
    if (e.nativeEvent.isComposing) return;

    const handleSearchPage = () => {
      if (currentKeyword !== highlightKeyword) {
        setCurrentKeyword(highlightKeyword);
      } else if (highlightIndex !== HIGHLIGHT_INDEX_DEFAULT) {
        V2Route.goSearchPage(currentKeyword);
      } else {
        V2Route.goSearchPage(keyword);
      }
    };

    const handleArrowUp = () => {
      // 키워드 리스트 첫 번째인 경우, 검색 후 화살표 위 키를 누른 경우 마지막 키워드에 하이라이트
      if (highlightIndex <= 0) setHighlightIndex(keywordListLength - 1);
      else setHighlightIndex(highlightIndex - 1);
    };

    const handleArrowDown = () => {
      // 마지막 키워드에서 화살표 아래 키를 누른 경우 첫번째 키워드에 하이라이트
      if (highlightIndex === keywordListLength - 1) setHighlightIndex(0);
      else setHighlightIndex(highlightIndex + 1);
    };

    if (enterKey.includes(e.code)) {
      e.preventDefault();
      handleSearchPage();
    } else if (arrowKey.includes(e.code)) {
      switch (e.code) {
        case 'ArrowUp':
          handleArrowUp();
          return;
        case 'ArrowDown':
          handleArrowDown();
          return;
      }
    }
  };

  const onChangeKeyword = (e: ChangeEvent<HTMLInputElement>) => {
    setKeyword(e.target.value);
    setCurrentKeyword(e.target.value);
    setHighlightIndex(HIGHLIGHT_INDEX_DEFAULT);
    if (!autoVisible) {
      setAutoCompleteListVisible(true);
    }
  };

  useEffect(() => {
    //검색창 열릴 경우 input에 자동 focus 가도록 함
    if (shouldFocusOnMount) {
      keywordInputRef.current?.focus();
    }
  }, []);

  useClickAway(() => {
    setAutoCompleteListVisible(false);
  }, keywordAreaRef);

  useEffect(() => {
    //검색값 저장
    setCurrentKeyword(queryKeyword);
  }, [queryKeyword]);

  return (
    <div className={cx('inputWrap')} ref={keywordAreaRef}>
      <Icon name="searchLight" width={32} height={32} />
      <input
        type="text"
        className={cx('input')}
        placeholder={'기업, 포지션명을 검색해보세요.'}
        value={currentKeyword}
        onChange={onChangeKeyword}
        onKeyDown={onCheckKeyDown}
        onFocus={() => setAutoCompleteListVisible(true)}
        ref={keywordInputRef}
      />
      {autoCompleteListVisible && isFetched && (!!data?.companyKeywords.length || !!data?.positionKeywords.length) && (
        <div className={cx('autoCompleteArea')}>
          {!!data?.companyKeywords.length && <ul className={cx('autoCompleteList')}>{companyKeywordListEl}</ul>}
          {!!data?.positionKeywords.length && <ul className={cx('autoCompleteList')}>{positionKeywordListEl}</ul>}
        </div>
      )}
    </div>
  );
};

export default KeywordFilter;
