import React, { FC } from 'react';

import classnames from 'classnames/bind';
import styles from './fileAttachmentTermsModal.module.scss';

const cx = classnames.bind(styles);

interface TermLabelProps {
  children: React.ReactNode;
  isChecked: boolean;
  onClick: () => void;
}

const TermLabel: FC<TermLabelProps> = ({ children, isChecked, onClick }) => {
  return (
    <label className={cx('label')}>
      <input type="checkbox" className={cx('input', 'hidden')} checked={isChecked} readOnly={true} onClick={onClick} />
      <span className={cx('mark')} />
      {children}
    </label>
  );
};

export default TermLabel;
