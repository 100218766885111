import { AccProgressCompleteDto, AccProgressCompleteRs } from '@domain/rs/acca/test/AccProgressCompleteRs';
import { AnalysisType } from '@domain/constant/analysisType';
import { ResultType, resultTypeCamelCaseMap } from '@domain/constant/resultType';
import DateFormat from '@utils/date/format';

export class AccProgressCompleteVO {
  sn: number;
  code: string;
  result: string;
  applyEndDateTime: string;
  matchingUseYn: boolean;

  constructor(dto: AccProgressCompleteDto) {
    this.sn = dto.sn;
    this.code = dto.code;
    this.result = getAccResult(dto.analysisType, dto.resultType);
    this.applyEndDateTime = DateFormat.format(new Date(dto.applyEndDateTime), 'yyyy.MM.dd HH:mm');
    this.matchingUseYn = dto.matchingUseYn;
  }
}

const getAccResult = (analysisType: AnalysisType, resultType: ResultType) => {
  if (analysisType === 'FAILED') return '분석 실패';
  else if (analysisType === 'PROGRESS') return '분석중';

  return resultTypeCamelCaseMap[resultType];
};

export class AccProgressCompleteListVO {
  resultRecords: AccProgressCompleteVO[];

  constructor(rs: AccProgressCompleteRs) {
    this.resultRecords = rs.resultRecords.map((resultRecord) => new AccProgressCompleteVO(resultRecord));
  }
}
