import { AxiosResponse } from 'axios';
import { axios } from '@repository/RequestClient';
import { PaccFeedbackSaveRq } from '@domain/rq/phs/pacc/result/PaccFeedbackSaveRq';
import { PaccGameResultRs } from '@domain/rs/phs/pacc/results/PaccGameResultRs';
import { PaccApplyResultRs } from '@domain/rs/phs/pacc/results/PaccApplyResultRs';
import { PaccSelfReportResultRs } from '@domain/rs/phs/pacc/results/PaccSelfReportResultRs';
import { PaccApplyTipRs } from '@domain/rs/phs/pacc/results/PaccApplyTipRs';
import { PaccVideoResultRs } from '@domain/rs/phs/pacc/results/PaccVideoResultRs';
import { PaccCompanyCurationResultRs } from '@domain/rs/phs/pacc/competence/PaccCompanyCurationResultRs';
import { PaccJobCurationResultRs } from '@domain/rs/phs/pacc/competence/PaccJobCurationResultRs';
import { PaccProfileResultRs } from '@domain/rs/phs/pacc/competence/PaccProfileResultRs';
import { PaccProgressCompleteRs } from '@domain/rs/phs/pacc/results/PaccProgressCompleteRs';

const phsPaccResultPrefix = '/phs/pacc/results';
const phsPaccCompetencePrefix = '/phs/pacc/competence';

export default interface PhsPaccRepo {
  /** 응시 분석결괴 **/
  // 전략 게임 응시 결과
  fetchPHSPACCResultsGame(sn: number): Promise<AxiosResponse<PaccGameResultRs>>;

  // 이전 응시완료 결과 목록
  fetchPHSPACCResultsProgressComplete(): Promise<AxiosResponse<PaccProgressCompleteRs>>;

  // 종합 응시 결과, 과정별 피드백
  fetchPHSPACCResultsResult(sn: number): Promise<AxiosResponse<PaccApplyResultRs>>;

  // 성향 파악 응시 결과
  fetchPHSPACCResultsSelfReport(sn: number): Promise<AxiosResponse<PaccSelfReportResultRs>>;

  // 맞춤형 꿀팁
  fetchPHSPACCResultsTip(sn: number): Promise<AxiosResponse<PaccApplyTipRs>>;

  // 영상 면접 응시 결과
  fetchPHSPACCResultsVideo(sn: number): Promise<AxiosResponse<PaccVideoResultRs>>;

  /** 역량 분석결과 **/
  // 기업 문화 추천
  fetchPHSPACCCompetenceCurationCompany(sn: number): Promise<AxiosResponse<PaccCompanyCurationResultRs>>;

  // 개발 직무 추천
  fetchPHSPACCCompetenceCurationJob(sn: number): Promise<AxiosResponse<PaccJobCurationResultRs>>;

  //역량 프로피일 & 면접 예상 질문 및 가이드
  fetchPHSPACCCompetenceProfile(sn: number): Promise<AxiosResponse<PaccProfileResultRs>>;

  /** 피드백 **/
  // 피드백 작성 완료 여부
  fetchPHSPACCResultsFeedbackExists(sn: number): Promise<AxiosResponse<boolean>>;

  // 피드백 저장
  fetchPHSPACCResultsFeedback(rq: PaccFeedbackSaveRq): Promise<AxiosResponse<boolean>>;

  // 매칭에 사용될 결과표 선택
  fetchPHSPACCResultsSelectMatching(applySn: number): Promise<AxiosResponse>;
}

export class RemotePhsPaccRepo implements PhsPaccRepo {
  /** 응시 분석결괴 **/
  fetchPHSPACCResultsGame(sn: number): Promise<AxiosResponse<PaccGameResultRs>> {
    return axios.get(`${phsPaccResultPrefix}/game`, { params: { sn } });
  }

  fetchPHSPACCResultsProgressComplete(): Promise<AxiosResponse<PaccProgressCompleteRs>> {
    return axios.get(`${phsPaccResultPrefix}/progress-complete`);
  }

  fetchPHSPACCResultsResult(sn: number): Promise<AxiosResponse<PaccApplyResultRs>> {
    return axios.get(`${phsPaccResultPrefix}/result`, { params: { sn } });
  }

  fetchPHSPACCResultsSelfReport(sn: number): Promise<AxiosResponse<PaccSelfReportResultRs>> {
    return axios.get(`${phsPaccResultPrefix}/self-report`, { params: { sn } });
  }

  fetchPHSPACCResultsTip(sn: number): Promise<AxiosResponse<PaccApplyTipRs>> {
    return axios.get(`${phsPaccResultPrefix}/tip`, { params: { sn } });
  }

  fetchPHSPACCResultsVideo(sn: number): Promise<AxiosResponse<PaccVideoResultRs>> {
    return axios.get(`${phsPaccResultPrefix}/video`, { params: { sn } });
  }

  /** 역량 분석결과 **/
  fetchPHSPACCCompetenceCurationCompany(sn: number): Promise<AxiosResponse<PaccCompanyCurationResultRs>> {
    return axios.get(`${phsPaccCompetencePrefix}/curation/company`, { params: { sn } });
  }

  fetchPHSPACCCompetenceCurationJob(sn: number): Promise<AxiosResponse<PaccJobCurationResultRs>> {
    return axios.get(`${phsPaccCompetencePrefix}/curation/job`, { params: { sn } });
  }

  fetchPHSPACCCompetenceProfile(sn: number): Promise<AxiosResponse<PaccProfileResultRs>> {
    return axios.get(`${phsPaccCompetencePrefix}/profile`, { params: { sn } });
  }

  /** 피드백 **/
  fetchPHSPACCResultsFeedbackExists(sn: number): Promise<AxiosResponse<boolean>> {
    return axios.get(`${phsPaccResultPrefix}/feedback/exists`, { params: { sn } });
  }

  fetchPHSPACCResultsFeedback(rq: PaccFeedbackSaveRq): Promise<AxiosResponse<boolean>> {
    return axios.post(`${phsPaccResultPrefix}/feedback`, rq);
  }

  fetchPHSPACCResultsSelectMatching(applySn: number): Promise<AxiosResponse> {
    return axios.put(`${phsPaccResultPrefix}/select-matching`, { applySn });
  }
}
