import { FC, useState } from 'react';
import { useGetCheckConsent, useGetResumeNumber } from '@repository/match';
import { useResumeSite } from '@repository/resume/useResume';
import AgreementModal from '../agreementModal';
import PreviewModal from '../previewModal';
import ResumeSiteRs from '@domain/rs/resume/ResumeSiteRs';
import { V2Route } from '@utils/routeUtil';

import classnames from 'classnames/bind';
import styles from './resumeBtn.module.scss';
const cx = classnames.bind(styles);

type ApplyModalType = 'RESUME_PREVIEW' | 'RESUME_AGREEMENT' | null;

interface Props {
  positionSn: number | null;
}

const SubmitResumeBtn: FC<Props> = ({ positionSn }) => {
  const [modalState, setModalState] = useState<ApplyModalType>(null);
  const [resumesViewUrl, setResumesViewUrl] = useState('');
  const isVisibleModal = (modalType: ApplyModalType) => modalType === modalState;
  const handleClose = () => setModalState(null);

  const { mutateAsync: getResumeNumberMutateAsync } = useGetResumeNumber(); // 지원서 번호 조회

  const { mutate: fetchResumeSiteMutate } = useResumeSite(async (data: ResumeSiteRs) => {
    if (!positionSn) return;

    const resumeNumber = await getResumeNumberMutateAsync(positionSn);
    if (!resumeNumber) return;

    setResumesViewUrl(V2Route.getViewResumePage(data.url, resumeNumber));
    setModalState('RESUME_PREVIEW');
  });

  const { mutate: fetchCheckConsent } = useGetCheckConsent((result: boolean) => {
    if (result) {
      if (!positionSn) return;
      // 동의 상태라면 바로 지원서 띄워주기
      fetchResumeSiteMutate(positionSn);
    } else {
      // 비동의 상태라면 동의문 모달 띄워주기
      setModalState('RESUME_AGREEMENT');
    }
  }); // 동의 여부 확인하기

  if (!positionSn) return null;

  const onClickBtn = () => {
    fetchCheckConsent(positionSn);
  };

  return (
    <>
      <a className={cx('resumeBtnArea', `btnImg${'Submit'}`)} role="button" onClick={onClickBtn}>
        제출 지원서 확인하기
      </a>

      {/* [D] 지원서 확인을 위한 동의 모달 */}
      <AgreementModal positionSn={positionSn} visible={isVisibleModal('RESUME_AGREEMENT')} onClose={handleClose} />
      {/* [D] 지원서 확인하기 모달 */}
      <PreviewModal visible={isVisibleModal('RESUME_PREVIEW')} previewUrl={resumesViewUrl} onClose={handleClose} />
    </>
  );
};

export default SubmitResumeBtn;
