import { useQuery } from 'react-query';
import { RemoteSearchRepo } from '@repository/serch/SearchRepo';
import SimilarKeywordListRs from '@domain/rs/search/SimilarKeywordListRs';

const remoteSearchRepo = new RemoteSearchRepo();

const defaultRsValue: SimilarKeywordListRs = {
  positionKeywords: [],
  companyKeywords: [],
};

const fetchSimilarKeywords = async (keyword: string) => {
  if (keyword) {
    const { data } = await remoteSearchRepo.fetchSimilarKeywordList(keyword);
    return data;
  } else {
    //키워드 미입력 상테에서는 미표시 처리
    return defaultRsValue;
  }
};

export default function useSimilarKeyword(keyword: string) {
  return useQuery(['similarKeyword', 'list', keyword], () => fetchSimilarKeywords(keyword), {
    initialData: defaultRsValue,
  });
}
