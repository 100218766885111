import React, { useEffect } from 'react';
import KeywordFilter from '@components/search/KeywordFilter';
import RecommendTagList from '@components/search/searchModal/RecommendTagList';
import RecommendMenuList from '@components/search/searchModal/RecommendMenuList';
import RecommendPositionList from '@components/search/searchModal/RecommendPositionList';
import { useUserInfo } from '@repository/auth/useUserInfo';
import PersonalFitKeyword from './PersonalFitKeyword';
import { bodyScrollHidden } from '@utils/common';
import classnames from 'classnames/bind';
import styles from './searchModal.module.scss';
const cx = classnames.bind(styles);

interface SearchModalProps {
  onClose: () => void;
}

const SearchModal = ({ onClose }: SearchModalProps) => {
  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      onClose();
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    bodyScrollHidden(true);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      bodyScrollHidden(false);
    };
  }, []);

  const { data: userInfo } = useUserInfo();
  const isLogin = !!userInfo?.id;
  const title = isLogin ? '나에게 맞는 추천 키워드' : '추천 키워드';

  return (
    <div className={cx('searchModal')}>
      <div className={cx('searchArea')}>
        <div className={cx('inputArea')}>
          <div className={cx('inputInner')}>
            <KeywordFilter />

            <div className={cx('searchInner')}>
              {/* 개인화 맞춤 키워드 */}
              <div className={cx('personalKeyword')}>
                <p className={cx('title')}>{title}</p>
                <PersonalFitKeyword onClose={onClose} />
              </div>
              {/* 인기 검색어 */}
              <div className={cx('popularList')}>
                <div className={cx('popularCompany')}>
                  <p className={cx('title')}>인기 기업</p>
                  <RecommendTagList />
                </div>
                <div className={cx('popularPosition')}>
                  <p className={cx('title')}>인기 포지션</p>
                  <RecommendPositionList />
                </div>
              </div>
              {/* 잡다 추천 키워드 */}
              <div className={cx('recommendMenu')}>
                <p className={cx('title')}>잡다 추천 서비스</p>
                <RecommendMenuList />
              </div>
            </div>
          </div>
          <a className={cx('btnClose')} role="button" onClick={onClose}>
            <span className={cx('hidden')}>close</span>
          </a>
        </div>
      </div>
    </div>
  );
};

export default SearchModal;
