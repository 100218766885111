import { FormProvider, useForm } from 'react-hook-form';
import PositionFilter from './positionFilter';
import PositionList from './positionList';
import PositionListFilterVO from '@domain/vo/search/PositionListFilterVO';

const PositionTab = () => {
  const methods = useForm<PositionListFilterVO>({
    defaultValues: new PositionListFilterVO(),
  });

  return (
    <FormProvider {...methods}>
      {/* 필터 */}
      <PositionFilter />
      {/* 공고 리스트 */}
      <PositionList />
    </FormProvider>
  );
};

export default PositionTab;
