import JobTitleRs from '@domain/rs/code/JobTitleRs';
import { RecruitCategoryType } from '@domain/constant/recruitCategoryType';
import { LocationType } from '@domain/constant/locationType';
import { PositionOrderType } from '@domain/constant/positionOrderType';

export default class CompanyPositionFilterVO {
  jobTitles: JobTitleRs[] = [];
  recruitments: RecruitCategoryType[] = [];
  minSalary: number | null = null;
  maxSalary: number | null = null;
  locations: LocationType[] = [];
  matchingYn = false;
  orderType: PositionOrderType = 'POPULAR';

  constructor(vo?: CompanyPositionFilterVO) {
    if (!vo) return;

    this.jobTitles = vo.jobTitles;
    this.recruitments = vo.recruitments;
    this.minSalary = vo.minSalary;
    this.maxSalary = vo.maxSalary;
    this.locations = vo.locations;
    this.matchingYn = vo.matchingYn;
    this.orderType = vo.orderType;
  }
}
