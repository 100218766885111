import { useState } from 'react';
import ErrorModal from '@components/common/header/messageModal/messages/companyMessage/resultRequest/resultModals/errorModal';
import ResultSelectModal from './resultSelectModal';
import ResultSelectConfirmModal from './resultSelectConfirmModal';
import { resultModalStateType } from '../phsRequest';
import { AccProgressCompleteVO } from '@domain/vo/acca/test/AccProgressCompleteListVO';
import { PaccProgressCompleteVO } from '@domain/vo/phs/pacc/results/PaccProgressCompleteListVO';
import { PstProgressCompleteVO } from '@domain/vo/phs/pst/result/PstProgressCompleteListVO';
import { MatchingTalkApplyType } from '@domain/rs/match/message/MatchingMessageListRs';

export const getResultTypeText = (resultType: MatchingTalkApplyType | null) => {
  switch (resultType) {
    case 'ACC':
      return '역량검사';
    case 'PACC':
      return '개발자 역량검사';
    case 'PST':
      return '개발 구현 능력 검사';
  }
};

export const moveToResults = (resultType: MatchingTalkApplyType | null, resultSn: number) => {
  switch (resultType) {
    case 'ACC':
      return `/acca/result/${resultSn}`;
    case 'PACC':
      return `/phs/pacc/result/${resultSn}`;
    case 'PST':
      return `/phs/pst/result/${resultSn}`;
    default:
      return '/';
  }
};

export const getBadgeColor = (result: string) => {
  switch (result) {
    case 'Advanced High':
    case 'Advanced Mid':
    case 'Advanced Low':
    case 'Very Good':
      return 'yellow';
    case 'Intermediate High':
    case 'Intermediate Mid':
    case 'Intermediate Low':
    case 'Good':
      return 'silver';
    case 'Basic High':
    case 'Basic Low':
    case 'Try Again':
    case 'Cheer Up':
      return 'brown';
  }
};

interface ResultModalsProps {
  companySn: number | null;
  modalConfirm: () => void;
  modalClose: () => void;
  modalCancel: () => void;
  resultsData: AccProgressCompleteVO[] | PaccProgressCompleteVO[] | PstProgressCompleteVO[] | null;
  resultType: MatchingTalkApplyType | null;
  resultModalState: resultModalStateType;
  submitResult: (selectedResultSn: number) => void;
}

const ResultModals = ({
  companySn,
  modalConfirm,
  modalClose,
  modalCancel,
  resultsData,
  resultType,
  resultModalState,
  submitResult,
}: ResultModalsProps) => {
  const [selectedResultSn, setSelectedResultSn] = useState<number | null>(null);

  const isVisible = (currentState: resultModalStateType) => currentState === resultModalState;

  const selectedResult = resultsData?.filter((result) => result.sn === selectedResultSn)[0] || null;

  if (resultModalState === null || !companySn) return null;

  return (
    <>
      <ResultSelectModal
        isVisible={isVisible('SELECT')}
        resultType={resultType}
        resultsData={resultsData}
        selectedResultSn={selectedResultSn}
        setSelectedResultSn={setSelectedResultSn}
        onClose={modalClose}
        onConfirm={modalConfirm}
      />
      <ResultSelectConfirmModal
        companySn={companySn}
        isVisible={isVisible('CONFIRM')}
        resultType={resultType}
        selectedResult={selectedResult}
        onClose={modalClose}
        onCancel={modalCancel}
        onConfirm={submitResult}
      />
      {/* 에러 모달 */}
      <ErrorModal isVisible={isVisible('ERROR')} onConfirm={modalClose} />
    </>
  );
};

export default ResultModals;
