import { CurationFilterDto, IconFileDto } from '@domain/rs/position/list/CurationFilterListRs';

type CurationFilterType = 'ALL' | 'RECOMMEND' | null;

export class IconFileVO {
  fileUid: string;
  fileName: string;
  fileSize: number;
  fileUrl: string;

  constructor(rs: IconFileDto) {
    this.fileUid = rs.fileUid;
    this.fileName = rs.fileName;
    this.fileSize = rs.fileSize;
    this.fileUrl = rs.fileUrl;
  }
}

export default class CurationFilterListVO {
  sn?: number;
  title?: string;
  definition?: string;
  description?: string;
  priority?: number;
  openYn?: boolean;
  iconAttachFile?: IconFileVO;
  type: CurationFilterType;

  constructor(rs?: CurationFilterDto, type: CurationFilterType = null) {
    this.type = type;

    if (!rs) return;

    this.sn = rs.sn;
    this.title = rs.title;
    this.definition = rs.definition;
    this.description = rs.description;
    this.priority = rs.priority;
    this.openYn = rs.openYn;
    this.iconAttachFile = new IconFileVO(rs.iconAttachFile);
  }
}
