import { FC } from 'react';
import { useToast } from '@common/toast';
import { useMessageStore } from '@stores/MessageStore';
import ApplyMethodChangeInfoModal from '../applyMethodChangeInfoModal';
import useModal from '@utils/hooks/useModal';
import classnames from 'classnames/bind';
import styles from './resumeBtn.module.scss';
const cx = classnames.bind(styles);

interface Props {
  positionSn: number | null;
  beforeOpeningYn: boolean;
}

const WritingResumeBtn: FC<Props> = ({ positionSn, beforeOpeningYn }) => {
  const messagesInfo = useMessageStore((state) => state.messagesInfo);
  const Toast = useToast();
  const { closeModal, isModalOpen, openModal } = useModal();

  if (!messagesInfo) return null;
  const { openYn, deleteYn, endDateTime, deleteDateTime, closingYn } = messagesInfo;
  const isNotice = !openYn || deleteYn || closingYn;

  const viewToastError = () => {
    const positionDelete = deleteYn && deleteDateTime;
    const positionEnd = !openYn && endDateTime;
    let content = '';

    if (positionDelete) {
      content = '포지션이 삭제되었습니다.';
    } else if (positionEnd) {
      content = '포지션이 종료되었습니다.';
    } else content = '포지션이 마감되었습니다.';
    return Toast({ type: 'error', iconType: 'info', content: content, message: 'message' });
  };

  if (!positionSn) return null;

  const handleClickWritingResumeBtn = async () => {
    if (isNotice) {
      viewToastError();
    } else if (beforeOpeningYn) {
      Toast({
        type: 'result',
        size: 'content',
        iconType: 'info',
        content: '지원하신 포지션의 시작일이 변경됐습니다. 해당 일자 이후에 확인하실 수 있습니다.',
      });
    } else {
      openModal();
    }
  };

  return (
    <>
      <a className={cx('resumeBtnArea', `btnImg${'Writing'}`)} role="button" onClick={handleClickWritingResumeBtn}>
        작성 중인 지원서 확인하기
      </a>

      {/* 지원 방식 변경 안내 모달 */}
      <ApplyMethodChangeInfoModal visible={isModalOpen} onClose={closeModal} positionSn={positionSn} />
    </>
  );
};

export default WritingResumeBtn;
