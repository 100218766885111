import React from 'react';
import Modal from '@common/modal';
import { JOBDA_SUPPORT_EMAIL } from '@domain/constant/contact';
import classnames from 'classnames/bind';
import styles from './atsErrorModal.module.scss';
const cx = classnames.bind(styles);

interface ProfileErrorModalProps {
  visible: boolean;
  onClose: () => void;
}

const ProfileErrorModal = ({ visible, onClose }: ProfileErrorModalProps) => {
  return (
    <Modal visible={visible} isUseScrollHidden={false}>
      <div className={cx('layer', 'layerError')}>
        <em className={cx('title')}>현재 서비스가 원활하지 않습니다.</em>
        <span className={cx('text')}>
          일시적인 장애이거나 네트워크 문제일 수 있습니다.
          <br />
          오류가 지속될 경우 아래의 E-mail (잡다 헬프데스크)로
          <br />
          문의 해주세요.
        </span>
        <a className={cx('email')} href={`mailto:${JOBDA_SUPPORT_EMAIL}`}>
          {JOBDA_SUPPORT_EMAIL}
        </a>
        <div className={cx('btnArea')}>
          <a className={cx('btn', 'confirm')} role="button" onClick={onClose}>
            확인
          </a>
        </div>
      </div>
    </Modal>
  );
};

export default ProfileErrorModal;
