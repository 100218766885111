import React, { useEffect, useState } from 'react';
import Select, { SingleValue } from 'react-select';
import LineChart from '@common/lineChart';
import { ChartData, LineChartColorsType } from '@common/lineChart/data';
import Tooltip from '@common/lineChart/tooltip';
import NoSsr from '@common/noSsr';
import { ReactSelectStyles } from './ReactSelectStyles';
import CompanyEmployeeVO from '@domain/vo/company/sn/CompanyEmployeeVO';
import { round } from '@utils/chartUtil';
import useUpdateEffect from '@utils/hooks/useUpdateEffect';
import NumberFormat from '@utils/number/format';
import classnames from 'classnames/bind';
import styles from './lineChart.module.scss';
const cx = classnames.bind(styles);

type EmployeeType = 'ALL' | 'ENTRANT' | 'RETIREE';

const chartColosMap: Record<EmployeeType, LineChartColorsType> = {
  ALL: 'black',
  ENTRANT: 'green',
  RETIREE: 'blue',
};

const chartTextMap: Record<EmployeeType, string> = {
  ALL: '직원 수',
  ENTRANT: '입사자',
  RETIREE: '퇴사자',
};

type SelectValueType = { value: number; label: string };

interface EmployeeInfoLineChartProps {
  companyEmployee: CompanyEmployeeVO;
  trigger: boolean;
}

const EmployeeInfoLineChart = ({ companyEmployee, trigger: visible }: EmployeeInfoLineChartProps) => {
  const [employeeType, setEmployeeType] = useState<EmployeeType>('ALL');
  const [yearsValue, setYearsValue] = useState<SelectValueType | null>(null);
  const [trigger, setTrigger] = useState(false);

  const { yearlyEmployments } = companyEmployee;

  const isOn = (type: EmployeeType) => type === employeeType;

  const handleClick = (type: EmployeeType) => {
    setEmployeeType(type);
    animation();
  };

  const handleChange = (option: SingleValue<SelectValueType>) => {
    setEmployeeType('ALL');
    setYearsValue(option);
    animation();
  };

  const animation = () => {
    setTrigger(false);
    setTimeout(() => setTrigger(true), 0);
  };

  const getMonthlyEmployments = () => yearlyEmployments?.find((d) => d.year === yearsValue?.value)?.monthlyEmployments;

  const getIsOpen = (type: EmployeeType) => {
    const yearlyEmployment = yearlyEmployments?.find((d) => d.year === yearsValue?.value);
    switch (type) {
      case 'ALL':
        return yearlyEmployment?.totalEmployeeOpenType;
      case 'ENTRANT':
        return yearlyEmployment?.newEmployeeOpenType;
      case 'RETIREE':
        return yearlyEmployment?.resignEmployeeOpenType;
    }
  };

  const options =
    yearlyEmployments
      ?.map((d) => {
        return { value: d.year, label: `${d.year}년` };
      })
      .reverse() ?? [];

  const getRows = () =>
    getMonthlyEmployments()?.map((d) => {
      return `${String(yearsValue?.value).slice(-2)}.${NumberFormat.formatPad(d.month)}`;
    }) || [];

  const getValue = () =>
    getMonthlyEmployments()?.map((d) => {
      switch (employeeType) {
        case 'ALL':
          return d.totalEmployeeNumber;
        case 'ENTRANT':
          return d.newEmployeeNumber;
        case 'RETIREE':
          return d.resignEmployeeNumber;
      }
    }) || [];

  const getAxisMinMax = () => {
    const values = getValue()
      .filter((d) => d !== null)
      .map((d) => d ?? 0);
    const valuesWithoutNull = values.length === 0 ? [0] : values;

    const max = Math.max(...valuesWithoutNull);
    const min = Math.min(...valuesWithoutNull);
    const range = max - min;
    const pad = Math.round(range * 0.1); // 10% 여유 공간
    const axisMin = round(Math.max(min - pad, 0), 'floor');
    const axisMax = round(max + pad, 'ceil');

    return { axisMin, axisMax };
  };

  const getUnit = () => {
    const { axisMin, axisMax } = getAxisMinMax();
    return Math.ceil((axisMax - axisMin) / 5);
  };

  const getColumns = () => {
    const { axisMin } = getAxisMinMax();
    const unit = getUnit();
    return new Array(6).fill(null).map((_, i) => {
      const convertNum = (axisMin + i * unit).toLocaleString();
      return `${convertNum}명`;
    });
  };

  const getChartData = (): ChartData[] =>
    getValue().map((d) => {
      const convertResignEmployeeNumber =
        d != null ? `${chartTextMap[employeeType]} : ${d.toLocaleString()}명` : `${chartTextMap[employeeType]} : - `;

      return {
        column: d,
        toolTip: <Tooltip value={convertResignEmployeeNumber} type={chartColosMap[employeeType]} />,
      };
    });

  useEffect(() => {
    setTrigger(visible);
    setYearsValue(options[0]);
  }, [visible]);

  useUpdateEffect(() => {
    setYearsValue(options[0]);
  }, [companyEmployee]);

  if (!yearlyEmployments || yearlyEmployments.length === 0) return null;

  return (
    <div className={cx('chartArea')}>
      <div className={cx('tabArea')}>
        <div className={cx('tabWrap')}>
          {getIsOpen('ALL') && (
            <a className={cx('tab', { on: isOn('ALL') })} onClick={() => handleClick('ALL')} role="button">
              전체 직원
            </a>
          )}
          {getIsOpen('ENTRANT') && (
            <a
              className={cx('tab', chartColosMap['ENTRANT'], { on: isOn('ENTRANT') })}
              onClick={() => handleClick('ENTRANT')}
              role="button"
            >
              입사자
            </a>
          )}

          {getIsOpen('RETIREE') && (
            <a
              className={cx('tab', chartColosMap['RETIREE'], { on: isOn('RETIREE') })}
              onClick={() => handleClick('RETIREE')}
              role="button"
            >
              퇴사자
            </a>
          )}
        </div>
        <div className={cx('dropDownArea')}>
          <NoSsr>
            <Select
              className={cx('select')}
              value={yearsValue}
              isSearchable={false}
              options={options}
              styles={ReactSelectStyles}
              // @ts-ignore
              onChange={handleChange}
            />
          </NoSsr>
        </div>
      </div>
      <div className={cx('chartWrap')}>
        <LineChart
          colors={chartColosMap[employeeType]}
          viewCount={12}
          rows={getRows()}
          columns={getColumns()}
          data={getChartData()}
          onIndex={11}
          trigger={trigger}
          {...getAxisMinMax()}
        />
      </div>
    </div>
  );
};

export default EmployeeInfoLineChart;
