export const rejectSubReasonTextMaxLength = 50;

export enum OfferRejectEnum {
  INDUSTRY_OR_JOB_TITLE = 'INDUSTRY_OR_JOB_TITLE',
  WORKING_PREFERENCE = 'WORKING_PREFERENCE',
  JOB_SEARCH = 'JOB_SEARCH',
  INDIVIDUAL_REASON = 'INDIVIDUAL_REASON',
  CONTACT_ANOTHER_COMPANY = 'CONTACT_ANOTHER_COMPANY',
  OTHER = 'OTHER',
}

export const OfferRejectLabel = {
  [OfferRejectEnum.INDUSTRY_OR_JOB_TITLE]: '관심있는 업종, 직무가 아니에요.',
  [OfferRejectEnum.WORKING_PREFERENCE]: '제가 원하는 근로조건이 아니에요.',
  [OfferRejectEnum.JOB_SEARCH]: '당장 취업/이직할 의사가 없어요.',
  [OfferRejectEnum.INDIVIDUAL_REASON]: '개인적인 사정으로 면접 참여가 어려워졌습니다.',
  [OfferRejectEnum.CONTACT_ANOTHER_COMPANY]: '다른 기업과 처우 협의 중입니다.',
  [OfferRejectEnum.OTHER]: '직접 입력',
};

export type OffetRejectType = keyof typeof OfferRejectEnum;

export enum OfferRejectSubReasonEnum {
  SALARY = 'SALARY',
  WELFARE = 'WELFARE',
  LOCATION = 'LOCATION',
}

export const OfferRejectSubReasonLabel = {
  [OfferRejectSubReasonEnum.SALARY]: '연봉',
  [OfferRejectSubReasonEnum.WELFARE]: '복지',
  [OfferRejectSubReasonEnum.LOCATION]: '근무지',
};

export type OfferRejectSubReasonType = keyof typeof OfferRejectSubReasonEnum;
