import React, { useEffect, useState } from 'react';
import { MatchTalkMessageOptionDto } from '@domain/rs/match/message/MatchingMessageListRs';
import MatchMessageVO from '@domain/vo/match/message/MatchMessageVO';
import InterviewSchedules from './interviewSchedules';
import InterviewLocation from './interviewLocation';

import classnames from 'classnames/bind';
import styles from './interview.module.scss';
import useModals from '@utils/hooks/useModals';
import CompanyMessageModals from '../common/companyMessageModals';
import FileList from '../common/FileList';
import MessageButton from '../../common/button';
const cx = classnames.bind(styles);

type DecisionProps = {
  options: MatchTalkMessageOptionDto[];
  positive: MatchTalkMessageOptionDto | null;
  negative: MatchTalkMessageOptionDto | null;
};

const getOptions = (options: MatchTalkMessageOptionDto[]) => {
  return options.reduce(
    (prev, cur) => {
      const { command } = cur;

      switch (command) {
        case 'ACCEPT':
          prev.options.push(cur);
          return prev;

        case 'REFUSE':
          prev.negative = cur;
          return prev;

        default:
          return prev;
      }
    },
    {
      options: [],
      positive: null,
      negative: null,
    } as DecisionProps,
  );
};

type InterviewInfoType = {
  sn: string | null;
  date: string | null;
};

const initInterviewValue = { sn: null, date: null };

interface InterviewMessageProps {
  positionSn: number;
  companyMessage: MatchMessageVO;
  openYn: boolean;
  deleteYn: boolean;
}

const Interview = ({ positionSn, companyMessage, openYn, deleteYn }: InterviewMessageProps) => {
  const {
    title,
    message,
    options = [],
    decision,
    decisionDateTime,
    decisionDueDateTimeOriginal,
    interviewLocation,
    command,
  } = companyMessage;

  const { openModal, closeModal, isVisible } = useModals([
    'ACCEPT',
    'REJECT',
    'INVALID_DATE',
    'ATS_ERROR',
    'ENDED',
    'DELETED',
    'COORDINATION',
  ]);
  const [interview, setInterview] = useState<InterviewInfoType>(initInterviewValue);
  const [selection, setSelection] = useState<DecisionProps>(getOptions(options));

  useEffect(() => {
    setInterview(initInterviewValue);
    setSelection(getOptions(options));
  }, [options]);

  const handleChoice = (args: { sn: string; date: string }) => setInterview(args);
  const hasOption = options.length > 0;
  const getResultText = () =>
    decision?.command === 'REFUSE'
      ? '면접 제안 거절'
      : decision?.command === 'ADJUST'
      ? '면접 조율 신청'
      : '면접 제안 수락';

  const isDisabled = !openYn || deleteYn || interview.sn === null || command === 'INTERVIEW_CANCEL';
  const isButtonVisible = !decision && hasOption;

  const getOptionSn = (optionSn: string | null | undefined) => {
    if (!optionSn) return null;
    return parseInt(optionSn);
  };

  const reject = () => {
    openModal('REJECT');
  };

  const accept = () => openModal('ACCEPT');

  const ButtonElements = () => {
    if (command === 'INTERVIEW_CANCEL') {
      return (
        <MessageButton className={cx('canceled')} disabled>
          면접 요청 만료
        </MessageButton>
      );
    }

    return (
      isButtonVisible && (
        <div className={cx('noticeBtnWrapper')}>
          <MessageButton theme="secondary" onClick={reject}>
            거절하기
          </MessageButton>
          <MessageButton onClick={isDisabled ? undefined : accept} disabled={isDisabled}>
            수락하기
          </MessageButton>
        </div>
      )
    );
  };

  return (
    <div className={cx('wrapper')}>
      <div className={cx('noticeArea')}>
        <strong className={cx('title')}>{title}</strong>
        <div className={cx('message')}>{message} </div>
        <FileList positionSn={positionSn} attachFiles={companyMessage.companyAttachFiles} />
      </div>

      <div className={cx('noticeArea', 'interviewArea')}>
        <strong className={cx('title', 'interview')}>면접 일정을 선택해 주세요.</strong>

        {/* [D] 면접 장소 영역 */}
        {interviewLocation && <InterviewLocation location={interviewLocation} />}

        {/* [D] 면접 시간 영역 */}
        <InterviewSchedules
          interviewSn={interview.sn}
          decisionDueDateTime={decisionDueDateTimeOriginal}
          options={selection.options}
          decision={decision}
          onClick={handleChoice}
          openModal={openModal}
        />

        {decision && (
          <MessageButton className={cx('noticeWrap')} disabled>
            {decisionDateTime} {getResultText()}
          </MessageButton>
        )}

        <ButtonElements />

        {/* [D] 면접 수락 / 거절 모달 */}
        <CompanyMessageModals
          isVisible={isVisible}
          openModal={openModal}
          closeModal={closeModal}
          options={options}
          positiveOptionSn={getOptionSn(interview.sn)}
          negativeOptionsSn={getOptionSn(selection.negative?.sn)}
          date={interview.date}
          interviewLocation={interviewLocation}
        />
      </div>
    </div>
  );
};

export default Interview;
