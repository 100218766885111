import { useState } from 'react';
import { fetchMatchingPositionDetail } from '@repository/match';
import MatchingProfilePreviewModal from '../../common/MatchingProfilePreviewModal';
import ProfileDetailVO from '@domain/vo/profile/ProfileDetailVO';
import useModal from '@utils/hooks/useModal';

import { useKeyDown } from '@utils/hooks/useKeyboard';
import classnames from 'classnames/bind';
import styles from './resumeBtn.module.scss';
const cx = classnames.bind(styles);

interface Props {
  positionSn: number | null;
}

const MatchingProfileResumeViewBtn = ({ positionSn }: Props) => {
  const [profileDetail, setProfileDetail] = useState<ProfileDetailVO | null>(null);
  const { closeModal, isModalOpen, openModal } = useModal();

  useKeyDown(closeModal, 'Escape');

  if (!positionSn) return null;

  const handleClick = async () => {
    if (profileDetail !== null) {
      openModal();
      return;
    }

    const detail = await fetchMatchingPositionDetail(positionSn);
    if (!detail) return;
    setProfileDetail(detail);
    openModal();
  };

  return (
    <>
      <a className={cx('resumeBtnArea', `btnImg${'Submit'}`, 'previewProfileBtn')} role="button" onClick={handleClick}>
        제출한 프로필 확인하기
      </a>

      {/* 프로필 미리보기 */}
      {profileDetail && (
        <MatchingProfilePreviewModal profileDetail={profileDetail} visible={isModalOpen} onClose={closeModal} />
      )}
    </>
  );
};

export default MatchingProfileResumeViewBtn;
