import Modal from '@components/common/modal';
import Button from '@components/common/button';
import Icon from '@components/common/assets';
import { useLoggingContext } from '@/src/logging';
import { JOBDA_SUPPORT_EMAIL } from '@domain/constant/contact';
import { V2Route } from '@utils/routeUtil';

import classnames from 'classnames/bind';
import styles from './applyMethodChangeInfoModal.module.scss';
const cx = classnames.bind(styles);

interface Props {
  visible: boolean;
  onClose: () => void;
  positionSn: number;
}

const ApplyMethodChangeInfoModal = ({ visible, onClose, positionSn }: Props) => {
  const { handleLoggingNewTab } = useLoggingContext();
  const handleClick = (e: any) => {
    handleLoggingNewTab(e, V2Route.getPositionJDPage(positionSn));
    window.open(V2Route.getPositionJDPage(positionSn));
    onClose();
  };

  return (
    <Modal visible={visible}>
      <div className={cx('layer')}>
        <div className={cx('closeBtn')}>
          <a onClick={onClose} role="button">
            <Icon name="closeLight" width={32} height={32} />
          </a>
        </div>
        <div className={cx('title')}>지원 방식이 변경되었습니다.</div>

        <div className={cx('card')}>
          지원서 작성<span className={cx('arrow')}>→</span>내 프로필 제출
        </div>

        <div className={cx('desc')}>
          작성 중이었던 지원서 내용이 필요하시면
          <br />
          <a href={`mailto:${JOBDA_SUPPORT_EMAIL}`} className={cx('mail')}>
            {JOBDA_SUPPORT_EMAIL}
          </a>
          으로 문의주세요.
        </div>

        <Button className={cx('button')} onClick={handleClick}>
          포지션에 지원하러 가기
        </Button>
      </div>
    </Modal>
  );
};

export default ApplyMethodChangeInfoModal;
