import React, { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import PositionBookmarkListItem from '@components/common/positionBookmarkListItem';
import { useCurationPositionList } from '@components/position/list/query/useCuration';
import SkeletonPositionListList from '@components/position/list/positionList/SkeletonPositionListList';
import NoPositionData from './NoPositionData';
import CurationFilterListVO from '@domain/vo/position/list/CurationFilterListVO';
import PositionListFilterVO from '@domain/vo/position/list/PositionListFilterVO';

import classnames from 'classnames/bind';
import styles from './positionList.module.scss';
const cx = classnames.bind(styles);

interface ICurationPositionListProps {
  vo: CurationFilterListVO;
}

const CurationPositionList: FC<ICurationPositionListProps> = ({ vo }) => {
  const { watch } = useFormContext<PositionListFilterVO>();
  const watchFilter = watch();
  const { data, fetchNextPage, isLoading } = useCurationPositionList(vo.sn, watchFilter);
  const moreBtnVisible = data?.pages[data.pages.length - 1].isExistNextData;

  const positionEls = data?.pages.map(({ positions }, idx) => {
    const isRankVisible = ['POPULAR', 'SALARY', 'NONE'].includes(watchFilter.orderType) && idx === 0;

    //key : 캐싱 문제로 북마크 상태 변경안되는 문제가 있어 key에 북마크 값 추가
    return positions.map((item, idx) => {
      return (
        <li className={cx('listItem')} key={`${item.sn}_${item.bookmarkYn}`}>
          <PositionBookmarkListItem index={idx} data={item} isRankVisible={isRankVisible} />
        </li>
      );
    });
  });

  if (isLoading) return <SkeletonPositionListList length={12} />;
  //리스트 없는 경우
  if (data?.pages[0] && data?.pages[0].positions.length < 1) return <NoPositionData />;

  return (
    <>
      <ul className={cx('positionList')}>{positionEls}</ul>
      <div className={cx('btnMoreArea')}>
        {moreBtnVisible && (
          <a className={cx('btnMore')} role="button" onClick={() => fetchNextPage()}>
            포지션 더보기
          </a>
        )}
      </div>
    </>
  );
};

export default CurationPositionList;
