import React from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useApplicationPositionList } from '@repository/match';
import { useNotificationActions, useNotificationStore } from '@stores/NotificationStore';
import { LogTypeEnum } from '@/src/logging/LogType';
import { V2Route, RouteUrl } from '@utils/routeUtil';

import classnames from 'classnames/bind';
import styles from './messageModalTitle.module.scss';

const cx = classnames.bind(styles);

const MessageModalTitle = () => {
  const { pathname } = useRouter();
  const isDashBoard = pathname === RouteUrl.MY_PAGE_APPLY;
  const positionInfo = useNotificationStore((state) => state.positionInfo);
  const { setMessageModalVisible } = useNotificationActions();
  const { refetch } = useApplicationPositionList();

  if (!positionInfo || !positionInfo.positionSn) return null;

  const { positionSn, positionName, companyName, companySn } = positionInfo;
  const companyUrl = companySn ? V2Route.getCompanyChannelPage(companySn) : '';

  const onRoute = () => {
    if (!positionSn) return;
    setMessageModalVisible(false);
  };

  const onClose = async () => {
    if (isDashBoard) await refetch();
    setMessageModalVisible(false);
  };

  return (
    <div className={cx('titleArea')}>
      <a className={cx('btnCancel')} role="button" onClick={onClose} />
      <strong className={cx('title')}>{positionName}</strong>
      <div className={cx('companyInfoArea')}>
        <Link
          className={cx('companyName')}
          href={companyUrl}
          onClick={onRoute}
          id={LogTypeEnum.log_message_company_info}
        >
          {companyName}
        </Link>
        <Link
          className={cx('chips')}
          href={V2Route.getPositionJDPage(positionSn)}
          onClick={onRoute}
          id={LogTypeEnum.log_message_position_info}
        >
          포지션 정보 확인
        </Link>
      </div>
    </div>
  );
};

export default MessageModalTitle;
