import React, { FC } from 'react';
import Modal from '@common/modal';
import classnames from 'classnames/bind';
import styles from './invalidDateModal.module.scss';

const cx = classnames.bind(styles);

interface InvalidDateModalProps {
  visible: boolean;
  onClose: () => void;
}

const InvalidDateModal: FC<InvalidDateModalProps> = ({ visible, onClose }) => {
  return (
    <Modal visible={visible} isUseScrollHidden={false}>
      <div className={cx('inner')}>
        <strong>다른 면접 시간을 선택해 주세요.</strong>
        <p className={cx('notification')}>
          이미 마감된 시간이에요.
          <br />
          만약 원하는 시간이 없다면 인사 담당자에게 연락해 주세요.
        </p>
        <div className={cx('box', 'oneBtn')}>
          <a id={'agree'} className={cx('btn')} role={'button'} onClick={onClose}>
            확인
          </a>
        </div>
      </div>
    </Modal>
  );
};

export default InvalidDateModal;
