import React, { createContext, useCallback, useState } from 'react';
import Header from './Header';
import Content from './Content';
import AnimationAccordion from './AnimationAccordion';

type AccordionType = Record<string, { height?: number; visible: boolean }>;

interface AccordionContextProps {
  toggle: (id: string) => void;
  accordions: AccordionType; // 추가
  registerAccordion: (key: string, height?: number) => void; // 추가
}

const AccordionContext = createContext<AccordionContextProps>({
  toggle: () => {},
  accordions: {},
  registerAccordion: () => {},
});

export const useAccordion = () => {
  const context = React.useContext(AccordionContext);
  if (!context) {
    throw new Error('Header, Content, AccordionAnimation 컴포넌트는 Accordion 컴포넌트 내부에서 사용되어야 합니다.');
  }
  return context;
};

interface Props {
  children: React.ReactNode | ((accordions: AccordionType) => React.ReactNode);
}

const Accordion = ({ children }: Props) => {
  const [accordions, setAccordions] = useState<Record<string, { height?: number; visible: boolean }>>({});

  const toggle = (id: string) => {
    setAccordions((prev) => ({ ...prev, [id]: { ...prev[id], visible: !prev[id]?.visible } }));
  };

  const registerAccordion = useCallback((key: string, height?: number) => {
    setAccordions((prev) => ({ ...prev, [key]: { height, visible: prev[key]?.visible } }));
  }, []);

  return (
    <AccordionContext.Provider value={{ accordions, toggle, registerAccordion }}>
      {typeof children === 'function' ? children(accordions) : children}
    </AccordionContext.Provider>
  );
};

export default Accordion;

Accordion.Header = Header;
Accordion.Content = Content;
Accordion.Animation = AnimationAccordion;
