import React, { FC } from 'react';

import Icon from '@components/common/assets';
import classnames from 'classnames/bind';
import styles from './gradeBadge.module.scss';
const cx = classnames.bind(styles);

interface GradeBadgeProps {
  grade?: string;
  className?: string;
}

const GradeBadge: FC<GradeBadgeProps> = ({ grade, className }) => {
  const isIconVisible = !(grade === 'Try Again' || grade === '분석중' || !grade);
  const badgeClassByGrade = (() => {
    switch (grade) {
      case 'Platinum':
      case 'Gold':
      case 'Silver':
      case 'Bronze':
        return grade.toLowerCase();
      case 'Try Again':
        return 'tryAgain';
      case '분석중':
        return 'analyzing';
      default:
        return '';
    }
  })();

  return (
    <span className={cx('badge', badgeClassByGrade, className)}>
      {isIconVisible && <Icon name="star" />}
      {grade}
    </span>
  );
};

export default GradeBadge;
