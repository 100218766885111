import React, { FC } from 'react';
import useRecommendKeyword from '@components/search/query/useRecommendKeyword';
import { V2Route } from '@utils/routeUtil';

import classnames from 'classnames/bind';
import styles from './searchModal.module.scss';
const cx = classnames.bind(styles);

const RecommendPositionList: FC = () => {
  const { data, isFetched } = useRecommendKeyword();

  if (!isFetched) return null;

  const recommendPositionListEl = data?.keywords.slice(0, 5).map((item, idx) => {
    const { positionName } = item;
    const positionNumber = idx + 1;

    return (
      <a key={idx} href={V2Route.getSearchPage(positionName)} className={cx('keyword')} rel="noreferrer">
        <span className={cx('order', { excellent: positionNumber <= 3 })}>{positionNumber}</span>
        <span className={cx('name')}>{positionName}</span>
      </a>
    );
  });

  return <>{recommendPositionListEl}</>;
};

export default RecommendPositionList;
