import React, { FC } from 'react';
import useRecommendKeyword from '@components/search/query/useRecommendKeyword';
import { V2Route } from '@utils/routeUtil';

import classnames from 'classnames/bind';
import styles from './searchModal.module.scss';
const cx = classnames.bind(styles);

const RecommendTagList: FC = () => {
  const { data, isFetched } = useRecommendKeyword();

  if (!isFetched) return null;

  const excludeWinbackPosition = data?.keywords
    .filter((item) => !['잡다', 'JOBDA'].includes(item.companyName))
    .slice(0, 5);

  const recommendTagListEl = excludeWinbackPosition?.map((item, idx) => {
    const { companyName } = item;
    const positionNumber = idx + 1;

    return (
      <a key={idx} href={V2Route.getSearchPage(companyName)} className={cx('keyword')} rel="noreferrer">
        <span className={cx('order', { excellent: positionNumber <= 3 })}>{positionNumber}</span>
        <span className={cx('name')}>{companyName}</span>
      </a>
    );
  });

  return <>{recommendTagListEl}</>;
};

export default RecommendTagList;
