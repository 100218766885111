import React from 'react';

import classnames from 'classnames/bind';
import styles from './positionListCard.module.scss';
const cx = classnames.bind(styles);

const PositionListCard = () => {
  return (
    // [D] 해당 요소는 li 안에 넣어주셔야 됩니다. (기존 li가 간격 스타일을 가지고 있기 때문입니다.)
    // [D] 기본: li.listItem > a.link / 스켈레톤: li.listItem > Skeleton(해당 요소)
    <div className={cx('positionListCardArea')}>
      <div className={cx('imgArea')} />

      <div className={cx('positionInfoArea')}>
        <div className={cx('logo')} />
        <div className={cx('positionInfoWrap')}>
          <div className={cx('positionInfoText')} />
          <div className={cx('positionInfoText')} />
        </div>
      </div>

      <div className={cx('companyInfoArea')}>
        <div className={cx('companyInfoText')} />
        <div className={cx('companyInfoText')} />
      </div>
    </div>
  );
};

export default PositionListCard;
