import React, { FC, useEffect, useState } from 'react';
import Link from 'next/link';
import Icon from '@common/assets';
import KakaoMap from '@components/common/KakaoMap';
import { MatchinigReportPositionAddressDto } from '@domain/rs/match/report/MatchingReportRs';
import { kakaoFindMapUrl } from '@utils/KakaoUtils';
import useLocationCoordinates from '@utils/hooks/useLocationCoordinates';
import classnames from 'classnames/bind';
import styles from './mapLocation.module.scss';
const cx = classnames.bind(styles);

interface MapLocationProps {
  data?: MatchinigReportPositionAddressDto;
}

const MapLocation: FC<MapLocationProps> = ({ data }) => {
  const [visible, setVisible] = useState(true);
  const coords = useLocationCoordinates(data?.address ?? '');

  useEffect(() => {
    setVisible(!!data?.address);
  }, [data]);

  if (!data || !visible) return null;

  return (
    <div className={cx('mapInner')}>
      <div className={cx('basicTitle')}>
        우리 집부터 기업까지
        <br />
        소요 시간을 미리 확인해 보세요.
        {/* 지도 위치와 카카오맵 소요시간 확인하기 */}
        <div className={cx('mapDetail')}>
          <div className={cx('location')}>
            <Icon name="pinLight" width={20} height={20} />
            {`${data.address}${data.detailAddress ? `, ${data.detailAddress}` : ''}`}
          </div>
          <Link href={kakaoFindMapUrl(data.address, coords?.Ma, coords?.La)} legacyBehavior>
            <a className={cx('btnLink')} role="button" target="_blank">
              카카오맵으로 소요 시간 확인하기
            </a>
          </Link>
        </div>
      </div>

      {/* [D] 지도 영역 */}
      <KakaoMap className={cx('map')} address={data.address} handleError={() => setVisible(false)} />
    </div>
  );
};

export default MapLocation;
