import React from 'react';
import { useToast } from '@components/common/toast';
import PreferDemandModal from '@components/common/matchSwitch/matchProposalModals/PreferDemandModal';
import { useNotificationStore } from '@stores/NotificationStore';
import AcceptModal from './acceptModal';
import InvalidDateModal from './invalidDateModal';
import AtsErrorModal from './atsErrorModal';
import EndedModal from './endedModal';
import DeletedModal from './deletedModal';
import CoordinationModal from './coordinationModal';
import RejectModal from './rejectModal';
import { MatchTalkMessageOptionDto } from '@domain/rs/match/message/MatchingMessageListRs';

interface CompanyMessageModalsProps {
  isVisible: (type: string) => boolean;
  openModal: (type: string) => void;
  closeModal: () => void;
  options?: MatchTalkMessageOptionDto[];
  positiveOptionSn: number | null;
  negativeOptionsSn: number | null;
  date?: string | null;
  interviewLocation: string | null;
}

const CompanyMessageModals = ({
  isVisible,
  openModal,
  closeModal,
  options,
  positiveOptionSn,
  negativeOptionsSn,
  date,
  interviewLocation,
}: CompanyMessageModalsProps) => {
  const Toast = useToast();
  const positionInfo = useNotificationStore((state) => state.positionInfo);
  const positionSn = positionInfo?.positionSn ?? 0;
  const conpanySn = positionInfo?.companySn ?? 0;
  const companyName = positionInfo?.companyName ?? '';
  const handleErrors = (result: string) => {
    switch (result) {
      case 'B901':
        openModal('ENDED');
        break;
      case 'B903':
        Toast({ type: 'error', iconType: 'info', content: '만료된 요청입니다.' });
        break;
      case 'B904':
        openModal('INVALID_DATE');
        break;
      case 'B908':
        openModal('ATS_ERROR');
        break;
      case 'B910':
        openModal('DELETED');
        break;
    }
  };

  return (
    <>
      {/* [D] 면접 시간 마감 모달 */}
      <InvalidDateModal visible={isVisible('INVALID_DATE')} onClose={closeModal} />

      {/* [D] 서비스 장애 모달 */}
      <AtsErrorModal visible={isVisible('ATS_ERROR')} onClose={closeModal} />

      {/* [D] 종료된 포지션 모달 */}
      <EndedModal visible={isVisible('ENDED')} onClose={closeModal} />

      {/* [D] 삭제된 포지션 모달 */}
      <DeletedModal visible={isVisible('DELETED')} onClose={closeModal} />

      {/* [D] 면접 수락 모달 */}
      <AcceptModal
        visible={isVisible('ACCEPT')}
        optionSn={positiveOptionSn}
        interviewLocation={interviewLocation}
        date={date}
        handleErrors={handleErrors}
        onClose={closeModal}
      />

      {/* [D] 제안 거절 모달 매칭리포트 x */}
      <RejectModal
        visible={isVisible('REJECT')}
        onClose={closeModal}
        optionSn={negativeOptionsSn}
        positionSn={positionSn}
        handleErrors={handleErrors}
      />

      {/* [D] 일정 조율 모달 */}
      <CoordinationModal
        visible={isVisible('COORDINATION')}
        optionSn={options?.find(({ command }) => command === 'ADJUST')?.sn}
        onClose={closeModal}
      />

      {/* 선호 정보 변경 유도 모달 */}
      <PreferDemandModal
        visible={isVisible('PREFER_DEMAND')}
        onClose={() => {
          openModal('PREFER');
        }}
        onNext={closeModal}
        closeBtnText="닫기"
        confirmBtnText="선호 정보 수정"
      />
    </>
  );
};

export default CompanyMessageModals;
