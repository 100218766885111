import { useMutation, useQuery } from 'react-query';
import { RemoteChatRepo } from '@repository/chat/ChatRepo';
import ChatNotificationUpdateRq from '@domain/rq/chat/ChatNotificationUpdateRq';
import ChatMessageReportRq from '@domain/rq/chat/ChatMessageReportRq';

const remoteChatRepo = new RemoteChatRepo();

export const useChatCompanyChannelByChannelId = (channelId: string) => {
  return useQuery(
    ['chat', 'company-channel', channelId],
    async () => {
      const { data } = await remoteChatRepo.fetchChatCompanyChannelByChannelId(channelId);
      return data;
    },
    {
      enabled: !!channelId,
    },
  );
};

export const useChatCompanyByCompanySn = (companySn: number) => {
  return useQuery(
    ['chat', 'company', companySn],
    async () => {
      const { data } = await remoteChatRepo.fetchChatCompanyByCompanySn(companySn);
      return data;
    },
    {
      enabled: !!companySn,
    },
  );
};

export const useChatNotifications = (enabled: boolean) => {
  return useQuery(
    ['chat', 'notifications'],
    async () => {
      const { data } = await remoteChatRepo.fetchChatNotifications();
      return data;
    },
    {
      enabled,
    },
  );
};

export const useChatNotificationsByChannelId = (channelId: string) => {
  return useQuery(
    ['chat', 'notifications'],
    async () => {
      const { data } = await remoteChatRepo.fetchChatNotificationsByChannelId(channelId);
      return data;
    },
    {
      enabled: !!channelId,
    },
  );
};

export const usePutChatNotificationsByChannelId = () => {
  return useMutation(
    ['chat', 'notifications'],
    async ({ channelId, rq }: { channelId: string; rq: ChatNotificationUpdateRq }) => {
      const { data } = await remoteChatRepo.fetchPutChatNotificationsByChannelId(channelId, rq);
      return data;
    },
  );
};

export const useChatToken = (enabled: boolean) => {
  return useQuery(
    ['chat', 'token'],
    async () => {
      const { data } = await remoteChatRepo.fetchChatToken();
      return data;
    },
    {
      enabled,
    },
  );
};

export const useChatEnterLog = () => {
  return useMutation(['chat', 'enter-log'], async ({ channelId }: { channelId: string }) => {
    const { data } = await remoteChatRepo.fetchChatEnterLog(channelId);
    return data;
  });
};

export const useChatChannelMessagesReportByChannelIdAndMessageId = () => {
  return useMutation(
    ['chat', 'channel-messages-report'],
    async ({ channelId, messageId, rq }: { channelId: string; messageId: string; rq: ChatMessageReportRq }) => {
      const { data } = await remoteChatRepo.fetchChatChannelMessagesReportByChannelIdAndMessageId({
        channelId,
        messageId,
        rq,
      });
      return data;
    },
  );
};
