import { useRef } from 'react';
import Icon from '@common/assets';
import Colors from '@domain/constant/colors';
import classnames from 'classnames/bind';
import styles from './search.module.scss';

const cx = classnames.bind(styles);

interface SearchProps {
  keyword: string;
  onChange: (keyword: string) => void;
}

const Search = ({ keyword, onChange }: SearchProps) => {
  const inputEl = useRef<HTMLInputElement>(null);

  const onClickSearchCloseBtn = () => {
    if (inputEl.current) {
      inputEl.current.value = '';
      inputEl.current.focus();
    }
  };

  const onKeyUpInput = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const enterKey = ['NumpadEnter', 'Enter'];
    if (enterKey.includes(event.code)) {
      if (inputEl.current) {
        const currentValue = inputEl.current.value;
        inputEl.current.blur();
        onChange(currentValue ? currentValue : keyword);
      }
    }
  };

  const onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (!e.target.value) e.target.value = keyword;
  };

  return (
    <div className={cx('searchWrap')}>
      <span className={cx('icon', 'search')}>
        <Icon name="searchLight" width={24} height={24} fill={Colors.C_COOL_GRAY_70} />
      </span>
      <input
        ref={inputEl}
        type="text"
        className={cx('searchInput', { empty: !keyword })}
        placeholder={'기업을 검색해 주세요.'}
        onKeyUp={onKeyUpInput}
        autoComplete="off"
        defaultValue={keyword}
        onBlur={onBlur}
      />
      <a className={cx('icon', 'close', { isVisible: true })} role="button" onClick={onClickSearchCloseBtn}>
        <Icon name="closeLight" width={24} height={24} fill={Colors.C_COOL_GRAY_70} />
      </a>
    </div>
  );
};

export default Search;
