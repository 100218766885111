import React from 'react';
import { useNotificationStore } from '@stores/NotificationStore';
import CompanyLogo from './../companyMessage/common/companyLogo/index';
import CompanyWriter from './../companyMessage/common/companyWriter/index';
import MatchingMessageListInfoVO from '@domain/vo/match/message/MatchingMessageListInfoVO';
import MatchMessageVO from '@domain/vo/match/message/MatchMessageVO';

import classnames from 'classnames/bind';
import styles from './index.module.scss';
const cx = classnames.bind(styles);

interface CompanyMessageProps {
  companyMessage: MatchMessageVO;
  messagesInfo: MatchingMessageListInfoVO;
}

const SystemMessage = ({ companyMessage, messagesInfo }: CompanyMessageProps) => {
  const { createdDayTime } = companyMessage;
  const positionInfo = useNotificationStore((state) => state.positionInfo);

  if (!positionInfo) return null;
  const { companyName, logoImageUrl } = positionInfo;

  return (
    <div className={cx('wrap')}>
      {/* [D] 회사 로고 영역 */}
      <CompanyLogo companyName={companyName} logoImageUrl={logoImageUrl} />

      <div className={cx('contentArea')}>
        {/* [D] 회사 담당자 영역 */}
        <CompanyWriter companyName={companyName} createdDayTime={createdDayTime} />

        {/* [D] 회사 메세지 컨턴츠 영역 */}
        <div className={cx('messageArea')}>{companyMessage.title}</div>
      </div>
    </div>
  );
};

export default SystemMessage;
