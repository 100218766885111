import React from 'react';

import classnames from 'classnames/bind';
import styles from './companyWriter.module.scss';
const cx = classnames.bind(styles);

interface CompanyWriterProps {
  companyName: string | null;
  createdDayTime: string;
}

const CompanyWriter = ({ companyName, createdDayTime }: CompanyWriterProps) => {
  return (
    <div className={cx('writerArea')}>
      <strong className={cx('name')}>{`${companyName} 담당자`}</strong>
      <span className={cx('date')}>{createdDayTime}</span>
    </div>
  );
};

export default CompanyWriter;
