import React, { FC, useEffect, useState } from 'react';
import Icon from '@common/assets';
import Colors from '@domain/constant/colors';

import classnames from 'classnames/bind';
import styles from './pageTopButton.module.scss';
const cx = classnames.bind(styles);

const PageTopButton: FC = () => {
  const [isVisible, setIsVisible] = useState(false);

  const handleScrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const onScroll = () => {
    if (document.documentElement.scrollTop === 0) {
      setIsVisible(false);
    } else {
      setIsVisible(true);
    }
  };

  useEffect(() => {
    onScroll();
    document.addEventListener('scroll', onScroll);

    return () => {
      document.removeEventListener('scroll', onScroll);
    };
  }, []);

  return (
    <a id={'topBtn'} className={cx('topBtn', { hidden: !isVisible })} role="button" onClick={handleScrollTop}>
      <Icon name="arrowUpwardLight" width={32} height={32} fill={Colors.C_WHITE} />
    </a>
  );
};

export default PageTopButton;
