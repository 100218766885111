import React, { useRef, useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import Icon from '@common/assets';
import LoginModal from '@components/loginModal';
import { useUserInfo } from '@repository/auth/useUserInfo';
import useClickAway from '@utils/hooks/useClickOutside';

import classnames from 'classnames/bind';
import styles from '../positionFilter.module.scss';
const cx = classnames.bind(styles);

const fieldName = 'matchingYn';

const MatchingStatusFilter = () => {
  const [selectOptionAreaVisible, setSelectOptionAreaVisible] = useState(false);
  const [loginModalVisible, setLoginModalVisible] = useState(false);

  const { data: userInfo } = useUserInfo();
  const isLogin = !!userInfo?.id;

  const matchStateFilterRef = useRef<HTMLDivElement>(null);
  const { control } = useFormContext();

  const { field } = useController({
    control,
    name: fieldName,
    defaultValue: false,
  });

  const btnName = field.value ? '추천 받음' : '추천 상태';

  const onChangeStatus = () => {
    if (!isLogin) {
      setLoginModalVisible(true);
      return;
    }
    field.onChange(!field.value);
  };

  useClickAway(() => setSelectOptionAreaVisible(false), matchStateFilterRef);

  return (
    <div className={cx('selectWrap')} ref={matchStateFilterRef}>
      <a
        className={cx('btnSelect', { open: selectOptionAreaVisible }, { on: field.value })}
        onClick={() => setSelectOptionAreaVisible(!selectOptionAreaVisible)}
        role="button"
      >
        {btnName}
        {selectOptionAreaVisible && <Icon name="arrowTopLight" width={24} height={24} />}
        {!selectOptionAreaVisible && <Icon name="arrowBottomLight" width={24} height={24} />}
      </a>
      {selectOptionAreaVisible && (
        <div className={cx('selectOptionArea', 'matchState')}>
          <label className={cx('label')}>
            <input type="checkbox" className={cx('hidden', 'input')} checked={field.value} onChange={onChangeStatus} />
            <span className={cx('checkbox')} />
            추천 받음
          </label>
        </div>
      )}
      <LoginModal
        subTitle="채용정보 · 역량검사 · 매칭까지"
        title="취업기회 지금 바로 잡다!"
        visible={loginModalVisible}
        onClose={() => setLoginModalVisible(false)}
      />
    </div>
  );
};

export default MatchingStatusFilter;
