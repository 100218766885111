import RemoteProfileResumeRepo from './ProfileResumeRepo';
import ProfileDetailVO from '@domain/vo/profile/ProfileDetailVO';

const remoteProfileResumeRepo = new RemoteProfileResumeRepo();

export const fetchProfileResumeSn = async (positionSn: number) => {
  const { data } = await remoteProfileResumeRepo.fetchProfileResumeSn(positionSn);
  return data;
};

export const fetchProfileResumeDetail = async (profileResumeSn: number) => {
  const { data } = await remoteProfileResumeRepo.fetchProfileResumeDetail(profileResumeSn);
  return new ProfileDetailVO(data);
};
