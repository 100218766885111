import React from 'react';
import { ScheduleProps } from '../index';

import classnames from 'classnames/bind';
import styles from './schedules.module.scss';
import Text from '@components/common/text';
import { FontStyle } from '@domain/constant/fontStyle';
import Icon from '@components/common/assets';
import DateFormat from '@utils/date/format';
import Colors from '@domain/constant/colors';
import { LogTypeEnum } from '@/src/logging/LogType';

const cx = classnames.bind(styles);

interface SchedulesProps {
  interviewSn: string | null;
  decisionDueDateTime: string | null;
  options: ScheduleProps[];
  onClick?: (args: { sn: string; date: string }) => void;
  openModal: (type: string) => void;
}

const Schedules = ({ interviewSn, decisionDueDateTime, options, onClick, openModal }: SchedulesProps) => {
  const optionsEl = options.map((option) => {
    const { sn, title, disabled } = option;
    const onChange = () => onClick?.({ sn, date: title });
    const isChecked = interviewSn === option.sn;

    return (
      <label key={sn} className={cx('option', { isChecked }, { disabled })}>
        <Text className={cx('text')} fontStyle={FontStyle.B2_B} color={Colors.C_COOL_GRAY_90}>
          {title}
        </Text>
        <input
          type="checkbox"
          className={cx('input', 'hidden')}
          checked={isChecked}
          onChange={onChange}
          disabled={disabled}
        />
        <span className={cx('mark', { disabled })} />
      </label>
    );
  });

  return (
    <div className={cx('scheduleArea')}>
      <div className={cx('title')}>
        <Text fontStyle={FontStyle.B2P_B}>면접 시간</Text>

        <button
          onClick={() => openModal('COORDINATION')}
          id={LogTypeEnum.log_message_company_interview_request_schedule}
        >
          <Text className={cx('info')} fontStyle={FontStyle.B3_M}>
            일정 조율
            <Icon name="arrowRightLight" width={24} height={24} />
          </Text>
        </button>
      </div>
      <div className={cx('optionArea')}>{optionsEl}</div>

      {decisionDueDateTime && (
        <div className={cx('textArea')}>
          <Text
            fontStyle={FontStyle.B2P_M}
            color={Colors.C_COOL_GRAY_70}
            styles={{ display: 'flex', alignItems: 'center', gap: 16 }}
          >
            <span className={cx('label')}>응답 기한</span>
            {DateFormat.format(new Date(decisionDueDateTime), 'yyyy.MM.dd (week)')}
          </Text>

          <Text fontStyle={FontStyle.B3P_M} color={Colors.C_COOL_GRAY_60} styles={{ marginTop: 4 }}>
            정해진 유효기간까지 제출하지 않을 경우 제출이 불가능합니다.
          </Text>
        </div>
      )}
    </div>
  );
};

export default Schedules;
