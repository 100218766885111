import React, { ReactElement } from 'react';
import classnames from 'classnames/bind';
import styles from './introduceCard.module.scss';
const cx = classnames.bind(styles);

interface IntroduceProps {
  title: string | ReactElement;
  subText: string | ReactElement;
}

const IntroduceCard = ({ title, subText }: IntroduceProps) => {
  return (
    // 기업정보 > 기업 소개 문구 카드
    <div className={cx('introduceCard')}>
      {/* 메인 텍스트 */}
      <p className={cx('title')}>{title}</p>
      {/* 서브 텍스트 */}
      <span className={cx('subText')}>{subText}</span>
    </div>
  );
};

export default IntroduceCard;
