import React from 'react';
import { useRouter } from 'next/router';
import { useCompaniesWelfare } from '@repository/companies/useCompanies';
import classnames from 'classnames/bind';
import styles from './welfare.module.scss';
const cx = classnames.bind(styles);

const Welfare = () => {
  const router = useRouter();
  const { sn } = router.query;
  const { data } = useCompaniesWelfare(Number(sn));

  const advantageEls = data?.keywords.map(({ keyword }, idx) => {
    return (
      <span key={idx} className={cx('btnMenu')}>
        {keyword}
      </span>
    );
  });

  if (!data?.keywords.length) return null;

  return (
    // 복지 영역
    <div className={cx('snInner')}>
      {/* 영역 제목 */}
      <p className={cx('sectionTitle')}>복지</p>

      {/* 복지 리스트 */}
      <div className={cx('advantagesBtn')}>{advantageEls}</div>
    </div>
  );
};

export default Welfare;
