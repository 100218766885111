export default class ArrayUtil {
  static getRandomSamples(arr: any[], n: number) {
    const result = new Array(n);
    const taken = new Array(n);
    let len = arr.length;
    if (n > len) throw new RangeError('getRandom: more elements taken than available');
    while (n--) {
      const x = Math.floor(Math.random() * len);
      result[n] = arr[x in taken ? taken[x] : x];
      taken[x] = --len in taken ? taken[len] : len;
    }
    return result;
  }

  static shuffleArray<T>(arr: T[]) {
    const newArray = [...arr];
    for (let i = arr.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [newArray[i], newArray[j]] = [newArray[j], newArray[i]];
    }

    return newArray;
  }
}
