import React, { FC, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import OrderFilter from '@components/position/list/positionFilter/filter/OrderFilter';
import JobTitlesFilter from '@components/position/list/positionFilter/filter/JobTitlesFilter';
import RecruitmentFilter from '@components/position/list/positionFilter/filter/RecruitmentFilter';
import LocationFilter from '@components/position/list/positionFilter/filter/LocationFilter';
import MatchingStatusFilter from '@components/position/list/positionFilter/filter/MatchingStatusFilter';
import SalaryFilter from '@components/position/list/positionFilter/filter/SalaryFilter';
import { curationPositionKeys } from '@components/position/list/query/useCuration';
import { keys as allKeys } from '@components/position/list/query/usePositionList';
import PositionListFilterVO from '@domain/vo/position/list/PositionListFilterVO';
import { queryClient } from '@utils/queryClient';

import classnames from 'classnames/bind';
import styles from './positionFilter.module.scss';
const cx = classnames.bind(styles);

interface Props {
  isCuration: boolean;
  curationFilterSn?: number;
}

const PositionFilter: FC<Props> = ({ isCuration, curationFilterSn }) => {
  const { reset } = useFormContext<PositionListFilterVO>();

  const onInitFilter = () => {
    reset(new PositionListFilterVO(undefined));

    //필터 초기화 할때마다 재조회하긴 위함
    const keys = isCuration ? curationPositionKeys : allKeys;
    queryClient.refetchQueries(keys, { active: true });
  };

  useEffect(() => {
    if (isCuration) {
      reset(new PositionListFilterVO(undefined));
    }
  }, [curationFilterSn]);

  return (
    <div className={cx('filterArea')}>
      <div className={cx('filterWrap')}>
        <OrderFilter isCuration={isCuration} />
      </div>

      <div className={cx('filterWrap')}>
        <JobTitlesFilter />
        <RecruitmentFilter />
        <SalaryFilter />
        <LocationFilter />
        <MatchingStatusFilter />

        <a className={cx('btnReset')} role="button" onClick={onInitFilter}>
          필터초기화
        </a>
      </div>
    </div>
  );
};

export default PositionFilter;
