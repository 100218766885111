import React from 'react';
import Icon from '@components/common/assets';
import Text from '@components/common/text';
import { useDownloadApplicationFiles } from '@repository/match';
import { AttachFileDto } from '@domain/rs/match/message/MatchingMessageListRs';

import { FontStyle } from '@domain/constant/fontStyle';
import classnames from 'classnames/bind';
import styles from './index.module.scss';
const cx = classnames.bind(styles);

interface FileTableProps {
  positionSn?: number | null;
  attachFiles?: AttachFileDto[];
}

const FileList = ({ positionSn, attachFiles }: FileTableProps) => {
  // [D] 제출 완료한 파일 목록

  const { mutateAsync: downloadApplicationFiles } = useDownloadApplicationFiles();

  const handleDownload = async (attachFileDto: AttachFileDto) => {
    const { fileName, fileUid } = attachFileDto;
    // [D] 서버 파일 다운로드
    if (fileUid && positionSn) {
      await downloadApplicationFiles({ fileName, positionSn, fileUid });
      return;
    }
  };

  if (!attachFiles?.length) return null;

  const fileItem = attachFiles.map((attachFileDto, index) => {
    return (
      <button className={cx('item')} key={index} onClick={() => handleDownload(attachFileDto)}>
        <Icon name="download" width={24} height={24} />
        <Text fontStyle={FontStyle.B3_B} ellipsis>
          {attachFileDto.fileName}
        </Text>
      </button>
    );
  });

  return <div className={cx('fileList')}>{fileItem}</div>;
};

export default FileList;
