import React, { FC, useState } from 'react';
import Link from 'next/link';
import Modal from '@common/modal';
import {
  getResultTypeText,
  moveToResults,
} from '@common/header/messageModal/messages/companyMessage/resultRequest/resultModals';

import GradeBadge from '@components/match/common/gradeBadge';
import { useTermsResultPrivacyProvision, useAgreeTermsResultPrivacyProvision } from '@repository/terms/useTerms';
import Terms from './terms';
import { AccProgressCompleteVO } from '@domain/vo/acca/test/AccProgressCompleteListVO';
import { PaccProgressCompleteVO } from '@domain/vo/phs/pacc/results/PaccProgressCompleteListVO';
import { PstProgressCompleteVO } from '@domain/vo/phs/pst/result/PstProgressCompleteListVO';
import { MatchingTalkApplyType } from '@domain/rs/match/message/MatchingMessageListRs';
import { ResultSubmitTermsEnum } from '@domain/constant/termsType';

import classnames from 'classnames/bind';
import styles from './resultSelectConfirmModal.module.scss';
const cx = classnames.bind(styles);

export const getResultTypeType = (resultType: MatchingTalkApplyType | null) => {
  switch (resultType) {
    case 'ACC':
      return ResultSubmitTermsEnum.ACC_RESULT_PRIVACY_PROVISION;
    case 'PACC':
      return ResultSubmitTermsEnum.PACC_RESULT_PRIVACY_PROVISION;
    case 'PST':
      return ResultSubmitTermsEnum.PST_RESULT_PRIVACY_PROVISION;
  }
};

interface IResultSelectConfirmModal {
  companySn: number;
  resultType: MatchingTalkApplyType | null;
  selectedResult: AccProgressCompleteVO | PaccProgressCompleteVO | PstProgressCompleteVO | null;
  isVisible: boolean;
  onCancel: () => void;
  onConfirm: (selectedResultSn: number) => void;
  onClose: () => void;
}

const ResultSelectConfirmModal: FC<IResultSelectConfirmModal> = ({
  companySn,
  resultType,
  selectedResult,
  isVisible,
  onCancel,
  onConfirm,
  onClose,
}) => {
  const { data: termsData } = useTermsResultPrivacyProvision(companySn, getResultTypeType(resultType));
  const { mutate: agreeTermsMutate } = useAgreeTermsResultPrivacyProvision(() => {
    if (selectedResult?.sn) {
      onConfirm(selectedResult.sn);
    }
  });

  const [isChecked, setIsChecked] = useState(false);
  if (!selectedResult || !termsData) return null;

  const { result, sn, applyEndDateTime } = selectedResult;
  const resultTitle = `제출할 ${getResultTypeText(resultType)} 결과`;
  const isAnalysis = result === '분석중';

  const handleChangeIsChecked = () => {
    setIsChecked(!isChecked);
  };

  const handleConfirm = () => {
    agreeTermsMutate({ companySn, termsSn: termsData.sn });
  };

  return (
    <Modal visible={isVisible} dimmed={true} isUseScrollHidden={false}>
      <div className={cx('layer')}>
        <strong className={cx('title')}>제출 전 꼭 확인하세요.</strong>

        <div className={cx('resultArea')}>
          {/* [D] 결과에 따라 텍스트 변경해주세요. */}
          <em className={cx('resultTitle')}>{resultTitle}</em>

          <div className={cx('resultItem')}>
            <div className={cx('timeTitle')}>완료시간</div>
            <div className={cx('infoArea')}>
              <div className={cx('infoWrap')}>
                <span className={cx('time')}>{applyEndDateTime}</span>
                {resultType === 'PST' && <GradeBadge grade={result} className={cx('badge')} />}
              </div>
              {isAnalysis ? (
                <a className={cx('resultLink', { disabled: isAnalysis })}>결과 자세히 보기</a>
              ) : (
                <Link href={moveToResults(resultType, sn)} legacyBehavior>
                  <a className={cx('resultLink')} target="_blank" role="button">
                    결과 자세히 보기
                  </a>
                </Link>
              )}
            </div>
          </div>
        </div>

        <ul className={cx('noticeList')}>
          <li className={cx('listItem')}>응시 결과 제출 후에는 수정이 어려울 수 있어요.</li>
          <li className={cx('listItem')}>분석 중에도 제출은 가능하지만 등급은 분석 완료 이후 확인할 수 있어요.</li>
          <li className={cx('listItem')}>제출된 결과는 지원현황에서 계속 확인할 수 있어요.</li>
        </ul>
        <div className={cx('termsArea')}>
          <Terms
            title={termsData.title}
            onClick={handleChangeIsChecked}
            contents={termsData.contents}
            isChecked={isChecked}
          />
        </div>
        <div className={cx('btnArea')}>
          <a className={cx('btn', 'cancel')} role="button" onClick={onCancel}>
            다시 선택
          </a>
          <button className={cx('btn', 'confirm')} disabled={!isChecked} onClick={handleConfirm}>
            최종 제출
          </button>
        </div>

        <a className={cx('btnClose')} role="button" onClick={onClose}>
          <span className={cx('hidden')}>close</span>
        </a>
      </div>
    </Modal>
  );
};

export default ResultSelectConfirmModal;
