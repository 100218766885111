import React, { useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';
import dynamic from 'next/dynamic';
import Icon from '@common/assets';
import GnbList from '@common/header/gnbList';
import { TOOL_TIP_EXPIRES_KEY } from '@common/header/chatTooltip';
import MessageModal from '@components/common/header/messageModal';
import { ChatToastProvider } from '@components/chat/common/chatToast';
import SiteMap from '@components/common/header/SiteMap';
import LoginModal from '@components/loginModal';
import { useLogout, useUserInfo } from '@repository/auth/useUserInfo';
import SearchModal from '@components/search/searchModal';
import { useNotificationActions, useNotificationStore } from '@stores/NotificationStore';

import { LogTypeEnum } from '@/src/logging/LogType';

import { useLoggingContext } from '@/src/logging';

import MyMatchPositionNotifications from './notification';
import { IMenu, profileSubMenus } from '@domain/constant/header';
import useClickAway from '@utils/hooks/useClickOutside';
import { RouteUrl } from '@utils/routeUtil';
import useLocalStorageExpires from '@utils/hooks/useLocalStorageExpires';
import { useKeyDown } from '@utils/hooks/useKeyboard';
import classnames from 'classnames/bind';
import styles from './header.module.scss';
const cx = classnames.bind(styles);

const ChatProvider = dynamic(() => import('@components/chat/ChatContext'), {
  ssr: false,
});
const Chat = dynamic(() => import('@components/chat'), {
  ssr: false,
});

const HeaderLogo = () => {
  return (
    <Link className={cx('logo')} href={RouteUrl.INDEX} id={LogTypeEnum.log_gnb_menu}>
      <>
        <Icon name="logo" width={80} height={18} />
        <span className={cx('hidden')}>JOBDA</span>
      </>
    </Link>
  );
};

type subMenuType = 'profile';
interface ISubMenuListProps {
  list: IMenu[];
  type?: subMenuType;
}
const SubMenuList = ({ list, type }: ISubMenuListProps) => {
  const { push } = useRouter();
  const { mutateAsync: logout } = useLogout();
  const { handleLoggingNewTab } = useLoggingContext();

  const subMenuEls = list.map((item, i) => {
    const onClickMenu = (e: any) => {
      if (item.isNewWindow) {
        handleLoggingNewTab(e, item.url);
        window.open(item.url);
        return;
      }

      push({ pathname: item.url, query: item.query });
    };

    return (
      <li key={i} className={cx('listItem')}>
        <a className={cx('subMenuTitle')} role={'button'} onClick={onClickMenu}>
          {item.title}
        </a>
      </li>
    );
  });

  return (
    <ul className={cx('subMenuList')}>
      {subMenuEls}
      {type === 'profile' && (
        <li className={cx('listItem', 'account')}>
          <a className={cx('subMenuTitle')} onClick={() => logout()} role="button">
            로그아웃
          </a>
        </li>
      )}
    </ul>
  );
};

const Header = () => {
  const alarmRef = useRef<HTMLDivElement | null>(null);
  const isTooltipVisible = useNotificationStore((state) => state.isTooltipVisible);
  const unreadCount = useNotificationStore((state) => state.count);
  const notifications = useNotificationStore((state) => state.notifications);
  const { setTooltipVisible, updateCreatedDateTime, subscribeNotification } = useNotificationActions();

  const { data: userInfo } = useUserInfo();
  const isLogin = !!userInfo?.id;
  const profileImageUrl = userInfo?.profileImageUrl;

  const { setExpires } = useLocalStorageExpires();

  const [loginModalVisible, setLoginModalVisible] = useState(false); // 로그인 모달
  const [searchModalVisible, setSearchModalVisible] = useState(false); // 검색 모달
  const [chatSNBVisible, setChatSNBVisible] = useState(false); // 채팅 SNB
  // const [chatTooltipVisible, setChatTooltipVisible] = useState(false);
  const [siteMapVisible, setSiteMapVisible] = useState(false); // GNB 메뉴

  const hasNotification = unreadCount > 0;

  const openLoginModal = () => setLoginModalVisible(true);
  const closeLoginModal = () => setLoginModalVisible(false);
  const handleSearchModalVisible = () => setSearchModalVisible(!searchModalVisible);
  const handleCloseMatchMenu = () => setTooltipVisible(false);

  const handleClickChat = () => {
    setExpires({ key: TOOL_TIP_EXPIRES_KEY });
    setChatSNBVisible(!chatSNBVisible);
    // setChatTooltipVisible(false);
  };

  const handleOpen = async () => {
    const isActionToOpen = !isTooltipVisible;
    updateCreatedDateTime();
    setTooltipVisible(isActionToOpen);
  };

  const toggleSiteMapVisible = () => {
    setSiteMapVisible((prev) => !prev);
  };

  useEffect(() => {
    if (isLogin) {
      subscribeNotification();
    }
  }, [isLogin]);

  useClickAway(handleCloseMatchMenu, alarmRef, 'click');
  useKeyDown(() => {
    const modalContainer = document.body.querySelector('#modal');
    if (!modalContainer || modalContainer.childElementCount > 0) return;

    if (siteMapVisible) {
      setSiteMapVisible(false);
    }
  }, 'Escape');

  return (
    <>
      <MessageModal />

      <SiteMap visible={siteMapVisible} toggleSiteMapVisible={toggleSiteMapVisible} />

      <header className={cx('header')} data-id={'header'}>
        <div className={cx('headerContainer')}>
          <div className={cx('headerInner')}>
            <button className={cx('gnbMenu')} onClick={toggleSiteMapVisible}>
              <Icon name={siteMapVisible ? 'closeLight' : 'moveLight'} width={32} height={32} />
              <span className={cx('hidden')}>메뉴</span>
            </button>

            <HeaderLogo />

            <GnbList siteMapVisible={siteMapVisible} />
          </div>

          <div className={cx('headerInner')}>
            <div className={cx('headerWrap')}>
              <div className={cx('btnWrap')} id={LogTypeEnum.log_gnb_search}>
                <button
                  className={cx('btn', 'iconWrap', 'gnb_search', { selected: searchModalVisible })}
                  onClick={handleSearchModalVisible}
                >
                  <Icon name="searchLight" width={32} height={32} />
                  <span className={cx('hidden')}>검색</span>
                </button>
                {/* 검색 모달 영역 */}
                {searchModalVisible && <SearchModal onClose={handleSearchModalVisible} />}
              </div>

              <div className={cx('btnWrap')} id={LogTypeEnum.log_gnb_chat}>
                <button
                  className={cx('btn', 'iconWrap', 'chat', 'gnb_chat', { selected: chatSNBVisible })}
                  onClick={handleClickChat}
                >
                  <Icon name="messageLight" width={32} height={32} />
                  <span className={cx('hidden')}>채팅</span>
                </button>
                {/* <ChatTooltip
                  chatTooltipVisible={chatTooltipVisible}
                  setChatTooltipVisible={setChatTooltipVisible}
                  setChatSNBVisible={setChatSNBVisible}
                /> */}
                {/* 채팅 SNB 영역 */}
                <ChatProvider>
                  <ChatToastProvider>
                    <Chat chatSNBVisible={chatSNBVisible} setChatSNBVisible={setChatSNBVisible} />
                  </ChatToastProvider>
                </ChatProvider>
              </div>

              {isLogin ? (
                <>
                  <div ref={alarmRef} className={cx('btnWrap')} id={LogTypeEnum.log_gnb_notification}>
                    {/* 'gnb_alarm' 유저 트래킹용 클래스 */}
                    <button
                      className={cx(
                        'btn',
                        'iconWrap',
                        'gnb_alarm',
                        { shake: hasNotification },
                        { selected: isTooltipVisible },
                      )}
                      onClick={handleOpen}
                    >
                      <Icon name="bellLight" width={32} height={32} />
                      <span className={cx('hidden')}>alert</span>
                      {hasNotification && (
                        <span className={cx('iconNew')}>
                          <Icon name="new" width={10} height={11} />
                        </span>
                      )}
                    </button>
                    {isTooltipVisible && (
                      <MyMatchPositionNotifications isLogin={isLogin} notifications={notifications} />
                    )}
                  </div>

                  <div className={cx('btnWrap', 'moreGap')}>
                    <button
                      aria-label={`프로필`}
                      className={cx('btn', 'profile', { default: !profileImageUrl })}
                      style={profileImageUrl ? { backgroundImage: `url(${profileImageUrl})` } : {}}
                    >
                      {!profileImageUrl && <Icon name="profileDefault" width={40} height={40} />}
                    </button>
                    <div className={cx('subMenuListArea')} id={LogTypeEnum.log_gnb_hover_menu}>
                      <SubMenuList list={profileSubMenus} type="profile" />
                    </div>
                  </div>
                </>
              ) : (
                <div className={cx('btnWrap', 'moreGap')}>
                  <button aria-label={'로그인 / 회원가입'} className={cx('btn', 'login')} onClick={openLoginModal}>
                    로그인 / 회원가입
                  </button>
                  <LoginModal
                    subTitle="채용정보 · 역량검사 · 매칭까지"
                    title="취업기회 지금 바로 잡다!"
                    visible={loginModalVisible}
                    onClose={closeLoginModal}
                  />
                </div>
              )}
            </div>

            <div className={cx('headerWrap')}>
              <div className={cx('btnWrap')}>
                <Link href={RouteUrl.PREPARATION} legacyBehavior>
                  <button className={cx('btn', 'primary')}>취업준비</button>
                </Link>
              </div>

              <div className={cx('btnWrap')}>
                <Link className={cx('btn', 'outLine')} href={RouteUrl.MATCH_JOBDA_IM} target="_blank" rel="noreferrer">
                  기업서비스
                  <Icon name="arrowRightLight" width={24} height={24} />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
