import React, { FC } from 'react';

import { useRouter } from 'next/router';
import { useUserInfo } from '@repository/auth/useUserInfo';
import useProfileConditions from '@repository/profiles/useProfileConditions';
import { useJobGroupsWithTitles } from '@repository/codes/useCodes';
import { FILTER_STORAGE_KEY } from '@components/position/list';
import { LocationCode, LocationShort } from '@domain/constant/locationType';
import SessionStorageUtil from '@utils/storage/sessionStorage';
import { RouteUrl } from '@utils/routeUtil';
import ArrayUtil from '@utils/array/util';
import classnames from 'classnames/bind';
import styles from './searchModal.module.scss';
const cx = classnames.bind(styles);

interface ChipItemProps {
  title: string;
  onClose: () => void;
  onClick: () => void;
}
const ChipItem: FC<ChipItemProps> = ({ title, onClose, onClick }) => {
  const router = useRouter();
  return (
    <button
      className={cx('chip')}
      onClick={() => {
        if (router.pathname !== RouteUrl.POSITION) {
          onClick();
        } else {
          onClick();
          onClose();
        }
        router.replace(
          {
            pathname: RouteUrl.POSITION,
            query: { reload: true },
          },
          undefined,
          { shallow: true },
        );
      }}
    >
      #{title}
    </button>
  );
};

interface PersonalFitKeywordProps {
  onClose: () => void;
}
const PersonalFitKeyword: FC<PersonalFitKeywordProps> = ({ onClose }) => {
  const { data: userInfo } = useUserInfo();
  const isLogin = !!userInfo?.id;
  const { data: profileConditionsData } = useProfileConditions(isLogin);
  const { data: jobGroupsWithTitles } = useJobGroupsWithTitles();

  const chipClick = (key: string, data: any) => {
    SessionStorageUtil.setItem(FILTER_STORAGE_KEY, { [key]: [data] });
  };

  const getJobTitles = () => {
    const userJobTitles = profileConditionsData?.jobGroups.flatMap((jobTitles) => jobTitles.jobTitles) ?? [];
    const randomUserJobTitles = ArrayUtil.shuffleArray(userJobTitles).slice(0, 3);
    const jobTitles = jobGroupsWithTitles?.flatMap((jobTitles) => jobTitles.jobTitles) ?? [];
    const randomJobTitles = ArrayUtil.shuffleArray(jobTitles)[0];

    if (randomUserJobTitles?.length) {
      return randomUserJobTitles.map((jobTitle) => (
        <ChipItem
          key={jobTitle.jobTitleCodes}
          title={jobTitle.jobTitleNames}
          onClick={() => chipClick('jobTitles', { code: jobTitle.jobTitleCodes, name: jobTitle.jobTitleNames })}
          onClose={onClose}
        />
      ));
    }

    return (
      <ChipItem
        title={randomJobTitles?.name}
        onClick={() => chipClick('jobTitles', randomJobTitles)}
        onClose={onClose}
      />
    );
  };

  const getLocations = () => {
    const userLocations = profileConditionsData?.locations
      ? ArrayUtil.shuffleArray(profileConditionsData.locations).slice(0, 2)
      : [];

    if (userLocations.length) {
      return (
        <>
          {userLocations.map((location) => (
            <ChipItem
              key={location}
              title={LocationShort[LocationCode[location] as keyof typeof LocationCode]}
              onClick={() => chipClick('locations', LocationCode[location])}
              onClose={onClose}
            />
          ))}
        </>
      );
    }

    return (
      <>
        <ChipItem title={LocationShort['SEOUL']} onClick={() => chipClick('locations', 'SEOUL')} onClose={onClose} />
        <ChipItem
          title={LocationShort['GYEONGGI']}
          onClick={() => chipClick('locations', 'GYEONGGI')}
          onClose={onClose}
        />
      </>
    );
  };

  const getSalary = () => {
    const userSalary = profileConditionsData?.salary;
    if (userSalary) {
      return (
        <ChipItem
          title={`연봉 ${userSalary}만원 이상`}
          onClick={() => SessionStorageUtil.setItem(FILTER_STORAGE_KEY, { minSalary: userSalary, maxSalary: 10000 })}
          onClose={onClose}
        />
      );
    }

    return (
      <ChipItem
        title={'연봉 4000만원 이상'}
        onClick={() => SessionStorageUtil.setItem(FILTER_STORAGE_KEY, { minSalary: 4000, maxSalary: 10000 })}
        onClose={onClose}
      />
    );
  };

  return (
    <div className={cx('keywordArea')}>
      {getJobTitles()}
      {getLocations()}
      {getSalary()}
    </div>
  );
};

export default PersonalFitKeyword;
