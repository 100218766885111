export enum Result {
  VERY_GOOD = 'VERY_GOOD',
  GOOD = 'GOOD',
  CHEER_UP = 'CHEER_UP',
}

export const resultTypeStringMap: Record<ResultType, string> = {
  VERY_GOOD: 'veryGood',
  GOOD: 'good',
  CHEER_UP: 'cheerUp',
};

export const resultTypeCamelCaseMap: Record<ResultType, string> = {
  VERY_GOOD: 'Very Good',
  GOOD: 'Good',
  CHEER_UP: 'Cheer Up',
};

export type ResultType = keyof typeof Result;
