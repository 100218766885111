import React, { useState } from 'react';
import { usePositionAgreeInformation, useSavePositionTerms, useTerms } from '@repository/terms/useTerms';
import FileTable from './fileTable';
import MessageContents from './messageContents';
import FileAttachmentModals, { FileAttachmentModalType } from './fileAttachmentModals';
import FileSubmit from './fileSubmit';
import MatchMessageVO from '@domain/vo/match/message/MatchMessageVO';

import classnames from 'classnames/bind';
import styles from './fileAttachment.module.scss';
const cx = classnames.bind(styles);

interface FileAttachmentMessageProps {
  positionSn: number;
  companyMessage: MatchMessageVO;
  openYn: boolean;
  deleteYn: boolean;
}

const FileAttachment = ({ positionSn, companyMessage, openYn, deleteYn }: FileAttachmentMessageProps) => {
  const {
    title,
    message,
    userAttachFiles,
    companyAttachFiles,
    decision,
    decisionDateTime,
    decisionDueDateTimeOriginal,
    options,
  } = companyMessage;
  const [fileAttachmentModalState, setFileAttachmentModalState] = useState<FileAttachmentModalType | null>(null);
  const [filesState, setFilesState] = useState<File[]>([]);
  const { data: positionAgreeInformationData, refetch: refetchAgreeInfo } = usePositionAgreeInformation();
  const { data: positionPrivacyPolicyTermsData } = useTerms({
    termType: 'POSITION_PRIVACY_POLICY',
    termRequired: true,
    options: {
      enabled: positionAgreeInformationData?.privacyPolicyYn === false,
    },
  });
  const { data: positionPrivacyProvisionTermsData } = useTerms({
    termType: 'POSITION_PRIVACY_PROVISION',
    termRequired: true,
    options: {
      enabled: positionAgreeInformationData?.privacyProvisionYn === false,
    },
  });
  const { mutateAsync: savePositionTerms } = useSavePositionTerms({
    onSuccess: () => {
      refetchAgreeInfo();
    },
  });

  const isCompleted = !!decision; // 완료된 요청
  const isUploaded = filesState.length > 0;

  const provisionSnList = positionPrivacyProvisionTermsData?.map((term) => term.sn);
  const policySnList = positionPrivacyPolicyTermsData?.map((term) => term.sn);

  const onAgreeTerms = async () => {
    const result = await savePositionTerms({ termsSns: [...(provisionSnList ?? []), ...(policySnList ?? [])] });
    if (result) {
      setFileAttachmentModalState(null);
    } else setFileAttachmentModalState('SERVICE_ERROR');
  };

  return (
    <div className={cx('noticeArea')}>
      {/* [D] 메세지 영역 */}
      <MessageContents
        title={title}
        message={message}
        decisionDueDateTimeOriginal={decisionDueDateTimeOriginal}
        attachFiles={companyAttachFiles}
        positionSn={positionSn}
      />

      {/* [D] 파일 리스트 영역 */}
      <FileTable
        positionSn={positionSn}
        filesState={filesState}
        setFilesState={setFilesState}
        isCompleted={isCompleted}
        attachFiles={userAttachFiles}
        setFileAttachmentModalState={setFileAttachmentModalState}
        openYn={openYn}
        deleteYn={deleteYn}
      />

      {/* [D] 파일 제출 버튼 영역 */}
      <FileSubmit
        isCompleted={isCompleted}
        isUploaded={isUploaded}
        decisionDateTime={decisionDateTime}
        setFileAttachmentModalState={setFileAttachmentModalState}
        openYn={openYn}
        deleteYn={deleteYn}
      />

      {/* [D] 파일 제출 모달 영역 */}
      <FileAttachmentModals
        positionSn={positionSn}
        optionSn={options[0]?.sn}
        filesState={filesState}
        fileAttachmentModalState={fileAttachmentModalState}
        setFileAttachmentModalState={setFileAttachmentModalState}
        onAgreeTerms={onAgreeTerms}
      />
    </div>
  );
};

export default FileAttachment;
