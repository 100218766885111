import React, { useState } from 'react';
import Modal from '@common/modal';
import Text from '@components/common/text';
import Icon from '@components/common/assets';
import Button from '@components/common/button';
import { useMessageActions } from '@stores/MessageStore';
import { useUploadApplicationFiles } from '@repository/match';
import { FontStyle } from '@domain/constant/fontStyle';
import Colors from '@domain/constant/colors';
import classnames from 'classnames/bind';
import styles from './fileAttachmentConfirmModal.module.scss';

const cx = classnames.bind(styles);

interface FileAttachmentConfirmModal {
  visible: boolean;
  onClose: () => void;
  filesState: File[];
  positionSn: number | null;
  optionSn: string | null;
  handleErrors: (errorCode: string) => void;
}

const FileAttachmentConfirmModal = ({
  visible,
  onClose,
  filesState,
  positionSn,
  optionSn,
  handleErrors,
}: FileAttachmentConfirmModal) => {
  const { mutateAsync: uploadApplicationFiles } = useUploadApplicationFiles();
  const [isLoading, setIsLoading] = useState(false);
  const { fetchMessagesList } = useMessageActions();

  const handleSubmit = async () => {
    if (!positionSn || !optionSn || isLoading) return;
    setIsLoading(true);
    try {
      await uploadApplicationFiles({ positionSn, optionSn: Number(optionSn), files: filesState });
      onClose();
    } catch (e: any) {
      handleErrors(e);
    } finally {
      await fetchMessagesList(positionSn);
      setIsLoading(false);
    }
  };

  const filesEl = filesState.map((file, index) => {
    return (
      <div key={index} className={cx('fileItem')}>
        <Icon name="fileAddLight" width={24} height={24} />
        <Text fontStyle={FontStyle.B2P_M} color={Colors.C_COOL_GRAY_70} ellipsis>
          {file.name}
        </Text>
      </div>
    );
  });

  return (
    <Modal visible={visible} isUseScrollHidden={false}>
      <div className={cx('layer')}>
        <Text fontStyle={FontStyle.H4_B}>파일을 제출하시겠어요?</Text>
        <Text fontStyle={FontStyle.B2_M} color={Colors.C_COOL_GRAY_70} styles={{ marginTop: 16 }}>
          제출하시는 내용을 잘 확인하셨나요?
        </Text>
        <Text fontStyle={FontStyle.B3_M} color={Colors.C_ERROR} styles={{ marginTop: 8 }}>
          *최종 제출 이후에는 수정이 불가능합니다.
        </Text>

        <div className={cx('fileConfirmArea')}>
          <div className={cx('fileConfirmInner')}>
            <div className={cx('fileConfirmWrap')}>{filesEl}</div>
          </div>
        </div>

        <div className={cx('btnArea')}>
          <Button theme="secondary" className={cx('btn')} onClick={onClose} fontStyle={FontStyle.B2_B}>
            취소
          </Button>
          <Button className={cx('btn')} onClick={handleSubmit} fontStyle={FontStyle.B2_B} isLoading={isLoading}>
            제출
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default FileAttachmentConfirmModal;
