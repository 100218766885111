import React from 'react';
import Modal from '@common/modal';
import CloseButton from '@components/common/closeButton';
import Text from '@components/common/text';
import { useNotificationStore } from '@stores/NotificationStore';
import { useAcceptInterview } from '@repository/match';
import { useMessageActions } from '@stores/MessageStore';
import { LogTypeEnum } from '@/src/logging/LogType';
import { FontStyle } from '@domain/constant/fontStyle';
import { hasErrorCode } from '@utils/typeGuard';
import classnames from 'classnames/bind';
import styles from './acceptModal.module.scss';

const cx = classnames.bind(styles);

interface AcceptModalProps {
  visible: boolean;
  optionSn: number | null;
  interviewLocation: string | null;
  date?: string | null;
  handleErrors: (result: string) => void;
  onClose: () => void;
}

const AcceptModal = ({ visible, optionSn, interviewLocation, date, handleErrors, onClose }: AcceptModalProps) => {
  const { fetchMessagesList } = useMessageActions();
  const { mutateAsync: acceptInterview } = useAcceptInterview();
  const positionInfo = useNotificationStore((state) => state.positionInfo);

  if (!positionInfo || !positionInfo.positionSn || !optionSn) return null;

  const { positionSn } = positionInfo;

  const accept = async () => {
    try {
      await acceptInterview({ optionSn, positionSn });
    } catch (e) {
      if (hasErrorCode(e)) handleErrors(e.response.data.errorCode);
    }
    await fetchMessagesList(positionSn);
    onClose();
  };

  return (
    <Modal visible={visible} isUseScrollHidden={false}>
      <div className={cx('inner')}>
        <CloseButton className={cx('btnClose')} onClick={onClose} />

        <Text fontStyle={FontStyle.H4_B}>면접 제안을 수락하시겠어요?</Text>

        <p className={cx('notification')}>
          면접 장소와 시간을 잘 확인해 주세요.
          <br />
          수락한 제안을 취소할 수 없으니 신중하게 선택해 주세요.
        </p>

        {(interviewLocation || date) && (
          <div className={cx('placeArea')}>
            {interviewLocation && (
              <Text fontStyle={FontStyle.B2P_B}>
                면접 장소 <span className={cx('text')}>{interviewLocation}</span>
              </Text>
            )}
            {date && (
              <Text fontStyle={FontStyle.B2P_B}>
                면접 시간 <span className={cx('text')}>{date}</span>
              </Text>
            )}
          </div>
        )}

        <button className={cx('btn')} onClick={accept} id={LogTypeEnum.log_message_company_interview_accept}>
          수락하기
        </button>
      </div>
    </Modal>
  );
};
export default AcceptModal;
