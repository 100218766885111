import React, { useState } from 'react';
import Modal from '@common/modal';
import { useGetAgreeUrl, usePostProcessingConsent } from '@repository/match';
import { V2Route } from '@utils/routeUtil';

import classnames from 'classnames/bind';
import styles from './agreementModal.module.scss';
const cx = classnames.bind(styles);

interface AgreementModalProps {
  visible: boolean;
  onClose: () => void;
  positionSn: number;
}

const AgreementModal = ({ visible, onClose, positionSn }: AgreementModalProps) => {
  const { mutate: fetchProcessingConsent } = usePostProcessingConsent(() => onClose()); // 동의하기
  const { data: agreeUrlData } = useGetAgreeUrl(positionSn, visible); // 동의문 url
  const detailUrl = V2Route.getResumeThirdpartyAgreementPage(agreeUrlData?.url ?? '');
  const [isCheck, setIsCheck] = useState<boolean>(true);

  return (
    <Modal visible={visible}>
      <div className={cx('layer')}>
        <strong className={cx('title')}>지원서 확인을 위한 동의가 필요해요.</strong>

        <div className={cx('serviceArea')}>
          <em className={cx('subTitle')}>
            잡다에 지원서 저장하고, <br />
            이용할 수 있는 서비스를 확인해보세요.
          </em>

          <ul className={cx('serviceList')}>
            <li className={cx('listItem', 'resumeCheck')}>
              <div className={cx('serviceTitle')}>지원서 확인하기</div>
              <p className={cx('serviceDesc')}>
                제출한 지원서를
                <br />한 눈에 확인해보세요.
              </p>
            </li>
            <li className={cx('listItem', 'resumeLoad')}>
              <div className={cx('serviceTitle')}>지원서 불러오기</div>
              <p className={cx('serviceDesc')}>
                다른 지원서 작성에
                <br />
                활용해보세요.
              </p>
            </li>
            <li className={cx('listItem', 'resumeManagement')}>
              <div className={cx('serviceTitle')}>지원현황 통합 관리</div>
              <p className={cx('serviceDesc')}>
                작성한 지원서를
                <br />한 곳에 모아보세요.
              </p>
            </li>
          </ul>
        </div>

        <div className={cx('agreementArea')}>
          <label className={cx('label')}>
            <input
              type="checkbox"
              className={cx('input', 'hidden')}
              defaultChecked={!isCheck ? true : false}
              onClick={(e) => (e.currentTarget.checked ? setIsCheck(false) : setIsCheck(true))}
            />
            <span className={cx('checkbox')} />
            (필수) 지원서 작성하기 서비스에 대한 개인정보 제3자 제공 동의
          </label>
          <a className={cx('btnDetail')} role="button" target="_blank" rel="noreferrer" href={detailUrl}>
            자세히
          </a>
        </div>

        <button
          className={cx('btnConfirm')}
          type="button"
          disabled={isCheck}
          onClick={() => fetchProcessingConsent(positionSn)}
        >
          동의하기
        </button>

        <a className={cx('btnClose')} role="button" onClick={onClose}>
          <span className={cx('hidden')}>close</span>
        </a>
      </div>
    </Modal>
  );
};

export default AgreementModal;
