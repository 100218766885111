import React from 'react';
import { useRouter } from 'next/router';
import { useCompaniesInformation } from '@repository/companies/useCompanies';
import classnames from 'classnames/bind';
import styles from './companyInfo.module.scss';
const cx = classnames.bind(styles);

const CompanyInfo = () => {
  const router = useRouter();
  const { sn } = router.query;
  const { data } = useCompaniesInformation(Number(sn));

  return (
    <div className={cx('infoInner')}>
      <div className={cx('basicTitle')}>
        {data?.companyName}의 정보를
        <br />
        간략하게 알려드릴게요.
      </div>
      <div className={cx('infoList')}>
        <div className={cx('infoItem')}>
          <span className={cx('infoTitle')}>기업 규모</span>
          <span className={cx('infoDesc')}>{data?.companyDetail.businessSizeText}</span>
        </div>
        <div className={cx('infoItem')}>
          <span className={cx('infoTitle')}>산업</span>
          <span className={cx('infoDesc', { noData: data?.companyDetail.industryName === '-' })}>
            {data?.companyDetail.industryName}
          </span>
        </div>
        <div className={cx('infoItem')}>
          <span className={cx('infoTitle')}>{new Date().getFullYear()} 매출액</span>
          <span className={cx('infoDesc', { noData: !data?.companyDetail.revenue })}>
            {data?.companyDetail.revenueText}
          </span>
        </div>
        <div className={cx('infoItem')}>
          <span className={cx('infoTitle')}>직원 수</span>
          <span className={cx('infoDesc', { noData: !data?.companyDetail.numberOfEmployees })}>
            {data?.companyDetail.numberOfEmployeesText}
          </span>
        </div>
        <div className={cx('infoItem')}>
          <span className={cx('infoTitle')}>평균 근속</span>
          <span className={cx('infoDesc', { noData: !data?.companyDetail.averageWorkingYears })}>
            {data?.companyDetail.averageWorkingYearsText}
          </span>
        </div>
      </div>
    </div>
  );
};

export default CompanyInfo;
