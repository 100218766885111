import { ReactElement } from 'react';
import Colors from '@domain/constant/colors';

export type LineChartColorsType = 'black' | 'green' | 'blue';
export type LineChartSizeType = 'small' | 'large';

export const EDGE_SPACE = {
  small: 1.2,
  large: 0.66,
};

export const LineColors = {
  black: {
    small: Colors.C_COOL_GRAY_60,
    large: Colors.C_COOL_GRAY_80,
  },
  green: {
    small: Colors.C_GREEN_90,
    large: Colors.C_GREEN_200,
  },
  blue: {
    small: Colors.C_BLUE_60,
    large: Colors.C_BLUE_100,
  },
};

export interface ChartData {
  column: number | null;
  toolTip?: ReactElement;
}
