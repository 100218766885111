import { useState } from 'react';
import { fetchProfileResumeDetail, fetchProfileResumeSn } from '@repository/profileResume/useProfileResume';
import { LogTypeEnum } from '@/src/logging/LogType';
import MatchingProfilePreviewModal from '../../common/MatchingProfilePreviewModal';
import ProfileDetailVO from '@domain/vo/profile/ProfileDetailVO';
import useModal from '@utils/hooks/useModal';

import { useKeyDown } from '@utils/hooks/useKeyboard';
import classnames from 'classnames/bind';
import styles from './resumeBtn.module.scss';
const cx = classnames.bind(styles);

interface Props {
  positionSn: number | null;
  btnText?: string;
}

const ProfileResumeViewBtn = ({ positionSn, btnText = '지원서 확인하기' }: Props) => {
  const [profileDetail, setProfileDetail] = useState<ProfileDetailVO | null>(null);
  const { closeModal, isModalOpen, openModal } = useModal();

  useKeyDown(closeModal, 'Escape');

  if (!positionSn) return null;

  const handleClick = async () => {
    if (profileDetail !== null) {
      openModal();
      return;
    }

    const profileResumeSn = await fetchProfileResumeSn(positionSn);
    if (!profileResumeSn || isNaN(Number(profileResumeSn))) return;

    const detail = await fetchProfileResumeDetail(profileResumeSn);
    if (!detail) return;
    setProfileDetail(detail);
    openModal();
  };

  return (
    <>
      <button
        className={cx('resumeBtnArea', `btnImg${'Submit'}`, 'previewProfileBtn')}
        onClick={handleClick}
        id={LogTypeEnum.log_message_user_submit_profile}
      >
        {btnText}
      </button>

      {/* 프로필 미리보기 */}
      {profileDetail && (
        <MatchingProfilePreviewModal profileDetail={profileDetail} visible={isModalOpen} onClose={closeModal} />
      )}
    </>
  );
};

export default ProfileResumeViewBtn;
