import { UseQueryOptions, useMutation, useQuery } from 'react-query';
import { AxiosError } from 'axios';
import { RemoteTermsRepo } from '@repository/terms/TermsRepo';
import { RemoteUserTermsAgreesRepo } from '@repository/userTermsAgrees/UserTermsAgreesRepo';
import { AgreeTermsThirdPartyPrivacyProvisionRq } from '@domain/rq/terms/AgreeTermsThirdPartyPrivacyProvisionRq';
import { ThirdPartyProvideTermsGetRq } from '@domain/rq/terms/ThirdPartyProvideTermsGetRq';
import { AgreePositionInformationRq } from '@domain/rq/terms/AgreePositionInformationRq';

import TermsVO from '@domain/vo/terms/TermsVO';
import { resultSubmitTermsType, TERMS_TYPE, THIRD_PARTY_TERMS_TYPE } from '@domain/constant/termsType';

export const keys = ['terms'];
const remoteTermsRepo = new RemoteTermsRepo();
const remoteUserTermsAgreesRepo = new RemoteUserTermsAgreesRepo();

const fetchTermsOne = async (termsSn?: number | null) => {
  if (!termsSn) return undefined;
  const { data } = await remoteTermsRepo.fetchTermsOne(termsSn);
  return data.terms;
};

const fetchTermsResultPrivacyProvision = async (companySn: number, termsType?: resultSubmitTermsType) => {
  if (!termsType) return;
  const { data } = await remoteTermsRepo.fetchTermsResultPrivacyProvision(companySn, termsType);
  return data;
};

const fetchAgreeTermsResultPrivacyProvision = async (companySn: number, termsSn: number) => {
  const { data } = await remoteTermsRepo.fetchAgreeTermsResultPrivacyProvision(companySn, termsSn);
  return data;
};

const fetchTermsList = async (termType: TERMS_TYPE, termRequired?: boolean) => {
  const { data } = await remoteTermsRepo.fetchTermsList(termType);
  return data.terms.map((item) => new TermsVO(item, termRequired));
};

const fetchTermsThirdPartyPrivacyProvision = async (rq: ThirdPartyProvideTermsGetRq) => {
  const { data } = await remoteTermsRepo.fetchTermsThirdPartyPrivacyProvision(rq);
  return data;
};

const fetchAgreeTermsThirdPartyPrivacyProvision = async (rq: AgreeTermsThirdPartyPrivacyProvisionRq) => {
  const { data } = await remoteTermsRepo.fetchAgreeTermsThirdPartyPrivacyProvision(rq);
  return data;
};

const fetchTermsThirdPartyPrivacyProvisionAgreement = async (
  termsType: THIRD_PARTY_TERMS_TYPE,
  companyName: string,
) => {
  const { data } = await remoteTermsRepo.fetchTermsThirdPartyPrivacyProvisionAgreement(termsType, companyName);
  return data;
};

const fetchPositionAgreeInformation = async () => {
  const { data } = await remoteUserTermsAgreesRepo.fetchPositionAgreeInformation();
  return data;
};

const savePositionTerms = async (rq: AgreePositionInformationRq) => {
  const { data } = await remoteUserTermsAgreesRepo.savePositionTerms(rq);
  return data;
};

// useQuery ///
//약관 본문 조회
export function useTermsOne(termsSn?: number | null, isEnabled = true) {
  return useQuery([...keys, termsSn], () => fetchTermsOne(termsSn), {
    enabled: isEnabled && !!termsSn,
  });
}

export function useTerms({
  termType,
  termRequired,
  options,
}: {
  termType: TERMS_TYPE;
  termRequired?: boolean;
  options?: UseQueryOptions;
}) {
  return useQuery([...keys, termType], () => fetchTermsList(termType, termRequired), options as any);
}

export function useTermsResultPrivacyProvision(companySn: number, termsType?: resultSubmitTermsType) {
  return useQuery(
    [...keys, 'result', 'privacyProvision', companySn, termsType],
    () => fetchTermsResultPrivacyProvision(companySn, termsType),
    {
      enabled: !!termsType,
    },
  );
}

export function useTermsThirdPartyPrivacyProvision(rq: ThirdPartyProvideTermsGetRq) {
  const { termsType, companyName, provideItems } = rq;
  return useQuery([...keys, 'third-party', rq], () => fetchTermsThirdPartyPrivacyProvision(rq), {
    enabled: !!(termsType && companyName && provideItems),
  });
}

export function usePositionAgreeInformation() {
  return useQuery([...keys, 'position'], fetchPositionAgreeInformation);
}

// useMutation ///

export function useAgreeTermsResultPrivacyProvision(onSuccess?: () => void) {
  return useMutation(
    (termsRq: { companySn: number; termsSn: number }) =>
      fetchAgreeTermsResultPrivacyProvision(termsRq.companySn, termsRq.termsSn),
    {
      onSuccess,
      onError: (error: AxiosError) => {
        console.log(error.response?.data);
        return;
      },
    },
  );
}

export function useTermsThirdPartyPrivacyProvisionAgreement() {
  return useMutation((termsRq: { termsType: THIRD_PARTY_TERMS_TYPE; companyName: string }) =>
    fetchTermsThirdPartyPrivacyProvisionAgreement(termsRq.termsType, termsRq.companyName),
  );
}

export function useAgreeTermsThirdPartyPrivacyProvision() {
  return useMutation((rq: AgreeTermsThirdPartyPrivacyProvisionRq) => fetchAgreeTermsThirdPartyPrivacyProvision(rq));
}

export function useSavePositionTerms(options?: UseQueryOptions) {
  return useMutation((rq: AgreePositionInformationRq) => savePositionTerms(rq), options as any);
}
