import { SpecialMessageDto } from '@domain/rs/match/report/MatchingReportCompanyRs';
import { MatchingReportCompetencyAnalysisDto } from '@domain/rs/match/report/MatchingReportRs';

export default class SpecialMessageParsingVO {
  greeting: string;
  companyIntroduction: string;
  applicantSpecialMessage: string[];
  closingRemark: string;

  constructor(
    specialMessage: SpecialMessageDto,
    competencyAnalysis: MatchingReportCompetencyAnalysisDto,
    name: string,
    companyName: string,
  ) {
    this.greeting = this.getReplaceStr(specialMessage.greeting, competencyAnalysis, name, companyName) as string;
    this.companyIntroduction = this.getReplaceStr(
      specialMessage.companyIntroduction,
      competencyAnalysis,
      name,
      companyName,
    ) as string;
    this.applicantSpecialMessage = this.getReplaceStr(
      specialMessage.applicantSpecialMessage,
      competencyAnalysis,
      name,
      companyName,
      true,
    ) as string[];
    this.closingRemark = this.getReplaceStr(
      specialMessage.closingRemark,
      competencyAnalysis,
      name,
      companyName,
    ) as string;
  }

  private getReplaceStr(
    str: string | null,
    competencyAnalysis: MatchingReportCompetencyAnalysisDto,
    name: string,
    companyName: string,
    split: boolean = false,
  ) {
    if (!str) return [];

    const replaceStr = str
      .replace(/\$이름\$/g, name)
      .replace(/\$강점1\$/g, competencyAnalysis.strengths[0].keyword)
      .replace(/\$강점2\$/g, competencyAnalysis.strengths[1].keyword)
      .replace(/\$강점3\$/g, competencyAnalysis.strengths[2].keyword)
      .replace(/\$기업명\$/g, companyName);

    return split ? replaceStr.split('\n') : replaceStr;
  }
}
