import { AxiosResponse } from 'axios';
import { axios } from '@repository/RequestClient';
import MatchingOfferCompanyRs from '@domain/rs/match/report/MatchingOfferCompanyRs';
import MatchingOfferPositionRs from '@domain/rs/match/report/MatchingOfferPositionRs';
import MatchingReportRs from '@domain/rs/match/report/MatchingReportRs';
import MatchingReportCompanyRs from '@domain/rs/match/report/MatchingReportCompanyRs';

export default interface MatchReportRepo {
  // 매칭 리포트 기업 목록 조회
  fetchMatchReportCompanies(excludeCompanySn: number): Promise<AxiosResponse<MatchingOfferCompanyRs>>;

  //매칭 리포트 > 기업 정보 상세 조회
  fetchMatchReportCompanyDetail(companySn: number): Promise<AxiosResponse<MatchingReportCompanyRs>>;

  // 매칭 리포트 기업 내 포지션 목록 조회
  fetchMatchReportCompanyPositions(companySn: number): Promise<AxiosResponse<MatchingOfferPositionRs>>;

  // 매칭 리포트 상세 조회
  fetchMatchReport(positionSn: number): Promise<AxiosResponse<MatchingReportRs>>;
}

export class RemoteMatchReportRepo implements MatchReportRepo {
  fetchMatchReportCompanies(excludeCompanySn: number): Promise<AxiosResponse<MatchingOfferCompanyRs>> {
    return axios.get(`/match/report/companies`, {
      params: {
        excludeCompanySn,
      },
    });
  }

  fetchMatchReportCompanyDetail(companySn: number): Promise<AxiosResponse<MatchingReportCompanyRs>> {
    return axios.get(`/match/report/companies/${companySn}`);
  }

  fetchMatchReportCompanyPositions(companySn: number): Promise<AxiosResponse<MatchingOfferPositionRs>> {
    return axios.get(`/match/report/companies/${companySn}/positions`);
  }

  fetchMatchReport(positionSn: number): Promise<AxiosResponse<MatchingReportRs>> {
    return axios.get(`/match/report`, {
      params: {
        positionSn,
      },
    });
  }
}
