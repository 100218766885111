import React from 'react';
import MessageContents from '@components/common/header/messageModal/messages/companyMessage/resultRequest/messageContents';
import MatchingProfilePreviewModal from '@components/common/header/messageModal/messages/common/MatchingProfilePreviewModal';
import PreferDemandModal from '@components/common/matchSwitch/matchProposalModals/PreferDemandModal';
import PreferenceModal from '@components/match/profile/matchProposal/PreferenceModal';
import { useProfileDetail } from '@repository/profiles/useProfile';
import ConfirmModal from './confirmModal';
import RefuseModal from './refuseModal';
import MessageButton from '../../common/button';
import MatchMessageVO from '@domain/vo/match/message/MatchMessageVO';
import useModals from '@utils/hooks/useModals';
import { PATTERN } from '@utils/validators/pattern';

import classnames from 'classnames/bind';
import styles from './matchOffer.module.scss';
const cx = classnames.bind(styles);

interface NoticeProps {
  companySn: number;
  positionSn: number;
  companyName: string;
  companyMessage: MatchMessageVO;
}

const MatchOffer = ({ companySn, positionSn, companyName, companyMessage }: NoticeProps) => {
  const { data: profileData } = useProfileDetail(true);
  const { closeModal, isVisible, openModal } = useModals([
    'REFUSE',
    'CONFIRM',
    'PROFILE_PREVIEW',
    'PREFER_DEMAND',
    'PREFER',
  ] as const);

  const {
    title,
    message,
    decisionDateTime,
    decisionDueDateTimeValue,
    decisionMatchingDueDateTimeValue,
    options,
    decision,
    isEndDecisionDateTime,
    sendDateTime,
    command,
  } = companyMessage;
  const acceptOptions = options.find((option) => option.command === 'ACCEPT');
  const acceptOptionSn = Number(acceptOptions?.sn ?? 0);
  const refuseOptions = options.find((option) => option.command === 'REFUSE');
  const refuseOptionSn = Number(refuseOptions?.sn ?? 0);
  const isAccepted = decision?.command === 'ACCEPT';
  const isRefused = decision?.command === 'REFUSE';

  // message 중 'http'|'https' 로 시작하는 URL이 있으면 a 태그로 변경
  const parsedMessage = message.replace(
    PATTERN.PROTOCOL,
    (url) => `<a href="${url}" target="_blank" rel="noreferrer" class="${cx('hyperLink')}">${url}</a>`,
  );

  const handleProfilePreviewClick = async () => {
    if (!profileData) return;
    openModal('PROFILE_PREVIEW');
  };

  const ButtonElement = () => {
    if (command === 'INTERVIEW_CANCEL') {
      return (
        <MessageButton className={cx('button', 'full')} disabled>
          면접 요청 만료
        </MessageButton>
      );
    }

    if (isEndDecisionDateTime) {
      return (
        <MessageButton className={cx('button', 'full')} disabled>
          응답 기한 만료
        </MessageButton>
      );
    }

    if (isAccepted) {
      return (
        <MessageButton className={cx('button', 'full')} disabled>
          {decisionDateTime} 수락 완료
        </MessageButton>
      );
    }

    if (isRefused) {
      return (
        <MessageButton className={cx('button', 'full')} disabled>
          {decisionDateTime} 거절 완료
        </MessageButton>
      );
    }

    return (
      <>
        <MessageButton theme="secondary" className={cx('button', 'refuseButton')} onClick={() => openModal('REFUSE')}>
          거절하기
        </MessageButton>
        <MessageButton theme="primary" className={cx('button')} onClick={() => openModal('CONFIRM')}>
          수락하기
        </MessageButton>
      </>
    );
  };

  return (
    <>
      <div className={cx('noticeArea')}>
        <MessageContents
          title={title}
          message={parsedMessage}
          decisionDueDateTimeValue={decisionDueDateTimeValue}
          decisionMatchingDueDateTimeValue={decisionMatchingDueDateTimeValue}
          isDangerousHtml
          isMatching
        />
        <div className={cx('buttonArea')}>
          <ButtonElement />
        </div>
      </div>

      {/* 제안 거절 모달 */}
      <RefuseModal
        visible={isVisible('REFUSE')}
        onClose={closeModal}
        optionSn={refuseOptionSn}
        positionSn={positionSn}
        companyName={companyName}
        companySn={companySn}
        onPreferenceReasonClick={() => {
          openModal('PREFER_DEMAND');
        }}
      />

      {/* 제안 수락 모달 */}
      <ConfirmModal
        visible={isVisible('CONFIRM')}
        onClose={closeModal}
        optionSn={acceptOptionSn}
        positionSn={positionSn}
        handleProfilePreviewClick={handleProfilePreviewClick}
        sendDateTime={sendDateTime}
      />

      {/* 선호 정보 변경 유도 모달 */}
      <PreferDemandModal
        visible={isVisible('PREFER_DEMAND')}
        onClose={() => {
          openModal('PREFER');
        }}
        onNext={closeModal}
        closeBtnText="닫기"
        confirmBtnText="선호 정보 수정"
      />

      {/* 선호 정보 변경 모달 */}
      <PreferenceModal visible={isVisible('PREFER')} onClose={closeModal} />

      {/* 프로필 미리보기 */}
      {profileData && (
        <MatchingProfilePreviewModal
          profileDetail={profileData}
          visible={isVisible('PROFILE_PREVIEW')}
          onClose={() => openModal('CONFIRM')}
        />
      )}
    </>
  );
};

export default MatchOffer;
