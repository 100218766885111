import { useInfiniteQuery } from 'react-query';
import { PageState } from '@domain/rs/common/PageableRs';
import { PositionBookmarkListItemVO } from '@domain/vo/common/PositionBookmarkListItemVO';
import { RemotePositionRepo } from '@repository/position/PositionRepo';
import PositionListFilterRq from '@domain/rq/position/list/PositionListFilterRq';
import PositionListFilterVO from '@domain/vo/position/list/PositionListFilterVO';
import { RecruitCategory } from '@domain/constant/recruitCategoryType';

export const keys = ['position', 'list'];
const remotePositionRepo = new RemotePositionRepo();

const defaultPage: PageState = {
  page: 0,
  size: 60,
  totalPages: 0,
  totalElements: 0,
};

export const fetchPositionList = async (vo?: PositionListFilterVO, page = 0) => {
  const rq = new PositionListFilterRq(page, defaultPage.size, vo);
  const { data } = await remotePositionRepo.fetchPositionList(rq);
  return {
    ...data,
    positions: data.positions.map(
      (item) =>
        new PositionBookmarkListItemVO({
          ...item,
          sn: item.positionSn,
          title: item.positionName,
        }),
    ),
    isExistNextData: data.pages.totalPages > data.pages.page + 1, //다음 불러올 데이터 존재 유무
  };
};

export default function usePositionList(vo?: PositionListFilterVO) {
  const isNewOrCareer = vo?.recruitments.some((e) =>
    [RecruitCategory.NEW, RecruitCategory.CAREER].includes(e as RecruitCategory),
  );
  const convertVO = isNewOrCareer
    ? ({ ...vo, recruitments: [...(vo?.recruitments ?? []), RecruitCategory.NEWORCAREER] } as PositionListFilterVO)
    : vo;
  return useInfiniteQuery([...keys, vo], ({ pageParam }) => fetchPositionList(convertVO, pageParam), {
    getNextPageParam: ({ pages: { page, totalPages } }) => (page < totalPages ? page + 1 : undefined),
    refetchOnMount: false,
    refetchOnReconnect: false,
    retry: 1,
    cacheTime: 0, //필터 초기화시 0 페이지만 조회 하기 위함
  });
}
