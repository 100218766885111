import React, { FC } from 'react';
import Icon from '@common/assets';
import Colors from '@domain/constant/colors';

import classnames from 'classnames/bind';
import styles from './ellipsisTooltip.module.scss';
const cx = classnames.bind(styles);

interface EllipsisTooltipProps {
  text: string;
  type?: 'top' | '';
}

const EllipsisTooltip: FC<EllipsisTooltipProps> = ({ text, type }) => {
  if (type === 'top') {
    return (
      <div className={cx('tooltipArea', 'top')}>
        <div className={cx('tooltip')}>{text}</div>
        <Icon name="tooltipTopTail" width={8} height={8} fill={Colors.C_COOL_GRAY_70} />
      </div>
    );
  }

  return (
    <div className={cx('tooltipArea')}>
      <Icon name="tooltipTopTail" width={8} height={8} fill={Colors.C_COOL_GRAY_70} />
      <div className={cx('tooltip')}>{text}</div>
    </div>
  );
};

export default EllipsisTooltip;
