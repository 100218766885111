import React, { FC, useState, useEffect, useRef } from 'react';
import Slider from 'react-slick';
import Icon from '@common/assets';
import NoCompleteMissionModal from '@components/position/list/noCompleteMissionModal';
import useCurationFilterList from '@components/position/list/query/useCuration';
import LoginModal from '@components/loginModal';
import { useUserInfo } from '@repository/auth/useUserInfo';
import CurationFilterListVO from '@domain/vo/position/list/CurationFilterListVO';

import classnames from 'classnames/bind';
import styles from './positionCuration.module.scss';
const cx = classnames.bind(styles);

export const defaultCurationFilter = new CurationFilterListVO(undefined, 'ALL');
export const recommendCurationFilter = new CurationFilterListVO(
  {
    sn: 0,
    title: '잡다가 추천하는 포지션',
    definition: '잡다가 추천하는 포지션',
    description: '입력한 프로필, 역량검사결과를 분석하여 꼭 맞는 포지션을 찾았어요.',
    priority: 0,
    openYn: true,
    iconAttachFile: {
      fileUid: '',
      fileName: '',
      fileSize: 0,
      fileUrl: '',
    },
  },
  'RECOMMEND',
);

interface ICurationFilter {
  selectedCuration: CurationFilterListVO;
  setSelectedCuration: (selected: CurationFilterListVO) => void;
}

const CurationFilter: FC<ICurationFilter> = ({ selectedCuration, setSelectedCuration }) => {
  const [noCompleteMissionModalVisible, setNoCompleteMissionModalVisible] = useState(false);
  const [loginModalVisible, setLoginModalVisible] = useState(false);

  const { data: userInfo } = useUserInfo();
  const isLogin = !!userInfo?.id;
  const { data } = useCurationFilterList();
  const sliderRef = useRef<Slider>(null);

  useEffect(() => {
    window.addEventListener('resize', () => {
      sliderRef.current?.slickGoTo(0, true);
    });
  }, []);

  const onClickRecommendCuration = async () => {
    if (!isLogin) {
      setLoginModalVisible(true);
      return;
    }

    handelClickCuration(recommendCurationFilter);
  };

  const curationListSettings = {
    draggable: false,
    infinite: false,
    arrows: true,
    slidesToShow: 12,
    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 1480,
        settings: {
          slidesToShow: 9,
        },
      },
    ],
  };

  if (!data) return null;

  const handelClickCuration = (curationFilterData: CurationFilterListVO) => {
    window.scrollTo({
      top: -88,
      behavior: 'smooth',
    });
    setSelectedCuration(curationFilterData);
  };

  const curationListEl = data.map((item, idx) => {
    const { title, sn, iconAttachFile } = item;

    return (
      <a
        key={idx}
        className={cx('btnCuration', { on: selectedCuration.sn == sn })}
        role="button"
        onClick={() => handelClickCuration(item)}
      >
        <div className={cx('icon')} style={{ backgroundImage: `url(${iconAttachFile?.fileUrl})` }} />
        {title}
      </a>
    );
  });

  return (
    <div className={cx('curationArea')}>
      <Slider className={cx('curationListWrap')} {...curationListSettings} ref={sliderRef}>
        <a
          className={cx('btnCuration', { on: 'ALL' === selectedCuration.type })}
          role="button"
          onClick={() => handelClickCuration(defaultCurationFilter)}
        >
          <div className={cx('icon')}>
            <Icon name="listViewLight" width={32} height={32} />
          </div>
          전체
        </a>
        <a
          className={cx('btnCuration', { on: 'RECOMMEND' === selectedCuration.type })}
          role="button"
          onClick={onClickRecommendCuration}
        >
          <div className={cx('icon')}>
            <Icon name="thumbsUpLight" width={32} height={32} />
          </div>
          JOBDA 추천
        </a>
        {curationListEl}
      </Slider>

      {/* 미션 미완료 모달 */}
      <NoCompleteMissionModal
        visible={noCompleteMissionModalVisible}
        onClose={() => setNoCompleteMissionModalVisible(false)}
      />
      <LoginModal
        subTitle="채용정보 · 역량검사 · 매칭까지"
        title="취업기회 지금 바로 잡다!"
        visible={loginModalVisible}
        onClose={() => setLoginModalVisible(false)}
      />
    </div>
  );
};

export default CurationFilter;
