import Link from 'next/link';
import { NextRouter, useRouter } from 'next/router';
import Icon, { IconNameType } from '@components/common/assets';
import Tooltip from '@components/common/tooltip';
import Colors from '@domain/constant/colors';
import classnames from 'classnames/bind';
import styles from './gnbList.module.scss';
const cx = classnames.bind(styles);

interface Menu {
  text: string;
  url: string | (() => void);
}

interface SubMenu extends Menu {
  title: string;
  iconName: IconNameType;
  isNew?: boolean;
}

interface Props {
  title: Menu;
  sub?: SubMenu[];
  isOn: boolean;
  siteMapVisible: boolean;
  isNew?: boolean;
}

const GnbListItem = ({ title, isOn, sub, siteMapVisible, isNew = false }: Props) => {
  const router = useRouter();
  if (!sub) {
    return (
      <>
        <CustomLink url={title.url} className={cx('listTitle', 'gnb_apply', { on: isOn }, { isNew })} router={router}>
          {title.text}
          <span className={cx('newLogo', { isNew })}>
            <Icon name="newLong" width={19} height={6} fill={Colors.C_ERROR} />
          </span>
        </CustomLink>
      </>
    );
  }

  return (
    <Tooltip
      label={
        <>
          <CustomLink url={title.url} className={cx('listTitle', 'gnb_apply', { on: isOn }, { isNew })} router={router}>
            {title.text}
            <span className={cx('newLogo', { isNew })}>
              <Icon name="newLong" width={19} height={6} fill={Colors.C_ERROR} />
            </span>
          </CustomLink>
        </>
      }
      withArrow={false}
      top={40}
      tooltipClassName={cx('subMenuWrap')}
      duration={0}
      condition={!siteMapVisible}
    >
      <div className={cx('subMenuArea')}>
        {sub.map((item) => (
          <CustomLink url={item.url} key={item.text} router={router}>
            <div className={cx('subMenu')}>
              <div className={cx('icon')}>
                <Icon name={item.iconName} width={28} height={28} />
              </div>
              <div className={cx('content')}>
                <div className={cx('subMenuTitle', { new: item.isNew })}>{item.title}</div>
                <div className={cx('subMenuSubTitle')}>{item.text}</div>
              </div>
              <div className={cx('rightIcon')}>
                <Icon name="arrowRightLight" width={28} height={28} />
              </div>
            </div>
          </CustomLink>
        ))}
      </div>
    </Tooltip>
  );
};

export default GnbListItem;

interface CustomLinkProps {
  url: string | (() => void);
  className?: string;
  children: React.ReactNode;
  router: NextRouter;
}

const CustomLink = ({ children, url, className, router }: CustomLinkProps) => {
  if (typeof url === 'function') {
    return (
      <button className={className} onClick={url}>
        {children}
      </button>
    );
  }

  if (router.pathname === url) {
    return (
      <button className={className} onClick={() => router.reload()}>
        {children}
      </button>
    );
  }

  return (
    <Link className={className} href={url}>
      {children}
    </Link>
  );
};
