import React from 'react';
import { useRouter } from 'next/router';
import { useCompaniesInformation, useCompanyEmployee } from '@repository/companies/useCompanies';
import EmployeeInfoLineChart from './lineChart';
import InfoListItem from '../infoListItem';
import classnames from 'classnames/bind';
import styles from './employeeInfo.module.scss';
const cx = classnames.bind(styles);

const EmployeeInfo = () => {
  const router = useRouter();
  const { sn } = router.query;
  const { data: companyInfoData } = useCompaniesInformation(Number(sn));
  const { data: employeeChartData } = useCompanyEmployee(Number(sn));

  const isNotData =
    !companyInfoData?.companyDetail?.averageWorkingYears &&
    !companyInfoData?.companyDetail?.numberOfNewEmployees &&
    !companyInfoData?.companyDetail?.numberOfTerminateEmployees &&
    !companyInfoData?.companyDetail?.numberOfEmployees;

  if (isNotData) return null;

  return (
    // 고용 안정성 영역
    <div className={cx('snInner', 'noBorder', 'noPadding')}>
      {/* 영역 제목 */}
      <p className={cx('sectionTitle')}>
        직원 수<span className={cx('updateDate')}>{companyInfoData?.lastModifiedDateText}</span>
      </p>

      {/* 안정성 카드 */}
      <ul className={cx('companyInfoList')}>
        {/* 평균 근속 */}
        <InfoListItem type={'length'} title={companyInfoData?.companyDetail.averageWorkingYearsText ?? '-'} />
        {/*입사자/퇴사자 */}
        <InfoListItem
          type={'yearCount'}
          labelText={`${new Date(companyInfoData.lastModifiedDate).getFullYear() - 1} 입사자 / 퇴사자`}
          title={`${companyInfoData?.companyDetail.numberOfNewEmployeesText} / ${companyInfoData?.companyDetail.numberOfTerminateEmployeesText}`}
        />
        {/* 임직원 */}
        <InfoListItem type={'employee'} title={companyInfoData?.companyDetail.numberOfEmployeesText ?? '-'} />
      </ul>

      {employeeChartData && (
        <div className={cx('chartArea')}>
          <EmployeeInfoLineChart companyEmployee={employeeChartData} trigger={true} />
        </div>
      )}
    </div>
  );
};

export default EmployeeInfo;
