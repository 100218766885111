import { useToast } from '@components/common/toast';
import FileAttachmentConfirmModal from './fileAttachmentConfirmModal';
import FileAttachmentTermsModal from './fileAttachmentTermsModal';
import FileAttachmentErrorModal, { FileAttachmentType } from './fileAttachmentErrorModal';

export type FileAttachmentModalType = 'FILE_CONFIRM' | 'FILE_TERMS' | FileAttachmentType;

interface FileAttachmentModalsProps {
  positionSn: number | null;
  optionSn: string | null;
  filesState: File[];
  fileAttachmentModalState: FileAttachmentModalType | null;
  setFileAttachmentModalState: (fileAttachmentModalState: FileAttachmentModalType | null) => void;
  onAgreeTerms: () => void;
}

const FileAttachmentModals = ({
  positionSn,
  optionSn,
  filesState,
  fileAttachmentModalState,
  setFileAttachmentModalState,
  onAgreeTerms,
}: FileAttachmentModalsProps) => {
  const Toast = useToast();
  const isVisible = (type: FileAttachmentModalType) => type === fileAttachmentModalState;
  const handleClose = () => setFileAttachmentModalState(null);
  const handleErrors = (errorCode: string) => {
    switch (errorCode) {
      case 'B901':
        setFileAttachmentModalState('END_POSITION_ERROR');
        break;
      case 'B909':
        Toast({ type: 'error', iconType: 'info', content: '응답 마감 기한이 지났습니다.' });
        handleClose();
        break;
      default:
        setFileAttachmentModalState('SERVICE_ERROR');
        break;
    }
  };

  return (
    <>
      {/* [D] 파일 첨부 확인 모달 */}
      <FileAttachmentConfirmModal
        visible={isVisible('FILE_CONFIRM')}
        onClose={handleClose}
        filesState={filesState}
        positionSn={positionSn}
        optionSn={optionSn}
        handleErrors={handleErrors}
      />

      {/* [D] 약관 동의 모달 */}
      <FileAttachmentTermsModal visible={isVisible('FILE_TERMS')} onClose={handleClose} onAgreeTerms={onAgreeTerms} />

      {/* [D] 파일 개수 에러 모달 */}
      <FileAttachmentErrorModal
        visible={isVisible('FILE_COUNT_ERROR')}
        type={'FILE_COUNT_ERROR'}
        onClose={handleClose}
      />

      {/* [D] 빈 파일, 손상된 파일 에러 */}
      <FileAttachmentErrorModal
        visible={isVisible('WRONG_FILE_ERROR')}
        type={'WRONG_FILE_ERROR'}
        onClose={handleClose}
      />

      {/* [D] 파일 형식 에러 모달 */}
      <FileAttachmentErrorModal
        visible={isVisible('FILE_FORMAT_ERROR')}
        type={'FILE_FORMAT_ERROR'}
        onClose={handleClose}
      />

      {/* [D] 서비스 에러 모달 */}
      <FileAttachmentErrorModal visible={isVisible('SERVICE_ERROR')} type={'SERVICE_ERROR'} onClose={handleClose} />

      {/* [D] 파일 용량 에러 모달 */}
      <FileAttachmentErrorModal
        visible={isVisible('FILE_CAPACITY_ERROR')}
        type={'FILE_CAPACITY_ERROR'}
        onClose={handleClose}
      />

      {/* [D] 종료된 포지션 모달 */}
      <FileAttachmentErrorModal
        visible={isVisible('END_POSITION_ERROR')}
        type={'END_POSITION_ERROR'}
        onClose={handleClose}
      />
    </>
  );
};

export default FileAttachmentModals;
