import { AxiosResponse } from 'axios';
import MatchReportRepo from './MatchReportRepo';
import MatchingOfferCompanyRs from '@domain/rs/match/report/MatchingOfferCompanyRs';
import MatchingOfferPositionRs from '@domain/rs/match/report/MatchingOfferPositionRs';
import MatchingReportRs from '@domain/rs/match/report/MatchingReportRs';
import MatchingReportCompanyRs from '@domain/rs/match/report/MatchingReportCompanyRs';

export default class MatchReportMock implements MatchReportRepo {
  // 매칭 리포트 기업 목록 조회
  async fetchMatchReportCompanies(excludeCompanySn: number) {
    return {
      data: {
        companies: [
          {
            sn: 3849287,
            name: '엘리멘탈 시티',
            logo: {
              filePath: '/public',
              fileUid: '44bcceef-a449-4f83-953a-871503db925c.png',
              fileName: 'picture.png',
              fileSize: 150640,
              fileUrl: 'https://static-jobda-im.kr-dv-jainwon.com/public/44bcceef-a449-4f83-953a-871503db925c.png',
            },
          },
          {
            sn: 3763345,
            name: '비엠더블유코리아(주)',
            logo: {
              filePath: '/public',
              fileUid: '9b17065c-6b7b-4eca-a5d9-dda04607ee81.png',
              fileName: 'picture.png',
              fileSize: 166686,
              fileUrl: 'https://static-jobda-im.kr-dv-jainwon.com/public/9b17065c-6b7b-4eca-a5d9-dda04607ee81.png',
            },
          },
        ],
      },
    } as AxiosResponse<MatchingOfferCompanyRs>;
  }

  //매칭 리포트 > 기업 정보 상세 조회
  async fetchMatchReportCompanyDetail(companySn: number) {
    return {
      data: {
        specialMessage: {
          greeting: `안녕하세요 $이름$님\n도전과 열정의 주인공을 기다리는\n$기업명$입니다.`,
          companyIntroduction: `$기업명$는 1979년 개관 이래 지속적인 발전을 통해 국내 주요 도시에 총 5천 여 객실을 보유한\n국내 최대 호텔 체인으로 성장했습니다. 섬세하고 품격 있는 시설과 서비스로 비교할 수 없는 명\n성과 대표성을 가지고 있는 기업으로 자부심 있게 일할 수 있는 기업입니다. $기업명$는 1979년\n개관 이래 지속적인 발전을 통해 국내 주요 도시에 총 5천 여 객실을 보유한 국내 최대 호텔 체인\n으로 성장했습니다. 섬세하고 품격 있는 시설과 서비스로 비교할 수 없는 명성과 대표성을 가지고\n있는 기업으로 자부심 있게 일할 수 있는 기업입니다. $기업명$는 1979년 개관 이래 지속적인 발\n전을 통해 국내 주요 도시에 총 5천 여 객실을 보유한 국내 최대 호텔 체인으로 성장했습니다. 섬\n세하고 품격 있는 시설과 서비스로 비교할 수 없는 명성과 대표성을 가지고 있는 기업으로 자부심\n있게 일할 수 있는 기업입니다. $기업명$는 1979년 개관 이래 지속적인 발전을 통해 국내 주요 도\n시에 총 5천 여 객실을 보유한 국내 최대 호텔 체인으로 성장했습니다. 섬세하고 품격 있는 시설\n과 서비스로 비교할 수 없는 명성과 대표성을 가지고 있는 기업으로 자부심 있게 일할 수 있는 기\n업입니다. $기업명$는 1979년 개관이래 지속적인 발전을 통해 국내 주요 도시에 총 5천 여 객실\n을 보유한 국내 최대 호텔 체인으로 성장했습니다. 섬세하고 품격 있는 시설과 서비스로 비교\n할 수 없는 명성과 대표성을 가지고 있는 기업으로 자부심 있게 일할 수 있는 기업입니다.`,
          applicantSpecialMessage: `$이름$님께서 보유하신 $강점1$, $강점2$, $강점3$이라는\n특별한 강점이 저희 호텔롯데에 더해진다면\n우리 기업에서 가고자 하는 비전에 더 한 발짝 가까이 갈 수 있다고 생각합니다.`,
          closingRemark: '$기업명$와 함께 비전을 그려나갈 미래를 기다리겠습니다.',
        },
        vision: {
          visionTitle: '새로운 50년을\n향한 다짐',
          visionDetails: `롯데는 미래 50년 동안에도 지속가능한 성장을 이룰 수 있도록 그룹의 성장 방향을 질적 성장으로 전환하고, 이에 맞춰 새로운 비전을 선포하였습니다. 「Lifetime Value Creator」에는 롯데의 브랜드를 통해 고객에게 전 생애주기에 걸쳐 최고의 가치를 선사하겠다는 의미가 담겨 있습니다.롯데는 미래 50년 동안에도 지속가능한 성장을 이룰 수 있도록 그룹의 성장 방향을 질적 성장으로 전환하고, 이에 맞춰 새로운 비전을 선포하였습니다. 「Lifetime Value Creator」에는 롯데의 브랜드를 통해 고객에게 전 생애주기에 걸쳐 최고의 가치를 선사하겠다는 의미가 담겨 있습니다.`,
        },
        idealTalents: [
          {
            text: '인재상 내용',
          },
        ],
        managementPhilosophies: [
          {
            text: '경영이념 내용',
          },
        ],
      },
    } as AxiosResponse<MatchingReportCompanyRs>;
  }

  // 매칭 리포트 기업 내 포지션 목록 조회
  async fetchMatchReportCompanyPositions(companySn: number) {
    return {
      data: {
        positions: [
          {
            sn: 1,
            name: '포지션 이름1',
            responseType: null,
            options: [
              {
                optionSn: 1,
                optionCommand: 'ACCEPT',
              },
              {
                optionSn: 2,
                optionCommand: 'REFUSE',
              },
            ],
            decisionDueDateTime: '2023-12-27T09:59:59',
          },
          {
            sn: 2,
            name: '포지션 이름2',
            responseType: 'ACCEPTED',
            options: [
              {
                optionSn: 1,
                optionCommand: 'ACCEPT',
              },
              {
                optionSn: 2,
                optionCommand: 'REFUSE',
              },
            ],
            decisionDueDateTime: '2021-08-30T11:59:59',
          },
        ],
      },
    } as AxiosResponse<MatchingOfferPositionRs>;
  }

  // 매칭 리포트 상세 조회
  async fetchMatchReport(positionSn: number) {
    return {
      data: {
        score: {
          matchingTotalScore: 80,
          profileScore: 3000,
          competencyScore: 3000,
        },
        profileFit: {
          jobTitleFit: {
            jobTitleFits: [
              {
                code: '1',
                name: '직무다잉',
                fitYn: false,
              },
              {
                code: '2',
                name: '2직무다잉',
                fitYn: false,
              },
            ],
          },
          salaryFit: null,
          careerFit: {
            minYear: 1,
            maxYear: 4,
            fitYn: true,
          },
        },
        competencyAnalysis: {
          strengths: [
            {
              keyword: '높은 긍정성',
              comment: `  주변 환경을 긍정적인 시선으로 바라보네요. 만약 개선할 점이 있다고 느껴지면 적극적으로 나서서 좋은 방향으로
              나아가고자 노력합니다.`,
              questions: [
                {
                  question: `Q1. 다른 사람의 기분이나 감정을 잘 파악한다고 생각하십니까?`,
                  comment: ` 근거를 들어 설명하기 어려울 수 있습니다. 따라서 기분이나 감정을 남들보다 빠르게 알아채서 실제로 도움이 된 경험을
                  구체적으로 떠올려 보고 해당 경험을 바탕으로 설명해 보세요.`,
                },
                {
                  question: '질문2',
                  comment: '코멘트2',
                },
              ],
            },
            {
              keyword: '빠른 적응력',
              comment: ` 목표를 세웠으면 반드시 해내야 하는 성향을 가진 것 같아요. 문제를 집요하게 파고들어 성취를 이뤄내는 모습에 주변
              사람들로부터 많은 인정을 받을 것 같아요.`,
              questions: [
                {
                  question: `Q1. 다른 사람의 기분이나 감정을 잘 파악한다고 생각하십니까?`,
                  comment: ` 근거를 들어 설명하기 어려울 수 있습니다. 따라서 기분이나 감정을 남들보다 빠르게 알아채서 실제로 도움이 된 경험을
                  구체적으로 떠올려 보고 해당 경험을 바탕으로 설명해 보세요.`,
                },
                {
                  question: '질문2',
                  comment: '코멘트2',
                },
              ],
            },
            {
              keyword: '꾸준한 실천',
              comment: `주변 환경을 긍정적인 시선으로 바라보네요. 만약 개선할 점이 있다고 느껴지면 적극적으로 나서서 좋은 방향으로
              나아가고자 노력합니다.`,
              questions: [
                {
                  question: 'Q1. 다른 사람의 기분이나 감정을 잘 파악한다고 생각하십니까?',
                  comment: `근거를 들어 설명하기 어려울 수 있습니다. 따라서 기분이나 감정을 남들보다 빠르게 알아채서 실제로 도움이 된 경험을
                  구체적으로 떠올려 보고 해당 경험을 바탕으로 설명해 보세요.`,
                },
                {
                  question: '질문2',
                  comment: '코멘트2',
                },
              ],
            },
          ],
        },
        addressInfo: {
          address: '서울특별시 강남구 테헤란로 427',
          detailAddress: '엘리멘탈시티 7층',
        },
        offerMessage: `님의 이력을 보고 우리 회사에 적합한 인재라고 판단되었어요. 신입답지 않은 기획 경력부터 모범
        학생으로 보여지는 성실한 학교 생활까지 우리 회사에서 잘 적응할 수 있는 역량이 가득해 하루 빨리 같이 일하고
        싶다는 마음이 들었습니다. 신입답지 않은 기획 경력부터 모범 학생으로 보여지는 성실한 학교 생활까지 우리
        회사에서 잘 적응할 수 있는 역량이 가득해 하루 빨리 같이 일하고 싶다는 마음이 들었습니다.`,
      },
    } as AxiosResponse<MatchingReportRs>;
  }
}
