import React, { useState, FC } from 'react';
import { useRouter } from 'next/router';
import RefuseModal from '@components/common/header/messageModal/messages/companyMessage/matchOffer/refuseModal';
import ConfirmModal from '@components/common/header/messageModal/messages/companyMessage/matchOffer/confirmModal';
import MatchingProfilePreviewModal from '@components/common/header/messageModal/messages/common/MatchingProfilePreviewModal';
import Countdown from '@components/common/countdown';
import PreferDemandModal from '@components/common/matchSwitch/matchProposalModals/PreferDemandModal';
import { fetchMatchingPositionDetail } from '@repository/match';
import PreferenceModal from '@components/match/profile/matchProposal/PreferenceModal';
import { CompanyTabEnum } from '../..';
import ProfileDetailVO from '@domain/vo/profile/ProfileDetailVO';
import { MatchingOfferPositionVO } from '@domain/vo/match/report/MatchingOfferPositionVO';
import DateUtil from '@utils/date/util';
import useModals from '@utils/hooks/useModals';
import useDateTimer from '@utils/hooks/useDateTimer';
import useActiveTabFlag from '@utils/hooks/useActiveTabFlag';
import classnames from 'classnames/bind';
import styles from './bar.module.scss';

const cx = classnames.bind(styles);

interface BarProps {
  selectedMatchingPosition?: MatchingOfferPositionVO;
  refetch: () => void;
  selectedMatchingPositionSn: number | null;
  setSelectedMatchingPositionSn: (sn: number | null) => void;
  companyName?: string;
}

const Bar: FC<BarProps> = ({
  selectedMatchingPosition,
  refetch,
  selectedMatchingPositionSn,
  setSelectedMatchingPositionSn,
  companyName,
}) => {
  const router = useRouter();
  const { sn, tab } = router.query;
  const [profileDetail, setProfileDetail] = useState<ProfileDetailVO | null>(null);
  const { isVisible, openModal, closeModal } = useModals([
    'REFUSE',
    'ACCEPT',
    'PROFILE_PREVIEW',
    'PREFER_DEMAND',
    'PREFER',
  ]);
  const { getRemainingTimeFormat, remainingTime } = useDateTimer({
    endDate: selectedMatchingPosition?.decisionDueDateTime ?? new Date(),
  });

  //매칭 포지션 수락 bar표시 여부
  const isVisibleMatcingAcceptBar =
    tab === CompanyTabEnum.REPORT &&
    selectedMatchingPositionSn &&
    selectedMatchingPosition &&
    !selectedMatchingPosition.isAccepted &&
    remainingTime > 0;

  //수락, 거절 메세지 옵션
  const acceptOptions = selectedMatchingPosition?.options.find(({ optionCommand }) => optionCommand === 'ACCEPT');
  const acceptOptionSn = Number(acceptOptions?.optionSn ?? 0);
  const refuseOptions = selectedMatchingPosition?.options.find(({ optionCommand }) => optionCommand === 'REFUSE');
  const refuseOptionSn = Number(refuseOptions?.optionSn ?? 0);

  //프로필 미리보기 처리
  const handleProfilePreviewClick = async () => {
    if (!selectedMatchingPositionSn) return;

    if (profileDetail !== null) {
      openModal('PROFILE_PREVIEW');
      return;
    }

    const detail = await fetchMatchingPositionDetail(selectedMatchingPositionSn);
    if (!detail) return;
    setProfileDetail(detail);
    openModal('PROFILE_PREVIEW');
  };

  const [days, hours, minutes, seconds] = getRemainingTimeFormat('dd:HH:mm:ss').split(':');

  const createTimeElements = (timeValue: string, identifier: string, maxRangeArr: number[]) =>
    timeValue
      .split('')
      .map((digit, index) => (
        <Countdown
          key={`${identifier}_${index}_${selectedMatchingPositionSn}`}
          className={cx('countDown')}
          number={digit}
          maxRange={maxRangeArr[index]}
          width={24}
          height={32}
        />
      ));

  const initFlag = useActiveTabFlag();
  const daysEl = initFlag ? createTimeElements(days, 'days', [9, 9]) : null;
  const hoursEl = initFlag ? createTimeElements(hours, 'hours', [2, 3]) : null;
  const minutesEl = initFlag ? createTimeElements(minutes, 'minutes', [5, 9]) : null;
  const secondsEl = initFlag ? createTimeElements(seconds, 'seconds', [5, 9]) : null;

  if (!isVisibleMatcingAcceptBar) return null;

  return (
    <>
      <div className={cx('floatingBar')}>
        <div className={cx('barInner')}>
          {/* 채용명, 타이머 */}
          <div className={cx('barDesc')}>
            <span className={cx('title')}>{selectedMatchingPosition?.name}</span>
            <span className={cx('timerTitle')}>매칭 수락 마감까지</span>
            {/* 타이머 */}
            <div className={cx('timer')}>
              {daysEl}
              <div className={cx('text')}>일</div>
              {hoursEl}
              <div className={cx('text')}>시간</div>
              {minutesEl}
              <div className={cx('text')}>분</div>
              {secondsEl}
              <div className={cx('text')}>초</div>
            </div>
          </div>

          {/* [D] 링크 연결 필요 */}
          <div className={cx('barBtn')}>
            <a className={cx('btnLink')} role="button" onClick={() => openModal('REFUSE')}>
              거절하기
            </a>
            <a className={cx('btnLink', 'btnAccept')} role="button" onClick={() => openModal('ACCEPT')}>
              수락하기
            </a>
          </div>
        </div>
      </div>

      {/* 매칭 거절 모달 */}
      <RefuseModal
        visible={isVisible('REFUSE')}
        onClose={closeModal}
        onRefuseAfter={() => {
          refetch();
          setSelectedMatchingPositionSn(null); //거절된 매칭 포지션은 사라지므로 선택된 매칭 포지션도 초기화
        }}
        optionSn={refuseOptionSn}
        positionSn={selectedMatchingPositionSn}
        companyName={companyName ?? ''}
        companySn={Number(sn)}
        onPreferenceReasonClick={() => {
          openModal('PREFER_DEMAND');
        }}
      />
      {/* 선호 정보 변경 유도 모달 */}
      <PreferDemandModal
        visible={isVisible('PREFER_DEMAND')}
        onClose={() => {
          openModal('PREFER');
        }}
        onNext={closeModal}
        closeBtnText="닫기"
        confirmBtnText="선호 정보 수정"
      />

      {/* 선호 정보 변경 모달 */}
      <PreferenceModal visible={isVisible('PREFER')} onClose={closeModal} />
      {/* 매칭 수락 모달 */}
      <ConfirmModal
        visible={isVisible('ACCEPT')}
        onClose={closeModal}
        onAcceptAfter={refetch}
        optionSn={acceptOptionSn}
        positionSn={selectedMatchingPositionSn}
        handleProfilePreviewClick={handleProfilePreviewClick}
        sendDateTime={`${DateUtil.getNow()}`} //이번 매칭 수락 모달은 항상 11/30 이후니까 현재 시간으로 보냄.
      />

      {/* 프로필 미리보기 */}
      {profileDetail && (
        <MatchingProfilePreviewModal
          profileDetail={profileDetail}
          visible={isVisible('PROFILE_PREVIEW')}
          onClose={() => openModal('ACCEPT')}
        />
      )}
    </>
  );
};

export default Bar;
