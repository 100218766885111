import React, { FC, useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import { useUserInfo } from '@repository/auth/useUserInfo';
import { useCompaniesHeader } from '@repository/companies/useCompanies';
import { useMatchReport } from '@repository/match/report/useMatchReport';
import SpecialMessageParsingVO from '@domain/vo/match/report/SpecialMessageParsingVO';
import { SpecialMessageDto } from '@domain/rs/match/report/MatchingReportCompanyRs';
import ObjectUtil from '@utils/object/util';
import classnames from 'classnames/bind';
import styles from './specialMessage.module.scss';
const cx = classnames.bind(styles);

const TextWrapperSvg = ({ height }: { height: number }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="894"
      height={147 + height}
      viewBox={`0 0 894 ${147 + height}`}
      fill="none"
    >
      <path
        d={`M1 ${height} V45.1786L49.3089 1H846.121L893 45.1697 V${100 + height}L846.112 ${146 + height}H49.3177L1 ${
          100 + height
        }Z`}
        fill="none"
        stroke="#D2C9BA"
        strokeWidth="2"
      />
    </svg>
  );
};

interface SpecialMessageProps {
  data?: SpecialMessageDto;
  selectedPositionSn: number;
}

const SpecialMessage: FC<SpecialMessageProps> = ({ data: specialMessageData, selectedPositionSn }) => {
  const [svgHeight, setSvgHeight] = useState<number>(0);
  const messageRef = useRef<HTMLDivElement>(null);
  const { data: userInfo } = useUserInfo();
  const router = useRouter();
  const { sn } = router.query;
  const { data: headerData } = useCompaniesHeader(Number(sn));
  const { data: reportData } = useMatchReport(selectedPositionSn, !!selectedPositionSn);

  // ref값이 생성되면 svgHeight를 업데이트
  useEffect(() => {
    if (!messageRef.current || !reportData || !headerData) return;
    const svgHeight = messageRef.current?.clientHeight ? messageRef.current?.clientHeight - 128 : 0;
    setSvgHeight(svgHeight);
  }, [messageRef.current, reportData, headerData]);

  if (
    !specialMessageData ||
    ObjectUtil.isKeysValueAllEmpty(specialMessageData, [
      'greeting',
      'companyIntroduction',
      'applicantSpecialMessage',
      'closingRemark',
    ]) ||
    !userInfo ||
    !headerData ||
    !reportData?.competencyAnalysis.strengths.length
  )
    return null;

  const parsingMessages = new SpecialMessageParsingVO(
    specialMessageData,
    reportData?.competencyAnalysis,
    userInfo?.name,
    headerData?.companyHeaderDto.name,
  );

  const applicantSpecialMessageEl = parsingMessages.applicantSpecialMessage?.map((message, idx) => (
    <span key={idx} className={cx('applicantSpecialMessage')}>
      {message}
    </span>
  ));

  return (
    <>
      <div className={cx('basicTitle')}>
        {userInfo?.name}님을 위한
        <br />
        특별한 메세지가 도착했어요
      </div>
      <div className={cx('specialMessageWrapper')}>
        <div className={cx('svg')}>
          <TextWrapperSvg height={svgHeight} />
        </div>
        <div className={cx('specialMessageArea')}>
          <div className={cx('specialMessage')} ref={messageRef}>
            <div className={cx('companyName')}>{headerData?.companyHeaderDto.name}</div>
            <div className={cx('message')}>
              <span className={cx('greeting')}>{parsingMessages.greeting}</span>
              <span className={cx('companyIntroduction')}>{parsingMessages.companyIntroduction}</span>
              <div className={cx('personalMessage')}>{applicantSpecialMessageEl}</div>
              <span className={cx('closingRemark')}>{parsingMessages.closingRemark}</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SpecialMessage;
