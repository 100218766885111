import { useQuery } from 'react-query';
import { RemoteSearchRepo } from '@repository/serch/SearchRepo';
import RecommendKeywordListRs from '@domain/rs/search/RecommendKeywordListRs';

const remoteSearchRepo = new RemoteSearchRepo();

const defaultRsValue: RecommendKeywordListRs = {
  keywords: [],
};

const fetchRecommendKeywords = async () => {
  const { data } = await remoteSearchRepo.fetchRecommendKeywordList(1, 7);
  return data;
};

export default function useRecommendKeywords() {
  return useQuery(['recommendKeyword', 'list'], () => fetchRecommendKeywords(), {
    initialData: defaultRsValue,
    cacheTime: 0,
  });
}
