import React from 'react';
import Text from '@components/common/text';
import { FontStyle } from '@domain/constant/fontStyle';
import Colors from '@domain/constant/colors';

import classnames from 'classnames/bind';
import styles from './interviewLocation.module.scss';
const cx = classnames.bind(styles);

interface InterviewLocationProps {
  location: string;
}

const InterviewLocation = ({ location }: InterviewLocationProps) => {
  if (!location) return null;

  return (
    <>
      <Text fontStyle={FontStyle.B2P_M} color={Colors.C_COOL_GRAY_70} styles={{ marginTop: 24 }}>
        <span className={cx('info')}>면접 장소</span>
        {location}
      </Text>
    </>
  );
};

export default InterviewLocation;
