export interface MatchTalkMessageOptionDto {
  action: string; // 메시지 선택지 내용
  command: string; // 구분이 필요한 특수목적의 메시지의 경우 선택옵션 유형
  title: string; // 메시지 선택지 제목
  sn: string; // 메시지 선택지 sn
  disabled: boolean; // 메시지 선택불가 여부
  interviewLocation: string | null; //면접 장소
}

export enum CommandTypeCode {
  INTERVIEW_OFFER = 'INTERVIEW_OFFER', // 면접 제안
  INTERVIEW_OFFER_SCHEDULE = 'INTERVIEW_OFFER_SCHEDULE', // 면접 일자
  INTERVIEW_FAILED = 'INTERVIEW_FAILED', // 면접 탈락
  INTERVIEW_PASSED = 'INTERVIEW_PASSED', // 면접 합격
  INTERVIEW_ACCEPTED = 'INTERVIEW_ACCEPTED', // 면접 수락
  FILE_REQUEST = 'FILE_REQUEST', // 추가 서류 제출
  FILE_SUBMIT = 'FILE_SUBMIT', // 추가 서류 확인
  ACC_REQUEST = 'ACC_REQUEST', // 추가 역량검사 제출
  PHS_REQUEST = 'PHS_REQUEST', // 추가 개발자 역량검사 제출
  WRITING_RESUME = 'WRITING_RESUME', // 작성 중인 지원서 확인
  SHOW_SUBMIT_RESUME = 'SHOW_SUBMIT_RESUME', // 제출 한 지원서 바로가기
  SHOW_SUBMIT_PROFILE_RESUME = 'SHOW_SUBMIT_PROFILE_RESUME', // 프로필 지원서 바로가기
  SHOW_MATCHING_PROFILE = 'SHOW_MATCHING_PROFILE', // 제안 받은 프로필 확인하기
  SHOW_MATCHING_REPORT = 'SHOW_MATCHING_REPORT',
  INTERVIEW_CANCEL = 'INTERVIEW_CANCEL', // 면접 요청 만료
  MATCH_OFFER = 'MATCH_OFFER', //매칭 입사지원 제안
  MATCH_INTERVIEW_OFFER = 'MATCH_INTERVIEW_OFFER', //매칭 면접 제안
  MATCH_INTERVIEW_OFFER_SCHEDULE = 'MATCH_INTERVIEW_OFFER_SCHEDULE', //매칭 면접 일자
  SHOW_SUBMITTED_PROFILE = 'SHOW_SUBMITTED_PROFILE', // 제출한 프로필 확인하기
}

export enum MessageTypeCode {
  OPTIONS = 'OPTIONS',
  SIMPLE = 'SIMPLE',
}

export enum SenderTypeCode {
  COMPANY = 'COMPANY',
  USER = 'USER',
  CBO = 'CBO',
  SYSTEM = 'SYSTEM',
}

export enum InterviewLocationTypeCode {
  ONLINE = 'ONLINE',
  OFFLINE = 'OFFLINE',
}

export interface AttachFileDto {
  fileUid: string;
  filePath: string;
  fileName: string;
  fileSize: number;
}

export type MatchingTalkApplyType = 'ACC' | 'PACC' | 'PST';

export interface MatchingTalkApplyMessageDto {
  applyType: MatchingTalkApplyType;
  applySn: number;
  code: string;
}

export interface MatchingTalkMessageDto {
  sn: number;
  sender: SenderTypeCode; // 발신자 종류
  messageType: MessageTypeCode; // 메시지 타입
  command: CommandTypeCode | null;
  title: string; // 메시지 제목
  decisionDateTime: string | null; // 사용자 답변일
  decisionDueDateTime: string | null; // 사용자 답변 마감일
  message: string; // 메시지 내용
  interviewLocationType: InterviewLocationTypeCode | null; // 메시지 유형
  interviewLocation: string | null; // 인터뷰 장소
  options: MatchTalkMessageOptionDto[] | []; // 옵션 선택지
  companyAttachFiles: AttachFileDto[]; // 파일 리스트
  userAttachFiles: AttachFileDto[]; // 유저 파일 리스트
  decision: MatchTalkMessageOptionDto | null; // 메시지 선택지
  sendDateTime: string; // 생성 일시
  submitApplies: MatchingTalkApplyMessageDto[];
}

export interface MatchingMessageListRs {
  positionName: string; // 포지션명
  companySn: number;
  companyName: string; // 기업명
  logoImageUrl: string; // 로고 이미지 UID
  messages: MatchingTalkMessageDto[];
  endDateTime: string | null; // 포지션 종료 시간
  deleteDateTime: string | null; // 포지션 삭제 시간
  openYn: boolean; // 포지션 오픈 여부
  openingDateTime: string; //포지션 시작 시간
  deleteYn: boolean; // 포지션 삭제 여부
  closingDateTime: string | null; // 포지션 마감 시간
}
