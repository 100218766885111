import { useQuery } from 'react-query';
import { RemoteMatchReportRepo } from './MatchReportRepo';
import MatchReportMock from './MatchReportMockRepo';
import MatchingOfferPositionListVO from '@domain/vo/match/report/MatchingOfferPositionVO';
import MatchingReportVO from '@domain/vo/match/report/MatchingReportVO';

const repo = true ? new RemoteMatchReportRepo() : new MatchReportMock();
const keys = ['match', 'report'];
// 매칭 리포트 기업 목록 조회
const fetchMatchReportCompanies = async (excludeCompanySn: number) => {
  const { data } = await repo.fetchMatchReportCompanies(excludeCompanySn);
  return data;
};

export const useMatchReportCompanies = (enabled: boolean, excludeCompanySn: number) => {
  return useQuery([...keys, 'companies', excludeCompanySn], () => fetchMatchReportCompanies(excludeCompanySn), {
    enabled,
  });
};

//매칭 리포트 > 기업 정보 상세 조회
const fetchMatchReportCompanyDetail = async (companySn: number) => {
  const { data } = await repo.fetchMatchReportCompanyDetail(companySn);
  return data;
};

export const useMatchReportCompanyDetail = (companySn: number) => {
  return useQuery([...keys, 'company', 'detail', companySn], () => fetchMatchReportCompanyDetail(companySn));
};

// 매칭 리포트 기업 내 포지션 목록 조회
const fetchMatchReportCompanyPositions = async (companySn: number) => {
  const { data } = await repo.fetchMatchReportCompanyPositions(companySn);
  return new MatchingOfferPositionListVO(data);
};

export const useMatchReportCompanyPositions = (enabled: boolean, companySn: number) => {
  return useQuery([...keys, 'positions', companySn], () => fetchMatchReportCompanyPositions(companySn), {
    enabled,
  });
};

// 매칭 리포트 상세 조회
const fetchMatchReport = async (positionSn: number) => {
  const { data } = await repo.fetchMatchReport(positionSn);
  return new MatchingReportVO(data);
};

export const useMatchReport = (positionSn: number, enabled = true) => {
  return useQuery([...keys, positionSn], () => fetchMatchReport(positionSn), {
    enabled,
  });
};
