import React from 'react';
import { createPortal } from 'react-dom';
import Icon from '@common/assets';
import Colors from '@domain/constant/colors';
import classnames from 'classnames/bind';
import styles from './index.module.scss';
const cx = classnames.bind(styles);

export interface ToastPopupProps {
  content?: React.ReactNode;
  onClose?: () => void;
  message?: string;
  bottom?: number;
}

interface ToastContextProps {
  showToast: (props: ToastPopupProps) => void;
}

let timeout: ReturnType<typeof setTimeout> | null = null;

const resetTimeout = () => {
  timeout && clearTimeout(timeout);
  timeout = null;
};

const ToastContext = React.createContext<ToastContextProps | undefined>(undefined);

export const ChatToastProvider = ({ children }: { children: React.ReactNode }) => {
  const [open, setOpen] = React.useState(false);
  const [state, setState] = React.useState<Omit<ToastPopupProps, 'onClose'>>({
    content: '',
    message: '',
    bottom: 0,
  });

  const showToast = ({ content, onClose, message, bottom }: ToastPopupProps) => {
    const handleClose = () => {
      onClose?.();
      resetTimeout();
      setState({});
      setOpen(false);
    };

    if (timeout) {
      handleClose();
    }

    /**
     * 이미 Toast가 있는 경우 기존 Toast를 제거하고 새로운 Toast를 생성해야 하고
     * setState가 한 Context 안에 여러개 있을 때 한 번에 실행되기 때문에(Batch) reqeustAnimationFrame으로 감싸줌
     */
    requestAnimationFrame(() => {
      setOpen(true);
      setState({ content, message, bottom });
    });

    timeout = setTimeout(() => {
      handleClose();
    }, 3000);
  };

  return (
    <ToastContext.Provider value={{ showToast }}>
      {children}
      {open &&
        createPortal(
          <div className={cx('toastArea')} style={{ bottom: state.bottom ? `${state.bottom}px` : '' }}>
            <div className={cx('toastWrap')}>
              <Icon name={'informationLight'} width={20} height={20} fill={Colors.C_WHITE} />
              {state.content}
            </div>
          </div>,
          document.getElementById('chatArea') as HTMLElement,
        )}
    </ToastContext.Provider>
  );
};

export const useChatToast = () => {
  const context = React.useContext(ToastContext);
  if (!context) {
    throw new Error('ToastProvider를 찾을 수 없습니다.');
  }
  return context.showToast;
};
