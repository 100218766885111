import React, { FC } from 'react';
import Link from 'next/link';
import Modal from '@common/modal';

import {
  getResultTypeText,
  moveToResults,
} from '@common/header/messageModal/messages/companyMessage/resultRequest/resultModals';
import GradeBadge from '@components/match/common/gradeBadge';
import { PaccProgressCompleteVO } from '@domain/vo/phs/pacc/results/PaccProgressCompleteListVO';
import { PstProgressCompleteVO } from '@domain/vo/phs/pst/result/PstProgressCompleteListVO';
import { AccProgressCompleteVO } from '@domain/vo/acca/test/AccProgressCompleteListVO';
import { MatchingTalkApplyType } from '@domain/rs/match/message/MatchingMessageListRs';
import { V2RouteUrl } from '@utils/routeUtil';
import classnames from 'classnames/bind';
import styles from './resultSelectModal.module.scss';
const cx = classnames.bind(styles);

interface ResultItemProps {
  resultType: MatchingTalkApplyType | null;
  resultData: AccProgressCompleteVO | PaccProgressCompleteVO | PstProgressCompleteVO;
  isChecked: (resultSn: number) => boolean;
  onClick: (resultSn: number) => void;
}

const ResultItem = ({ resultType, resultData, isChecked, onClick }: ResultItemProps) => {
  const { sn, result, applyEndDateTime } = resultData;

  if (result === '분석 실패') return null;

  const isAnalysis = result === '분석중';

  return (
    <li className={cx('listItem', { on: isChecked(sn) })} onClick={() => onClick(sn)}>
      <div className={cx('timeTitle')}>완료시간</div>
      <div className={cx('infoArea')}>
        <div className={cx('infoWrap')}>
          <span className={cx('time')}>{applyEndDateTime}</span>
          {resultType === 'PST' && <GradeBadge grade={result} className={cx('badge')} />}
        </div>

        {isAnalysis ? (
          <a className={cx('resultLink', { disabled: isAnalysis })} role="button">
            결과 자세히 보기
          </a>
        ) : (
          <Link href={moveToResults(resultType, sn)} legacyBehavior>
            <a className={cx('resultLink')} target="_blank" role="button">
              결과 자세히 보기
            </a>
          </Link>
        )}
      </div>
    </li>
  );
};

interface ResultSelectModalProps {
  selectedResultSn: number | null;
  setSelectedResultSn: (selectedResultSn: number | null) => void;
  resultsData: AccProgressCompleteVO[] | PaccProgressCompleteVO[] | PstProgressCompleteVO[] | null;
  resultType: MatchingTalkApplyType | null;
  onClose: () => void;
  onConfirm: () => void;
  isVisible: boolean;
}

const ResultSelectModal: FC<ResultSelectModalProps> = ({
  selectedResultSn,
  setSelectedResultSn,
  resultsData,
  resultType,
  onClose,
  onConfirm,
  isVisible,
}) => {
  const isResultData = resultsData?.filter(({ result }) => result !== '분석 실패').length;
  const isSelected = selectedResultSn !== null;

  const moveTo = () => {
    switch (resultType) {
      case 'ACC':
        return '/acca/test';
      case 'PACC':
        return V2RouteUrl.PHS_PACC_TEST_GATE;
      case 'PST':
        return V2RouteUrl.PHS_PST_TEST_GATE;
      default:
        return '/';
    }
  };

  const resultTitle = `나의 ${getResultTypeText(resultType)} 결과`;
  const emptyModalText = `${getResultTypeText(resultType)} 결과가 없습니다.`;

  const isChecked = (resultSn: number) => selectedResultSn === resultSn;

  const checkResult = (resultSn: number) => {
    if (selectedResultSn === resultSn) setSelectedResultSn(null);
    else setSelectedResultSn(resultSn);
  };

  const resultItemEl = resultsData?.map((resultData) => (
    <ResultItem
      key={resultData.sn}
      resultType={resultType}
      isChecked={isChecked}
      onClick={checkResult}
      resultData={resultData}
    />
  ));

  return (
    <Modal visible={isVisible} dimmed={true} isUseScrollHidden={false}>
      <div className={cx('layer')}>
        <strong className={cx('title')}>응시 결과를 선택해 주세요.</strong>

        {isResultData ? (
          // [D] 결과 있는 경우
          <div className={cx('resultArea')}>
            {/* [D] 결과에 따라 텍스트 변경해주세요. */}
            <em className={cx('resultTitle')}>{resultTitle}</em>

            <div className={cx('resultListWrap')}>
              <ul className={cx('resultList')}>
                {/* [D] 결과 자세히 보기 링크랑 겹쳐서 해당 요소가 클릭 요소 역할도 합니다. */}
                {/* [D] 선택시 'on' 클래스 추가해주세요. */}
                {resultItemEl}
              </ul>
            </div>
          </div>
        ) : (
          // [D] 결과 없는 경우
          <div className={cx('emptyArea')}>
            <div className={cx('emptyInner')}>
              {/* [D] 결과에 따라 텍스트 변경해주세요. */}
              {/* [D] 역량검사: 역량검사 결과가 없습니다. / 개발자 역량검사: 개발자 역량검사 결과가 없습니다. / 개능검: 개발구현능력 검사 결과가 없습니다. */}
              <strong className={cx('emptyTitle')}>{emptyModalText}</strong>

              <Link href={moveTo()} legacyBehavior>
                <a className={cx('link')} target="_blank" role="button">
                  응시하러 가기
                </a>
              </Link>
            </div>
          </div>
        )}

        {/* [D] 개발구현능력 검사인 경우 제거되는 요소 */}
        {resultType !== 'PST' && <p className={cx('notice')}>*결과 자세히 보기를 통해 세부 내용을 확인할 수 있어요.</p>}

        <div className={cx('btnArea')}>
          {/* [D] 리스트 선택된 경우 disabled={false}로 변경해주세요. */}
          <button className={cx('btn')} type="button" disabled={!isSelected} onClick={onConfirm}>
            선택 완료
          </button>
        </div>

        <a className={cx('btnClose')} role="button" onClick={onClose}>
          <span className={cx('hidden')}>close</span>
        </a>
      </div>
    </Modal>
  );
};

export default ResultSelectModal;
