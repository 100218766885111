import React from 'react';
import { useRouter } from 'next/router';
import { useCompaniesInformation } from '@repository/companies/useCompanies';
import classnames from 'classnames/bind';
import styles from './salary.module.scss';
const cx = classnames.bind(styles);

const getSalaryHeight = (salary?: number) => {
  if (!salary) return;
  return `${Math.trunc(Math.abs(salary) / 1000000)}px`;
};

const Salary = () => {
  const router = useRouter();
  const { sn } = router.query;
  const { data: companyInfoData } = useCompaniesInformation(Number(sn));

  const isNoDataAverage = companyInfoData?.companyDetail.averageSalary == null;
  const isNoDataIndustry = companyInfoData?.companyDetail.industryAverageSalary == null;
  const isNotData = isNoDataAverage && isNoDataIndustry;

  const averageHeight = getSalaryHeight(companyInfoData?.companyDetail.averageSalary);
  const industryHeight = getSalaryHeight(companyInfoData?.companyDetail.industryAverageSalary);

  return (
    // 평균 연봉 영역
    <div className={cx('snInner', 'noBorder', 'noPadding')}>
      {/* 영역 제목 */}
      <p className={cx('sectionTitle')}>
        평균 연봉
        <span className={cx('updateDate', { hidden: isNotData })}>{companyInfoData?.lastModifiedDateText}</span>
      </p>

      {/* 평균 연봉 */}
      <div className={cx('salaryInner')}>
        {/* 좌측 평균 연봉 */}
        <div className={cx('salaryArea')}>
          <p className={cx('salaryTitle')}>
            {companyInfoData?.companyDetail.averageSalary
              ? companyInfoData?.companyDetail.averageSalaryText
              : '데이터 준비 중'}
          </p>
          <span className={cx('salaryText')}>전체 종업원 평균</span>
        </div>

        {/* 우측 연봉 그래프 */}
        <div className={cx('graphInner', { isNotData })}>
          {/* 기업 연봉*/}
          {/* 그래프 높이 금액에 비례 4,000만원 : 40px / 1억 1천만원 이상 = 110px (최대 110px) */}
          <div className={cx('progressBarWrap')}>
            <span className={cx('progressText')}>
              {isNotData ? '' : companyInfoData?.companyDetail.averageSalaryText}
            </span>
            <div
              className={cx('progressBar', { noData: isNoDataAverage })}
              style={{
                height: averageHeight,
                maxHeight: !isNoDataIndustry && isNoDataAverage ? industryHeight : undefined,
              }}
            />
            <span className={cx('progressDesc')}>{companyInfoData?.companyName}</span>
          </div>

          {/* 동종 업종 */}
          <div className={cx('progressBarWrap')}>
            <span className={cx('progressText')}>
              {isNotData ? '' : companyInfoData?.companyDetail.industryAverageSalaryText}
            </span>
            <div
              className={cx('progressBar', 'sameIndustry', {
                noDataIndustry: isNoDataIndustry,
              })}
              style={{
                height: industryHeight,
                maxHeight: !isNoDataAverage && isNoDataIndustry ? averageHeight : undefined,
              }}
            />
            <span className={cx('progressDesc')}>동종 업종</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Salary;
