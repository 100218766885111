import { useRef, useState } from 'react';
import { ChartData, EDGE_SPACE, LineChartColorsType, LineChartSizeType } from './data';
import useUpdateEffect from '@utils/hooks/useUpdateEffect';
import { createScaler } from '@utils/chartUtil';
import classnames from 'classnames/bind';
import styles from './lineChart.module.scss';

const cx = classnames.bind(styles);

interface PointsProps {
  data: ChartData[];
  type: LineChartSizeType;
  colors: LineChartColorsType;
  onIndex: number | null;
  handleClick?: (index: number) => void;
  handleMouseEnter?: (index: number) => void;
  lineTrigger: boolean;
  trigger: boolean;
  height: number;
  axisMin: number;
  axisMax: number;
}

const pointsAnimationDelay = [0, 0.1, 0.2, 0.3, 0.4, 0.45, 0.5, 0.55, 0.6, 0.65, 0.7, 0.75];

const Points = ({
  data,
  type,
  colors,
  onIndex,
  handleClick,
  handleMouseEnter,
  lineTrigger,
  trigger,
  height,
  axisMin,
  axisMax,
}: PointsProps) => {
  const [offsetX, setOffsetX] = useState(0);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const scaleY = createScaler(0, height, axisMin, axisMax);

  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>, index: number) => {
    if (!(e.target instanceof HTMLDivElement) || !wrapperRef.current) {
      return;
    }
    const { clientX, target } = e;
    const rect = target.getBoundingClientRect();
    const mid = (rect.left + rect.right) / 2;
    const isMouseOverRightArea = mid < clientX;

    if (isMouseOverRightArea) {
      handleMouseEnter?.(index);
    } else {
      handleMouseEnter?.(index === 0 ? 0 : index - 1);
    }
  };

  useUpdateEffect(() => {
    // onIndex 값이 바뀔 때마다 세로선 움직임을 위한 offsetX 값 변경
    if (onIndex === null || !wrapperRef.current) return;
    const index = onIndex === 0 ? 1 : onIndex + 1;
    const target = wrapperRef.current.childNodes[index] as HTMLDivElement | null;
    const targetRect = target?.getBoundingClientRect() ?? { left: 0 };
    const wrapperRect = wrapperRef.current.getBoundingClientRect();
    const diff = targetRect.left - wrapperRect.left - 1;
    setOffsetX(diff);
  }, [onIndex]);

  const getDataEl = () => {
    const dataEl = data.map((d, index) => {
      const { column, toolTip } = d;
      const isOn = index === onIndex;

      if (column === null) {
        return (
          <div
            key={index}
            className={cx('dataWrap', 'empty')}
            style={{ width: index === 0 ? `${EDGE_SPACE[type] * 100}%` : '100%' }}
          />
        );
      }

      const offsetY = scaleY(column);

      return (
        <div
          key={index}
          className={cx('dataWrap', colors, { on: isOn })}
          style={{ width: index === 0 ? `${EDGE_SPACE[type] * 100}%` : '100%' }}
          onMouseEnter={(e) => handleMouseMove(e, index)}
          onMouseMove={(e) => handleMouseMove(e, index)}
        >
          <a
            className={cx('dataBtn', type, { animation: trigger })}
            style={{
              transform: `translateY(-${offsetY}px)`,
              animationDelay: `${pointsAnimationDelay[index]}s`,
              zIndex: data.length - index,
            }}
            onClick={() => handleClick?.(index)}
            onMouseEnter={() => handleMouseEnter?.(index)}
            role="button"
          />
          <div className={cx('tooltipArea', { on: isOn })} style={{ top: `${height - offsetY + -20}px` }}>
            {toolTip}
          </div>
        </div>
      );
    });

    return (
      <>
        {dataEl}
        <div className={cx('dataWrap', 'empty')} style={{ width: `${EDGE_SPACE[type] * 100}%` }} />
      </>
    );
  };

  return (
    <div className={cx('pointsArea')} ref={wrapperRef}>
      {getDataEl()}
      {!lineTrigger && <div className={cx('line', colors)} style={{ transform: `translateX(${offsetX}px)` }} />}
    </div>
  );
};

export default Points;
