import React, { FC } from 'react';
import Modal from '@common/modal';
import Icon from '@common/assets';
import { JOBDA_SUPPORT_EMAIL } from '@domain/constant/contact';
import { V2Route } from '@utils/routeUtil';

import classnames from 'classnames/bind';
import styles from './companyUpdateModal.module.scss';
const cx = classnames.bind(styles);

interface ICompanyUpdateModalProps {
  visible: boolean;
  onClose: () => void;
}

const CompanyUpdateModal: FC<ICompanyUpdateModalProps> = ({ visible, onClose }) => {
  const handleClickAtsCompanyUpdateBtn = () => {
    window.open(V2Route.getAtsCompanyUpdatePage());
    onClose();
  };

  return (
    <Modal visible={visible} dimmed={true}>
      <div className={cx('layer')}>
        <strong className={cx('title')}>기업정보 수정하기</strong>
        <p className={cx('desc')}>수정방식을 선택해 주세요.</p>
        <div className={cx('btnArea')}>
          <a className={cx('btn', 'update')} role={'button'} onClick={handleClickAtsCompanyUpdateBtn}>
            기업회원 로그인하고
            <br />
            수정하기
          </a>
          <a className={cx('btn', 'request')} role={'button'} href={`mailto:${JOBDA_SUPPORT_EMAIL}`} onClick={onClose}>
            잡다 고객센터에
            <br />
            요청하기
          </a>
        </div>
        <a className={cx('btnClose')} role="button" onClick={onClose}>
          <Icon name="closeLight" width={32} height={32} />
          <span className={cx('hidden')}>close</span>
        </a>
      </div>
    </Modal>
  );
};

export default CompanyUpdateModal;
