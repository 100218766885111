import React from 'react';
import { useRouter } from 'next/router';
import { useCompaniesThemes } from '@repository/companies/useCompanies';
import IntroduceCard from './introduceCard';
import classnames from 'classnames/bind';
import styles from './introduce.module.scss';
const cx = classnames.bind(styles);

const IntroduceText = () => {
  const router = useRouter();
  const { sn } = router.query;
  const { data: themesData } = useCompaniesThemes(Number(sn));

  if (!themesData?.companyThemeDtos.length) return null;

  const itemEls = themesData.companyThemeDtos.map((item, index) => {
    return (
      <IntroduceCard
        key={index}
        title={(
          <>
            {item.adjective}
            <br />
            {item.name}
          </>
        )}
        subText={item.review}
      />
    );
  });

  return (
    // 기업정보 > 기업 소개 문구 리스트
    <div className={cx('introduceInner')}>{itemEls}</div>
  );
};

export default IntroduceText;
