import React, { useEffect, useRef } from 'react';
import { useMessageStore } from '@stores/MessageStore';
import CompanyMessage from './companyMessage';
import UserMessage from './userMessage';
import CBOMessage from './cboMessage';
import SystemMessage from './systemMessage';
import MatchMessageVO from '@domain/vo/match/message/MatchMessageVO';
import { SenderTypeCode } from '@domain/rs/match/message/MatchingMessageListRs';

import classnames from 'classnames/bind';
import styles from './messages.module.scss';
const cx = classnames.bind(styles);

const Message = () => {
  const contentRef = useRef<HTMLDivElement>(null);
  const groupedMessagesByDate = useMessageStore((state) => state.groupedMessagesByDate);
  const messagesInfo = useMessageStore((state) => state.messagesInfo);
  const isNotice = !messagesInfo?.openYn || messagesInfo?.deleteYn;

  useEffect(() => {
    initScroll();
  }, []);

  const initScroll = () => {
    const el = contentRef.current;
    if (el) el.scrollTop = el.scrollHeight;
  };

  if (!groupedMessagesByDate || !messagesInfo) return null;

  // [D] 한 날짜의 메세지들
  const messagesEls = (messages: MatchMessageVO[]) => {
    return messages.map((message, index) => {
      switch (message.sender) {
        // [D] 유저 메세지
        case SenderTypeCode.USER:
          return <UserMessage key={index} userMessage={message} messagesInfo={messagesInfo} />;

        // [D] CBO 메세지
        case SenderTypeCode.CBO:
          return <CBOMessage key={index} companyMessage={message} messagesInfo={messagesInfo} />;

        //회사 메세지
        case SenderTypeCode.COMPANY:
          return <CompanyMessage key={index} companyMessage={message} messagesInfo={messagesInfo} />;

        //시스템 메세지
        case SenderTypeCode.SYSTEM:
          return <SystemMessage key={index} companyMessage={message} messagesInfo={messagesInfo} />;
      }
    });
  };

  // [D] 날짜별로 묶은 메세지
  const groupedMessagesByDateEls = Object.entries(groupedMessagesByDate).map(([createdDateTime, messages], index) => {
    return (
      <div className={cx('historyWrap')} key={index}>
        <span className={cx('date')}>{createdDateTime}</span>
        <div className={cx('history')}>{messagesEls(messages)}</div>
      </div>
    );
  });

  return (
    <div ref={contentRef} className={cx('contentArea', { notice: isNotice })}>
      {groupedMessagesByDateEls}
    </div>
  );
};

export default Message;
