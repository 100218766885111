/**
 * 만 단위 이상은 천 단위에서 / 그 아래에선 2 번째 자리에서 부터 올림 또는 내림을 수행하는 함수
 * 예) 인원수 등 라인 차트에서 보여줄 값을 계산
 * @param number
 * @param type
 * @returns
 */
export const round = (number: number, type: 'floor' | 'ceil'): number => {
  if (number >= 10000) {
    // 만 단위 이상이면 천 단위로 반올림
    number = Math[type](number / 1000) * 1000;
  } else if (number >= 1000) {
    // 천 단위 이상이면 백 단위로 반올림
    number = Math[type](number / 100) * 100;
  } else {
    // 그 미만이면 십 단위로 반올림
    number = Math[type](number / 10) * 10;
  }

  return number;
};

/**
 * 보여줄 그래프의 최소, 최대값과 데이터의 최소, 최대값을 받아서 실제 그래프에 표시될 값을 계산하는 함수를 반환
 * @param viewMin 그래프 최소값
 * @param viewMax 그래프 최대값
 * @param dataMin 데이터 최소값
 * @param dataMax 데이터 최대값
 * @returns
 */
export const createScaler = (viewMin: number, viewMax: number, dataMin: number, dataMax: number) => {
  // 실제 scale logic이 들어가는 내부 함수
  return (value: number) => {
    // 비율 계산
    const ratio = (value - dataMin) / (dataMax - dataMin);

    // viewMin ~ viewMax 사이 값 return
    return viewMin + ratio * (viewMax - viewMin);
  };
};
