import React, { FC } from 'react';
import MultiRange from '@common/multiRange';

import classNames from 'classnames/bind';
import styles from './SalaryMultiRange.module.scss';
const cx = classNames.bind(styles);

interface ICondition {
  minValue: number | null;
  maxValue: number | null;
  requirementType: 'REQUIRED' | 'PREFERENTIAL';
}

interface IDots {
  value: number;
  label: string;
}

interface ISalaryMultiRangeProps {
  min: number;
  max: number;
  dots: IDots[];
  condition: ICondition;
  setCondition: (condition: ICondition) => void;
}

const SalaryMultiRange: FC<ISalaryMultiRangeProps> = ({ min, max, dots, condition, setCondition }) => {
  return (
    <div className={cx('wrap')}>
      <MultiRange
        step={100}
        min={min}
        max={max}
        leftValue={condition && condition.minValue ? condition.minValue : min}
        rightValue={condition && condition.maxValue ? condition.maxValue : max}
        setLeftValue={(value) => {
          setCondition(
            condition
              ? { ...condition, minValue: value <= min ? null : value }
              : {
                  minValue: value <= min ? null : value,
                  maxValue: null,
                  requirementType: 'PREFERENTIAL',
                },
          );
        }}
        setRightValue={(value) => {
          setCondition(
            condition
              ? { ...condition, maxValue: value >= max ? null : value }
              : {
                  minValue: min,
                  maxValue: value >= max ? null : value,
                  requirementType: 'PREFERENTIAL',
                },
          );
        }}
        dots={dots}
      />
    </div>
  );
};

export default SalaryMultiRange;
