import React from 'react';
import { useRouter } from 'next/router';
import { useNotificationActions } from '@stores/NotificationStore';
import { LogTypeEnum } from '@/src/logging/LogType';
import { MatchNotificationVO } from '@domain/vo/match/MatchNotificationVO';
import useMessageModal from '@utils/hooks/useMessageModal';

import classnames from 'classnames/bind';
import styles from './index.module.scss';
const cx = classnames.bind(styles);

interface Props extends MatchNotificationVO {
  text: React.ReactNode;
  buttonText?: string;
  nextPath?: string | null;
}

const NotificationCard = ({
  accessYn,
  createdDateTimeText,
  notificationSn,
  positionSn,
  text,
  buttonText,
  nextPath,
}: Props) => {
  const { asPath, reload, push } = useRouter();
  const { openMessageModal } = useMessageModal();
  const { removeNotifications, fetchNotifications, setTooltipVisible, readNotifications } = useNotificationActions();

  const onDelete = async () => {
    await removeNotifications({ notificationSnList: [notificationSn] });
    fetchNotifications();
  };

  const handleClick = async () => {
    await readNotifications([notificationSn]);
    setTooltipVisible(false);

    if (nextPath) {
      const isSamePath = asPath === nextPath;
      if (isSamePath) reload();
      else push(nextPath);
    } else if (buttonText) {
      openMessageModal(positionSn);
    }
  };

  return (
    <li className={cx('listItem', { new: !accessYn })}>
      <span className={cx('icon', 'changeState')} />
      <div className={cx('alertInner')}>
        <span className={cx('time')}>{createdDateTimeText}</span>

        <p className={cx('desc')}>{text}</p>

        {buttonText && (
          <button className={cx('btnNext')} onClick={handleClick} id={LogTypeEnum.log_notification_message}>
            {buttonText}
          </button>
        )}
      </div>
      <a className={cx('btnDelete')} role="button" onClick={onDelete}>
        <span className={cx('hidden')}>삭제</span>
      </a>
    </li>
  );
};

export default NotificationCard;
