import { AxiosResponse } from 'axios';
import { axios } from '@repository/RequestClient';
import ChatNotificationUpdateRq from '@domain/rq/chat/ChatNotificationUpdateRq';
import ChatMessageReportRq from '@domain/rq/chat/ChatMessageReportRq';
import ChatTokenRs from '@domain/rs/chat/ChatTokenRs';
import ChatCompanyChannelGetRs from '@domain/rs/chat/ChatCompanyChannelGetRs';
import ChatChannelGetRs from '@domain/rs/chat/ChatChannelGetRs';
import ChatNotificationListGetRs from '@domain/rs/chat/ChatNotificationListGetRs';
import ChatNotificationGetRs from '@domain/rs/chat/ChatNotificationGetRs';

export default interface ChatRepo {
  // 채팅방 기업 채널 정보 조회
  fetchChatCompanyChannelByChannelId(channelId: string): Promise<AxiosResponse<ChatCompanyChannelGetRs>>;

  // 기업 채팅방 채널 ID 조회
  fetchChatCompanyByCompanySn(companySn: number): Promise<AxiosResponse<ChatChannelGetRs>>;

  // 전체 채팅방 알림 설정 조회
  fetchChatNotifications(): Promise<AxiosResponse<ChatNotificationListGetRs>>;

  // 채팅방 알림 설정 조회
  fetchChatNotificationsByChannelId(channelId: string): Promise<AxiosResponse<ChatNotificationGetRs>>;

  // 채팅방 알림 설정
  fetchPutChatNotificationsByChannelId(channelId: string, rq: ChatNotificationUpdateRq): Promise<AxiosResponse>;

  // 채팅방 토큰 조회
  fetchChatToken(): Promise<AxiosResponse<ChatTokenRs>>;

  // 채팅 입장 로그
  fetchChatEnterLog(channelId: string): Promise<AxiosResponse>;

  // 채팅 신고
  fetchChatChannelMessagesReportByChannelIdAndMessageId({
    channelId,
    messageId,
    rq,
  }: {
    channelId: string;
    messageId: string;
    rq: ChatMessageReportRq;
  }): Promise<AxiosResponse>;
}

export class RemoteChatRepo implements ChatRepo {
  fetchChatCompanyChannelByChannelId(channelId: string): Promise<AxiosResponse<ChatCompanyChannelGetRs>> {
    return axios.get<ChatCompanyChannelGetRs>(`/chat/company-channel/${channelId}`);
  }

  fetchChatCompanyByCompanySn(companySn: number): Promise<AxiosResponse<ChatChannelGetRs>> {
    return axios.get<ChatChannelGetRs>(`/chat/company/${companySn}`);
  }

  fetchChatNotifications(): Promise<AxiosResponse<ChatNotificationListGetRs>> {
    return axios.get<ChatNotificationListGetRs>(`/chat/notifications`);
  }

  fetchChatNotificationsByChannelId(channelId: string): Promise<AxiosResponse<ChatNotificationGetRs>> {
    return axios.get<ChatNotificationGetRs>(`/chat/notifications/${channelId}`);
  }

  fetchPutChatNotificationsByChannelId(channelId: string, rq: ChatNotificationUpdateRq): Promise<AxiosResponse> {
    return axios.put(`/chat/notifications/${channelId}`, rq);
  }

  fetchChatToken(): Promise<AxiosResponse<ChatTokenRs>> {
    return axios.get<ChatTokenRs>(`/chat/token`);
  }

  fetchChatEnterLog(channelId: string): Promise<AxiosResponse> {
    return axios.post(`/chat/channel/${channelId}/enter/log`);
  }

  fetchChatChannelMessagesReportByChannelIdAndMessageId({
    channelId,
    messageId,
    rq,
  }: {
    channelId: string;
    messageId: string;
    rq: ChatMessageReportRq;
  }): Promise<AxiosResponse> {
    return axios.post(`/chat/channel/${channelId}/messages/${messageId}/report`, rq);
  }
}
