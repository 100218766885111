import React, { useState } from 'react';
import Modal from '@common/modal';
import CloseButton from '@components/common/closeButton';
import Text from '@components/common/text';
import Button from '@components/common/button';
import { useInterviewAdjusted } from '@repository/match';
import { useNotificationStore } from '@stores/NotificationStore';
import { useMessageActions } from '@stores/MessageStore';
import { FontStyle } from '@domain/constant/fontStyle';
import classnames from 'classnames/bind';
import styles from './index.module.scss';

const cx = classnames.bind(styles);

interface CoordinationModalProps {
  visible: boolean;
  optionSn?: string;
  onClose: () => void;
}

const CoordinationModal = ({ visible, optionSn, onClose }: CoordinationModalProps) => {
  const { fetchMessagesList } = useMessageActions();
  const positionInfo = useNotificationStore((state) => state.positionInfo);
  const { mutateAsync } = useInterviewAdjusted();
  const [adjustReason, setAdjustReason] = useState('');

  const handleClick = async () => {
    if (!positionInfo?.positionSn || !optionSn) return;
    await mutateAsync({ positionSn: positionInfo.positionSn, optionSn: Number(optionSn), adjustReason });
    await fetchMessagesList(positionInfo.positionSn);
    onClose();
  };

  return (
    <Modal visible={visible} isUseScrollHidden={false}>
      <div className={cx('inner')}>
        <CloseButton className={cx('btnClose')} onClick={onClose} />

        <Text fontStyle={FontStyle.H4_B}>다른 일정에 면접을 보고싶나요?</Text>

        <p className={cx('notification')}>
          면접 일정을 조율할 수 있는지
          <br />
          인사담당자님께 전달해 드릴게요.
        </p>

        <div className={cx('textareaWrap')}>
          <textarea
            className={cx('textarea')}
            placeholder="원하는 면접 일정을 알려주세요."
            maxLength={100}
            onChange={(e) => setAdjustReason(e.target.value)}
          />
          <div className={cx('infoText')}>
            최대 글자수 : <span className={cx('value')}>{adjustReason.length}</span> / 100
          </div>
        </div>

        <Button className={cx('btn')} disabled={!adjustReason.length} onClick={handleClick}>
          확인
        </Button>
      </div>
    </Modal>
  );
};
export default CoordinationModal;
