import React from 'react';
import { ScheduleProps } from '../index';
import { MatchTalkMessageOptionDto } from '@domain/rs/match/message/MatchingMessageListRs';
import Text from '@components/common/text';
import { FontStyle } from '@domain/constant/fontStyle';
import Colors from '@domain/constant/colors';
import classnames from 'classnames/bind';
import styles from './decidedSchedules.module.scss';
import Icon from '@components/common/assets';
const cx = classnames.bind(styles);

interface DecidedSchedulesProps {
  interviewSn: string | null;
  decision?: MatchTalkMessageOptionDto;
  options: ScheduleProps[];
}

const DecidedSchedules = ({ interviewSn, decision, options }: DecidedSchedulesProps) => {
  const optionsEl = options.map((option) => {
    const isChecked = interviewSn === option.sn;
    const isAccept = decision?.command === 'ACCEPT' && option.sn === decision?.sn;

    return (
      <label key={option.sn} className={cx('option', { isChecked: isAccept }, 'disabled')}>
        <Text className={cx('text')} fontStyle={FontStyle.B2_B} color={Colors.C_COOL_GRAY_90}>
          {option.title}
        </Text>

        <input type="checkbox" className={cx('input', 'hidden')} checked={isChecked} disabled={true} />
        <span className={cx('mark')} />
      </label>
    );
  });

  return (
    <div className={cx('scheduleArea')}>
      <div className={cx('title')}>
        <Text fontStyle={FontStyle.B2P_B}>면접 시간</Text>
        <Text className={cx('info')} fontStyle={FontStyle.B3_M}>
          일정 조율
          <Icon name="arrowRightLight" width={24} height={24} />
        </Text>
      </div>
      <div className={cx('optionArea')}>{optionsEl}</div>
    </div>
  );
};

export default DecidedSchedules;
