import React from 'react';
import { useRouter } from 'next/router';
import { useUserInfo } from '@repository/auth/useUserInfo';
import LoginModal from '@components/loginModal';
import { LogTypeEnum } from '@/src/logging/LogType';
import GnbListItem from './GnbListItem';
import { RouteUrl, useGetACCAPage, useGetMatchPage } from '@utils/routeUtil';

import useModal from '@utils/hooks/useModal';
import classnames from 'classnames/bind';
import styles from './gnbList.module.scss';
const cx = classnames.bind(styles);

interface Props {
  siteMapVisible: boolean;
}

const GnbList = ({ siteMapVisible }: Props) => {
  const { pathname } = useRouter();

  const { data: userInfo } = useUserInfo();
  const isLogin = !!userInfo?.id;
  const { closeModal, isModalOpen, openModal } = useModal();

  const matchUrl = useGetMatchPage();
  const accaTutorialUrl = useGetACCAPage();

  return (
    <>
      <div className={cx('gnbListArea')}>
        <div className={cx('gnbListInner')} id={LogTypeEnum.log_gnb_menu}>
          <GnbListItem
            title={{ text: '잡다매칭', url: matchUrl }}
            isOn={RouteUrl.MATCH_INTRODUCE === pathname || RouteUrl.MATCH_APPLY === pathname}
            sub={[
              {
                iconName: 'pageChecklistBlue',
                title: '매칭 현황',
                text: '인사담당자가 직접 보낸 제안을 확인해 보세요.',
                url: isLogin ? RouteUrl.MATCH_APPLY : openModal,
              },
              {
                iconName: 'resumeDoneGreen',
                title: '잡다매칭 소개',
                text: '스펙아닌 역량으로 취업하는 방법을 알려드릴게요.',
                url: RouteUrl.MATCH_INTRODUCE,
              },
            ]}
            siteMapVisible={siteMapVisible}
          />

          <GnbListItem
            title={{ text: '채용정보', url: RouteUrl.JOBS }}
            isOn={RouteUrl.JOBS === pathname || RouteUrl.POSITION === pathname || RouteUrl.CHALLENGE === pathname}
            sub={[
              {
                iconName: 'calendarBlue',
                title: '채용 캘린더',
                text: '채용 스케줄을 놓치지 말고 관리해 보세요.',
                url: RouteUrl.JOBS,
              },
              {
                iconName: 'briefcaseYellow',
                title: '채용 공고',
                text: '내가 선택한 필터로 공고를 쉽게 찾아보세요.',
                url: RouteUrl.POSITION,
              },
              // TODO: 채용 이벤트 있을 때 주석 해제
              // {
              //   iconName: 'rocket',
              //   title: '채용 이벤트',
              //   text: '직군, 기업, 산업 등 다양한 테마의 채용을 만나보세요.',
              //   url: RouteUrl.CHALLENGE,
              // },
            ]}
            siteMapVisible={siteMapVisible}
          />

          <GnbListItem
            title={{ text: '역량검사', url: accaTutorialUrl }}
            isOn={RouteUrl.ACCA_INTRODUCE === pathname || RouteUrl.ACCA_TEST_GATE === pathname}
            sub={[
              {
                iconName: 'acca',
                title: '역량검사',
                text: '750개 이상의 기업에서 확인하는 ‘진짜’ 역량을 진단해 보세요.',
                url: RouteUrl.ACCA_TEST_GATE,
              },
              {
                iconName: 'coding',
                title: '개발자 검사',
                text: '실무와 가장 유사한 실전 개발 역량을 진단해 보세요.',
                url: RouteUrl.PHS_TEST_GATE,
              },
            ]}
            siteMapVisible={siteMapVisible}
          />

          <GnbListItem
            title={{ text: '취업 콘텐츠', url: RouteUrl.INFO_LIST }}
            isNew
            isOn={
              RouteUrl.INFO_LIST === pathname ||
              RouteUrl.PASSBOARD === pathname ||
              RouteUrl.PASS_INTERVIEW === pathname ||
              RouteUrl.PASS_INTERVIEW_HISTORY === pathname ||
              RouteUrl.PASS_INTERVIEW_START === pathname
            }
            sub={[
              {
                iconName: 'chatBlue',
                title: '합격 후기 게시판',
                text: '합격한 선배들의 이야기를 들어보세요.',
                url: RouteUrl.PASSBOARD,
              },
              {
                iconName: 'bookGreen',
                title: '취업 콘텐츠',
                text: '지원부터 합격까지 필요한 정보들이 모여있어요.',
                url: RouteUrl.INFO_LIST,
              },
              {
                iconName: 'flagRed',
                title: '기출 면접 연습',
                text: '기업별 예상 면접 질문 확인과 면접 연습을 할 수 있어요.',
                url: RouteUrl.PASS_INTERVIEW,
                isNew: true,
              },
            ]}
            siteMapVisible={siteMapVisible}
          />

          {/* <GnbListItem
            title={{ text: '이벤트', url: RouteUrl.EVENT }}
            isOn={RouteUrl.EVENT === pathname}
            siteMapVisible={siteMapVisible}
          /> */}
        </div>
      </div>

      <LoginModal
        subTitle="채용정보 · 역량검사 · 매칭까지"
        title="취업기회 지금 바로 잡다!"
        visible={isModalOpen}
        onClose={closeModal}
      />
    </>
  );
};

export default GnbList;
