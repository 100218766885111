import React, { useEffect } from 'react';
import Link from 'next/link';
import Button from '@components/common/button';
import Modal from '@components/common/modal';
import Checkbox from '@components/common/checkbox';
import RadioInput from '@components/common/radioInput';
import { useToast } from '@components/common/toast';
import { MATCH_LIST_QUERY_KEY, useRejectMatching } from '@repository/match';
import { CompanyTabEnum } from '@components/company/sn';
import { useMessageActions } from '@stores/MessageStore';
import { useJD } from '@repository/position/usePosition';
import {
  OfferRejectEnum,
  OfferRejectLabel,
  OfferRejectSubReasonEnum,
  OfferRejectSubReasonLabel,
  OfferRejectSubReasonType,
  rejectSubReasonTextMaxLength,
} from '@domain/OfferRejectType';
import { LogTypeEnum } from '@/src/logging/LogType';
import { queryClient } from '@utils/queryClient';

import { V2Route } from '@utils/routeUtil';
import ArrayUtil from '@utils/array/util';
import classnames from 'classnames/bind';
import styles from './refuseModal.module.scss';
const cx = classnames.bind(styles);

interface IRefuseModalProps {
  visible: boolean;
  onClose: () => void;
  onRefuseAfter?: () => void;
  optionSn: number;
  positionSn: number;
  companyName: string;
  companySn: number;
  onPreferenceReasonClick: () => void;
}

const RefuseModal = ({
  onClose,
  onRefuseAfter,
  visible,
  optionSn,
  positionSn,
  companyName,
  companySn,
  onPreferenceReasonClick,
}: IRefuseModalProps) => {
  const { fetchMessagesList } = useMessageActions();
  const Toast = useToast();
  const { refetch } = useJD(positionSn);

  const [rejectReason, setRejectReason] = React.useState<OfferRejectEnum | null>(null);
  const [subReason, setSubReason] = React.useState<OfferRejectSubReasonType[]>([]);
  const [reasonText, setReasonText] = React.useState<string>('');
  const [refuseOptions, _] = React.useState(
    ArrayUtil.shuffleArray([
      OfferRejectEnum.INDUSTRY_OR_JOB_TITLE,
      OfferRejectEnum.WORKING_PREFERENCE,
      OfferRejectEnum.JOB_SEARCH,
    ]),
  );
  const submitButtonDisabled =
    !rejectReason ||
    (rejectReason === OfferRejectEnum.OTHER && !reasonText) ||
    (rejectReason === OfferRejectEnum.WORKING_PREFERENCE && subReason.length === 0);

  const { mutateAsync: rejectMatchingAsync } = useRejectMatching(
    async () => {
      fetchMessagesList(positionSn);
      queryClient.refetchQueries(MATCH_LIST_QUERY_KEY);
      refetch();
      Toast({ type: 'success', iconType: 'info', content: '제안을 거절하셨습니다.' });
      onRefuseAfter?.();
    },
    (err) => {
      let content;
      switch (err?.response?.data.errorCode) {
        case 'B901':
          content = '포지션이 종료되었습니다.';
          break;
        case 'B902':
          content = '포지션이 삭제되었습니다.';
          break;
        case 'B904':
          content = '이미 거절한 제안입니다.';
          break;
        case 'B906':
          content = '응답 기한이 만료되었습니다.';
          break;
        default:
          content = '제안 거절에 실패했습니다.';
          break;
      }
      Toast({ type: 'error', iconType: 'info', content });
    },
  );

  const onRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRejectReason(e.target.value as OfferRejectEnum);
  };

  const onSubReasonChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = e.target;
    if (checked) {
      setSubReason([...subReason, value] as OfferRejectSubReasonType[]);
    } else {
      setSubReason(subReason.filter((item) => item !== value));
    }
  };

  const onSubReasonTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e.target;
    if (value.length > rejectSubReasonTextMaxLength) return;
    setReasonText(value);
  };

  const onCloseWithReset = () => {
    setRejectReason(null);
    setSubReason([]);
    setReasonText('');
    onClose();
  };

  const onSubmit = async () => {
    if (submitButtonDisabled || !rejectReason) return;

    const reason = OfferRejectLabel[rejectReason];
    switch (rejectReason) {
      case OfferRejectEnum.WORKING_PREFERENCE:
        await rejectMatchingAsync({
          optionSn,
          positionSn,
          rejectReasonType: rejectReason,
          rejectReason: reason,
          subReasons: subReason,
        });
        onPreferenceReasonClick();
        return;
      case OfferRejectEnum.INDUSTRY_OR_JOB_TITLE:
        await rejectMatchingAsync({
          optionSn,
          positionSn,
          rejectReasonType: rejectReason,
          rejectReason: reason,
          subReasons: [],
        });
        onPreferenceReasonClick();
        return;
      case OfferRejectEnum.JOB_SEARCH:
        await rejectMatchingAsync({
          optionSn,
          positionSn,
          rejectReasonType: rejectReason,
          rejectReason: reason,
          subReasons: [],
        });
        break;
      case OfferRejectEnum.OTHER:
        await rejectMatchingAsync({
          optionSn,
          positionSn,
          rejectReasonType: rejectReason,
          rejectReason: reasonText,
          subReasons: [],
        });
        break;
    }
    onCloseWithReset();
  };

  useEffect(() => {
    if (rejectReason !== OfferRejectEnum.INDUSTRY_OR_JOB_TITLE) {
      setSubReason([]);
    }

    if (rejectReason !== OfferRejectEnum.OTHER) {
      setReasonText('');
    }
  }, [rejectReason]);

  const RefuseListEls = refuseOptions.map((e) => {
    if (e == OfferRejectEnum.WORKING_PREFERENCE) {
      return (
        <RadioInput
          key={e}
          name="refuseType"
          label={OfferRejectLabel[OfferRejectEnum.WORKING_PREFERENCE]}
          value={OfferRejectEnum.WORKING_PREFERENCE}
          onChange={onRadioChange}
          checked={rejectReason === OfferRejectEnum.WORKING_PREFERENCE}
          condition={rejectReason === OfferRejectEnum.WORKING_PREFERENCE}
        >
          <div className={cx('checkboxWrap')}>
            <Checkbox
              type="square"
              className={cx('checkbox')}
              value={OfferRejectSubReasonEnum.SALARY}
              checked={subReason.includes(OfferRejectSubReasonEnum.SALARY)}
              onChange={onSubReasonChange}
            >
              {OfferRejectSubReasonLabel.SALARY}
            </Checkbox>
            <Checkbox
              type="square"
              className={cx('checkbox')}
              value={OfferRejectSubReasonEnum.WELFARE}
              checked={subReason.includes(OfferRejectSubReasonEnum.WELFARE)}
              onChange={onSubReasonChange}
            >
              {OfferRejectSubReasonLabel.WELFARE}
            </Checkbox>
            <Checkbox
              type="square"
              className={cx('checkbox')}
              value={OfferRejectSubReasonEnum.LOCATION}
              checked={subReason.includes(OfferRejectSubReasonEnum.LOCATION)}
              onChange={onSubReasonChange}
            >
              {OfferRejectSubReasonLabel.LOCATION}
            </Checkbox>
          </div>
        </RadioInput>
      );
    }
    return (
      <RadioInput
        key={e}
        name="refuseType"
        label={OfferRejectLabel[e]}
        value={e}
        onChange={onRadioChange}
        checked={rejectReason === e}
      />
    );
  });

  return (
    <Modal visible={visible}>
      <div className={cx('refuseModalArea')}>
        <div className={cx('title')}>제안을 거절하시겠어요?</div>
        <div className={cx('description')}>
          걱정하지 마세요! 거절에 따른 불이익은 없습니다.
          <br />더 좋고, 정확한 제안을 드리기 위해 사유를 알려주세요.
        </div>

        <Link
          className={cx('matchingReportBanner')}
          href={`${V2Route.getCompanyChannelPage(companySn)}?tab=${CompanyTabEnum.REPORT}&positionSn=${positionSn}`}
        >
          <span className={cx('bannerTitle')}>잠깐! 답변을 망설이고 있으신가요?</span>
          <div className={cx('bannerDesc')}>
            <span>{companyName}에서 보낸</span>
            <span>특별한 매칭 리포트를 확인하고</span>
            <span>조금 더 고민해 보세요.</span>
          </div>
        </Link>

        <form className={cx('form')}>
          {RefuseListEls}

          {/* 직접 입력 */}
          <RadioInput
            name="refuseType"
            label={OfferRejectLabel[OfferRejectEnum.OTHER]}
            value={OfferRejectEnum.OTHER}
            onChange={onRadioChange}
            checked={rejectReason === OfferRejectEnum.OTHER}
            condition={rejectReason === OfferRejectEnum.OTHER}
            className={cx('label')}
          >
            <div className={cx('textareaWrap')}>
              <textarea
                placeholder="거절 사유를 입력해 주세요."
                maxLength={rejectSubReasonTextMaxLength}
                rows={4}
                onChange={onSubReasonTextChange}
              />
              <div className={cx('infoText')}>
                최대 글자수 : <span className={cx('value')}>{reasonText.length}</span> / {rejectSubReasonTextMaxLength}
              </div>
            </div>
          </RadioInput>
        </form>

        <div className={cx('buttonArea')}>
          <Button
            theme="primary"
            onClick={onSubmit}
            disabled={submitButtonDisabled}
            className={cx('button')}
            id={LogTypeEnum.log_message_cbo_match_reject}
          >
            확인
          </Button>
        </div>
      </div>

      <a className={cx('btnClose')} role="button" onClick={onCloseWithReset}>
        <span className={cx('hidden')}>close</span>
      </a>
    </Modal>
  );
};

export default RefuseModal;
