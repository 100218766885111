import React from 'react';
import Modal from '@common/modal';

import classnames from 'classnames/bind';
import styles from './previewModal.module.scss';
const cx = classnames.bind(styles);

interface PreviewModalProps {
  visible: boolean;
  onClose: () => void;
  previewUrl: string;
}

const PreviewModal = ({ visible, onClose, previewUrl }: PreviewModalProps) => {
  return (
    <Modal visible={visible} dimmed={true}>
      <div className={cx('layer')}>
        <iframe src={previewUrl} className={cx('frame')} />

        <a className={cx('btnClose')} role="button" onClick={onClose}>
          <span className={cx('hidden')}>close</span>
        </a>
      </div>
    </Modal>
  );
};

export default PreviewModal;
