import React from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';
import CustomImage from '@components/common/image';
import { useMatchReportCompanies } from '@repository/match/report/useMatchReport';
import { useUserInfo } from '@repository/auth/useUserInfo';
import { CompanyTabEnum } from '..';
import { V2Route } from '@utils/routeUtil';
import classnames from 'classnames/bind';
import styles from './suggestCompany.module.scss';
const cx = classnames.bind(styles);

const SuggestCompany = () => {
  const router = useRouter();
  const { sn } = router.query;
  const { data: userInfo } = useUserInfo();
  const isLogin = !!userInfo?.id;
  const { data } = useMatchReportCompanies(isLogin && !!sn, Number(sn));

  if (!data || !data.companies.length) return null;

  const companyItemEls = data?.companies?.map((company) => {
    return (
      <Link
        className={cx('companyItem')}
        key={company.sn}
        href={{
          pathname: V2Route.getCompanyChannelPage(company?.sn),
          query: { tab: CompanyTabEnum.REPORT },
        }}
      >
        <span className={cx('logo')}>
          <CustomImage src={company.logo.fileUrl} alt={company.name} width={40} height={40} />
        </span>
        <span className={cx('companyName')}>{company.name}</span>
      </Link>
    );
  });

  return (
    // 플로팅 영역 : 제안 받은 다른 매칭 기업
    <div className={cx('wrapper')}>
      <span className={cx('title')}>제안 받은 다른 매칭 기업</span>
      <div className={cx('companyList')}>{companyItemEls}</div>
    </div>
  );
};

export default SuggestCompany;
