import React from 'react';
import Link from 'next/link';
import Text from '@components/common/text';
import { useNotificationStore } from '@stores/NotificationStore';
import WritingResumeBtn from './resumeBtn/WritingResumeBtn';
import SubmitResumeBtn from './resumeBtn/SubmitResumeBtn';
import ProfileResumeBtn from './resumeBtn/ProfileResumeBtn';
import MatchingProfileResumeViewBtn from './resumeBtn/MatchingProfileResumeBtn';
import { moveToResults } from '../companyMessage/resultRequest/resultModals';
import MatchMessageVO from '@domain/vo/match/message/MatchMessageVO';
import MatchingMessageListInfoVO from '@domain/vo/match/message/MatchingMessageListInfoVO';
import { CommandTypeCode } from '@domain/rs/match/message/MatchingMessageListRs';
import { FontStyle } from '@domain/constant/fontStyle';
import Colors from '@domain/constant/colors';

import classnames from 'classnames/bind';
import styles from './userMessage.module.scss';
const cx = classnames.bind(styles);

interface UserMessageProps {
  userMessage: MatchMessageVO;
  messagesInfo: MatchingMessageListInfoVO;
}

const UserMessage = ({ userMessage, messagesInfo }: UserMessageProps) => {
  const { title, createdDayTime, submitApplies, command, message, decision } = userMessage;
  const isResultRequest = submitApplies.length;
  const positionInfo = useNotificationStore((state) => state.positionInfo);

  if (!positionInfo?.companySn) return null;

  const { beforeOpeningYn } = messagesInfo;

  const getResumeContents = () => {
    switch (command) {
      case CommandTypeCode.WRITING_RESUME:
        return <WritingResumeBtn positionSn={positionInfo.positionSn} beforeOpeningYn={beforeOpeningYn} />;

      case CommandTypeCode.SHOW_SUBMIT_RESUME:
        return (
          <>
            <p className={cx('desc')}>
              지원서 수정은 지원 취소 후에 가능합니다.
              <br />
              단, 지원 취소가 제한되는 상황이 있을 수 있습니다.
            </p>
            <SubmitResumeBtn positionSn={positionInfo.positionSn} />
          </>
        );

      case CommandTypeCode.SHOW_SUBMIT_PROFILE_RESUME:
        return (
          <>
            <div className={cx('desc')}>
              <div>
                지원서 수정은 지원 취소 후에 가능합니다.
                <br />
                전형이 진행된 경우나 지원 마감 시에는 취소가 불가능 합니다.
              </div>
            </div>
            <ProfileResumeBtn positionSn={positionInfo.positionSn} />
          </>
        );

      case CommandTypeCode.SHOW_SUBMITTED_PROFILE:
        return (
          <>
            <ProfileResumeBtn positionSn={positionInfo.positionSn} btnText="제출한 프로필 확인하기" />
          </>
        );

      case CommandTypeCode.SHOW_MATCHING_PROFILE:
        return <MatchingProfileResumeViewBtn positionSn={positionInfo.positionSn} />;

      case CommandTypeCode.INTERVIEW_ACCEPTED:
        return (
          <div>
            <Text fontStyle={FontStyle.B2P_M} color={Colors.C_WHITE} styles={{ marginTop: 16 }}>
              수락한 제안은 취소할 수 없습니다.
              <br /> 일정 조율이 필요하다면 채용담당자님께 문의해주세요.
            </Text>
            <div className={cx('divider')} />
            <div className={cx('interviewInfo')}>
              {!!decision?.interviewLocation && (
                <div className={cx('info')}>
                  <Text fontStyle={FontStyle.B2P_B} color={Colors.C_WHITE}>
                    면접 장소
                  </Text>
                  <Text fontStyle={FontStyle.B2P_M} color={Colors.C_WHITE}>
                    {decision?.interviewLocation}
                  </Text>
                </div>
              )}
              {!!decision?.title && (
                <div className={cx('info')}>
                  <Text fontStyle={FontStyle.B2P_B} color={Colors.C_WHITE}>
                    면접 시간
                  </Text>
                  <Text fontStyle={FontStyle.B2P_M} color={Colors.C_WHITE}>
                    {decision?.title}
                  </Text>
                </div>
              )}
            </div>
          </div>
        );

      default:
        return (
          <>
            {!!message && (
              <Text
                fontStyle={FontStyle.B2P_M}
                color={Colors.C_WHITE}
                styles={{ marginTop: 16, whiteSpace: 'pre-wrap' }}
              >
                {message}
              </Text>
            )}
          </>
        );
    }
  };

  const messageContents = isResultRequest ? (
    <Link href={moveToResults(submitApplies[0].applyType, submitApplies[0].applySn)} legacyBehavior>
      <a className={cx('result')} target="_blank" role="button">
        제출 결과표 확인하기
      </a>
    </Link>
  ) : (
    getResumeContents()
  );

  return (
    <div className={cx('userMessageArea')}>
      <div className={cx('date')}>{createdDayTime}</div>
      <div className={cx('content')}>
        <div className={cx('title')}>{title}</div>
        {messageContents}
      </div>
    </div>
  );
};

export default UserMessage;
