import { useFormContext } from 'react-hook-form';
import JobTitlesFilter from '@components/position/list/positionFilter/filter/JobTitlesFilter';
import RecruitmentFilter from '@components/position/list/positionFilter/filter/RecruitmentFilter';
import SalaryFilter from '@components/position/list/positionFilter/filter/SalaryFilter';
import LocationFilter from '@components/position/list/positionFilter/filter/LocationFilter';
import CompanyPositionFilterVO from '@domain/vo/company/sn/CompanyPositionFilterVO';
import classnames from 'classnames/bind';
import styles from './positionFilter.module.scss';
const cx = classnames.bind(styles);

const PositionFilter = () => {
  const { reset } = useFormContext<CompanyPositionFilterVO>();

  const onInitFilter = () => {
    reset(new CompanyPositionFilterVO());
  };

  return (
    <div className={cx('filterArea')}>
      <div className={cx('filterWrap')}>
        <JobTitlesFilter />
        <RecruitmentFilter />
        <SalaryFilter />
        <LocationFilter />
      </div>
      <a className={cx('btnReset')} role="button" onClick={onInitFilter}>
        초기화
      </a>
    </div>
  );
};

export default PositionFilter;
