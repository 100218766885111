import Advantages from './advantages';
import CheerMessage from './cheerMessage';
import IntroduceText from './introduceText';
import Salary from './salary';
import Welfare from './welfare';
import Location from './location';
import EmployeeInfo from './info/employeeInfo';
import FinanceInfo from './info/financeInfo';
import CompanyUpdateBanner from './companyUpdateBanner';

const InfoTab = () => {
  return (
    <>
      {/* 기업 소개 문구 */}
      <IntroduceText />

      {/* 잡다가 분석한 특별한 강점 */}
      {/* 현재 기준이 명확하지않아 잠정제거 */}
      {/* <Strength /> */}

      {/* 입사 선배가 알려주는 장점 */}
      <Advantages />

      {/* 현직자 이야기 */}
      <CheerMessage />

      {/* 복지 */}
      <Welfare />

      {/* 평균 연봉 */}
      <Salary />

      {/* 재무 정보 */}
      <FinanceInfo />

      {/* 직원 수 */}
      <EmployeeInfo />

      {/* 위치 */}
      <Location />

      <CompanyUpdateBanner />
    </>
  );
};

export default InfoTab;
