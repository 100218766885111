import React, { FC, useState } from 'react';

import useUpdateEffect from '@utils/hooks/useUpdateEffect';
import classnames from 'classnames/bind';
import styles from './index.module.scss';
const cx = classnames.bind(styles);

interface CountdownProps {
  className?: string;
  number: number | string;
  width: number;
  height: number;
  maxRange?: number;
}

const Countdown: FC<CountdownProps> = ({ className, number, width, height, maxRange = 9 }) => {
  //0까지 숫자를 할당하기 위해 length + 1
  const NUMBER = Array.from({ length: maxRange + 1 }, (_, i) => maxRange - i);
  const ANGLE = 360 / NUMBER.length;
  //현재 숫자인덱스(number + 1)
  const numberIndex = Number(number) + 1;
  const translateZ = height - 3 * (10 - NUMBER.length) + 2;
  const [rotateX, setRotateX] = useState(numberIndex * ANGLE);

  useUpdateEffect(() => setRotateX((prev) => prev - ANGLE), [number]);

  return (
    <div className={cx('counter', className)} style={{ width, height }}>
      <div
        className={cx('rotator')}
        style={{
          transform: `rotateX(${rotateX}deg)`,
        }}
      >
        {NUMBER.map((number, index) => (
          <div
            key={number}
            className={cx('item')}
            style={{ transform: `rotateX(${index * ANGLE}deg) translateZ(${translateZ}px)` }}
          >
            {number}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Countdown;
