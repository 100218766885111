import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import Icon from '@components/common/assets';
import KakaoMap from '@components/common/KakaoMap';
import { useCompaniesInformation } from '@repository/companies/useCompanies';
import Colors from '@domain/constant/colors';
import classnames from 'classnames/bind';
import styles from './location.module.scss';

const cx = classnames.bind(styles);

const Location = () => {
  const router = useRouter();
  const { sn } = router.query;
  const { data: companyInfoData } = useCompaniesInformation(Number(sn));
  const [visible, setVisible] = useState(!!companyInfoData?.companyDetail.location);

  useEffect(() => {
    setVisible(!!companyInfoData?.companyDetail.location);
  }, [companyInfoData]);

  if (!visible) return null;

  return (
    //위치
    <div className={cx('snInner', 'noBorder')}>
      {/* 영역 제목 */}
      <p className={cx('sectionTitle')}>
        위치
        <span className={cx('location')}>
          <Icon name="pinLight" width={32} height={32} fill={Colors.C_COOL_GRAY_70} />
          {companyInfoData?.companyDetail?.location}
        </span>
      </p>
      {/* [D] 지도 영역 */}
      <KakaoMap
        className={cx('map')}
        address={companyInfoData?.companyDetail?.location as string}
        handleError={() => setVisible(false)}
      />
    </div>
  );
};

export default Location;
