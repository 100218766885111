import React, { FC, useState, useRef } from 'react';
import EllipsisTooltip from '@common/ellipsisTooltip';

import classnames from 'classnames/bind';
import styles from './ellipsisText.module.scss';
const cx = classnames.bind(styles);

interface EllipsisTextProps {
  text: string;
  type: 'width' | 'height';
  offset: number;
  customClassName: string;
  tooltipType?: 'top' | '';
}

const EllipsisText: FC<EllipsisTextProps> = ({ text, type, offset, customClassName, tooltipType }) => {
  const [isTooltipVisible, setIsTooltipVisible] = useState<boolean | null>(null);
  const [isTooltipHover, setIsTooltipHover] = useState(false);
  const ref = useRef<HTMLSpanElement>(null);

  const handleMouseEnter = () => {
    if (!ref.current) return;

    const tooltipVisible =
      isTooltipVisible === null
        ? type === 'width'
          ? ref.current.offsetWidth > offset
          : ref.current.offsetHeight > offset
        : isTooltipVisible;
    setIsTooltipHover(true);
    setIsTooltipVisible(tooltipVisible);
  };

  const handleMouseLeave = () => {
    setIsTooltipHover(false);
  };

  return (
    <div className={cx('ellipsisTextArea')} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <div className={cx('ellipsisTextWrap', customClassName)}>
        <span className={cx('ellipsisText')} ref={ref}>
          {text}
        </span>
      </div>

      {isTooltipVisible && isTooltipHover && <EllipsisTooltip text={text} type={tooltipType} />}
    </div>
  );
};

export default EllipsisText;
