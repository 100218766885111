// 매칭 알림타입
export enum MatchingNotificationTypeCode {
  MATCHING_OFFER = 'MATCHING_OFFER', // 매칭제안
  STATUS_CHANGE = 'STATUS_CHANGE', // 상태변경
  INSIGHT_STATUS_CHANGE = 'INSIGHT_STATUS_CHANGE', // 인사이트 전형 상태 변경
  DETAILED_BROWSE = 'DETAILED_BROWSE', //프로필 상세 조회
  PROFILE_UPDATE_BY_RESUME = 'PROFILE_UPDATE_BY_RESUME', //지원서 기반 프로필 업데이트
  FILE_REQUEST = 'FILE_REQUEST', //추가 서류 제출
  SIMPLE_MESSAGE = 'SIMPLE_MESSAGE', // 일반 메시지 도착
  ALIMTALK_MESSAGE = 'ALIMTALK_MESSAGE', // 알림톡 메시지 도착
  ACC_REQUEST = 'ACC_REQUEST', // 역량검사 제출 요청
  PHS_REQUEST = 'PHS_REQUEST', // 개발자 검사 제출 요청
  MATCHING_TALK_LINK = 'MATCHING_TALK_LINK', //매칭톡 메시지 연동
  ACC_ANALYSIS_COMPLETE = 'ACC_ANALYSIS_COMPLETE', // 역량검사 분석완료
  PACC_ANALYSIS_COMPLETE = 'PACC_ANALYSIS_COMPLETE', //개발자 역량검사 분석완료
  PST_ANALYSIS_COMPLETE = 'PST_ANALYSIS_COMPLETE', // 개능검 분석완료
  ACC_EXPIRED = 'ACC_EXPIRED', // 역량검사 유효기간 만료
  PACC_EXPIRED = 'PACC_EXPIRED', // 개발자 역량검사 유효기간 만료
  PST_EXPIRED = 'PST_EXPIRED', //개능검 유효기간 만료
  GENERAL_CREATED = 'GENERAL_CREATED', // 일반 인재풀 추가
  DEVELOPER_CREATED = 'DEVELOPER_CREATED', // 개발자 인재풀 추가
  GENERAL_DELETED = 'GENERAL_DELETED', // 일반 인재풀 제외
  DEVELOPER_DELETED = 'DEVELOPER_DELETED', // 개발자 인재풀 제외
  JOB_APPLICATION_SUBMITTED = 'JOB_APPLICATION_SUBMITTED', // 입사 지원 완료
  JOB_APPLICATION_CANCELED = 'JOB_APPLICATION_CANCELED', //입사 지원 취소
  INTERVIEW_OFFER_RESPONDED = 'INTERVIEW_OFFER_RESPONDED', //면접 제안 답변 완료
  INTERVIEW_OFFER_DEADLINE_REMINDER_D_DAY = 'INTERVIEW_OFFER_DEADLINE_REMINDER_D_DAY', // 면접 제안 답변 기안(D-Day)
  INTERVIEW_OFFER_DEADLINE_REMINDER_D_3 = 'INTERVIEW_OFFER_DEADLINE_REMINDER_D_3', // 면접 제안 답변 기안(D-3)
  JOB_APPLICATION_DEADLINE_REMINDER_D_DAY = 'JOB_APPLICATION_DEADLINE_REMINDER_D_DAY', // 입사 지원 마감(D-Day)
  JOB_APPLICATION_DEADLINE_REMINDER_D_3 = 'JOB_APPLICATION_DEADLINE_REMINDER_D_3', // 입사 지원 마감(D-3)
  PROFILE_INPUT_REMINDER = 'PROFILE_INPUT_REMINDER', //프로필 입력 유도
  ACC_COMPLETE = 'ACC_COMPLETE', // 역량검사 응시완료
  PACC_COMPLETE = 'PACC_COMPLETE', // 개발자 역량검사 응시완료
  PST_COMPLETE = 'PST_COMPLETE', //개능검 응시완료
  ACC_RESULT_SUBMITTED = 'ACC_RESULT_SUBMITTED', // 역량검사 결과 제출완료
  PACC_RESULT_SUBMITTED = 'PACC_RESULT_SUBMITTED', // 개발자 역량검사 결과 제출완료
  PST_RESULT_SUBMITTED = 'PST_RESULT_SUBMITTED', // 개능검 결과 제출완료
  ACC_REMINDER = 'ACC_REMINDER', // 역량검사 응시 시도 유도
  PACC_REMINDER = 'PACC_REMINDER', // 개발자 역량검사 응시 시도 유도
  PST_REMINDER = 'PST_REMINDER', // 개능검 응시 시도 유도
  ACC_COMPLETE_REMINDER = 'ACC_COMPLETE_REMINDER', // 역량검사 응시 완료 유도
  PACC_COMPLETE_REMINDER = 'PACC_COMPLETE_REMINDER', // 개발자 역량검사 응시 완료 유도
  PST_COMPLETE_REMINDER = 'PST_COMPLETE_REMINDER', // 개능검 응시 완료 유도
  ACC_RESULT_SUBMITTED_REMINDER_D_DAY = 'ACC_RESULT_SUBMITTED_REMINDER_D_DAY', // 역량검사 결과 제출 유도(D-Day)
  PACC_RESULT_SUBMITTED_REMINDER_D_DAY = 'PACC_RESULT_SUBMITTED_REMINDER_D_DAY', // 개발자 역량검사 결과 제출 유도(D-Day)
  PST_RESULT_SUBMITTED_REMINDER_D_DAY = 'PST_RESULT_SUBMITTED_REMINDER_D_DAY', // 개능검 결과 제출 유도(D-Day)
  ACC_RESULT_SUBMITTED_REMINDER_D_3 = 'ACC_RESULT_SUBMITTED_REMINDER_D_3', // 역량검사 결과 제출 유도(D-3)
  PACC_RESULT_SUBMITTED_REMINDER_D_3 = 'PACC_RESULT_SUBMITTED_REMINDER_D_3', // 개발자 역량검사 결과 제출 유도(D-3)
  PST_RESULT_SUBMITTED_REMINDER_D_3 = 'PST_RESULT_SUBMITTED_REMINDER_D_3', // 개능검 결과 제출 유도(D-3)
  REGISTER_USER = 'REGISTER_USER', // 회원가입 축하
  ACC_RECOMMEND = 'ACC_RECOMMEND', // 역량검사 응시 유도
  FILE_REQUEST_DEADLINE_REMINDER_D_DAY = 'FILE_REQUEST_DEADLINE_REMINDER_D_DAY', // 추가 서류 제출 마감(D-Day)

  // 잡다매칭
  MATCH_OFFER = 'MATCH_OFFER', // 매칭 제안
  INTERVIEW_OFFER = 'INTERVIEW_OFFER', // 면접 제안
  PROFILE_OPEN = 'PROFILE_OPEN', // 프로필 열람
  RECOMMEND = 'RECOMMEND', // 추천 포지션
}

export type MatchingNotificationType = keyof typeof MatchingNotificationTypeCode;

export const TEST_NAME_MAP = {
  ACC: '역량검사',
  PACC: '개발자 역량검사',
  PST: '개발 구현 능력 검사',
};

export type TestType = 'ACC' | 'PACC' | 'PST';
