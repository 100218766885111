import React from 'react';
import { useNotificationStore } from '@stores/NotificationStore';

import MatchOffer from './matchOffer';
import MatchingReport from './matchingReport';
import Notice from './notice';
import CompanyLogo from '../companyMessage/common/companyLogo';
import CompanyWriter from '../companyMessage/common/companyWriter';
import MatchMessageVO from '@domain/vo/match/message/MatchMessageVO';
import MatchingMessageListInfoVO from '@domain/vo/match/message/MatchingMessageListInfoVO';
import { CommandTypeCode } from '@domain/rs/match/message/MatchingMessageListRs';
import classnames from 'classnames/bind';
import styles from './index.module.scss';
const cx = classnames.bind(styles);

interface CompanyMessageProps {
  companyMessage: MatchMessageVO;
  messagesInfo: MatchingMessageListInfoVO;
}

const CBOMessage = ({ companyMessage, messagesInfo }: CompanyMessageProps) => {
  const { command, createdDayTime } = companyMessage;
  const positionInfo = useNotificationStore((state) => state.positionInfo);
  const { openYn, deleteYn, companySn } = messagesInfo;

  if (!positionInfo || !positionInfo.companyName || !positionInfo.positionSn || !positionInfo.companySn) return null;
  const { companyName, logoImageUrl, positionSn } = positionInfo;

  const getMessageContents = () => {
    switch (command) {
      // 입사지원 제안
      case CommandTypeCode.MATCH_OFFER:
        return (
          <MatchOffer
            companySn={companySn}
            positionSn={positionSn}
            companyName={companyName}
            companyMessage={companyMessage}
          />
        );

      // 매칭 리포트
      case CommandTypeCode.SHOW_MATCHING_REPORT:
        return <MatchingReport companyName={companyName} companySn={companySn} positionSn={positionSn} />;

      default:
        return <Notice companyMessage={companyMessage} />;
    }
  };

  return (
    <div className={cx('wrap', { notReport: command !== CommandTypeCode.SHOW_MATCHING_REPORT })}>
      {/* [D] 회사 로고 영역 */}
      {command !== CommandTypeCode.SHOW_MATCHING_REPORT && (
        <CompanyLogo companyName={companyName} logoImageUrl={logoImageUrl} />
      )}

      <div className={cx('contentArea')}>
        {/* [D] 회사 담당자 영역 */}
        {command !== CommandTypeCode.SHOW_MATCHING_REPORT && (
          <CompanyWriter companyName={companyName} createdDayTime={createdDayTime} />
        )}

        {/* [D] 회사 메세지 컨턴츠 영역 */}
        {getMessageContents()}
      </div>
    </div>
  );
};

export default CBOMessage;
