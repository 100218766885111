export enum Grade {
  TRY_AGAIN = 'TRY_AGAIN',
  BRONZE = 'BRONZE',
  SILVER = 'SILVER',
  GOLD = 'GOLD',
  PLATINUM = 'PLATINUM',
}

export const gradeTextSelectMap: Record<GradeType, string> = {
  TRY_AGAIN: 'Try Again',
  BRONZE: 'Bronze',
  SILVER: 'Silver',
  GOLD: 'Gold',
  PLATINUM: 'Platinum',
};

export const gradeList = Object.keys(Grade) as GradeType[];

export type GradeType = keyof typeof Grade;
