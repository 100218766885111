import { StylesConfig } from 'react-select';
import Colors from '@domain/constant/colors';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ReactSelectStyles: StylesConfig<Record<string, any>, true> = {
  container: (base, state) => ({
    // @ts-ignore
    border: `1px solid ${Colors.C_COOL_GRAY_50} !important`,
    borderRadius: '4px',
  }),
  control: () => ({
    position: 'relative',
    cursor: 'pointer',
  }),
  valueContainer: () => ({
    position: 'relative',
    height: 34,
    padding: '0 51px 0 18px',
    fontSize: 13,
    fontWeight: 500,
    lineHeight: '18px',
    letterSpacing: '-0.01em',
    color: Colors.C_COOL_GRAY_80,

    ':after': {
      position: 'absolute',
      top: '50%',
      right: 16,
      width: 20,
      height: 20,
      backgroundImage: `url('/img/v2/components/common/reactSelect/iconLargeArrowBottomLight.png')`,
      backgroundSize: 'cover',
      transform: 'translateY(-50%)',
      content: `''`,
    },
  }),
  singleValue: () => ({
    position: 'absolute',
    top: '50%',
    right: 46,
    left: 24,
    transform: 'translateY(-50%)',
  }),
  placeholder: () => ({
    position: 'absolute',
    top: 0,
    right: 46,
    bottom: 0,
    left: 24,
    display: 'flex',
    alignItems: 'center',
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '18px',
    letterSpacing: '-0.01em',
    color: Colors.C_COOL_GRAY_60,
  }),
  input: () => ({
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '18px',
    letterSpacing: '-0.01em',
    color: Colors.C_JOBDA_BLACK,
  }),
  indicatorsContainer: () => ({
    display: 'none',
  }),
  menu: () => ({
    position: 'absolute',
    right: 0,
    bottom: -4,
    left: 0,
    border: `1px solid ${Colors.C_COOL_GRAY_50}`,
    borderRadius: 4,
    backgroundColor: Colors.C_WHITE,
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.06), 0px 4px 6px rgba(0, 0, 0, 0.1)',
    transform: 'translateY(100%)',
    zIndex: 60,
  }),
  menuList: () => ({
    overflowY: 'auto',
    maxHeight: 182,
    padding: '4px 0',
  }),
  option: (base, state) => ({
    padding: '8px 22px',
    fontSize: 13,
    lineHeight: '20px',
    color: state.isSelected ? Colors.C_JOBDA_BLACK : Colors.C_COOL_GRAY_70,
    wordBreak: 'keep-all',
    cursor: 'pointer',

    ':hover': {
      backgroundColor: Colors.C_JOBDA_WHITE,
      color: Colors.C_COOL_GRAY_80,
    },
  }),
};
