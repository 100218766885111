import React from 'react';
import classnames from 'classnames/bind';
import styles from './layout.module.scss';

const cx = classnames.bind(styles);

interface LayoutProps {
  children?: React.ReactNode;
}

const Layout = ({ children }: LayoutProps) => {
  return <div className={cx('layout')}>{children}</div>;
};

export default Layout;
