import React, { FC } from 'react';
import { useUserInfo } from '@repository/auth/useUserInfo';
import NotificationCard from './NotificationCard';
import { MatchNotificationVO } from '@domain/vo/match/MatchNotificationVO';
import { MatchingApplicationStatusEnum } from '@domain/rs/match/MatchingHomeApplicationPositionRs';
import { MatchingNotificationTypeCode, TEST_NAME_MAP, TestType } from '@domain/constant/notificationTypeCode';

import { RouteUrl, V2Route } from '@utils/routeUtil';
import classnames from 'classnames/bind';
import styles from './index.module.scss';

const cx = classnames.bind(styles);

interface IMatchPositionCard {
  isLogin: boolean;
  onLoginModal: () => void;
  notifications: MatchNotificationVO[];
}

const MatchPositionCard: FC<IMatchPositionCard> = ({ notifications }) => {
  const { data: userInfo } = useUserInfo();
  const userName = userInfo?.name ?? '회원';

  if (notifications.length === 0) {
    // [D] 알림이 없는 경우 노출해주세요.
    return (
      <div className={cx('emptyAlertArea')}>
        <div className={cx('emptyAlertWrap', 'empty')}>
          <p className={cx('desc')}>새로운 알람이 없습니다.</p>
        </div>
      </div>
    );
  }

  const Cards = notifications.map((notification) => {
    const texts = getNotificationTextProps({ ...notification, userName });
    if (!texts) return null;
    return (
      <NotificationCard
        key={notification.notificationSn}
        {...notification}
        {...texts}
        nextPath={getNextPath(notification)}
      />
    );
  });

  return (
    <div className={cx('alertListArea')}>
      <div className={cx('alertListWrap')}>
        <ul className={cx('alertList')}>{Cards}</ul>
      </div>
    </div>
  );
};

export default MatchPositionCard;

type GetNotificationTextProps = MatchNotificationVO & { userName: string };
type GetNotificationReturn = { text: React.ReactNode; buttonText?: string } | null;

const getNotificationTextProps = ({
  type,
  userName,
  expirationDays,
  companyName: cn,
  positionName: pn,
}: GetNotificationTextProps): GetNotificationReturn => {
  const companyName = cn ? cn : '';
  const positionName = pn ? pn : '';

  switch (type) {
    case MatchingNotificationTypeCode.ACC_ANALYSIS_COMPLETE:
    case MatchingNotificationTypeCode.PACC_ANALYSIS_COMPLETE:
    case MatchingNotificationTypeCode.PST_ANALYSIS_COMPLETE: {
      const testType = type.split('_')[0] as TestType;
      return {
        text: (
          <>
            <em className={cx('emphasis')}>{TEST_NAME_MAP[testType]} 분석이 완료</em>되었어요!
            <br />
            지금 바로 결과를 확인해보세요.
          </>
        ),
        buttonText: '분석결과 확인하기',
      };
    }

    case MatchingNotificationTypeCode.ACC_COMPLETE:
    case MatchingNotificationTypeCode.PACC_COMPLETE:
    case MatchingNotificationTypeCode.PST_COMPLETE: {
      const testType = type.split('_')[0] as TestType;
      return {
        text: `${TEST_NAME_MAP[testType]} 응시가 완료되었어요.\n결과 분석은 최대 1일 가량 소요될 수 있어요.`,
      };
    }

    case MatchingNotificationTypeCode.ACC_COMPLETE_REMINDER:
    case MatchingNotificationTypeCode.PACC_COMPLETE_REMINDER:
    case MatchingNotificationTypeCode.PST_COMPLETE_REMINDER: {
      const testType = type.split('_')[0] as TestType;
      return {
        text: `${userName}님, 현재 응시 중인 ${TEST_NAME_MAP[testType]}${
          testType === 'PST' ? '가' : '이'
        } 존재합니다.\n이어서 응시하시고 빠르게 취업에 성공해보세요.`,
        buttonText: `응시 중인 ${TEST_NAME_MAP[testType]} 확인하기`,
      };
    }

    case MatchingNotificationTypeCode.ACC_EXPIRED:
    case MatchingNotificationTypeCode.PACC_EXPIRED:
    case MatchingNotificationTypeCode.PST_EXPIRED: {
      const testType = type.split('_')[0] as TestType;
      return {
        text: (
          <>
            {TEST_NAME_MAP[testType]}의 <em className={cx('emphasis')}>유효기간이 {expirationDays}일</em> 남았어요.
            <br />
            유효기간이 지나면 새롭게 응시를 해주세요.
          </>
        ),
        buttonText: '홈으로 바로가기',
      };
    }

    case MatchingNotificationTypeCode.ACC_RESULT_SUBMITTED:
    case MatchingNotificationTypeCode.PACC_RESULT_SUBMITTED:
    case MatchingNotificationTypeCode.PST_RESULT_SUBMITTED: {
      const testType = type.split('_')[0] as TestType;
      return {
        text: `${TEST_NAME_MAP[testType]} 결과 제출이 완료되었어요.\n제출한 결과표를 확인하러 가볼까요?`,
        buttonText: '제출한 결과표 확인하기',
      };
    }

    case MatchingNotificationTypeCode.ACC_RESULT_SUBMITTED_REMINDER_D_3:
    case MatchingNotificationTypeCode.PACC_RESULT_SUBMITTED_REMINDER_D_3:
    case MatchingNotificationTypeCode.PST_RESULT_SUBMITTED_REMINDER_D_3: {
      const notificationText = companyName && positionName ? `${positionName}의 ${companyName}` : `${userName}님,`;
      const testType = type.split('_')[0] as TestType;
      return {
        text: `${notificationText} ${TEST_NAME_MAP[testType]} 결과 제출 마감까지 3일 남았습니다.\n서둘러 제출해주세요!`,
        buttonText: `${TEST_NAME_MAP[testType]} 결과 제출하기`,
      };
    }

    case MatchingNotificationTypeCode.ACC_RESULT_SUBMITTED_REMINDER_D_DAY:
    case MatchingNotificationTypeCode.PACC_RESULT_SUBMITTED_REMINDER_D_DAY:
    case MatchingNotificationTypeCode.PST_RESULT_SUBMITTED_REMINDER_D_DAY: {
      const notificationText = companyName && positionName ? `${positionName}의 ${companyName}` : `${userName}님,`;
      const testType = type.split('_')[0] as TestType;
      return {
        text: `${notificationText} ${TEST_NAME_MAP[testType]} 결과 제출 마감일입니다.\n서둘러 제출해주세요!`,
        buttonText: `${TEST_NAME_MAP[testType]} 결과 제출하기`,
      };
    }

    case MatchingNotificationTypeCode.ACC_REMINDER:
    case MatchingNotificationTypeCode.PACC_REMINDER: {
      const testType = type.split('_')[0] as TestType;
      return {
        text: `${userName}님, ${TEST_NAME_MAP[testType]} 응시하고 나에게 딱 맞는 포지션을 쉽게 찾아보세요.`,
        buttonText: '역량검사 응시하러 가기',
      };
    }
    case MatchingNotificationTypeCode.PST_REMINDER:
      return {
        text: `${userName}님, 개발 구현 능력 검사 응시하고 개발 실무 능력을 길러 보세요.`,
        buttonText: '개발 구현 능력 검사 응시하러 가기',
      };

    case MatchingNotificationTypeCode.DEVELOPER_CREATED:
    case MatchingNotificationTypeCode.GENERAL_CREATED:
      return {
        text: '잡다매칭 참여 준비가 완료되었어요.\n딱 맞는 공고를 카카오톡 알림톡으로 보내드릴게요!',
        buttonText: '잡다매칭 소개 바로가기',
      };

    case MatchingNotificationTypeCode.DEVELOPER_DELETED:
    case MatchingNotificationTypeCode.GENERAL_DELETED:
      return {
        text: '잡다매칭 신청을 취소했습니다.\n더 좋은 제안을 드리기위해 노력하고 있을게요.',
        buttonText: '잡다매칭 혜택 다시보기',
      };

    case MatchingNotificationTypeCode.ACC_RECOMMEND:
      return {
        text: '750개 이상의 회사가 사용하는 역량검사로 나의 잠재역량, 잘 맞는 직무 및 기업을 파악해보세요.',
        buttonText: '나 알아보기',
      };
    case MatchingNotificationTypeCode.ACC_REQUEST:
      return {
        text: (
          <>
            {companyName}의 {positionName}에서 <em className={cx('emphasis')}>역량검사 검사 결과 제출을 요청</em>
            했어요.
            <br />
            지금 자세한 내용을 확인해보세요.
          </>
        ),
        buttonText: '확인하기',
      };
    case MatchingNotificationTypeCode.PHS_REQUEST:
      return {
        text: (
          <>
            {companyName}의 {positionName}에서 <em className={cx('emphasis')}>개발자 검사 결과 제출을 요청</em>
            했어요.
            <br />
            지금 자세한 내용을 확인해보세요.
          </>
        ),
        buttonText: '확인하기',
      };
    case MatchingNotificationTypeCode.ALIMTALK_MESSAGE:
      return {
        text: `${companyName}의 ${positionName}에서 제안이 왔어요!\n포지션 상세내용을 확인해보세요.`,
        buttonText: '확인하기',
      };
    case MatchingNotificationTypeCode.DETAILED_BROWSE:
      return {
        text: `${companyName}의 ${positionName}에서 내 프로필을 유심히 보고 있어요.`,
        buttonText: '확인하기',
      };
    case MatchingNotificationTypeCode.INSIGHT_STATUS_CHANGE:
      return {
        text: (
          <>
            {companyName}의 {positionName}에서 <em className={cx('emphasis')}>지원 상태가 변경</em>되었어요.
            <br />
            지금 자세한 내용을 확인해보세요.
          </>
        ),
        buttonText: '확인하기',
      };
    case MatchingNotificationTypeCode.INTERVIEW_OFFER_DEADLINE_REMINDER_D_3: {
      const notificationText = companyName && positionName ? `${positionName}의 ${companyName}` : `${userName}님,`;
      return {
        text: `${notificationText} 면접제안 응답 마감까지 3일 남았습니다.\n서둘러 답변해주세요!`,
        buttonText: '지원현황 확인하기',
      };
    }
    case MatchingNotificationTypeCode.INTERVIEW_OFFER_DEADLINE_REMINDER_D_DAY: {
      const notificationText = companyName && positionName ? `${positionName}의 ${companyName}` : `${userName}님,`;
      return {
        text: `${notificationText} 면접제안 응답 마감일입니다.\n서둘러 답변해주세요!`,
        buttonText: '지원현황 확인하기',
      };
    }
    case MatchingNotificationTypeCode.INTERVIEW_OFFER_RESPONDED:
      return {
        text: `${companyName}의 ${positionName} 면접이 확정되었어요.\n잡다에서 10,000개의 면접 질문을 연습해보세요.`,
        buttonText: '면접 연습하러 가기',
      };
    case MatchingNotificationTypeCode.JOB_APPLICATION_CANCELED:
      return {
        text: `${companyName}의 ${positionName} 지원이 취소되었어요. 다른 포지션을 찾아볼까요?`,
        buttonText: '포지션 보러가기',
      };
    case MatchingNotificationTypeCode.JOB_APPLICATION_DEADLINE_REMINDER_D_3: {
      const notificationText = companyName && positionName ? `${positionName}의 ${companyName}` : `${userName}님,`;
      return {
        text: `${notificationText} 입사 지원 마감까지 3일 남았습니다.\n서둘러 지원해주세요!`,
        buttonText: '지원서 작성하기',
      };
    }
    case MatchingNotificationTypeCode.JOB_APPLICATION_DEADLINE_REMINDER_D_DAY: {
      const notificationText = companyName && positionName ? `${positionName}의 ${companyName}` : `${userName}님,`;
      return {
        text: `${notificationText} 입사 지원 마감일입니다.\n서둘러 지원해주세요!`,
        buttonText: '지원서 작성하기',
      };
    }
    case MatchingNotificationTypeCode.JOB_APPLICATION_SUBMITTED:
      return {
        text: `${companyName}의 ${positionName} 지원이 완료되었어요. 지원자님의 행운을 빌어요!`,
        buttonText: '지원현황 확인하기',
      };
    case MatchingNotificationTypeCode.MATCHING_TALK_LINK:
      return {
        text: (
          <>
            {companyName}의 {positionName} 포지션 <em className={cx('emphasis')}>메세지가 연동되었어요.</em> 지금 바로
            확인해보세요.
          </>
        ),
        buttonText: '확인하기',
      };
    case MatchingNotificationTypeCode.PROFILE_INPUT_REMINDER:
      return {
        text: '프로필을 작성하면 맞춤형 기업 추천, 빠른 입사지원 혜택을 누릴 수 있어요.',
        buttonText: '프로필 작성하러 가기',
      };
    case MatchingNotificationTypeCode.PROFILE_UPDATE_BY_RESUME:
      return {
        text: (
          <>
            <em className={cx('emphasis')}>내 프로필</em> 정보가 <em className={cx('emphasis')}>업데이트</em> 되었어요!
            <br />
            상세 내용은 메일을 통해 확인해 주세요.
          </>
        ),
        buttonText: '내 프로필 바로가기',
      };
    case MatchingNotificationTypeCode.REGISTER_USER:
      return {
        text: `${userName}님 만나서 반가워요!\n잡다에서는 경력이 없어도 기업에게 면접제안을 받을 수 있어요.`,
        buttonText: '면접제안 받기',
      };
    case MatchingNotificationTypeCode.SIMPLE_MESSAGE:
      return {
        text: (
          <>
            {companyName}의 {positionName}에서 <em className={cx('emphasis')}>메세지를 보냈어요.</em> 지금 자세한 내용을
            확인해보세요.
          </>
        ),
        buttonText: '확인하기',
      };
    case MatchingNotificationTypeCode.STATUS_CHANGE:
      return {
        text: (
          <>
            {companyName}의 {positionName}에서 <em className={cx('emphasis')}>최종 결과</em>가 발표되었어요. 지금 자세한
            내용을 확인해보세요.
          </>
        ),
        buttonText: '확인하기',
      };

    case MatchingNotificationTypeCode.INTERVIEW_OFFER:
    case MatchingNotificationTypeCode.MATCHING_OFFER:
      return {
        text: (
          <>
            {companyName}의 {positionName}에서 <em className={cx('emphasis')}>면접 제안</em>이 왔어요!
            <br />
            어떤 팀에서 제안이 왔는지 확인해 보세요.
          </>
        ),
        buttonText: '확인하기',
      };
    case MatchingNotificationTypeCode.MATCH_OFFER:
      return {
        text: (
          <>
            {companyName}에서 <em className={cx('emphasis')}>입사지원 제안</em>이 도착했어요. 어떤 제안이 왔는지
            확인해보세요.
          </>
        ),
        buttonText: '확인하기',
      };
    case MatchingNotificationTypeCode.RECOMMEND:
      return {
        text: `${companyName}의 ${positionName} 포지션에 추천됐어요. 포지션이 마음에 든다면, 관심 표현하고 제안받을 확률 올려보세요.`,
        buttonText: '확인하기',
      };
    case MatchingNotificationTypeCode.PROFILE_OPEN:
      return {
        text: (
          <>
            {companyName}에서 {positionName} 포지션의 인재를 채용하기 위해{' '}
            <em className={cx('emphasis')}>내 프로필을 열람</em> 했어요.
          </>
        ),
        buttonText: '확인하기',
      };
    case MatchingNotificationTypeCode.FILE_REQUEST:
      return {
        text: (
          <>
            {companyName}의 {positionName}에서 <em className={cx('emphasis')}>추가 서류를 요청</em>했어요.
            <br />
            지금 자세한 내용을 확인해보세요.
          </>
        ),
        buttonText: '확인하기',
      };
    case MatchingNotificationTypeCode.FILE_REQUEST_DEADLINE_REMINDER_D_DAY:
      return {
        text: (
          <>
            {companyName}의 {positionName}
            <em className={cx('emphasis')}>추가 서류 제출 마감</em>
            일입니다. 서둘러 제출해 주세요!
          </>
        ),
        buttonText: '추가 서류 제출하기 >',
      };
    default:
      return null;
  }
};

const getNextPath = ({ type, applySn, positionSn }: MatchNotificationVO) => {
  switch (type) {
    case MatchingNotificationTypeCode.ACC_ANALYSIS_COMPLETE:
      return RouteUrl.ACCA_RESULTS;
    case MatchingNotificationTypeCode.PACC_ANALYSIS_COMPLETE:
      return RouteUrl.PHS_PACC_RESULTS;
    case MatchingNotificationTypeCode.PST_ANALYSIS_COMPLETE:
      return RouteUrl.PHS_PST_RESULTS;
    case MatchingNotificationTypeCode.ACC_COMPLETE_REMINDER:
      return RouteUrl.ACCA_TEST_LIST;
    case MatchingNotificationTypeCode.ACC_REMINDER:
    case MatchingNotificationTypeCode.ACC_RECOMMEND:
      return RouteUrl.ACCA_TEST_GATE;
    case MatchingNotificationTypeCode.PACC_COMPLETE_REMINDER:
    case MatchingNotificationTypeCode.PST_COMPLETE_REMINDER:
    case MatchingNotificationTypeCode.PACC_REMINDER:
    case MatchingNotificationTypeCode.PST_REMINDER:
      return RouteUrl.PHS_TEST_GATE;

    case MatchingNotificationTypeCode.ACC_EXPIRED:
    case MatchingNotificationTypeCode.PACC_EXPIRED:
    case MatchingNotificationTypeCode.PST_EXPIRED:
    case MatchingNotificationTypeCode.ACC_RESULT_SUBMITTED_REMINDER_D_3:
    case MatchingNotificationTypeCode.PACC_RESULT_SUBMITTED_REMINDER_D_3:
    case MatchingNotificationTypeCode.PST_RESULT_SUBMITTED_REMINDER_D_3:
    case MatchingNotificationTypeCode.ACC_RESULT_SUBMITTED_REMINDER_D_DAY:
    case MatchingNotificationTypeCode.PACC_RESULT_SUBMITTED_REMINDER_D_DAY:
    case MatchingNotificationTypeCode.PST_RESULT_SUBMITTED_REMINDER_D_DAY:
    case MatchingNotificationTypeCode.INTERVIEW_OFFER_DEADLINE_REMINDER_D_3:
    case MatchingNotificationTypeCode.INTERVIEW_OFFER_DEADLINE_REMINDER_D_DAY:
      return RouteUrl.MY_PAGE_APPLY + `?tab=${MatchingApplicationStatusEnum.IN_PROGRESS}`;

    case MatchingNotificationTypeCode.JOB_APPLICATION_DEADLINE_REMINDER_D_3:
    case MatchingNotificationTypeCode.JOB_APPLICATION_DEADLINE_REMINDER_D_DAY:
      return RouteUrl.MY_PAGE_APPLYING;

    case MatchingNotificationTypeCode.JOB_APPLICATION_SUBMITTED:
      return RouteUrl.MY_PAGE_APPLY + `?tab=${MatchingApplicationStatusEnum.APPLICATION}`;

    case MatchingNotificationTypeCode.ACC_RESULT_SUBMITTED:
      return applySn ? V2Route.getAccaResultSnPage(applySn) : RouteUrl.ACCA_RESULTS;
    case MatchingNotificationTypeCode.PACC_RESULT_SUBMITTED:
      return applySn ? V2Route.getPhsPaccApplyResultPage(applySn) : RouteUrl.PHS_PACC_RESULTS;
    case MatchingNotificationTypeCode.PST_RESULT_SUBMITTED:
      return applySn ? V2Route.getPhsPstApplyResultPage(applySn) : RouteUrl.PHS_PST_RESULTS;
    case MatchingNotificationTypeCode.ALIMTALK_MESSAGE:
    case MatchingNotificationTypeCode.DETAILED_BROWSE:
      return positionSn ? V2Route.getPositionJDPage(positionSn) : RouteUrl.POSITION;
    case MatchingNotificationTypeCode.DEVELOPER_DELETED:
    case MatchingNotificationTypeCode.GENERAL_DELETED:
    case MatchingNotificationTypeCode.REGISTER_USER:
      return RouteUrl.MATCH_INTRODUCE;
    case MatchingNotificationTypeCode.INTERVIEW_OFFER_RESPONDED:
      return RouteUrl.PASS_INTERVIEW;
    case MatchingNotificationTypeCode.JOB_APPLICATION_CANCELED:
      return RouteUrl.POSITION;
    case MatchingNotificationTypeCode.PROFILE_INPUT_REMINDER:
    case MatchingNotificationTypeCode.PROFILE_UPDATE_BY_RESUME:
      return RouteUrl.PROFILE;
    case MatchingNotificationTypeCode.DEVELOPER_CREATED:
    case MatchingNotificationTypeCode.GENERAL_CREATED:
      return RouteUrl.MATCH_APPLY;
    case MatchingNotificationTypeCode.RECOMMEND:
      return `${RouteUrl.MATCH_APPLY}?tab=RECOMMEND`;
    case MatchingNotificationTypeCode.PROFILE_OPEN:
      return `${RouteUrl.MATCH_APPLY}?tab=PROFILE_OPEN`;

    // null일 경우 메세지 모달 Open
    case MatchingNotificationTypeCode.ACC_COMPLETE:
    case MatchingNotificationTypeCode.PACC_COMPLETE:
    case MatchingNotificationTypeCode.PST_COMPLETE:
    case MatchingNotificationTypeCode.ACC_REQUEST:
    case MatchingNotificationTypeCode.PHS_REQUEST:
    case MatchingNotificationTypeCode.INSIGHT_STATUS_CHANGE:
    case MatchingNotificationTypeCode.MATCHING_TALK_LINK:
    case MatchingNotificationTypeCode.SIMPLE_MESSAGE:
    case MatchingNotificationTypeCode.STATUS_CHANGE:
    case MatchingNotificationTypeCode.INTERVIEW_OFFER:
    case MatchingNotificationTypeCode.MATCHING_OFFER:
    case MatchingNotificationTypeCode.MATCH_OFFER:
    case MatchingNotificationTypeCode.FILE_REQUEST:
    case MatchingNotificationTypeCode.FILE_REQUEST_DEADLINE_REMINDER_D_DAY:

    default:
      return null;
  }
};
