import { useAccordion } from '@components/_atoms/Accordion';
import useTransitionStatus from '@utils/hooks/useTransitionStatus';

interface Props {
  children: React.ReactNode;
  contentRef?: React.MutableRefObject<HTMLDivElement>;
  duration?: number;
  className?: string;
  id?: string;
}

const Content = ({ children, contentRef, duration, className, id }: Props) => {
  const { accordions } = useAccordion();
  const visible = accordions[id ?? '']?.visible;
  const status = useTransitionStatus(visible, duration);

  if (duration && duration > 0) {
    const animateStyle: React.CSSProperties = {
      visibility: status === 'exited' ? 'hidden' : 'visible',
      pointerEvents: status === 'exited' ? 'none' : 'auto',
    };
    return (
      <div ref={contentRef} style={animateStyle} className={className}>
        {children}
      </div>
    );
  }

  return (
    visible && (
      <div ref={contentRef} className={className}>
        {children}
      </div>
    )
  );
};

export default Content;
