import React, { ReactNode } from 'react';
import Html from '@components/common/Html';
import FileList from '../../common/FileList';
import { AttachFileDto } from '@domain/rs/match/message/MatchingMessageListRs';
import classnames from 'classnames/bind';
import styles from './messageContents.module.scss';
const cx = classnames.bind(styles);

interface MessageContentsProps {
  title: string;
  message: string;
  decisionDueDateTimeValue: string | null;
  decisionMatchingDueDateTimeValue: string | null;
  fixedGuideMessage?: ReactNode | string;
  isDangerousHtml?: boolean;
  isMatching?: boolean;
  attachFiles?: AttachFileDto[];
  positionSn?: number | null;
}

const MessageContents = ({
  title,
  message,
  decisionDueDateTimeValue,
  decisionMatchingDueDateTimeValue,
  fixedGuideMessage,
  isDangerousHtml,
  isMatching,
  attachFiles,
  positionSn,
}: MessageContentsProps) => {
  return (
    <>
      <strong className={cx('title')}>{title}</strong>
      {isDangerousHtml ? (
        <Html className={cx('message')} html={message} />
      ) : (
        <div className={cx('message')}>{message}</div>
      )}
      {isMatching
        ? decisionMatchingDueDateTimeValue && (
            <div className={cx('decisionDueDate', 'matching')}>
              응답 기한 : <span className={cx('decisionDueDateTime')}>{decisionMatchingDueDateTimeValue}</span>
              <p className={cx('notice')}>*정해진 기한까지 응답하지 않을 경우 수락이 불가능합니다.</p>
              {fixedGuideMessage}
            </div>
          )
        : decisionDueDateTimeValue && (
            <div className={cx('decisionDueDate')}>
              제출 유효기간 : <span className={cx('decisionDueDateTime')}>{decisionDueDateTimeValue}</span>
              <p className={cx('notice')}>*정해진 유효기간까지 제출하지 않을 경우 제출이 불가능합니다.</p>
              {fixedGuideMessage}
            </div>
          )}

      <FileList positionSn={positionSn} attachFiles={attachFiles} />
    </>
  );
};

export default MessageContents;
