import { useRouter } from 'next/router';
import Link from 'next/link';
import Icon from '@components/common/assets';
import { useUserInfo } from '@repository/auth/useUserInfo';
import { useMatchReportCompanyPositions } from '@repository/match/report/useMatchReport';
import { CompanyTabEnum } from '../..';
import { CompanyListVO, CompanySearchVO } from '@domain/vo/company/sn/CompanyListVO';
import Colors from '@domain/constant/colors';
import { V2Route } from '@utils/routeUtil';
import classnames from 'classnames/bind';
import styles from './searchList.module.scss';
const cx = classnames.bind(styles);

interface SearchItemProps {
  company?: CompanySearchVO;
}

const SearchItem = ({ company }: SearchItemProps) => {
  const router = useRouter();
  const { sn } = router.query;
  const selectedSn = Number(sn);
  const { data: userInfo } = useUserInfo();
  const isLogin = !!userInfo?.id;
  const { data: matchCompanyPositions } = useMatchReportCompanyPositions(isLogin && !!sn, Number(sn));
  //매칭 기업인지 확인
  const isMatchingCompany = matchCompanyPositions && matchCompanyPositions?.positions.length > 0;

  return (
    <Link
      className={cx('itemWrapper', { selected: selectedSn === company?.sn })}
      href={{
        pathname: V2Route.getCompanyChannelPage(company?.sn ?? 0),
        query: {
          tab: isMatchingCompany ? CompanyTabEnum.REPORT : CompanyTabEnum.INFO,
        },
      }}
    >
      {company?.logoImageUrl ? (
        <span className={cx('logo')} style={{ backgroundImage: `url(${company.logoImageUrl})` }} />
      ) : (
        <div className={cx('default')}>
          <Icon name="logo" width={32} height={7} fill={Colors.C_COOL_GRAY_50} />
        </div>
      )}
      <span className={cx('companyName')}>{company?.name}</span>
    </Link>
  );
};

interface SearchListProps {
  companyList?: CompanyListVO;
}

const SearchList = ({ companyList }: SearchListProps) => {
  if (!companyList) return null;

  const searchItemEls = companyList?.companies.map((company) => {
    return <SearchItem key={company.sn} company={company} />;
  });

  return (
    <div className={cx('wrapper')}>
      <div className={cx('searchResult')}>
        <strong className={cx('label')}>검색 결과</strong>
        <span className={cx('companyCount')}>{companyList?.companies.length}</span>
      </div>
      <div className={cx('searchItemArea')}>
        <div className={cx('scrollArea')}>{searchItemEls}</div>
      </div>
    </div>
  );
};

export default SearchList;
