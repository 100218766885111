import React, { useState, FC } from 'react';
import Icon from '@components/common/assets';
import Accordion from '@components/_atoms/Accordion';
import {
  MatchingReportCompetencyAnalysisDto,
  MatchingReportSubFactorDto,
} from '@domain/rs/match/report/MatchingReportRs';
import classnames from 'classnames/bind';
import styles from './strengthList.module.scss';
const cx = classnames.bind(styles);

interface ResultListProps {
  data: MatchingReportSubFactorDto;
  onClickFaq: () => void;
}

const ResultList: FC<ResultListProps> = ({ data, onClickFaq }) => {
  const questionListEl = data.questions.map((el, index) => {
    return (
      <div key={index} className={cx('moreList')}>
        <span className={cx('question')}>{el.question}</span>
        <span className={cx('answer')}>{el.comment}</span>
      </div>
    );
  });

  return (
    <Accordion>
      {(accordion) => {
        return (
          <li className={cx('resultListInner')} onClick={onClickFaq}>
            <div className={cx('listItem')}>
              <Accordion.Header id="key">
                {/* 강점 클릭 영역 */}
                <div className={cx('listTitle')}>
                  <div className={cx('keywordArea')}>
                    <Icon
                      name={accordion['key']?.visible ? 'upSLightLightFilled' : 'downSLightFilled'}
                      width={24}
                      height={24}
                    />
                    <span className={cx('keyword')}>{data.keyword}</span>
                  </div>
                  <span className={cx('desc')}>{data.comment}</span>
                </div>
              </Accordion.Header>
              <Accordion.Animation id="key">
                <Accordion.Content className={cx('moreViewWrap')}>
                  <div className={cx('moreView')}>{questionListEl}</div>
                </Accordion.Content>
              </Accordion.Animation>
            </div>
          </li>
        );
      }}
    </Accordion>
  );
};

interface StrengthListProps {
  data: MatchingReportCompetencyAnalysisDto;
}

const StrengthList: FC<StrengthListProps> = ({ data }) => {
  //강점 예상 면접질문 탭 열림 여부
  const [questionTabViewStatus, setQuestionTabViewStatus] = useState(
    Array.from({ length: data.strengths.length }, () => false),
  );
  const isTooltipVisible = questionTabViewStatus.every((el) => !el);
  const onClickFaq = (order: number) => {
    const newQuestionTabViewStatus = [...questionTabViewStatus];
    newQuestionTabViewStatus[order] = !newQuestionTabViewStatus[order];
    setQuestionTabViewStatus(newQuestionTabViewStatus);
  };

  const faqListEl = data.strengths.map((el, i) => {
    return <ResultList key={i} data={el} onClickFaq={() => onClickFaq(i)} />;
  });

  return (
    <>
      <ul className={cx('faqList')}>{faqListEl}</ul>
      {/* [D] 툴팁 3개 항목 중 한 번도 클릭하지 않았을 경우에만 제공*/}
      <div className={cx('tooltip', { hidden: !isTooltipVisible })}>
        강점에 대한 예상 면접 질문이 궁금하다면 눌러보세요.
      </div>
    </>
  );
};

export default StrengthList;
