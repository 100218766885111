import React, { FC, useState } from 'react';
import Link from 'next/link';
import Icon from '@common/assets';
import Image from '@components/common/image';
import { PositionBookmarkListItemVO } from '@domain/vo/common/PositionBookmarkListItemVO';
import EllipsisText from '@components/common/ellipsisText';
import Text from '@components/common/text';
import LoginModal from '@components/loginModal';
import { useUserInfo } from '@repository/auth/useUserInfo';
import { useAddBookmark, useDeleteBookmark } from '@repository/position/usePosition';
import { LogTypeEnum } from '@/src/logging/LogType';
import Colors from '@domain/constant/colors';
import { FontStyle } from '@domain/constant/fontStyle';

import { dummyImgSrc } from '@domain/constant/img/positionDummyImgSrc';
import { V2Route } from '@utils/routeUtil';
import classnames from 'classnames/bind';

import styles from './positionBookmarkListItem.module.scss';
const cx = classnames.bind(styles);

interface PositionBookmarkListItemProps {
  index: number;
  data: PositionBookmarkListItemVO;
  isRankVisible?: boolean;
  isBookmarkVisible?: boolean;
  // 다크모드 대응(ex. 개발자검사 센터)
  theme?: 'dark' | '';
  dragging?: () => boolean;
  clickAfterBookmarkBtn?: () => void;
  isJD?: boolean;
}

const PositionBookmarkListItem: FC<PositionBookmarkListItemProps> = ({
  index,
  data,
  isRankVisible = false,
  isBookmarkVisible = true,
  theme,
  dragging,
  clickAfterBookmarkBtn,
  isJD = false,
}) => {
  const { sn, teamImageUrl, title, companyName, locationName, bookmarkYn, matchingYn, recruitment } = data;
  const [loginModalVisible, setLoginModalVisible] = useState(false);
  const [bookmark, setBookmark] = useState(bookmarkYn);
  const { mutate: deleteBookmark } = useDeleteBookmark(() => {
    clickAfterBookmarkBtn?.();
    setBookmark(false);
  });

  const { mutate: addBookmark } = useAddBookmark(() => {
    clickAfterBookmarkBtn?.();
    setBookmark(true);
  });

  const { data: userInfo } = useUserInfo();
  const isLogin = !!userInfo?.id;

  const handleBookMark = async () => {
    if (!isLogin) {
      setLoginModalVisible(true);
      return;
    }

    if (bookmark) deleteBookmark(sn);
    else addBookmark(sn);
  };

  return (
    <div className={cx('listItem', theme, { jdList: isJD })}>
      <Link
        className={cx('link')}
        href={V2Route.getPositionJDPage(sn)}
        id={LogTypeEnum.log_bookmark_position}
        onClick={(e) => {
          if (dragging?.()) {
            e.preventDefault();
          }
        }}
      >
        {/* 이미지 없는 경우 'default' 클래스 추가 */}
        <div
          className={cx(
            'imgArea',
            { default: !teamImageUrl },
            { rank: index < 3 && isRankVisible },
            { rank1: index === 0 && isRankVisible },
            { rank2: index === 1 && isRankVisible },
            { rank3: index === 2 && isRankVisible },
          )}
        >
          <div className={cx('imgWrap', { jdImage: isJD })}>
            <Image
              src={teamImageUrl}
              width={isJD ? 294 : 282}
              height={188}
              alt={`${companyName} 회사`}
              pointer
              ErrorImage={
                <Image src={dummyImgSrc} width={isJD ? 294 : 282} height={188} alt={`${companyName} 회사`} pointer />
              }
            />
          </div>
          {index < 3 && isRankVisible && (
            <span className={cx('rankArea', { rank1: index === 0 }, { rank2: index === 1 }, { rank3: index === 2 })}>
              {index + 1}등
            </span>
          )}
        </div>
        <div className={cx('positionInfoArea')}>
          <EllipsisText text={title} type={'height'} offset={40} customClassName={cx('positionTitle')} />
        </div>
        <Text fontStyle={FontStyle.B3_M} color={Colors.C_COOL_GRAY_70} styles={{ marginTop: 8 }}>
          {companyName}
        </Text>
        <div className={cx('companyInfoArea')}>
          <Text fontStyle={FontStyle.B3_M} color={Colors.C_COOL_GRAY_60}>
            {locationName}
          </Text>
          <Text fontStyle={FontStyle.B3_M} color={Colors.C_COOL_GRAY_60} className={cx('recruitment')}>
            {recruitment}
          </Text>
        </div>
      </Link>

      {matchingYn && <span className={cx('matchBadge')}>매칭받음</span>}
      {/* 북마크 영역 */}
      {isBookmarkVisible && (
        <a className={cx('btnBookmark')} role="button" onClick={handleBookMark}>
          {/* [D] 북마크 된 경우 'IconBookmarkLightFilled' 아이콘 적용 */}
          {bookmark ? (
            <Icon name="bookmarkLightFilled" width={32} height={32} fill={Colors.C_WHITE} />
          ) : (
            <Icon name="bookmarkLight" width={32} height={32} fill={Colors.C_WHITE} />
          )}
          <span className={cx('hidden')}>북마크</span>
        </a>
      )}

      {/* 로그인하지 않은 사용자가 북마크를 눌렀을 때 로그인모달 사용 */}
      <LoginModal
        subTitle="채용정보 · 역량검사 · 매칭까지"
        title="취업기회 지금 바로 잡다!"
        visible={loginModalVisible}
        onClose={() => setLoginModalVisible(false)}
      />
    </div>
  );
};

export default React.memo(PositionBookmarkListItem);
