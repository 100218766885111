import React, { useState } from 'react';
import Icon from '@common/assets';
import CompanyUpdateModal from './companyUpdateModal';
import Colors from '@domain/constant/colors';
import classnames from 'classnames/bind';
import styles from './companyUpdateBanner.module.scss';
const cx = classnames.bind(styles);

const CompanyUpdateBanner = () => {
  const [modalVisible, setModalVisible] = useState(false);

  return (
    <div className={cx('snInner')}>
      <div className={cx('companyUpdateBannerInner')}>
        <div className={cx('companyUpdateBannerWrap')}>
          <Icon name="logo" width={80} height={18} />
          <p className={cx('desc')}>
            <Icon name="informationLight" width={20} height={20} fill={Colors.C_COOL_GRAY_60} />
            JOBDA에서는 NICE평가정보, 국민연금 가입 내역, 금융감독원 공시 정보 및 기업 회원이 입력한 정보를
            <br />
            조합하여 제공합니다. 위 정보는 무단으로 수집 및 배포할 수 없습니다.
          </p>
        </div>

        <a className={cx('btn')} onClick={() => setModalVisible(true)} role="button">
          기업 정보 수정 요청
          <Icon name="arrowRightLight" width={24} height={24} />
        </a>
      </div>
      <CompanyUpdateModal visible={modalVisible} onClose={() => setModalVisible(false)} />
    </div>
  );
};

export default CompanyUpdateBanner;
