import { AxiosResponse } from 'axios';
import { axios } from '@repository/RequestClient';
import { PstResultRs } from '@domain/rs/phs/pst/result/PstResultRs';
import { PstProgressCompleteRs } from '@domain/rs/phs/pst/result/PstProgressCompleteRs';

const phsPstResultPrefix = '/v2/phs/pst';

export default interface PhsPstRepo {
  // 개발자 능력 검사 결과
  fetchPHSPSTResults(sn: number): Promise<AxiosResponse<PstResultRs>>;

  // 이전 응시완료 결과 목록
  fetchPHSPSTResultsProgressComplete(): Promise<AxiosResponse<PstProgressCompleteRs>>;

  // 매칭에 사용될 결과표 선택
  fetchPHSPSTResultsSelectMatching(applySn: number): Promise<AxiosResponse>;
}

export class RemotePhsPstRepo implements PhsPstRepo {
  fetchPHSPSTResults(sn: number): Promise<AxiosResponse<PstResultRs>> {
    return axios.get(`${phsPstResultPrefix}/results`, { params: { sn } });
  }

  fetchPHSPSTResultsProgressComplete(): Promise<AxiosResponse<PstProgressCompleteRs>> {
    return axios.get(`${phsPstResultPrefix}/results/progress-complete`);
  }

  fetchPHSPSTResultsSelectMatching(applySn: number): Promise<AxiosResponse> {
    return axios.put(`/phs/pst/results/select-matching`, { applySn });
  }
}
