import React, { FC } from 'react';
import Icon from '@common/assets';
import { useUserInfo } from '@repository/auth/useUserInfo';
import CurationFilterListVO from '@domain/vo/position/list/CurationFilterListVO';

import classnames from 'classnames/bind';
import styles from './curationTitle.module.scss';
const cx = classnames.bind(styles);

interface ICurationTitleProps {
  vo: CurationFilterListVO;
}

const CurationTitle: FC<ICurationTitleProps> = ({ vo }) => {
  const { definition, description, iconAttachFile, type } = vo;
  const { data: userInfo } = useUserInfo();

  const curationTitleEl =
    type === 'RECOMMEND' ? (
      <>
        <strong className={cx('title')}>
          <div className={cx('icon')}>
            <Icon name="thumbsUpLight" width={32} height={32} />
          </div>
          {userInfo?.name}님께 {definition}
        </strong>

        <p className={cx('desc')}>
          {userInfo?.name}님이 {description} 추천포지션에 지원하여 합격 확률을 높여보세요!
        </p>
      </>
    ) : (
      <>
        <strong className={cx('title')}>
          <div className={cx('icon')} style={{ backgroundImage: `url(${iconAttachFile?.fileUrl})` }} />
          {definition}
        </strong>

        <p className={cx('desc')}>{description}</p>
      </>
    );

  return <div className={cx('curationTitleArea')}>{curationTitleEl}</div>;
};

export default CurationTitle;
