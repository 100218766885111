const isServer = typeof window === 'undefined';

export default class SessionStorageUtil {
  static setItem = <T>(key: string, value: T) => {
    if (isServer) return;
    const item = JSON.stringify(value);
    return sessionStorage.setItem(encodeURIComponent(key), item);
  };

  static getItem = <T = any>(key: string): T | null => {
    if (isServer) return null;
    try {
      const item = sessionStorage.getItem(encodeURIComponent(key));
      return item ? (JSON.parse(item) as T) : null;
    } catch (e) {
      return null;
    }
  };

  static removeItem = (key: string) => {
    if (isServer) return;
    return sessionStorage.removeItem(encodeURIComponent(key));
  };
}
