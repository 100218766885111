import React, { FC } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import Icon from '@components/common/assets';
import { useCompaniesHeader } from '@repository/companies/useCompanies';
import { useChatCompanyByCompanySn } from '@repository/chat/useChat';
import CompanyDescList from './companyDescList';
import { isEmpty } from './../../../../../utils/common/index';
import { CompanyTabEnum } from '../..';
import MatchingReportCompanyRs from '@domain/rs/match/report/MatchingReportCompanyRs';
import { V2Route } from '@utils/routeUtil';
import classnames from 'classnames/bind';
import styles from './companyDesc.module.scss';
const cx = classnames.bind(styles);

interface CompanyDescProps {
  data?: MatchingReportCompanyRs;
}

const CompanyDesc: FC<CompanyDescProps> = ({ data }) => {
  const router = useRouter();
  const { tab, sn } = router.query;
  const { data: headerData } = useCompaniesHeader(Number(sn));
  const { data: chatData } = useChatCompanyByCompanySn(Number(sn));

  const openChat = () => {
    router.replace(
      {
        pathname: window.location.pathname,
        query: { tab, channelId: chatData?.channelId },
      },
      undefined,
      { shallow: true },
    );
  };

  const isNotData =
    !data?.idealTalents?.some(({ text }) => !isEmpty(text)) &&
    !data?.managementPhilosophies?.some(({ text }) => !isEmpty(text));

  return (
    <>
      <div className={cx('contentWrap', { hidden: isNotData })}>
        {!!data?.idealTalents?.length && (
          <CompanyDescList
            title="인재상"
            first={data?.idealTalents[0]?.text}
            second={data?.idealTalents[1]?.text}
            third={data?.idealTalents[2]?.text}
          />
        )}
        {!!data?.managementPhilosophies?.length && (
          <CompanyDescList
            title="경영이념"
            first={data?.managementPhilosophies[0]?.text}
            second={data?.managementPhilosophies[1]?.text}
            third={data?.managementPhilosophies[2]?.text}
          />
        )}
      </div>

      {/* 기업 더 알기 버튼 */}
      <div className={cx('btnArea')}>
        <Link
          className={cx('btnLink')}
          href={{
            pathname: V2Route.getCompanyChannelPage(Number(sn)),
            query: { ...router.query, tab: CompanyTabEnum.INFO },
          }}
        >
          <div className={cx('desc')}>
            <span>
              {headerData?.companyHeaderDto.name}에 대해
              <br />더 자세히 알고 싶으신가요?
            </span>
          </div>
          <div className={cx('title')}>
            <span>기업 정보 바로가기</span>
            <Icon name="linkArrow" width={24} height={24} />
          </div>
        </Link>
        <a className={cx('btnLink')} role="button" onClick={openChat}>
          <div className={cx('desc')}>
            <span>
              다른 지원자와
              <br />
              의견을 나눠보세요
            </span>
          </div>
          <div className={cx('title')}>
            <span>기업 오픈 채팅방 바로가기</span>
            <Icon name="linkArrow" width={24} height={24} />
          </div>
        </a>
      </div>
    </>
  );
};

export default CompanyDesc;
