import React, { FC } from 'react';
import Modal from '@common/modal';

import classnames from 'classnames/bind';
import styles from './errorModal.module.scss';
const cx = classnames.bind(styles);

interface IErrorModal {
  isVisible: boolean;
  onConfirm: () => void;
}

const ErrorModal: FC<IErrorModal> = ({ isVisible, onConfirm }) => {
  return (
    <Modal visible={isVisible} dimmed={true} isUseScrollHidden={false}>
      <div className={cx('layer')}>
        <strong className={cx('title')}>현재 서비스가 원활하지 않습니다.</strong>

        <p className={cx('desc')}>
          일시적인 장애이거나 네트워크 문제일 수 있습니다.
          <br />
          잠시 후 다시 시도해주세요.
        </p>

        <div className={cx('btnArea')}>
          <a className={cx('btn')} role="button" onClick={onConfirm}>
            확인
          </a>
        </div>
      </div>
    </Modal>
  );
};

export default ErrorModal;
