import { FC } from 'react';
import { useRouter } from 'next/router';
import {
  useMatchReportCompanyDetail,
  useMatchReportCompanyPositions,
  useMatchReport,
} from '@repository/match/report/useMatchReport';
import { useUserInfo } from '@repository/auth/useUserInfo';
import MatchingMessage from './matchingMessage';
import MatchingPositionList from './matchingPositionList';
import Graph from './graph';
import CompareItem from './compareItem';
import ResultStrength from './resultStrength';
import SpecialMessage from './specialMessage';
import CompanyInfo from './companyInfo';
import CompanyDesc from './companyDesc';
import MapLocation from './mapLocation';
import ReportFooter from './reportFooter';
import Vision from './companyInfo/vision';
import classnames from 'classnames/bind';
import styles from './reportTab.module.scss';
const cx = classnames.bind(styles);

interface MatchingReportTabProps {
  selectedMatchingPositionSn: number | null;
  setSelectedMatchingPositionSn: (sn: number | null) => void;
}

const MatchingReportTab: FC<MatchingReportTabProps> = ({
  selectedMatchingPositionSn,
  setSelectedMatchingPositionSn,
}) => {
  const router = useRouter();
  const { sn } = router.query;
  const { data: userInfo } = useUserInfo();
  const isLogin = !!userInfo?.id;
  const { data: matchingPositions } = useMatchReportCompanyPositions(isLogin && !!sn, Number(sn));
  const { data: reportData } = useMatchReport(selectedMatchingPositionSn as number, !!selectedMatchingPositionSn);
  const { data: companyDetailData } = useMatchReportCompanyDetail(Number(sn));

  if (!selectedMatchingPositionSn) return null;

  return (
    <>
      <div className={cx('matchingSuggest')}>
        {/* 매칭 제안 포지션 목록 */}
        <MatchingPositionList
          data={matchingPositions}
          selectedPositionSn={selectedMatchingPositionSn}
          setSelectedPositionSn={setSelectedMatchingPositionSn}
        />

        {/* 매칭 제안 메세지 */}
        <MatchingMessage data={reportData?.offerMessage} />
      </div>

      {/* 매칭 적합도와 4사분면 그래프 */}
      <Graph data={reportData?.score} />

      {/* 주요항목 비교 */}
      <CompareItem data={reportData?.profileFit} selectedPositionSn={selectedMatchingPositionSn} />

      {/* 역량분석 결과 */}
      <ResultStrength data={reportData?.competencyAnalysis} />

      {/* 특별 메세지 */}
      <SpecialMessage data={companyDetailData?.specialMessage} selectedPositionSn={selectedMatchingPositionSn} />

      {/* 기업 정보 */}
      <CompanyInfo />

      {/* 기업 다짐 */}
      <Vision data={companyDetailData?.vision} />

      {/* 기업 인재상과 경영이념 */}
      <CompanyDesc data={companyDetailData} />

      {/* 지도, 소요시간 확인하기 */}
      <MapLocation data={reportData?.addressInfo} />

      {/* 매칭 리포터 Footer */}
      <ReportFooter />
    </>
  );
};

export default MatchingReportTab;
