import React, { FC, useState } from 'react';
import Icon from '@common/assets';
import MatchingOfferPositionListVO, { MatchingOfferPositionVO } from '@domain/vo/match/report/MatchingOfferPositionVO';
import classnames from 'classnames/bind';
import styles from './matchingPositionList.module.scss';
const cx = classnames.bind(styles);

interface SelectedPositionProps {
  data?: MatchingOfferPositionVO;
  isList: boolean;
  additionListVisible: boolean;
  onClick?: () => void;
}

const SelectedPosition: FC<SelectedPositionProps> = ({ data, isList, additionListVisible, onClick }) => {
  if (!data) return null;

  return (
    <a className={cx('listBox', { isList })} onClick={onClick}>
      <div className={cx('listDesc')}>
        <span className={cx('listTitle')}>매칭 제안 목록</span>
        <span className={cx('positionName')}>{data.name}</span>
      </div>
      <div className={cx('listState')}>
        <span className={cx('nowState')}>{data.responseTypeText}</span>
        <span className={cx('date', { today: data.isToday })}>{data.decisionDueDateTimeText}</span>
        {!isList ? (
          <div className={cx('oneList')} />
        ) : additionListVisible ? (
          <Icon name="arrowTopLight" width={40} height={40} />
        ) : (
          <Icon name="arrowBottomLight" width={40} height={40} />
        )}
      </div>
    </a>
  );
};

interface NotSelectedPositionProps {
  data: MatchingOfferPositionVO;
  onClick?: () => void;
}
const NotSelectedPosition: FC<NotSelectedPositionProps> = ({ data, onClick }) => {
  return (
    <a className={cx('listBox', 'openList')} role="button" onClick={onClick}>
      <div className={cx('listDesc')}>
        <span className={cx('positionName')}>{data.name}</span>
      </div>
      <div className={cx('listState')}>
        <span className={cx('nowState')}>{data.responseTypeText}</span>
        <span className={cx('date', { today: data.isToday })}>{data.decisionDueDateTimeText}</span>
      </div>
    </a>
  );
};

interface MatchingPositionListProps {
  data?: MatchingOfferPositionListVO;
  selectedPositionSn: number;
  setSelectedPositionSn: (sn: number) => void;
}

const MatchingPositionList: FC<MatchingPositionListProps> = ({ data, selectedPositionSn, setSelectedPositionSn }) => {
  const [additionListVisible, setAdditionListVisible] = useState(false);
  const selectedPosition = data?.positions.find(({ sn }) => sn === selectedPositionSn);
  const notSelectedPosition = data?.positions.filter(({ sn }) => sn !== selectedPositionSn);

  const selectePosition = (sn: number) => {
    setSelectedPositionSn(sn);
    setAdditionListVisible((prev) => !prev);
  };

  const notSelectedPositionEl = notSelectedPosition?.map((data) => (
    <NotSelectedPosition key={data.sn} data={data} onClick={() => selectePosition(data.sn)} />
  ));

  if (!data) return null;

  const isList = data?.positions.length > 1;

  return (
    <div className={cx('listInner', { openList: additionListVisible })}>
      <SelectedPosition
        data={selectedPosition}
        isList={isList}
        additionListVisible={additionListVisible}
        onClick={() =>
          setAdditionListVisible((prev) => {
            if (isList) return !prev;
            return false;
          })
        }
      />

      {/* 클릭시 나오는 포지션 리스트 */}
      {additionListVisible && <div className={cx('openInner')}>{notSelectedPositionEl}</div>}
    </div>
  );
};

export default MatchingPositionList;
