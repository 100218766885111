import React, { FC } from 'react';
import Link from 'next/link';
import Icon from '@common/assets';
import Tooltip from '@components/common/tooltip';
import { MatchingReportProfileFitVO } from '@domain/vo/match/report/MatchingReportVO';
import { JobTitleFitDto } from '@domain/rs/match/report/MatchingReportRs';
import Colors from '@domain/constant/colors';
import { V2Route } from '@utils/routeUtil';

import classnames from 'classnames/bind';
import styles from './compareItem.module.scss';
const cx = classnames.bind(styles);

interface CompareItemProps {
  data?: MatchingReportProfileFitVO;
  selectedPositionSn: number;
}

// TODO : 디자인 확정되면 직무 부분 수정
const CompareItem: FC<CompareItemProps> = ({ data, selectedPositionSn }) => {
  const notJobTitleFit = data?.jobTitleFit?.fitJobTitleFits.length === 0;

  const getTooltipContent = (jobtitleFits: JobTitleFitDto[]) => {
    const tooltipJobtitle = jobtitleFits.slice(1);
    return tooltipJobtitle.map((item, idx) => (
      <span key={item.code}>{`${item.name}${idx + 1 !== tooltipJobtitle.length ? ',' : ''}`}</span>
    ));
  };

  const getJobContent = () => {
    if (!data?.jobTitleFit) return;

    if (notJobTitleFit) {
      return (
        <Tooltip
          label={
            <div className={cx('compareItem')}>
              <span className={cx('itemTitle')}>직무</span>
              <span className={cx('itemDesc')}>
                <span>{data.jobTitleFit.jobTitleFits[0].name}</span>
                <span className={cx({ hidden: data.jobTitleFit.jobTitleFits.length === 1 })}>
                  +{data.jobTitleFit.jobTitleFits.length - 1}
                </span>
              </span>
            </div>
          }
          theme="gray"
          content={<div className={cx('tooltipContents')}>{getTooltipContent(data.jobTitleFit.jobTitleFits)}</div>}
          placement="bottom-center"
          condition={data.jobTitleFit.jobTitleFits.length > 1}
        />
      );
    }

    return (
      <Tooltip
        label={
          <div className={cx('compareItem')}>
            <span className={cx('itemTitle')}>직무</span>
            <span className={cx('itemDesc')}>
              <span>{data.jobTitleFit.fitJobTitleFits[0].name}</span>
              <span className={cx('fitJobLength', { hidden: data.jobTitleFit.fitJobTitleFits.length === 1 })}>
                +{data.jobTitleFit.fitJobTitleFits.length - 1}
              </span>
              <span className={cx('fit')}>적합</span>
            </span>
          </div>
        }
        theme="gray"
        content={<div className={cx('tooltipContents')}>{getTooltipContent(data.jobTitleFit.fitJobTitleFits)}</div>}
        placement="bottom-center"
        condition={data.jobTitleFit.fitJobTitleFits.length > 1}
      />
    );
  };

  return (
    <div className={cx('compareInner')}>
      <div className={cx('compareTitle')}>
        <span className={cx('title')}>
          내 프로필 <span className={cx('emphasis')}>vs</span> 공고 주요 항목 비교
        </span>
        <Link href={V2Route.getPositionJDPage(selectedPositionSn)} legacyBehavior>
          <a className={cx('btnLink')} role="button">
            공고 자세히 보기
            <Icon name="arrowRightLight" width={24} height={24} fill={Colors.C_BLUE_100} />
          </a>
        </Link>
      </div>
      <div className={cx('compareList')}>
        {getJobContent()}
        <div className={cx('compareItem')}>
          <span className={cx('itemTitle')}>연봉</span>
          <span className={cx('itemDesc')}>
            {data?.salaryFit ? data.salaryFit.salaryText : '추후 협의'}
            <span className={cx('fit', { hidden: !data?.salaryFit?.fitYn })}>적합</span>
          </span>
        </div>
        <div className={cx('compareItem')}>
          <span className={cx('itemTitle')}>경력</span>
          <span className={cx('itemDesc', { fit: data?.careerFit?.fitYn })}>
            {data?.careerFit?.careerText}
            <span className={cx('fit', { hidden: !data?.careerFit?.fitYn })}>적합</span>
          </span>
        </div>
      </div>
    </div>
  );
};

export default CompareItem;
