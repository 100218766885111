import React, { FC } from 'react';
import Modal from '@common/modal';
import classnames from 'classnames/bind';
import styles from './endedModal.module.scss';

const cx = classnames.bind(styles);

interface InvalidDateModalProps {
  visible: boolean;
  onClose: () => void;
}

const EndedModal: FC<InvalidDateModalProps> = ({ visible, onClose }) => {
  return (
    <Modal visible={visible} isUseScrollHidden={false}>
      <div className={cx('layer')}>
        <strong className={cx('title')}>종료된 포지션입니다.</strong>
        <p className={cx('desc')}>포지션이 종료되어 선택이 불가능 합니다.</p>
        <a className={cx('btnConfirm')} role="button" onClick={onClose}>
          확인
        </a>
      </div>
    </Modal>
  );
};

export default EndedModal;
