import React from 'react';
import Link from 'next/link';
import Icon from '@common/assets';
import { useUserInfo } from '@repository/auth/useUserInfo';
import { RouteUrl } from '@utils/routeUtil';
import classnames from 'classnames/bind';
import styles from './reportFooter.module.scss';

const cx = classnames.bind(styles);

const ReportFooter = () => {
  const { data: userInfo } = useUserInfo();

  return (
    <div className={cx('mapInner')}>
      <div className={cx('basicTitle')}>
        {userInfo?.name}님이 취업하는 그 날까지
        <br />
        JOBDA가 응원할게요.
      </div>

      {/* [D] 링크 영역 - 링크 이동 확인 필요 */}
      <div className={cx('linkArea')}>
        <Link className={cx('btnLink')} href={RouteUrl.PREPARATION}>
          <span className={cx('btnName')}>
            <Icon name="workLight" width={32} height={32} />
            취업 준비
          </span>
          <Icon name="arrowRightLight" width={32} height={32} />
        </Link>
        <Link className={cx('btnLink')} href={RouteUrl.MY_PAGE_APPLY}>
          <span className={cx('btnName')}>
            <Icon name="chartLight" width={32} height={32} />
            지원 현황
          </span>
          <Icon name="arrowRightLight" width={32} height={32} />
        </Link>
        <Link className={cx('btnLink')} href={RouteUrl.PROFILE}>
          <span className={cx('btnName')}>
            <Icon name="guideLight" width={32} height={32} />내 프로필
          </span>
          <Icon name="arrowRightLight" width={32} height={32} />
        </Link>
      </div>

      {/* 공지글 */}
      <div className={cx('notice')}>
        <span className={cx('noticeText')}>
          <Icon name="checkLight" width={18} height={18} />
          매칭 수락 기한이 마감되었거나 제안을 거절한 리포트는 확인할 수 없습니다.
        </span>
        <span className={cx('noticeText')}>
          <Icon name="checkLight" width={18} height={18} />
          매칭 리포트는 JOBDA의 자산으로 무단 공유 및 배포할 수 없습니다.
        </span>
      </div>
    </div>
  );
};

export default ReportFooter;
