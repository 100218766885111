import React from 'react';
import Icon from '@components/common/assets';
import { useDownloadApplicationFiles } from '@repository/match';
import { FileAttachmentModalType } from '../fileAttachmentModals';
import Colors from '@domain/constant/colors';
import FileUtil from '@utils/fileUtil';
import classnames from 'classnames/bind';
import styles from './fileTable.module.scss';

const cx = classnames.bind(styles);

interface FileRowProps {
  index: number;
  positionSn: number | null;
  isCompleted: boolean;
  setFilesState: (filesState: File[] | ((filesState: File[]) => File[])) => void;
  setFileAttachmentModalState: (fileAttachmentModalState: FileAttachmentModalType | null) => void;
  file?: File;
  fileName: string;
  fileUid?: string;
}

const FileRow = ({
  index,
  positionSn,
  isCompleted,
  setFilesState,
  setFileAttachmentModalState,
  file,
  fileName,
  fileUid,
}: FileRowProps) => {
  const { mutateAsync: downloadApplicationFiles } = useDownloadApplicationFiles({
    onError: () => setFileAttachmentModalState('SERVICE_ERROR'),
  });
  const handleDelete = () => setFilesState((prev) => prev.filter((file, i) => i !== index));
  const handleDownload = async () => {
    // [D] 서버 파일 다운로드
    if (isCompleted && fileUid && positionSn) {
      await downloadApplicationFiles({ fileName, positionSn, fileUid });
      return;
    }

    // [D] 클라이언트 파일 다운로드
    if (file) FileUtil.fileDownload(fileName, await file.arrayBuffer());
  };

  return (
    <div className={cx('fileItem')}>
      <button className={cx('name', { isCompleted })} type="button" onClick={handleDownload}>
        {fileName}
      </button>
      <span className={cx('delete')}>
        <button disabled={isCompleted} onClick={handleDelete}>
          <Icon name="deleteLight" width={24} height={24} fill={isCompleted ? Colors.C_COOL_GRAY_50 : Colors.C_BLACK} />
          <span className={cx('hidden')}>파일 삭제</span>
        </button>
      </span>
    </div>
  );
};

export default FileRow;
