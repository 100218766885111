import React, { FC, useRef, useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import Icon from '@common/assets';
import SalaryMultiRange from '@components/position/list/salaryMultiRange';
import useClickAway from '@utils/hooks/useClickOutside';

import classnames from 'classnames/bind';
import styles from '../positionFilter.module.scss';
const cx = classnames.bind(styles);

const minFieldName = 'minSalary';
const maxFieldName = 'maxSalary';
const min = 0;
const max = 10000;

const dots = [
  { value: min, label: '0원' },
  { value: 2000, label: '' },
  { value: 4000, label: '' },
  { value: 6000, label: '' },
  { value: 8000, label: '' },
  { value: max, label: '1억원' },
];

const SalaryFilter: FC = () => {
  const [selectOptionAreaVisible, setSelectOptionAreaVisible] = useState(false);
  const salaryFilterRef = useRef<HTMLDivElement>(null);

  const [condition, setCondition] = useState<{
    minValue: number | null;
    maxValue: number | null;
    requirementType: 'REQUIRED' | 'PREFERENTIAL';
  }>({
    minValue: min,
    maxValue: max,
    requirementType: 'PREFERENTIAL',
  });

  const minValue = condition.minValue ?? min;
  const maxValue = condition.maxValue ?? max;

  const { control } = useFormContext();
  const { field: minSalaryField } = useController({
    control,
    name: minFieldName,
    defaultValue: null,
  });

  const { field: maxSalaryField } = useController({
    control,
    name: maxFieldName,
    defaultValue: null,
  });

  const btnName =
    minSalaryField.value || maxSalaryField.value
      ? `${minSalaryField.value.toLocaleString()}만원~${maxSalaryField.value.toLocaleString()}만원`
      : '연봉';

  const onSave = () => {
    minSalaryField.onChange(minValue);
    maxSalaryField.onChange(maxValue);
    setSelectOptionAreaVisible(false);
  };

  const onInit = () => {
    setCondition({
      ...condition,
      minValue: null,
      maxValue: null,
    });

    minSalaryField.onChange(undefined);
    maxSalaryField.onChange(undefined);

    setSelectOptionAreaVisible(false);
  };

  const openCloseSelectOptionArea = () => {
    if (!selectOptionAreaVisible) {
      setCondition({
        ...condition,
        minValue: minSalaryField.value,
        maxValue: maxSalaryField.value,
      });
    }

    setSelectOptionAreaVisible(!selectOptionAreaVisible);
  };

  useClickAway(() => setSelectOptionAreaVisible(false), salaryFilterRef);

  return (
    <div className={cx('selectWrap')} ref={salaryFilterRef}>
      <a
        className={cx(
          'btnSelect',
          { open: selectOptionAreaVisible },
          { on: minSalaryField.value || maxSalaryField.value },
        )}
        onClick={openCloseSelectOptionArea}
        role="button"
      >
        {btnName}
        {selectOptionAreaVisible && <Icon name="arrowTopLight" width={24} height={24} />}
        {!selectOptionAreaVisible && <Icon name="arrowBottomLight" width={24} height={24} />}
      </a>
      {selectOptionAreaVisible && (
        <div className={cx('selectOptionArea', 'salary')}>
          <div className={cx('salaryRangeArea')}>
            <div className={cx('salaryValueArea')}>
              <div className={cx('salaryValueWrap')}>
                최소
                <div className={cx('salaryValue')}>{condition.minValue?.toLocaleString() ?? min}만원</div>
              </div>
              <span className={cx('dash')}>~</span>
              <div className={cx('salaryValueWrap')}>
                최대
                <div className={cx('salaryValue')}>{condition.maxValue?.toLocaleString() ?? max}만원</div>
              </div>
            </div>
            <SalaryMultiRange min={min} max={max} dots={dots} condition={condition} setCondition={setCondition} />
          </div>
          <div className={cx('btnArea')}>
            <a className={cx('btnReset')} role="button" onClick={onInit}>
              선택 초기화
            </a>
            <a className={cx('btnConfirm')} role="button" onClick={onSave}>
              적용
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

export default SalaryFilter;
