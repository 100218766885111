import React, { FC } from 'react';

import PositionListCard from '@components/skeleton/positionListCard';
import classnames from 'classnames/bind';
import styles from './positionList.module.scss';
const cx = classnames.bind(styles);

interface ISkeletonPositionListListProps {
  length: number;
}

const SkeletonPositionListList: FC<ISkeletonPositionListListProps> = ({ length }) => {
  const skeletonListEl = new Array(length).fill(null).map((_, i) => {
    return (
      <li className={cx('listItem')} key={i}>
        <PositionListCard />
      </li>
    );
  });

  return <ul className={cx('positionList')}>{skeletonListEl}</ul>;
};

export default SkeletonPositionListList;
