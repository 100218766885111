import React, { FC, useState } from 'react';
import { AxiosError } from 'axios';
import PositionBookmarkListItem from '@components/common/positionBookmarkListItem';
import EmptyCuration, { EmptyInfoType } from '@components/position/list/emptyCuration';
import { useRecommendPositionList } from '@components/position/list/query/useCuration';
import SkeletonPositionListList from '@components/position/list/positionList/SkeletonPositionListList';

import classnames from 'classnames/bind';
import styles from './positionList.module.scss';
const cx = classnames.bind(styles);

const RecommendPositionList: FC = () => {
  const [errorType, setErrorType] = useState<EmptyInfoType>('EMPTY_POSITION');
  const { data, isFetched, isError, isLoading } = useRecommendPositionList((e: AxiosError) => {
    if (e.response?.data) {
      switch (e.response.data.errorCode) {
        case 'B901':
          setErrorType('ERROR');
          return;
        case 'B905':
          setErrorType('FIND_POSITION');
          return;
        default:
          setErrorType('EMPTY_POSITION');
          return;
      }
    }
  });

  if (isLoading) return <SkeletonPositionListList length={12} />;
  if (isError) return <EmptyCuration type={errorType} />;
  if (!data) return null;
  if (isFetched && data.length < 1) return <EmptyCuration type={'EMPTY_POSITION'} />;

  const positionEls = data.map((item, idx) => {
    return (
      <li className={cx('listItem')} key={`${item.sn}_${item.bookmarkYn}`}>
        <PositionBookmarkListItem index={idx} data={item} />
      </li>
    );
  });

  return <ul className={cx('positionList')}>{positionEls}</ul>;
};

export default RecommendPositionList;
