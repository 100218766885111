import React, { useRef, useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import Icon from '@common/assets';
import {
  RecruitCategoryFilter,
  RecruitCategoryFilterType,
  ORDER_RECRUIT_CATEGORY,
  RecruitCategoryTypeNameMap,
} from '@domain/constant/recruitCategoryType';
import useClickAway from '@utils/hooks/useClickOutside';

import TextFormat from '@utils/text/format';
import classnames from 'classnames/bind';
import styles from '../positionFilter.module.scss';
const cx = classnames.bind(styles);

const fieldName = 'recruitments';

const RecruitmentFilter = () => {
  const [selectOptionAreaVisible, setSelectOptionAreaVisible] = useState(false);
  const careerFilterRef = useRef<HTMLDivElement>(null);

  const { control } = useFormContext();
  const { field } = useController({
    control,
    name: fieldName,
    defaultValue: [],
  });

  const checkedList = field.value;
  const btnName = checkedList.length
    ? TextFormat.formatSummaryListWithCount(
        checkedList.map((item: RecruitCategoryFilterType) => RecruitCategoryTypeNameMap[item]),
      )
    : '경력';

  useClickAway(() => setSelectOptionAreaVisible(false), careerFilterRef);

  const recruitmentEl = Object.entries(RecruitCategoryFilter).map(([type, name]) => {
    const checked = checkedList.includes(type);
    const onCheck = () => {
      if (checked) {
        field.onChange(checkedList.filter((item: RecruitCategoryFilterType) => item !== type));
      } else {
        const tempList = [...checkedList, type];
        //정렬 이유 : 경력 버튼 표시시 제일 상단에 있는 경력 타입 명이 먼저 표시하기 위함
        tempList.sort((a, b) => ORDER_RECRUIT_CATEGORY.indexOf(a) - ORDER_RECRUIT_CATEGORY.indexOf(b));
        field.onChange(tempList);
      }
    };

    return (
      <label key={type} className={cx('label')}>
        <input type="checkbox" className={cx('hidden', 'input')} checked={checked} onChange={onCheck} />
        <span className={cx('checkbox')} />
        {RecruitCategoryTypeNameMap[name]}
      </label>
    );
  });

  return (
    <div className={cx('selectWrap')} ref={careerFilterRef}>
      <a
        className={cx('btnSelect', { open: selectOptionAreaVisible }, { on: checkedList.length })}
        onClick={() => setSelectOptionAreaVisible(!selectOptionAreaVisible)}
        role="button"
      >
        {btnName}
        {selectOptionAreaVisible && <Icon name="arrowTopLight" width={24} height={24} />}
        {!selectOptionAreaVisible && <Icon name="arrowBottomLight" width={24} height={24} />}
      </a>
      {selectOptionAreaVisible && <div className={cx('selectOptionArea', 'career')}>{recruitmentEl}</div>}
    </div>
  );
};

export default RecruitmentFilter;
