import React from 'react';
import { useRouter } from 'next/router';
import Icon from '@components/common/assets';
import Text from '@components/common/text';
import { useMatchReportCompanyPositions } from '@repository/match/report/useMatchReport';
import { CompanyTabEnum } from '@components/company/sn';

import { useUserInfo } from '@repository/auth/useUserInfo';
import { LogTypeEnum } from '@/src/logging/LogType';
import { FontStyle } from '@domain/constant/fontStyle';
import Colors from '@domain/constant/colors';
import { V2Route } from '@utils/routeUtil';

import classnames from 'classnames/bind';
import styles from './index.module.scss';

const cx = classnames.bind(styles);

interface Props {
  companyName: string | null;
  companySn: number | null;
  positionSn: number | null;
}

const MatchingReport = ({ companyName, companySn, positionSn }: Props) => {
  const router = useRouter();
  const { data: userInfo } = useUserInfo();
  const { data: matchingPositions } = useMatchReportCompanyPositions(!!companySn, Number(companySn));
  const selectedPosition = matchingPositions?.positions.find(({ sn }) => sn === positionSn);

  const onClick = (e: any) => {
    router.push({
      pathname: V2Route.getCompanyChannelPage(Number(companySn)),
      query: { tab: CompanyTabEnum.REPORT, positionSn },
    });
  };

  const disabled = !selectedPosition;

  if (!positionSn || !companySn) return null;

  return (
    <>
      <div className={cx('noticeArea')}>
        <div className={cx('title')}>{`${userInfo?.name}님을 위한 매칭 리포트가 도착했습니다.`}</div>
        <Text fontStyle={FontStyle.B2P_M} color={Colors.C_COOL_GRAY_70} styles={{ marginTop: '16px' }}>
          {companyName}와 {userInfo?.name}님만을 위한 특별한 리포트를 준비했어요.
          <br />
          제안 응답 전에 확인해 보세요.
        </Text>
        <button
          className={cx('matchingReportBtnArea', { disabled })}
          role="button"
          onClick={onClick}
          disabled={disabled}
          id={LogTypeEnum.log_message_cbo_match_report}
        >
          <Icon name="bookGreen" width={32} height={32} />
          매칭 리포트 확인하기
        </button>
        <Text
          fontStyle={FontStyle.B4P_M}
          color={Colors.C_COOL_GRAY_60}
          styles={{ display: 'flex', justifyContent: 'center', marginTop: '8px' }}
        >
          매칭 수락 기한이 마감되었거나 제안을 거절한 리포트는 확인할 수 없습니다.
        </Text>
      </div>
    </>
  );
};

export default MatchingReport;
