import { useInfiniteQuery, useQuery } from 'react-query';
import { AxiosError } from 'axios';
import { PageState } from '@domain/rs/common/PageableRs';
import { PositionBookmarkListItemVO } from '@domain/vo/common/PositionBookmarkListItemVO';
import { RemoteCurationRepo } from '@repository/curation/CurationRepo';
import CurationListFilterRq from '@domain/rq/position/list/CurationListFilterRq';
import PositionListFilterVO from '@domain/vo/position/list/PositionListFilterVO';
import CurationFilterListVO from '@domain/vo/position/list/CurationFilterListVO';

export const keys = ['position', 'curation'];
export const curationPositionKeys = [...keys, 'position', 'list'];
export const recommendPositionKeys = [...keys, 'RecommendPosition', 'list'];
export const curationFilterKeys = [...keys, 'filter', 'list'];

const remoteCurationRepo = new RemoteCurationRepo();

const defaultPage: PageState = {
  page: 0,
  size: 60,
  totalPages: 0,
  totalElements: 0,
};

export const fetchCurationFilterList = async () => {
  const { data } = await remoteCurationRepo.fetchCurationFilterList();
  return data.curations.map((item) => new CurationFilterListVO(item));
};

const fetchCurationPositionList = async (curationSn?: number, page = 0, vo?: PositionListFilterVO) => {
  const rq = new CurationListFilterRq(page, defaultPage.size, vo);
  const { data } = await remoteCurationRepo.fetchCurationPositionList(curationSn, rq);

  return {
    ...data,
    positions: data.positions.map(
      (item) =>
        new PositionBookmarkListItemVO({
          ...item,
          sn: item.positionSn,
          title: item.positionName,
        }),
    ),
    isExistNextData: data.pages.totalPages > data.pages.page + 1,
  };
};

const fetchRecommendPositionList = async () => {
  const { data } = await remoteCurationRepo.fetchRecommendPositionList();
  return data.positions.map(
    (item) =>
      new PositionBookmarkListItemVO({
        ...item,
        sn: item.positionSn,
        title: item.positionName,
      }),
  );
};

export default function useCurationFilterList() {
  return useQuery([...curationFilterKeys], fetchCurationFilterList);
}

export function useCurationPositionList(curationSn?: number, vo?: PositionListFilterVO) {
  return useInfiniteQuery(
    [...curationPositionKeys, curationSn, vo],
    ({ pageParam }) => fetchCurationPositionList(curationSn, pageParam, vo),
    {
      getNextPageParam: ({ pages: { page, totalPages } }) => (page < totalPages ? page + 1 : undefined),
      refetchOnMount: false,
      refetchOnReconnect: false,
      retry: 1,
      cacheTime: 0, //필터 초기화시 0 페이지만 조회 하기 위함
    },
  );
}

export function useRecommendPositionList(onError: (e: AxiosError) => void) {
  return useQuery(recommendPositionKeys, () => fetchRecommendPositionList(), {
    retry: 0,
    onError,
    cacheTime: 0,
  });
}
