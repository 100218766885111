import React, { useEffect, useState } from 'react';
import { AxiosError } from 'axios';
import { useToast } from '@common/toast';
import Icon from '@common/assets';
import { useSubmitApply } from '@repository/match';
import { useMessageActions, useMessageStore } from '@stores/MessageStore';
import { resultModalStateType } from '../phsRequest';
import { useAccResultProgressComplete } from '../query/useAccResultsProgressComplete';
import ResultSubmit from '../resultSubmit';
import ResultModals from '../resultModals';
import MessageContents from '../messageContents';
import { AccProgressCompleteVO } from '@domain/vo/acca/test/AccProgressCompleteListVO';
import MatchMessageVO from '@domain/vo/match/message/MatchMessageVO';
import { MatchingTalkApplyType } from '@domain/rs/match/message/MatchingMessageListRs';
import Colors from '@domain/constant/colors';
import useDebounce from '@utils/hooks/useDebounce';
import { RouteUrl, V2Route } from '@utils/routeUtil';

import classnames from 'classnames/bind';
import styles from './accRequest.module.scss';
const cx = classnames.bind(styles);

interface AccRequestProps {
  companySn: number;
  positionSn: number;
  companyMessage: MatchMessageVO;
}

const AccRequest = ({ companySn, positionSn, companyMessage }: AccRequestProps) => {
  const messagesInfo = useMessageStore((state) => state.messagesInfo);
  const { fetchMessagesList } = useMessageActions();
  const Toast = useToast();

  const {
    title,
    message,
    submitApplies,
    isEndDecisionDateTime,
    decisionDueDateTimeValue,
    decisionMatchingDueDateTimeValue,
    options,
    companyAttachFiles,
  } = companyMessage;
  const [resultModalState, setResultModalState] = useState<resultModalStateType>('CONFIRM');
  const [resultType, setResultType] = useState<MatchingTalkApplyType | null>(null);
  const [resultsData, setResultsData] = useState<AccProgressCompleteVO[] | null>(null);

  const { data: ACCdata, isLoading } = useAccResultProgressComplete();

  const submitSuccess = async () => {
    if (!positionSn) return;
    Toast({
      type: 'result',
      size: 'content',
      iconType: 'info',
      content: '정말 수고하셨어요! 제출된 결과를 인사담당자님에게 바로 전달해 드릴게요.',
    });
    await fetchMessagesList(positionSn);
    modalClose();
  };

  const submitError = (e: AxiosError) => {
    switch (e?.response?.data?.errorCode) {
      case 'B908':
        setResultModalState('ERROR');
        return;
      default:
        Toast({ type: 'error', iconType: 'info', content: e?.response?.data?.message });
        setResultModalState(null);
    }
  };

  const { mutate: fetchSubmitApply } = useSubmitApply(submitSuccess, submitError);

  const getUploaded = (applyType: MatchingTalkApplyType) =>
    submitApplies.some((submitApply) => submitApply.applyType === applyType);

  const submitResult = useDebounce(
    (selectedResultSn: number) => {
      if (positionSn && selectedResultSn)
        fetchSubmitApply({ positionSn, optionSn: Number(options[0].sn), applySn: selectedResultSn });
    },
    700,
    true,
  );

  const modalConfirm = () => setResultModalState('CONFIRM');
  const modalClose = () => setResultModalState(null);
  const modalCancel = () => setResultModalState('SELECT');

  useEffect(() => {
    if (ACCdata && resultType === 'ACC') setResultsData(ACCdata.resultRecords);
  }, [resultType, ACCdata]);

  if (isLoading || !messagesInfo) return null;

  const { openYn, deleteYn } = messagesInfo;
  const isNotice = !openYn || deleteYn;

  //역량검사 제출 고정 문구 추가
  const fixedGuideMessageEl = (
    <>
      <p className={cx('notice')}>*아직 역량검사를 응시하지 않았다면, 사전 준비 가이드를 꼭 확인해 주세요.</p>
      <a className={cx('link')} href={V2Route.getInfoPreTestPreparationGuidePage()} target={'_blank'} rel="noreferrer">
        <span className={cx('linkText')}>응시 사전 준비 가이드 확인하기</span>
        <Icon name="arrowRightLight" width={24} height={24} fill={Colors.C_BLUE_100} />
      </a>
      <p className={cx('notice')}>*튜토리얼 연습을 통해 실제 검사와 동일한 과제를 연습할 수 있어요.</p>
      <a className={cx('link')} href={RouteUrl.ACCA_TEST_GATE} target={'_blank'} rel="noreferrer">
        <span className={cx('linkText')}>튜토리얼 연습하기</span>
        <Icon name="arrowRightLight" width={24} height={24} fill={Colors.C_BLUE_100} />
      </a>
    </>
  );

  return (
    <div className={cx('noticeArea')}>
      {/* [D] 메세지 영역 */}
      <MessageContents
        title={title}
        message={message}
        decisionDueDateTimeValue={decisionDueDateTimeValue}
        decisionMatchingDueDateTimeValue={decisionMatchingDueDateTimeValue}
        fixedGuideMessage={fixedGuideMessageEl}
        attachFiles={companyAttachFiles}
        positionSn={positionSn}
      />

      {/* [D] 검사 제출 버튼 영역 */}
      {!getUploaded('ACC') && (isEndDecisionDateTime || isNotice) ? (
        <ResultSubmit submitType={null} setResultType={setResultType} setResultModalState={setResultModalState} />
      ) : (
        <ResultSubmit
          submitType={'ACC'}
          setResultType={setResultType}
          isUploaded={getUploaded('ACC')}
          setResultModalState={setResultModalState}
        />
      )}

      {/* [D] 검사 선택, 결정 모달 영역 */}
      <ResultModals
        companySn={companySn}
        modalConfirm={modalConfirm}
        modalClose={modalClose}
        modalCancel={modalCancel}
        resultsData={resultsData}
        resultType={resultType}
        resultModalState={resultModalState}
        submitResult={submitResult}
      />
    </div>
  );
};

export default AccRequest;
