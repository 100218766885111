import React, { useEffect } from 'react';
import Icon from '@common/assets';
import Colors from '@domain/constant/colors';
import useLocalStorageExpires from '@utils/hooks/useLocalStorageExpires';
import classnames from 'classnames/bind';
import styles from './index.module.scss';

const cx = classnames.bind(styles);

/**
 * [채팅 홍보 컴포넌트]
 * 채팅 아이콘을 클릭했을 경우, 당일에 보이지 않음. (X 버튼과 동일한 효과)
 * GNB 메뉴 설명보다 후순위 z-index를 가짐.
 *
 * [X 버튼 영역 제외한 영역 클릭 시]
 * 채팅 리스트가 보이는 SNB 출력, 하루 동안 해당 컴포넌트 보이지 않음.
 *
 * [X 버튼 영역 클릭 시]
 * 하루 동안 해당 컴포넌트 보이지 않음.
 * */

export const TOOL_TIP_EXPIRES_KEY = 'chatTooltipExpires';

interface ChatTooltipProps {
  chatTooltipVisible: boolean;
  setChatTooltipVisible: (chatTooltipVisible: boolean) => void;
  setChatSNBVisible: (chatSNBVisible: boolean) => void;
}

const ChatTooltip = ({ chatTooltipVisible, setChatTooltipVisible, setChatSNBVisible }: ChatTooltipProps) => {
  const { setExpires, getIsExpired } = useLocalStorageExpires();

  const handleClick = () => {
    setExpires({ key: TOOL_TIP_EXPIRES_KEY });
    setChatTooltipVisible(false);
    setChatSNBVisible(true);
  };

  const handleClose = (e: React.MouseEvent) => {
    e.stopPropagation();
    setExpires({ key: TOOL_TIP_EXPIRES_KEY });
    setChatTooltipVisible(false);
  };

  useEffect(() => {
    const isExpired = getIsExpired(TOOL_TIP_EXPIRES_KEY);
    setChatTooltipVisible(isExpired);
  }, []);

  if (!chatTooltipVisible) return null;

  return (
    <div className={cx('tooltipButton')} onClick={handleClick}>
      <span className={cx('text')}>실시간 인기 채팅방 보러 가기</span>
      <button onClick={handleClose}>
        <Icon name="closeLight" width={24} height={24} fill={Colors.C_COOL_GRAY_30} />
      </button>
    </div>
  );
};

export default ChatTooltip;
