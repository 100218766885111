import React from 'react';
import { useRouter } from 'next/router';
import BarChart from '@common/barChart';
import { useCompanyFinance } from '@repository/companies/useCompanies';
import classnames from 'classnames/bind';
import styles from './financeInfoGraph.module.scss';
const cx = classnames.bind(styles);

const FinanceInfoGraph = () => {
  const router = useRouter();
  const { sn } = router.query;
  const { data: companyFinance } = useCompanyFinance(Number(sn));

  if (!companyFinance) return null;
  const { revenues, profits } = companyFinance;

  if (revenues.length === 0 && profits.length === 0) return null;

  return (
    <div className={cx('graphArea')}>
      {revenues.length !== 0 && (
        <div className={cx('barChartArea')}>
          <div className={cx('barChartTitle')}>매출액</div>
          <div className={cx('barChartWrap')}>
            <BarChart data={revenues} />
          </div>
        </div>
      )}

      {profits.length !== 0 && (
        <div className={cx('barChartArea')}>
          <div className={cx('barChartTitle')}>영업 이익</div>
          <div className={cx('barChartWrap')}>
            <BarChart data={profits} />
          </div>
        </div>
      )}
    </div>
  );
};

export default FinanceInfoGraph;
