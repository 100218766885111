import { useRouter } from 'next/router';
import Image from '@components/common/image';
import Icon from '@components/common/assets';
import Tooltip from '@components/common/tooltip';
import { useAddCompanyLike, useCompaniesHeader, useDeleteCompanyLike } from '@repository/companies/useCompanies';
import LoginModal from '@components/loginModal';
import { useUserInfo } from '@repository/auth/useUserInfo';
import Colors from '@domain/constant/colors';
import useModal from '@utils/hooks/useModal';
import TextFormat from '@utils/text/format';
import classnames from 'classnames/bind';
import styles from './companyBannerArea.module.scss';
const cx = classnames.bind(styles);

const IMG_PATH = '/img/v2/components/company/sn/companyBg';

const BG_LENGTH = 40;

const getCompanyBgPng = (sn: number) => {
  const bgNumber = sn % BG_LENGTH;
  return `${IMG_PATH}/bg${bgNumber}.png`;
};

/**ATS 매칭 개발 완료 후 삭제 예정 */
const prMatchingCompany = [
  2, 3, 4, 7, 36, 38, 40, 45, 47, 50, 52, 83, 90, 95, 1000, 10547, 1202, 16697, 268, 271, 274, 28209, 292, 293, 295,
  299, 301, 313, 323, 335, 337, 340, 351, 386, 387, 392, 415, 445, 504, 505, 518, 525, 529, 53857, 571, 57695, 583, 599,
  640, 65562, 702, 74937, 86945, 91662, 91726, 91728, 91744, 91775,
];

const CompanyBannerArea = () => {
  const router = useRouter();
  const { sn } = router.query;
  const { data: userInfo } = useUserInfo();
  const isLogin = !!userInfo?.id;
  const { data: headerData, refetch } = useCompaniesHeader(Number(sn));
  const { mutateAsync: addCompanyLike } = useAddCompanyLike(refetch);
  const { mutateAsync: deleteCompanyLike } = useDeleteCompanyLike(refetch);
  const { openModal, closeModal, isModalOpen } = useModal();
  const isMatchingCompany = prMatchingCompany.includes(Number(sn));

  const clickLikeBtn = () => {
    if (!isLogin) {
      openModal();
      return;
    }

    if (headerData?.companyHeaderDto.likeYn) deleteCompanyLike(Number(sn));
    else addCompanyLike(Number(sn));
  };

  if (!headerData?.companyHeaderDto) return null;

  return (
    <div className={cx('wrapper')}>
      {/* 기업 메인 이미지 */}
      {headerData.companyHeaderDto.backgroundImageUid ? (
        <Image
          key={headerData.companyHeaderDto.sn}
          className={cx('companyBg')}
          src={headerData.companyHeaderDto.backgroundImageUid}
          width={894}
          height={400}
          alt={headerData.companyHeaderDto.name}
        />
      ) : (
        <img
          className={cx('companyBg')}
          src={getCompanyBgPng(Number(sn))}
          width={894}
          height={400}
          alt={headerData.companyHeaderDto.name}
        />
      )}

      {/* 기업 정보 */}
      <div className={cx('companyInfoArea')}>
        <div className={cx('info')}>
          {/* 로고 */}
          {headerData.companyHeaderDto.logoImageUid ? (
            <span
              className={cx('logo')}
              style={{ backgroundImage: `url(${headerData.companyHeaderDto.logoImageUid})` }}
            />
          ) : (
            <div className={cx('default')}>
              <Icon name="logo" width={38} height={8} fill={Colors.C_COOL_GRAY_50} />
            </div>
          )}

          {/* 기업명, 설명, 매칭 뱃지 */}
          <span className={cx('companyName')}>{headerData.companyHeaderDto.name}</span>
          <p className={cx('vision')}>{headerData.companyHeaderDto.vision}</p>
          <Tooltip
            tooltipClassName={cx('badge')}
            label={<p className={cx('matching', { hidden: !isMatchingCompany })}>MATCHING</p>}
            content={`잡다매칭이 진행중인 기업이에요.`}
            placement="bottom-center"
            theme="gray"
            left={-60}
            top={60}
            iconLeft={92}
          />
        </div>

        {/* 홈페이지 이동, 좋아요 버튼 */}
        <div className={cx('btnArea')}>
          <a
            href={TextFormat.formatProtocol(headerData.companyHeaderDto.homepageUrl ?? '')}
            role="button"
            target="_blank"
            className={cx('homepageUrl', { hidden: !headerData.companyHeaderDto.homepageUrl })}
            rel="noreferrer"
          >
            <Icon name="homeLight" width={24} height={24} fill={Colors.C_WHITE} />
            <span>홈페이지</span>
          </a>
          {/* [D] 좋아요 버튼 클릭 전 - IconHeartLight, 클릭 시 IconHeartLightFilled */}
          <a
            role="button"
            className={cx('likeBtn', { clicked: !!headerData.companyHeaderDto.likeYn })}
            onClick={clickLikeBtn}
          >
            {!!headerData.companyHeaderDto.likeYn ? (
              <Icon name="heartLightFilled" width={24} height={24} fill={Colors.C_ERROR} />
            ) : (
              <Icon name="heartLight" width={24} height={24} fill={Colors.C_WHITE} />
            )}
            <span>{headerData.companyHeaderDto.likeCount}</span>
          </a>
        </div>
      </div>

      <LoginModal
        subTitle="채용정보 · 역량검사 · 매칭까지"
        title="취업기회 지금 바로 잡다!"
        visible={isModalOpen}
        onClose={() => closeModal()}
      />
    </div>
  );
};

export default CompanyBannerArea;
