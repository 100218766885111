import { useMutation } from 'react-query';
import { RemoteResumeRepo } from '@repository/resume/ResumeRepo';
import ResumeSiteRs from '@domain/rs/resume/ResumeSiteRs';

const remoteResumeRepo = new RemoteResumeRepo();

export const fetchResumeSite = async (positionSn: number) => {
  const { data } = await remoteResumeRepo.fetchResumeSite(positionSn);
  return data;
};

export const useResumeSite = (onSuccess: (rs: ResumeSiteRs) => void, onError?: () => void) => {
  return useMutation((positionSn: number) => fetchResumeSite(positionSn), {
    onSuccess,
    onError,
  });
};
