import { useQuery } from 'react-query';
import { RemotePhsPstRepo } from '@repository/phs/pst/PhsPstRepo';
import { PstProgressCompleteListVO } from '@domain/vo/phs/pst/result/PstProgressCompleteListVO';

export const keys = ['phs', 'pst', 'results', 'progress-complete'];

const phsPstRepo = new RemotePhsPstRepo();

const fetchPHSPSTResultProgressComplete = async () => {
  const { data } = await phsPstRepo.fetchPHSPSTResultsProgressComplete();

  return new PstProgressCompleteListVO(data);
};

export function usePHSPSTResultProgressComplete() {
  return useQuery(keys, fetchPHSPSTResultProgressComplete);
}
