import React, { FC } from 'react';
import { useRouter } from 'next/router';
import Icon from '@common/assets';
import { useCompaniesHeader } from '@repository/companies/useCompanies';
import { useUserInfo } from '@repository/auth/useUserInfo';
import classnames from 'classnames/bind';
import styles from './matchingMessage.module.scss';
const cx = classnames.bind(styles);

interface MatchingMessageProps {
  data?: string;
}

const MatchingMessage: FC<MatchingMessageProps> = ({ data: reportData }) => {
  const router = useRouter();
  const { sn } = router.query;
  const { data: userInfo } = useUserInfo();
  const { data: headerData } = useCompaniesHeader(Number(sn));

  if (!userInfo || !headerData || !reportData) return null;

  return (
    <div className={cx('messageInner')}>
      <div className={cx('messageTitle')}>
        <b>{headerData?.companyHeaderDto?.name}</b>에서
        <br />
        <b>{userInfo?.name}님</b>의 잠재력을 확인하고
        <br />
        매칭을 제안했어요.
      </div>
      <div className={cx('message')}>
        <div className={cx('stateInner')}>
          <Icon name="verifiedLightFilled" width={24} height={24} />
          <span className={cx('state')}>매칭 제안</span>
        </div>
        <div className={cx('scrollInner')}>
          {/* 메세지 내용 */}
          <span className={cx('messageDesc')}>{reportData}</span>
        </div>
      </div>
    </div>
  );
};

export default MatchingMessage;
