import { FC, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import Tab from '@components/common/tab';
import PageTopButton from '@components/common/pageTopButton';
import { useCompaniesHeader } from '@repository/companies/useCompanies';
import { useUserInfo } from '@repository/auth/useUserInfo';
import { useMatchReportCompanyPositions } from '@repository/match/report/useMatchReport';
import SearchArea from './searchArea';
import CompanyBannerArea from './bannerArea';
import InfoTab from './infoTab';
import PositionTab from './positionTab';
import EmptyPage, { ErrorType } from './emptyPage';
import MatchingReportTab from './ReportTab';
import SuggestCompany from './suggestCompany';
import Bar from './ReportTab/bar';
import classnames from 'classnames/bind';
import styles from './companyDetail.module.scss';
const cx = classnames.bind(styles);

export enum CompanyTabEnum {
  REPORT = 'report',
  INFO = 'info',
  RECRUIT = 'recruit',
}

export type CompanyTabType = keyof typeof CompanyTabEnum;

interface CompanyContesntsAreaProps {
  selectedMatchingPositionSn: number | null;
  setSelectedMatchingPositionSn: (sn: number | null) => void;
}

const CompanyContentsArea: FC<CompanyContesntsAreaProps> = ({
  selectedMatchingPositionSn,
  setSelectedMatchingPositionSn,
}) => {
  const [apiErrorType, setApiErrorType] = useState<ErrorType>(null);
  const router = useRouter();
  const { sn, tab } = router.query;
  const { data: headerData, isLoading } = useCompaniesHeader(
    Number(sn),
    () => {
      setApiErrorType(null);
    },
    (err) => {
      switch (err?.response?.data?.errorCode) {
        case 'B901':
          setApiErrorType('NOT_REGISTER_COMPANY');
          return;
        case 'B001':
          setApiErrorType('NOT_REGISTER_COMPANY');
          return;
        default:
          setApiErrorType('NOT_EXIST_COMPANY');
      }
    },
  );

  const onTabChange = (tabType?: string) => {
    if (!sn) return;

    router.replace(
      {
        pathname: router.pathname,
        query: { ...router.query, tab: tabType },
      },
      undefined,
      { shallow: true },
    );
  };

  const getActiveTabType = () => {
    if (tab === CompanyTabEnum.RECRUIT) return CompanyTabEnum.RECRUIT;
    if (selectedMatchingPositionSn && (tab === CompanyTabEnum.REPORT || !tab)) return CompanyTabEnum.REPORT;
    else return CompanyTabEnum.INFO;
  };

  if (isLoading) return null;
  if (!apiErrorType && !headerData?.companyHeaderDto) return null;
  if (apiErrorType) return <EmptyPage type={apiErrorType} />;

  return (
    <>
      {/* 검색 결과 상세 */}
      <CompanyBannerArea />

      {/* 탭 메뉴 영역*/}
      <Tab className={cx('tabMenuList')} onTabChange={onTabChange} active={getActiveTabType()}>
        <Tab.Item
          index={CompanyTabEnum.REPORT}
          title="매칭 리포트"
          className={cx('btnMenu')}
          activeClassName={cx('on')}
          hidden={!selectedMatchingPositionSn}
        >
          {/* 매칭 리포트 탭 */}
          <MatchingReportTab
            selectedMatchingPositionSn={selectedMatchingPositionSn}
            setSelectedMatchingPositionSn={setSelectedMatchingPositionSn}
          />
        </Tab.Item>

        <Tab.Item index={CompanyTabEnum.INFO} title="기업 정보" className={cx('btnMenu')} activeClassName={cx('on')}>
          {/* 기업 정보 탭 */}
          <InfoTab />
        </Tab.Item>

        <Tab.Item index={CompanyTabEnum.RECRUIT} title="공고" className={cx('btnMenu')} activeClassName={cx('on')}>
          {/* 공고 탭 */}
          <PositionTab />
        </Tab.Item>
      </Tab>
    </>
  );
};

const CompanyDetail = () => {
  const router = useRouter();

  const { sn, tab, positionSn } = router.query;
  const { data: userInfo } = useUserInfo();
  const isLogin = !!userInfo?.id;
  const { data: headerData } = useCompaniesHeader(Number(sn));
  const { data: matchingPositions, refetch } = useMatchReportCompanyPositions(
    isLogin && !!headerData?.companyHeaderDto.matchingReportOpenYn && !!sn,
    Number(sn),
  );

  //매칭 리포트 선택 포지션 여부
  const [selectedMatchingPositionSn, setSelectedMatchingPositionSn] = useState<number | null>(null);
  const selectedMatchingPosition = matchingPositions?.positions.find(({ sn }) => sn === selectedMatchingPositionSn);

  //매칭 포지션 api 호출시 매칭포지션sn 설정
  useEffect(() => {
    if (matchingPositions?.positions.length && tab) {
      const isValidPositionSn = matchingPositions.positions.some(({ sn }) => sn === Number(positionSn));
      const initPositionSn = isValidPositionSn ? Number(positionSn) : matchingPositions.positions[0].sn;
      setSelectedMatchingPositionSn(initPositionSn);

      if (initPositionSn !== Number(positionSn)) {
        router.replace(
          {
            pathname: router.pathname,
            query: { ...router.query, tab: CompanyTabEnum.REPORT, positionSn: initPositionSn },
          },
          undefined,
          { shallow: true },
        );
      }
    } else setSelectedMatchingPositionSn(null); //다른 기업 매칭 리포트로 갈때 position값 초기화
  }, [matchingPositions, router]);

  //선택한 매칭 포지션 sn이 변경되면 query변경 + report tab으로 이동
  useEffect(() => {
    if (!sn || !tab || !positionSn || !selectedMatchingPosition) return;

    router.replace(
      {
        pathname: router.pathname,
        query: { sn, tab: CompanyTabEnum.REPORT, positionSn: selectedMatchingPositionSn },
      },
      undefined,
      { shallow: true },
    );
  }, [selectedMatchingPositionSn]);

  return (
    <>
      <div className={cx('wrapper')}>
        {/* 좌측 검색 Sticky 영역 */}
        <div className={cx('floatingArea')}>
          {/* 검색결과 */}
          <SearchArea />
          {/* 제안받은 매칭기업 */}
          <SuggestCompany />
        </div>

        {/* Detail View 영역 */}
        <div className={cx('companyDetail')}>
          <CompanyContentsArea
            selectedMatchingPositionSn={selectedMatchingPositionSn}
            setSelectedMatchingPositionSn={setSelectedMatchingPositionSn}
          />
        </div>

        <PageTopButton />
      </div>

      {/* floating bar 매칭 리포트일 경우에만 표시*/}
      {/* sticky효과를 주기 위해 밖으로 빼야함. */}
      <Bar
        selectedMatchingPosition={selectedMatchingPosition}
        refetch={refetch}
        selectedMatchingPositionSn={selectedMatchingPositionSn}
        setSelectedMatchingPositionSn={setSelectedMatchingPositionSn}
        companyName={headerData?.companyHeaderDto.name}
      />
    </>
  );
};

export default CompanyDetail;
