import React, { FC } from 'react';
import MatchPositionCard from '@common/header/notification/matchPositionCard';
import { useNotificationActions } from '@stores/NotificationStore';
import { MatchNotificationVO } from '@domain/vo/match/MatchNotificationVO';
import classnames from 'classnames/bind';
import styles from './notification.module.scss';

const cx = classnames.bind(styles);

interface INotificationsProps {
  isLogin: boolean;
  notifications: MatchNotificationVO[];
}

/* [D] 알림 리스트 영역입니다. */
const MatchPositionNotifications: FC<INotificationsProps> = ({ isLogin, notifications }) => {
  const { confirmNotifications, fetchNotifications, removeNotifications, setTooltipVisible, setLoginModalVisible } =
    useNotificationActions();

  const getAllNotificationsSn = () => notifications.map(({ notificationSn }) => notificationSn);

  const onRead = async () => {
    await confirmNotifications({ notificationSnList: getAllNotificationsSn() });
    fetchNotifications();
  };

  const onDelete = async () => {
    await removeNotifications({ notificationSnList: getAllNotificationsSn() });
    fetchNotifications();
  };

  const onLogin = () => {
    setTooltipVisible(false);
    setLoginModalVisible(true);
  };

  return (
    <div className={cx('alertTooltipArea')}>
      <div className={cx('titleArea')}>
        <span className={cx('title')}>알림</span>
        <div className={cx('optionArea')}>
          <a className={cx('btnOption', 'deleteAll')} role="button" onClick={onDelete}>
            모두 지우기
          </a>
          <a className={cx('btnOption', 'readAll')} role="button" onClick={onRead}>
            모두 읽음
          </a>
        </div>
      </div>
      <MatchPositionCard isLogin={isLogin} onLoginModal={onLogin} notifications={notifications} />
    </div>
  );
};

export default MatchPositionNotifications;
