import React, { useState } from 'react';

import CompanyMessageModals from '../common/companyMessageModals';
import MessageButton from '../../common/button';
import MatchMessageVO from '@domain/vo/match/message/MatchMessageVO';
import { MatchTalkMessageOptionDto } from '@domain/rs/match/message/MatchingMessageListRs';

import useModals from '@utils/hooks/useModals';
import classnames from 'classnames/bind';
import styles from './offer.module.scss';
const cx = classnames.bind(styles);

type DecisionProps = {
  positive: MatchTalkMessageOptionDto | null;
  negative: MatchTalkMessageOptionDto | null;
};

const getOptions = (options: MatchTalkMessageOptionDto[]) => {
  return options.reduce(
    (prev, cur) => {
      const { command } = cur;

      switch (command) {
        case 'ACCEPT':
          prev.positive = cur;
          return prev;

        case 'REFUSE':
          prev.negative = cur;
          return prev;

        default:
          return prev;
      }
    },
    {
      positive: null,
      negative: null,
    } as DecisionProps,
  );
};

interface NoticeProps {
  companyMessage: MatchMessageVO;
  openYn: boolean;
  deleteYn: boolean;
}

const Offer = ({ companyMessage, openYn, deleteYn }: NoticeProps) => {
  const {
    title,
    message,
    options = [],
    decision,
    decisionDateTime,
    decisionDueDateTime,
    interviewLocation,
    command,
  } = companyMessage;

  const { openModal, closeModal, isVisible } = useModals([
    'ACCEPT',
    'REJECT',
    'MATCHING_REJECT',
    'INVALID_DATE',
    'ATS_ERROR',
    'ENDED',
    'DELETED',
    'COORDINATION',
  ]);

  const [selection] = useState<DecisionProps>(getOptions(options));

  const isDisabled = !openYn || deleteYn;

  const getResultText = () => (decision?.command === 'REFUSE' ? '면접 제안 거절' : '면접 제안 수락');
  const reject = () => {
    command === 'MATCH_INTERVIEW_OFFER' ? openModal('MATCHING_REJECT') : openModal('REJECT');
  };
  const accept = () => openModal('ACCEPT');

  const getOptionSn = (optionSn: string | null | undefined) => {
    if (!optionSn) return null;
    return parseInt(optionSn);
  };

  const ButtonElements = () => {
    if (command === 'INTERVIEW_CANCEL') {
      return (
        <MessageButton className={cx('canceled')} disabled>
          면접 요청 만료
        </MessageButton>
      );
    }

    return (
      !decision && (
        <div className={cx('noticeBtnWrapper')}>
          <MessageButton
            className={cx('btn', { disabled: isDisabled })}
            theme="secondary"
            onClick={isDisabled ? undefined : reject}
            disabled={isDisabled}
          >
            거절하기
          </MessageButton>
          <MessageButton
            className={cx('btn', { disabled: isDisabled })}
            theme="primary"
            onClick={isDisabled ? undefined : accept}
            disabled={isDisabled}
          >
            수락하기
          </MessageButton>
        </div>
      )
    );
  };

  return (
    <div className={cx('noticeArea')}>
      <strong className={cx('title')}>{title}</strong>
      <div className={cx('message')}>{message}</div>

      <div className={cx('expiredDate')}>
        {decisionDueDateTime}
        <div className={cx('notice')}>*정해진 유효기간까지 답변이 없을 경우 자동 불참 처리됩니다.</div>
      </div>

      {command !== 'INTERVIEW_CANCEL' && decision && (
        <a className={cx('noticeWrap')}>
          {decisionDateTime} {getResultText()}
        </a>
      )}

      <ButtonElements />

      {/* [D] 면접 수락 / 거절 모달 */}
      <CompanyMessageModals
        isVisible={isVisible}
        openModal={openModal}
        closeModal={closeModal}
        positiveOptionSn={getOptionSn(selection.positive?.sn)}
        negativeOptionsSn={getOptionSn(selection.negative?.sn)}
        interviewLocation={interviewLocation}
      />
    </div>
  );
};

export default Offer;
