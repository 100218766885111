import React, { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import Icon from '@common/assets';
import PositionBookmarkListItem from '@components/common/positionBookmarkListItem';
import usePositionList from '@components/position/list/query/usePositionList';
import SkeletonPositionListList from '@components/position/list/positionList/SkeletonPositionListList';
import NoPositionData from './NoPositionData';
import PositionListFilterVO from '@domain/vo/position/list/PositionListFilterVO';

import classnames from 'classnames/bind';
import styles from './positionList.module.scss';
const cx = classnames.bind(styles);

const PositionList: FC = () => {
  const { watch } = useFormContext<PositionListFilterVO>();

  const watchFilter = watch();
  const { data, fetchNextPage, isLoading } = usePositionList(watchFilter);
  const moreBtnVisible = data?.pages[data.pages.length - 1].isExistNextData;

  const positionEls = data?.pages.map(({ positions }) => {
    return positions.map((item, idx) => {
      return (
        <li className={cx('listItem')} key={`${item.sn}_${item.bookmarkYn}`}>
          <PositionBookmarkListItem index={idx} data={item} />
        </li>
      );
    });
  });

  if (isLoading) return <SkeletonPositionListList length={12} />;
  //리스트 없는 경우
  if (data?.pages[0] && data?.pages[0].positions.length < 1) return <NoPositionData />;

  return (
    <>
      <ul className={cx('positionList')}>{positionEls}</ul>
      <div className={cx('btnMoreArea')}>
        {moreBtnVisible && (
          <a className={cx('btnMore')} role="button" onClick={() => fetchNextPage()}>
            포지션 더보기
            <Icon name="arrowBottomLight" width={32} height={32} />
          </a>
        )}
      </div>
    </>
  );
};

export default PositionList;
