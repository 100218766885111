import { AxiosResponse } from 'axios';
import { axios } from '@repository/RequestClient';
import ProfileDetailRs from '@domain/rs/position/jd/ProfileDetailRs';

export interface ProfileResumeRepo {
  // 포지션에 제출 한 프로필 지원서 순번 조회
  fetchProfileResumeSn(positionSn: number): Promise<AxiosResponse<number>>;

  // 프로필 지원서 상세 조회
  fetchProfileResumeDetail(profileResumeSn: number): Promise<AxiosResponse<ProfileDetailRs>>;
}

export default class RemoteProfileResumeRepo implements ProfileResumeRepo {
  fetchProfileResumeSn(positionSn: number): Promise<AxiosResponse<number>> {
    return axios.get<number>('/profile-resume', { params: { positionSn } });
  }

  fetchProfileResumeDetail(profileResumeSn: number): Promise<AxiosResponse<ProfileDetailRs>> {
    return axios.get<ProfileDetailRs>(`/profile-resume/${profileResumeSn}`);
  }
}
