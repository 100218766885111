import MatchingReportRs, {
  JobTitleFitDto,
  MatchingReportCareerFitDto,
  MatchingReportCompetencyAnalysisDto,
  MatchingReportJobTitleFitDto,
  MatchingReportProfileFitDto,
  MatchingReportSalaryFitDto,
  MatchingReportScoreDto,
  MatchingReportSubFactorDto,
  MatchingReportSubFactorQuestionDto,
  MatchinigReportPositionAddressDto,
} from '@domain/rs/match/report/MatchingReportRs';
import NumberFormat from '@utils/number/format';

export default class MatchingReportVO {
  score: MatchingReportScoreVO;
  profileFit: MatchingReportProfileFitVO;
  competencyAnalysis: MatchingReportCompetencyAnalysisVO;
  offerMessage: string;
  addressInfo: MatchinigReportPositionAddressDto;

  constructor(dto: MatchingReportRs) {
    this.score = new MatchingReportScoreVO(dto.score);
    this.profileFit = new MatchingReportProfileFitVO(dto.profileFit);
    this.competencyAnalysis = new MatchingReportCompetencyAnalysisVO(dto.competencyAnalysis);
    this.offerMessage = dto.offerMessage;
    this.addressInfo = dto.addressInfo;
  }
}

export class MatchingReportScoreVO {
  matchingTotalScore: number;
  profileScore: number;
  profileScoreText: string;
  profileScorePosition: number;
  competencyScore: number;
  competencyScoreText: string;
  competencyScorePosition: number;
  scoreTypeText: string;

  constructor(dto: MatchingReportScoreDto) {
    this.matchingTotalScore = Number(dto.matchingTotalScore.toFixed(1));
    this.profileScore = dto.profileScore;
    this.profileScoreText = dto.profileScore >= 3000 ? '매우 적합' : '적합';
    this.profileScorePosition = this.scaleValue(dto.profileScore, 'x');
    this.competencyScore = dto.competencyScore;
    this.competencyScoreText = dto.competencyScore >= 3000 ? '매우 적합' : '적합';
    this.competencyScorePosition = this.scaleValue(dto.competencyScore, 'y');
    this.scoreTypeText = this.getScoreTypeText(dto.profileScore, dto.competencyScore);
  }

  private scaleValue(value: number, axis: 'x' | 'y' = 'x') {
    const minVal = 1000;
    const maxVal = 5000;
    const scaledMin = 0;
    const scaledMax = 100;

    // 값 새로운 범위로 변환 + absolut 위치 기준 값 보정 1000 ~ 5000 -> 0 ~ 100
    const correction = axis === 'y' ? 2 : 0.5; //absolute 위치 기준값 보정.
    const newValue = ((value - minVal) / (maxVal - minVal)) * (scaledMax - scaledMin) + scaledMin - correction;
    //absolute bottom, left값 보정
    if (axis === 'x') {
      if (newValue < 1) return 1;
      if (newValue > 98) return 98;
    }
    if (axis === 'y') {
      if (newValue < 1) return 1;
      if (newValue > 96) return 96;
    }
    return newValue;
  }

  private getScoreTypeText(profileScore: number, competencyScore: number) {
    if (profileScore < 3000 && competencyScore < 3000) return '잠재력';
    else if (profileScore >= 3000 && competencyScore < 3000) return '기업 선호 부합';
    else if (profileScore < 3000 && competencyScore >= 3000) return '탁월한 역량';
    else return '최적의 인재';
  }
}

export class MatchingReportProfileFitVO {
  jobTitleFit: MatchingReportJobTitleFitVO | null;
  salaryFit: MatchingReportSalaryFitVO | null;
  careerFit: MatchingReportCareerFitVO | null;

  constructor(dto: MatchingReportProfileFitDto) {
    this.jobTitleFit = dto?.jobTitleFit ? new MatchingReportJobTitleFitVO(dto.jobTitleFit) : null;
    this.salaryFit = dto?.salaryFit ? new MatchingReportSalaryFitVO(dto.salaryFit) : null;
    this.careerFit = dto?.careerFit ? new MatchingReportCareerFitVO(dto.careerFit) : null;
  }
}
export class MatchingReportJobTitleFitVO {
  jobTitleFits: JobTitleFitDto[];
  fitJobTitleFits: JobTitleFitDto[];

  constructor(dto: MatchingReportJobTitleFitDto) {
    this.jobTitleFits = dto.jobTitleFits;
    this.fitJobTitleFits = this.getFitJobTitleFits(dto.jobTitleFits);
  }

  private getFitJobTitleFits(jobTitleFits: JobTitleFitDto[]) {
    return jobTitleFits.filter((fit) => fit.fitYn);
  }
}

export class MatchingReportSalaryFitVO {
  salaryText: string;
  fitYn: boolean;

  constructor(dto: MatchingReportSalaryFitDto) {
    this.salaryText = this.getSalaryText(dto.minSalary, dto.maxSalary);
    this.fitYn = dto.fitYn;
  }

  private getSalaryText(minSalary: number | null, maxSalary: number | null) {
    const minSalaryText = minSalary ? NumberFormat.format(minSalary * 10000, '10K') : null;
    const maxSalaryText = maxSalary ? NumberFormat.format(maxSalary * 10000, '10K') : null;

    if (minSalaryText && maxSalary) {
      return `${minSalaryText}원 ~ ${maxSalaryText}원`;
    } else if (minSalaryText) {
      return `${minSalaryText}원 이상`;
    } else if (maxSalaryText) {
      return `${maxSalaryText}원 이하`;
    } else {
      return '-';
    }
  }
}

export class MatchingReportCareerFitVO {
  careerText: string;
  fitYn: boolean;

  constructor(dto: MatchingReportCareerFitDto) {
    this.careerText = this.getCareerText(dto.minYear, dto.maxYear);
    this.fitYn = dto.fitYn;
  }

  private getCareerText(minYear: number | null, maxYear: number | null) {
    const minYearText = minYear ? NumberFormat.formatDecimal(minYear) : null;
    const maxYearText = maxYear ? NumberFormat.formatDecimal(maxYear) : null;

    if (!minYear && !maxYear) return '경력 무관';
    else if (minYearText && maxYear) {
      return `${minYearText} ~ ${maxYearText}년차`;
    } else if (minYearText) {
      return `${minYearText}년차 이상`;
    } else if (maxYearText) {
      return `${maxYearText}년차 이하`;
    } else {
      return '-';
    }
  }
}

export class MatchingReportCompetencyAnalysisVO {
  strengths: MatchingReportSubFactorVO[];

  constructor(dto: MatchingReportCompetencyAnalysisDto) {
    this.strengths = dto.strengths.map((strength) => new MatchingReportSubFactorVO(strength));
  }
}

export class MatchingReportSubFactorVO {
  keyword: string;
  comment: string;
  iconSrc: string;
  questions: MatchingReportSubFactorQuestionDto[];

  constructor(dto: MatchingReportSubFactorDto) {
    this.keyword = dto.keyword;
    this.iconSrc = this.getIconSrc(dto.keyword);
    this.comment = dto.comment;
    this.questions = dto.questions;
  }

  private getIconSrc(keyword: string) {
    return textToImageMap[keyword];
  }
}

const IMG_PATH = '/img/v2/components/company/sn/reportTab/resultStrength/';
const textToImageMap: Record<string, string> = {
  '높은 자신감': IMG_PATH + 'icon_person_ability_white.svg',
  '높은 긍정성': IMG_PATH + 'icon_bubble_emoji_smile.svg',
  '강한 인정욕구': IMG_PATH + 'icon_crown_white.svg',
  '강한 학습의지': IMG_PATH + 'icon_description_write_white.svg',
  '강한 성공의지': IMG_PATH + 'icon_laurel_wreath_star_white.svg',
  '빠른 학습력': IMG_PATH + 'icon_chart_search_white.svg',
  '높은 계획력': IMG_PATH + 'icon_matching_2_white.svg',
  '뛰어난 기억력': IMG_PATH + 'icon_star_person_white.svg',
  멀티태스킹: IMG_PATH + 'icon_page_checklist_white.svg',
  '높은 집중력': IMG_PATH + 'icon_chart_pyramid_white.svg',
  '꾸준한 실천': IMG_PATH + 'icon_test_white.svg',
  '세심한 성격': IMG_PATH + 'icon_diamond_white.svg',
  '높은 의도이해': IMG_PATH + 'icon_target_blue.svg',
  '뛰어난 설득력': IMG_PATH + 'icon_chat_white.svg',
  '목표 추구성': IMG_PATH + 'icon_compass_white.svg',
  '객관적인 시각': IMG_PATH + 'icon_equity_white.svg',
  '반성하는 자세': IMG_PATH + 'icon_diversity_2_white.svg',
  '겸손한 태도': IMG_PATH + 'icon_clap_white.svg',
  '규범의 준수': IMG_PATH + 'icon_diversity_white.svg',
  '빠른 적응력': IMG_PATH + 'icon_building_2_white.svg',
};
