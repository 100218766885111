import React from 'react';
import CustomImage from '@components/common/image';
import TextFormat from '@utils/text/format';
import classnames from 'classnames/bind';
import styles from './companyLogo.module.scss';
const cx = classnames.bind(styles);

interface EmptyCompanyLogoProps {
  companyName: string | null;
}

const EmptyCompanyLogo = ({ companyName }: EmptyCompanyLogoProps) => {
  if (!companyName) return null;

  return (
    <div className={cx('logo', 'default')}>
      <span>{`${TextFormat.formatRemoveParenthesesCompanyName(companyName)}`}</span>
    </div>
  );
};

interface CompanyLogoProps {
  companyName: string | null;
  logoImageUrl: string | null;
}

const CompanyLogo = ({ companyName, logoImageUrl }: CompanyLogoProps) => {
  if (!logoImageUrl) return <EmptyCompanyLogo companyName={companyName} />;
  if (!companyName) return null;

  return (
    <div className={cx('logo')}>
      <CustomImage className={cx('logo', 'image')} src={logoImageUrl} alt={companyName} width={48} height={48} />
    </div>
  );
};

export default CompanyLogo;
