import React, { FC } from 'react';
import { useUserInfo } from '@repository/auth/useUserInfo';
import { GraphProps } from '..';
import classnames from 'classnames/bind';
import styles from './fourQuadrants.module.scss';
const cx = classnames.bind(styles);

const FourQuadrants: FC<GraphProps> = ({ data }) => {
  const { data: userInfo } = useUserInfo();

  if (!data) return null;

  return (
    <div className={cx('scoreInner')}>
      {/* 4사분면 그래프 */}
      <div className={cx('fourQuadrants')}>
        <div className={cx('fourQuadrantsInner')}>
          {/* X축, Y축 설명 */}
          <div className={cx('axis', 'xDesc')}>
            <span className={cx('axisName')}>적합</span>
            <span className={cx('axisName')}>프로필 적합도</span>
            <span className={cx('axisName')}>매우 적합</span>
          </div>
          <div className={cx('axis', 'yDesc')}>
            <span className={cx('axisName')}>적합</span>
            <span className={cx('axisName')}>역량 적합도</span>
            <span className={cx('axisName')}>매우 적합</span>
          </div>

          {/* 그래프 4개의 영역 */}
          <div className={cx('fourQuadrantsGraph')}>
            {/* 점 */}
            <div
              className={cx('dotInner')}
              style={{
                bottom: `${data.competencyScorePosition}%`,
                left: `${data.profileScorePosition}%`,
              }}
            >
              <div className={cx('dot')}>
                <div className={cx('dotTooltip', { up: ['잠재력', '기업 선호 부합'].includes(data.scoreTypeText) })}>
                  <span className={cx('dotTooltipTitle')}>
                    {userInfo?.name}님의 매칭 적합도 <span className={cx('score')}>{`: ${data.scoreTypeText}`}</span>
                  </span>
                  <div className={cx('dotTooltipDesc')}>
                    프로필 적합도
                    <span className={cx('score')}>{` ${data.profileScoreText}`}</span>
                    <br />
                    역량 적합도<span className={cx('score')}>{` ${data.competencyScoreText}`}</span>
                  </div>
                </div>
              </div>
            </div>

            <div className={cx('quadrant', { on: data.scoreTypeText === '탁월한 역량' })}>탁월한 역량</div>
            <div className={cx('quadrant', { on: data.scoreTypeText === '최적의 인재' })}>최적의 인재</div>
            <div className={cx('quadrant', { on: data.scoreTypeText === '잠재력' })}>잠재력</div>
            <div className={cx('quadrant', { on: data.scoreTypeText === '기업 선호 부합' })}>기업 선호 부합</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FourQuadrants;
