import React from 'react';
import Link from 'next/link';
import Icon from '@components/common/assets';
import { RouteUrl } from '@utils/routeUtil';
import classnames from 'classnames/bind';
import styles from './searchModal.module.scss';
const cx = classnames.bind(styles);

const RecommendMenuList = () => {
  return (
    <div className={cx('recommendService')}>
      <Link href={RouteUrl.MATCH_APPLY} legacyBehavior>
        <a className={cx('btnService')} role="button">
          <Icon name="pageChecklistBlue" width={24} height={24} />
          매칭 현황
        </a>
      </Link>
      <Link href={RouteUrl.ACCA_TEST_GATE} legacyBehavior>
        <a className={cx('btnService')} role="button">
          <Icon name="acca" width={24} height={24} />
          역량검사
        </a>
      </Link>
      <Link href={RouteUrl.JOBS} legacyBehavior>
        <a className={cx('btnService')} role="button">
          <Icon name="calendarRed" width={24} height={24} />
          채용 캘린더
        </a>
      </Link>
      <Link href={RouteUrl.PASS_INTERVIEW} legacyBehavior>
        <a className={cx('btnService')} role="button">
          <Icon name="doc" width={24} height={24} />
          기출 면접 연습
        </a>
      </Link>
      <Link href={RouteUrl.MY_PAGE_PREMIUM} legacyBehavior>
        <a className={cx('btnService')} role="button">
          <Icon name="handShake" width={24} height={24} />
          제휴 서비스 신청
        </a>
      </Link>
      <Link href={RouteUrl.INFO_LIST} legacyBehavior>
        <a className={cx('btnService')} role="button">
          <Icon name="bookGreen" width={24} height={24} />
          취업 콘텐츠
        </a>
      </Link>
      <Link href={RouteUrl.EVENT} legacyBehavior>
        <a className={cx('btnService')} role="button">
          <Icon name="gift" width={24} height={24} />
          이벤트
        </a>
      </Link>
    </div>
  );
};

export default RecommendMenuList;
