import React, { FC } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { PositionOrderType } from '@domain/constant/positionOrderType';

import classnames from 'classnames/bind';
import styles from '../positionFilter.module.scss';
const cx = classnames.bind(styles);

const fieldName = 'orderType';

interface Props {
  isCuration: boolean;
}

const OrderFilter: FC<Props> = ({ isCuration }) => {
  const { control } = useFormContext();

  const { field } = useController({
    control,
    name: fieldName,
    defaultValue: 'POPULAR',
  });

  const onClickOrder = (type: PositionOrderType) => {
    field.onChange(type);
  };

  return (
    <>
      <label className={cx('option', { on: field.value === 'POPULAR' })} onClick={() => onClickOrder('POPULAR')}>
        <input className={cx('hidden')} type="radio" name={fieldName} value="POPULAR" />
        인기순
      </label>
      <label className={cx('option', { on: field.value === 'LATEST' })} onClick={() => onClickOrder('LATEST')}>
        <input className={cx('hidden')} type="radio" name={fieldName} value="LATEST" />
        최신순
      </label>
      <label className={cx('option', { on: field.value === 'SALARY' })} onClick={() => onClickOrder('SALARY')}>
        <input className={cx('hidden')} type="radio" name={fieldName} value="SALARY" />
        연봉순
      </label>
      <label className={cx('option', { on: field.value === 'DEADLINE' })} onClick={() => onClickOrder('DEADLINE')}>
        <input className={cx('hidden')} type="radio" name={fieldName} value="DEADLINE" />
        마감순
      </label>
      {isCuration && (
        <label className={cx('option', { on: field.value === 'NONE' })} onClick={() => onClickOrder('NONE')}>
          <input className={cx('hidden')} type="radio" name={fieldName} value="NONE" />
          추천순
        </label>
      )}
    </>
  );
};

export default OrderFilter;
