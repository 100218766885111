import React from 'react';
import { useNotificationStore } from '@stores/NotificationStore';
import CompanyLogo from './common/companyLogo';
import CompanyWriter from './common/companyWriter';
import Offer from './offer';
import Interview from './interview';
import Notice from './notice';
import AccRequest from './resultRequest/accRequest';
import PhsRequest from './resultRequest/phsRequest';
import MatchOffer from './matchOffer';
import FileAttachment from './file/attachment';
import MatchingMessageListInfoVO from '@domain/vo/match/message/MatchingMessageListInfoVO';
import MatchMessageVO from '@domain/vo/match/message/MatchMessageVO';
import { CommandTypeCode } from '@domain/rs/match/message/MatchingMessageListRs';

import classnames from 'classnames/bind';
import styles from './companyMessage.module.scss';
const cx = classnames.bind(styles);

interface CompanyMessageProps {
  companyMessage: MatchMessageVO;
  messagesInfo: MatchingMessageListInfoVO;
}

const CompanyMessage = ({ companyMessage, messagesInfo }: CompanyMessageProps) => {
  const { command, createdDayTime } = companyMessage;
  const positionInfo = useNotificationStore((state) => state.positionInfo);
  const { openYn, deleteYn } = messagesInfo;

  if (!positionInfo || !positionInfo.companyName || !positionInfo.positionSn || !positionInfo.companySn) return null;
  const { logoImageUrl, positionSn, companySn, companyName } = positionInfo;

  const getMessageContents = () => {
    switch (command) {
      // [D] 면접 제안 메세지
      case CommandTypeCode.INTERVIEW_OFFER:
      case CommandTypeCode.INTERVIEW_CANCEL:
        return <Offer companyMessage={companyMessage} openYn={openYn} deleteYn={deleteYn} />;

      // [D] 인터뷰 시간 선택 메세지
      case CommandTypeCode.INTERVIEW_OFFER_SCHEDULE:
        return (
          <Interview positionSn={positionSn} companyMessage={companyMessage} openYn={openYn} deleteYn={deleteYn} />
        );

      // [D] 추가 서류 제출, 추가 서류 확인 메세지
      case CommandTypeCode.FILE_REQUEST:
      case CommandTypeCode.FILE_SUBMIT:
        return (
          <FileAttachment positionSn={positionSn} companyMessage={companyMessage} openYn={openYn} deleteYn={deleteYn} />
        );

      // [D] 추가 역량검사 제출
      case CommandTypeCode.ACC_REQUEST:
        return <AccRequest companySn={companySn} positionSn={positionSn} companyMessage={companyMessage} />;

      case CommandTypeCode.PHS_REQUEST:
        return <PhsRequest companySn={companySn} positionSn={positionSn} companyMessage={companyMessage} />;

      // 입사지원 제안
      case CommandTypeCode.MATCH_OFFER:
        return (
          <MatchOffer
            companySn={companySn}
            positionSn={positionSn}
            companyName={companyName}
            companyMessage={companyMessage}
          />
        );

      // [D] 합격, 불합격, TypeCode null 메세지
      case CommandTypeCode.INTERVIEW_PASSED:
      case CommandTypeCode.INTERVIEW_FAILED:
      default:
        return <Notice companyMessage={companyMessage} positionSn={positionSn} />;
    }
  };

  return (
    <div className={cx('wrap')}>
      {/* [D] 회사 로고 영역 */}
      <CompanyLogo companyName={companyName} logoImageUrl={logoImageUrl} />

      <div className={cx('contentArea')}>
        {/* [D] 회사 담당자 영역 */}
        <CompanyWriter companyName={companyName} createdDayTime={createdDayTime} />

        {/* [D] 회사 메세지 컨턴츠 영역 */}
        {getMessageContents()}
      </div>
    </div>
  );
};

export default CompanyMessage;
