import {
  InsightVisibilityType,
  JobApplicationPositionType,
  JobApplicationProcessType,
} from '@domain/constant/jobApplicationProcessType';

export interface MatchingHomeApplicationPositionRs {
  jobApplicationProcesses: MatchingHomePositionCardDto[];
}

export interface MatchingHomeWritingPositionRs {
  writingResumes: MatchingHomePositionCardDto[];
}

export interface MatchingHomePositionCardDto {
  positionSn: number;
  positionName: string;
  companySn: number;
  companyName: string;
  companyLogoUrl: string;
  applicationCompleteTime: string | null;
  jobApplicationProcessType: JobApplicationProcessType;
  openingDateTime: string;
  closingDateTime: string;
  positionType: JobApplicationPositionType;
  openYn: boolean;
  visibilityType: InsightVisibilityType;
  matchingOfferYn: boolean;
}

export enum MatchingApplicationStatusEnum {
  ALL = 'ALL',
  APPLICATION = 'APPLICATION',
  IN_PROGRESS = 'IN_PROGRESS',
  END = 'END',
}
export type MatchingApplicationStatusType = 'ALL' | 'APPLICATION' | 'IN_PROGRESS' | 'END';
