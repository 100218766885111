import PageableRq from '@domain/rq/common/PageableRq';
import PositionListFilterVO from '@domain/vo/position/list/PositionListFilterVO';
import { RecruitCategoryType } from '@domain/constant/recruitCategoryType';
import { LocationCode } from '@domain/constant/locationType';
import { PositionOrderType } from '@domain/constant/positionOrderType';

export default class CurationListFilterRq extends PageableRq {
  jobTitles?: number[];
  locations?: number[];
  recruitments?: RecruitCategoryType[];
  minSalary?: number;
  maxSalary?: number;
  matchingYn?: boolean;
  orderType?: PositionOrderType;

  constructor(page: number, size: number, vo?: PositionListFilterVO) {
    super({
      page,
      size,
    });

    if (!vo) return;

    this.jobTitles = vo.jobTitles?.map((item) => item.code);
    this.recruitments = vo.recruitments;
    this.minSalary = this.getSalaryValue(vo.minSalary);
    this.maxSalary = this.getSalaryValue(vo.maxSalary);
    this.locations = vo.locations.map((type) => LocationCode[type]);
    this.matchingYn = vo.matchingYn;
    this.orderType = vo.orderType;
  }

  getSalaryValue(val?: number | null) {
    if (val === undefined || val === null) return undefined;
    return val;
  }
}
