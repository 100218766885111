import React, { FC, useRef, useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import Icon from '@common/assets';
import { Location, LocationType, OrderLocation } from '@domain/constant/locationType';
import useClickAway from '@utils/hooks/useClickOutside';

import TextFormat from '@utils/text/format';
import classnames from 'classnames/bind';
import styles from '../positionFilter.module.scss';
const cx = classnames.bind(styles);

const fieldName = 'locations';

const LocationFilter: FC = () => {
  const [selectOptionAreaVisible, setSelectOptionAreaVisible] = useState(false);

  const [selectedList, setSelectedList] = useState<LocationType[]>([]);
  const locationFilterRef = useRef<HTMLDivElement>(null);
  const { control } = useFormContext();

  const { field } = useController({
    control,
    name: fieldName,
    defaultValue: [],
  });

  const btnName = field.value.length
    ? TextFormat.formatSummaryListWithCount(field.value.map((type: LocationType) => Location[type]))
    : '지역';

  const onSave = () => {
    field.onChange(selectedList);
    setSelectOptionAreaVisible(false);
  };

  const onInit = () => {
    setSelectedList([]);
    field.onChange([]);

    setSelectOptionAreaVisible(false);
  };

  const openCloseSelectOptionArea = () => {
    //직무 Option 열릴 때 적용 버튼을 통해 초기화된 값으로 셋팅
    if (!selectOptionAreaVisible) {
      setSelectedList(field.value);
    }

    setSelectOptionAreaVisible(!selectOptionAreaVisible);
  };

  useClickAway(() => setSelectOptionAreaVisible(false), locationFilterRef);

  const locationEL = Object.entries(Location).map(([type, name]) => {
    const selected = selectedList.includes(type as LocationType);
    const onSelect = () => {
      if (selected) {
        setSelectedList(selectedList.filter((item: LocationType) => item !== type));
      } else {
        const tempList = [...selectedList, type as LocationType];
        //정렬 이유 : 지역 버튼 표시시 제일 상단에 있는 지역명이 먼저 표시하기 위함
        tempList.sort((a, b) => OrderLocation.indexOf(a) - OrderLocation.indexOf(b));
        setSelectedList(tempList);
      }
    };

    return (
      <label key={type} className={cx('label')}>
        <input type="checkbox" className={cx('hidden', 'input')} checked={selected} onChange={onSelect} />
        <span className={cx('checkbox')} />
        {name}
      </label>
    );
  });

  return (
    <div className={cx('selectWrap')} ref={locationFilterRef}>
      <a
        className={cx('btnSelect', { open: selectOptionAreaVisible }, { on: field.value.length })}
        onClick={openCloseSelectOptionArea}
        role="button"
      >
        {btnName}
        {selectOptionAreaVisible && <Icon name="arrowTopLight" width={24} height={24} />}
        {!selectOptionAreaVisible && <Icon name="arrowBottomLight" width={24} height={24} />}
      </a>
      {selectOptionAreaVisible && (
        <div className={cx('selectOptionArea', 'location')}>
          <div className={cx('optionArea')}>{locationEL}</div>

          <div className={cx('btnArea')}>
            <a className={cx('btnReset')} role="button" onClick={onInit}>
              선택 초기화
            </a>
            <a className={cx('btnConfirm')} role="button" onClick={onSave}>
              적용
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

export default LocationFilter;
