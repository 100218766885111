import React, { useEffect } from 'react';
import Button from '@components/common/button';
import Modal from '@components/common/modal';
import RadioInput from '@components/common/radioInput';
import { useToast } from '@components/common/toast';
import { MATCH_LIST_QUERY_KEY, useRejectInterview } from '@repository/match';
import { useMessageActions } from '@stores/MessageStore';
import { useJD } from '@repository/position/usePosition';
import {
  OfferRejectEnum,
  OfferRejectLabel,
  OfferRejectSubReasonType,
  rejectSubReasonTextMaxLength,
} from '@domain/OfferRejectType';
import { LogTypeEnum } from '@/src/logging/LogType';
import { queryClient } from '@utils/queryClient';

import { hasErrorCode } from '@utils/typeGuard';
import classnames from 'classnames/bind';
import styles from './refuseModal.module.scss';
const cx = classnames.bind(styles);

interface IRefuseModalProps {
  visible: boolean;
  onClose: () => void;
  onRefuseAfter?: () => void;
  optionSn: number | null;
  positionSn: number;
  handleErrors: (result: string) => void;
}

const RejectModal = ({ onClose, onRefuseAfter, visible, optionSn, positionSn, handleErrors }: IRefuseModalProps) => {
  const { fetchMessagesList } = useMessageActions();
  const Toast = useToast();
  const { refetch } = useJD(positionSn);

  const [rejectReason, setRejectReason] = React.useState<OfferRejectEnum | null>(null);
  const [subReason, setSubReason] = React.useState<OfferRejectSubReasonType[]>([]);
  const [reasonText, setReasonText] = React.useState<string>('');
  const submitButtonDisabled =
    !rejectReason ||
    (rejectReason === OfferRejectEnum.OTHER && !reasonText) ||
    (rejectReason === OfferRejectEnum.WORKING_PREFERENCE && subReason.length === 0);

  const { mutateAsync: rejectMatchingAsync } = useRejectInterview(
    async () => {
      fetchMessagesList(positionSn);
      queryClient.refetchQueries(MATCH_LIST_QUERY_KEY);
      refetch();
      Toast({ type: 'success', iconType: 'info', content: '제안을 거절하셨습니다.' });
      onRefuseAfter?.();
    },
    (e) => {
      if (hasErrorCode(e)) handleErrors(e.response.data.errorCode);
      fetchMessagesList(positionSn);
    },
  );

  const onRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRejectReason(e.target.value as OfferRejectEnum);
  };

  const onSubReasonTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e.target;
    if (value.length > rejectSubReasonTextMaxLength) return;
    setReasonText(value);
  };

  const onCloseWithReset = () => {
    setRejectReason(null);
    setSubReason([]);
    setReasonText('');
    onClose();
  };

  const onSubmit = async () => {
    if (submitButtonDisabled || !rejectReason || !optionSn) return;

    const reason = OfferRejectLabel[rejectReason];
    switch (rejectReason) {
      case OfferRejectEnum.OTHER:
        await rejectMatchingAsync({
          optionSn,
          positionSn,
          rejectReasonType: rejectReason,
          rejectReason: reasonText,
          subReasons: [],
        });
        break;

      default:
        await rejectMatchingAsync({
          optionSn,
          positionSn,
          rejectReasonType: rejectReason,
          rejectReason: reason,
          subReasons: [],
        });
        break;
    }
    onCloseWithReset();
  };

  useEffect(() => {
    if (rejectReason !== OfferRejectEnum.INDUSTRY_OR_JOB_TITLE) {
      setSubReason([]);
    }

    if (rejectReason !== OfferRejectEnum.OTHER) {
      setReasonText('');
    }
  }, [rejectReason]);

  return (
    <Modal visible={visible}>
      <div className={cx('refuseModalArea')}>
        <div className={cx('title')}>제안을 거절하시겠어요?</div>
        <div className={cx('description')}>
          걱정하지 마세요! 거절에 따른 불이익은 없습니다.
          <br />더 좋고, 정확한 제안을 드리기 위해 사유를 알려주세요.
        </div>

        <form className={cx('form')}>
          {/* 개인적인 사정으로 면접 참여가 어려워졌습니다. */}
          <RadioInput
            name="refuseType"
            label={OfferRejectLabel[OfferRejectEnum.INDIVIDUAL_REASON]}
            value={OfferRejectEnum.INDIVIDUAL_REASON}
            onChange={onRadioChange}
            checked={rejectReason === OfferRejectEnum.INDIVIDUAL_REASON}
          />

          {/* 다른 기업과 처우 협의 중입니다. */}
          <RadioInput
            name="refuseType"
            label={OfferRejectLabel[OfferRejectEnum.CONTACT_ANOTHER_COMPANY]}
            value={OfferRejectEnum.CONTACT_ANOTHER_COMPANY}
            onChange={onRadioChange}
            checked={rejectReason === OfferRejectEnum.CONTACT_ANOTHER_COMPANY}
          />

          {/* 직접 입력 */}
          <RadioInput
            name="refuseType"
            label={OfferRejectLabel[OfferRejectEnum.OTHER]}
            value={OfferRejectEnum.OTHER}
            onChange={onRadioChange}
            checked={rejectReason === OfferRejectEnum.OTHER}
            condition={rejectReason === OfferRejectEnum.OTHER}
            className={cx('label')}
          >
            <div className={cx('textareaWrap')}>
              <textarea
                placeholder="거절 사유를 입력해 주세요."
                maxLength={rejectSubReasonTextMaxLength}
                rows={4}
                onChange={onSubReasonTextChange}
              />
              <div className={cx('infoText')}>
                최대 글자수 : <span className={cx('value')}>{reasonText.length}</span> / {rejectSubReasonTextMaxLength}
              </div>
            </div>
          </RadioInput>
        </form>

        <div className={cx('buttonArea')}>
          <Button
            theme="primary"
            onClick={onSubmit}
            disabled={submitButtonDisabled}
            className={cx('button')}
            id={LogTypeEnum.log_message_company_interview_reject}
          >
            확인
          </Button>
        </div>
      </div>

      <a className={cx('btnClose')} role="button" onClick={onCloseWithReset}>
        <span className={cx('hidden')}>close</span>
      </a>
    </Modal>
  );
};

export default RejectModal;
