import { useMessageStore } from '@stores/MessageStore';
import DateFormat from '@utils/date/format';
import classnames from 'classnames/bind';
import styles from './notice.module.scss';
const cx = classnames.bind(styles);

// 포지션 종료
const EndNotice = ({ date }: { date: string | null }) => {
  const endDate = date && DateFormat.format(new Date(date), 'yyyy년 MM월 dd일 | HH시 mm분');

  return (
    <>
      <strong className={cx('title')}>포지션이 종료되었습니다.</strong>
      {date && <span className={cx('date')}>(종료 시간 : {endDate})</span>}
      <br />
      <span className={cx('desc')}>
        포지션이 종료되면 추가 서류, 역량검사, 개발자 검사 결과 제출이 불가능하며 답변 유효기간이 남아 있는 면접
        제안이라도 선택이 불가능합니다.
      </span>
    </>
  );
};

const DeleteNotice = ({ date }: { date: string | null }) => {
  const deleteDate = date && DateFormat.format(new Date(date), 'yyyy년 MM월 dd일 | HH시 mm분');

  return (
    <>
      <strong className={cx('title')}>포지션이 삭제되었습니다.</strong>
      <span className={cx('date')}>(삭제 시간 : {deleteDate})</span>
      <br />
      <span className={cx('desc')}>
        포지션이 삭제되면 추가 서류, 역량검사, 개발자 검사 결과 제출이 불가능하며 포지션 상세 페이지가 삭제됩니다.
      </span>
    </>
  );
};

const Notice = () => {
  const messagesInfo = useMessageStore((state) => state.messagesInfo);

  if (!messagesInfo) return null;

  const { openYn, deleteYn, positionEndYn, closingYn, endDateTime, deleteDateTime } = messagesInfo;
  const isNotice = !openYn || deleteYn || positionEndYn;

  if (!isNotice) return null;

  // 포지션 우선 처리 순서 (삭제 > 종료 > 마감)
  const getNotice = () => {
    if (deleteYn) return <DeleteNotice date={deleteDateTime} />;
    if (positionEndYn) return <EndNotice date={endDateTime} />;
    if (closingYn) return null;
  };

  return (
    <div className={cx('noticeArea')}>
      <div className={cx('noticeWrap')}>{getNotice()}</div>
    </div>
  );
};

export default Notice;
