import { ParsedUrlQueryInput } from 'querystring';
import Link from 'next/link';
import { NextRouter, useRouter } from 'next/router';
import Icon from '@components/common/assets';
import { useUserInfo } from '@repository/auth/useUserInfo';
import LoginModal from '@components/loginModal';
import { LogTypeEnum } from '@/src/logging/LogType';
import Colors from '@domain/constant/colors';
import { RouteUrl, useGetACCATutorialPage, useGetMatchPage } from '@utils/routeUtil';

import useModal from '@utils/hooks/useModal';
import classnames from 'classnames/bind';
import styles from './index.module.scss';
const cx = classnames.bind(styles);

interface Menu {
  text: string;
  url: string | (() => void);
  query?: string | null | ParsedUrlQueryInput | undefined;
  isNewWindow?: boolean;
  afterIcon?: React.ReactNode;
  isNeedLogin?: boolean;
}

const useSiteMapList = () => {
  const matchUrl = useGetMatchPage();
  const accaTutorialUrl = useGetACCATutorialPage();

  const SITE_MAP_LIST: { title: Menu; sub: Menu[] }[] = [
    {
      title: { text: '잡다매칭', url: matchUrl },
      sub: [
        { text: '매칭 현황', url: RouteUrl.MATCH_APPLY, isNeedLogin: true },
        { text: '잡다매칭 소개', url: RouteUrl.MATCH_INTRODUCE },
      ],
    },
    {
      title: { text: '채용정보', url: RouteUrl.JOBS },
      sub: [
        { text: '채용 캘린더', url: RouteUrl.JOBS },
        { text: '채용 공고', url: RouteUrl.POSITION },
        // TODO: 채용 이벤트 있을 때 주석 해제
        // { text: '채용이벤트', url: RouteUrl.CHALLENGE },
      ],
    },
    {
      title: { text: '역량검사', url: RouteUrl.ACCA_TEST_GATE },
      sub: [
        {
          text: '역량검사 응시',
          url: RouteUrl.ACCA_TEST_LIST,
          query: { utm_source: 'gnb' },
          isNewWindow: true,
          isNeedLogin: true,
        },
        { text: '역량검사 튜토리얼', url: accaTutorialUrl, isNeedLogin: true },
        { text: '역량검사 결과표', url: RouteUrl.ACCA_RESULTS, query: { utm_source: 'gnb' } },
        { text: '역량검사 소개', url: RouteUrl.ACCA_INTRODUCE },
      ],
    },
    {
      title: { text: '개발자 검사', url: RouteUrl.PHS_TEST_GATE },
      sub: [
        { text: '개발자 역량검사', url: RouteUrl.PHS_PACC_TEST_GATE, query: { utm_source: 'gnb' }, isNewWindow: true },
        { text: '개발구현능력 검사', url: RouteUrl.PHS_PST_TEST_GATE, query: { utm_source: 'gnb' }, isNewWindow: true },
        { text: '개발자 검사 결과표', url: RouteUrl.PHS_PACC_RESULTS },
        {
          text: '개발자 역량검사 소개 ',
          url: 'https://jobdadev.im/pacc',
          isNewWindow: true,
          afterIcon: <Icon name="openInNewLight" width={20} height={20} />,
        },
        {
          text: '개발구현능력검사 소개 ',
          url: 'https://jobdadev.im/pst',
          isNewWindow: true,
          afterIcon: <Icon name="openInNewLight" width={20} height={20} />,
        },
      ],
    },
    {
      title: { text: '취업 준비', url: RouteUrl.PREPARATION },
      sub: [
        { text: '취업 콘텐츠', url: RouteUrl.INFO_LIST },
        { text: '합격 후기 게시판', url: RouteUrl.PASSBOARD },
        { text: '기출 면접 연습', url: RouteUrl.PASS_INTERVIEW },
        { text: '취준 MBTI', url: 'https://acca.jobda.im/jbti/me', isNewWindow: true },
      ],
    },
    {
      title: { text: '이벤트', url: RouteUrl.EVENT },
      sub: [{ text: '이벤트', url: RouteUrl.EVENT }],
    },
    {
      title: { text: 'MY JOBDA', url: RouteUrl.MY_PAGE_ACCOUNT },
      sub: [
        { text: '내 프로필', url: RouteUrl.PROFILE },
        { text: '지원 현황', url: RouteUrl.MY_PAGE_APPLY, isNeedLogin: true },
        { text: '북마크', url: RouteUrl.MY_PAGE_BOOKMARK, isNeedLogin: true },
        { text: '제휴서비스 신청', url: RouteUrl.MY_PAGE_PREMIUM, isNeedLogin: true },
        { text: '계정 설정', url: RouteUrl.MY_PAGE_ACCOUNT, isNeedLogin: true },
      ],
    },
  ];

  return SITE_MAP_LIST;
};

interface SubMenuListItemProps extends Menu {
  isLogin: boolean;
  openLoginModal?: () => void;
  closeSiteMap: () => void;
  router: NextRouter;
}

const SubMenuListItem = ({
  text,
  url,
  query,
  isNewWindow,
  afterIcon,
  isLogin,
  isNeedLogin,
  openLoginModal,
  closeSiteMap,
  router,
}: SubMenuListItemProps) => {
  if (isNeedLogin && !isLogin) {
    return (
      <li className={cx('subMenuListItem')}>
        <a role="button" onClick={openLoginModal}>
          {text}
          {afterIcon}
        </a>
      </li>
    );
  }

  if (typeof url === 'function') {
    return (
      <li className={cx('subMenuListItem')}>
        <a role="button" onClick={url}>
          {text}
          {afterIcon}
        </a>
      </li>
    );
  }

  if (router.pathname === url) {
    return (
      <li className={cx('subMenuListItem')}>
        <a role="button" onClick={() => router.reload()}>
          {text}
          {afterIcon}
        </a>
      </li>
    );
  }

  return (
    <li className={cx('subMenuListItem')}>
      <Link href={{ pathname: url, query }} target={isNewWindow ? '_blank' : ''} onClick={closeSiteMap}>
        {text}
        {afterIcon}
      </Link>
    </li>
  );
};

interface Props {
  visible: boolean;
  toggleSiteMapVisible: () => void;
}

const SiteMap = ({ visible, toggleSiteMapVisible }: Props) => {
  const router = useRouter();
  const siteMapList = useSiteMapList();
  const { data: userInfo } = useUserInfo();
  const isLogin = !!userInfo?.id;

  const { closeModal, isModalOpen, openModal } = useModal();
  const closeSiteMap = () => {
    visible && toggleSiteMapVisible();
  };

  return (
    visible && (
      <>
        <div className={cx('siteMap')}>
          <div className={cx('siteMapWrap')}>
            <div className={cx('siteMapArea')}>
              {siteMapList.map(({ title, sub }) => {
                if (title.text === 'MY JOBDA' && !isLogin) return null;
                return (
                  <div key={title.text} className={cx('subMenuListWrap', { isLogin })} id={LogTypeEnum.log_gnb_menu}>
                    <Link
                      href={typeof title.url === 'string' ? title.url : ''}
                      className={cx('subMenuTitle')}
                      onClick={closeSiteMap}
                    >
                      {title.text}
                      <Icon name="arrowRightLight" width={20} height={20} fill={Colors.C_COOL_GRAY_60} />
                    </Link>

                    <ul className={cx('subMenuList')}>
                      {sub.map((item) => (
                        <SubMenuListItem
                          key={item.text}
                          {...item}
                          openLoginModal={openModal}
                          isLogin={isLogin}
                          closeSiteMap={closeSiteMap}
                          router={router}
                        />
                      ))}
                    </ul>
                  </div>
                );
              })}
            </div>
          </div>

          <a className={cx('siteMapDim')} onClick={toggleSiteMapVisible} role="button" />
        </div>

        <LoginModal
          subTitle="채용정보 · 역량검사 · 매칭까지"
          title="취업기회 지금 바로 잡다!"
          visible={isModalOpen}
          onClose={closeModal}
        />
      </>
    )
  );
};

export default SiteMap;
