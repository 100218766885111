import { useEffect, useRef, useState } from 'react';
import DateFormat from '@utils/date/format';
import DateUtil from '@utils/date/util';

/**
 * @param endDate: endDate
 * @param onCountDown: 타이머 카운트 다운시 실행되는 함수
 * @param onCountUp: 타이머 카운트 끝날 때 실행되는 함수
 */

interface UseDateTimerProps {
  endDate: Date;
  onCountDown?: (remainSec: number) => void;
  onCountUp?: () => void;
}

const useDateTimer = ({ endDate, onCountDown, onCountUp }: UseDateTimerProps) => {
  const frameId = useRef<number>(0);
  const initialRemainingTime = DateUtil.getLeftSeconds(endDate) <= 0 ? 0 : DateUtil.getLeftSeconds(endDate);
  const [remainingTime, setRemainingTime] = useState(initialRemainingTime);

  const getExactRemainingTime = () => {
    const remaining = (endDate.getTime() - new Date().getTime()) / 1000;
    return Math.max(0, remaining);
  };

  const countDown = () => {
    const exactRemainingTime = getExactRemainingTime();
    const isOver = exactRemainingTime === 0;
    onCountDown?.(exactRemainingTime);

    if (isOver) {
      onCountUp?.();
      cancelAnimationFrame();
      return;
    }

    setRemainingTime(Math.floor(exactRemainingTime));
    requestAnimationFrame();
  };

  const getRemainingTimeFormat = (format: string) => DateFormat.formatDateDiff(new Date(), endDate, format);

  const requestAnimationFrame = () => {
    frameId.current = window.requestAnimationFrame(countDown);
  };

  const cancelAnimationFrame = () => {
    if (frameId.current) window.cancelAnimationFrame(frameId.current);
  };

  useEffect(() => {
    cancelAnimationFrame();
    requestAnimationFrame();
    return () => cancelAnimationFrame();
  }, [endDate]);

  return { getRemainingTimeFormat, remainingTime };
};

export default useDateTimer;
