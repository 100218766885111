/**
 * [JDA-11399]
 * RouterQuery
 * GA UTM 파라미터를 통해 페이지 이동을 추적 하기 위한 함수
 * UtmOrigin - 시작 페이지
 * **/

export enum UtmOrigin {
  Header = 'mypage', // 헤더 서브 메뉴
  Footer = 'footer', // 푸터 링크
  Preparation = 'prepare', // 취업 준비 페이지
  ACCA = 'acca', // 역량검사 페이지
  Banner = 'banner', // 내프로필 역량검사 결과, 개발자 검사 결과, 기업 추천서비스 우측 배너 바로가기
  HeaderACCA = 'gnb', // 헤더 역량검사 -> 역량검사 바로가기
  Profile = 'profile',
}

export type UtmSourceOriginType = keyof typeof UtmOrigin;

const RouterQuery = {
  getUtmContent: (value: UtmOrigin) => {
    return {
      utm_content: value,
    };
  },
  getUtmSource: (value: UtmOrigin) => {
    return {
      utm_source: value,
    };
  },
};

export default RouterQuery;
