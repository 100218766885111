import { AxiosResponse } from 'axios';
import { axios } from '@repository/RequestClient';
import ResumeSiteRs from '@domain/rs/resume/ResumeSiteRs';

export default interface ResumeRepo {
  //포지션의 지원서사이트 연결 정보 조회
  fetchResumeSite(positionSn: number): Promise<AxiosResponse<ResumeSiteRs>>;
}

export class RemoteResumeRepo implements ResumeRepo {
  fetchResumeSite(positionSn: number) {
    return axios.get(`/resume/site`, {
      params: {
        positionSn,
      },
    });
  }
}
