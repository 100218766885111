import React, { FC } from 'react';
import { useUserInfo } from '@repository/auth/useUserInfo';
import StrengthList from './strengthList';
import { MatchingReportCompetencyAnalysisVO } from '@domain/vo/match/report/MatchingReportVO';
import classnames from 'classnames/bind';
import styles from './resultStrength.module.scss';
const cx = classnames.bind(styles);

interface ResultStrength {
  data?: MatchingReportCompetencyAnalysisVO;
}

const ResultStrength: FC<ResultStrength> = ({ data }) => {
  const { data: userInfo } = useUserInfo();

  if (!data?.strengths.length) return null;

  const strengthListEl = data.strengths.map((el, index) => {
    return (
      <div className={cx('strengthItem')} key={index}>
        {el.keyword}
        <img className={cx('icon')} src={el.iconSrc} alt={el.keyword} width={56} height={56} />
      </div>
    );
  });

  return (
    <div className={cx('resultInner')}>
      <div className={cx('basicTitle')}>
        기업에서 긍정적으로 평가할 수 있는
        <br />
        역량 분석 결과를 확인해 보세요.
      </div>

      <div className={cx('strengthResult')}>
        {/* 좌 : 강점 소개 */}
        <div className={cx('strengthTitle')}>
          <div className={cx('tooltip')}>우리 기업에서 선호할 수 있는</div>
          <div className={cx('resultIntro')}>
            {userInfo?.name}님만의
            <br />
            특별한 강점을 알려드릴게요.
          </div>
          <div className={cx('desc')}>
            <p className={cx('import')}>면접 준비 TIP</p>
            강점 키워드를 강조하여 자기 소개를 준비하고
            <br />
            나의 강점을 살려 면접 스토리를 만들어 보세요.
          </div>
        </div>

        {/* 우 : 강점 리스트 3개 */}
        <div className={cx('strengthList')}>{strengthListEl}</div>
      </div>

      <div className={cx('listWrap')}>
        <StrengthList data={data} />
      </div>
    </div>
  );
};

export default ResultStrength;
