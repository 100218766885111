import React, { useEffect } from 'react';
import { useRouter } from 'next/router';
import Modal from '@common/modal';
import { useNotificationActions, useNotificationStore } from '@stores/NotificationStore';
import { useMessageActions, useMessageStore } from '@stores/MessageStore';
import MessageModalTitle from './messageModalTitle';
import Messages from './messages';
import Notice from './notice';
import classnames from 'classnames/bind';
import styles from './messageModal.module.scss';

const cx = classnames.bind(styles);

const MessageModal = () => {
  const { events } = useRouter();
  const groupedMessagesByDate = useMessageStore((state) => state.groupedMessagesByDate);
  const { initMessages } = useMessageActions();
  const positionInfo = useNotificationStore((state) => state.positionInfo);
  const isMessageModalVisible = useNotificationStore((state) => state.isMessageModalVisible);
  const { setMessageModalVisible } = useNotificationActions();

  const handleRoute = () => setMessageModalVisible(false);
  const handleKeyEvent = (e: KeyboardEvent) => {
    const modalContainer = document.body.querySelector('#modal');
    if ((modalContainer?.childElementCount ?? 0) <= 1 && e?.key == 'Escape') {
      setMessageModalVisible(false);
    }
  };

  useEffect(() => {
    if (!positionInfo || !positionInfo.positionSn) return;
    if (!isMessageModalVisible) {
      // 모달 꺼질 때 메세지 초기화
      initMessages();
      return;
    }
  }, [isMessageModalVisible, positionInfo, initMessages]);

  useEffect(() => {
    // url 변경 시 꺼짐 처리 (뒤로가기 포함)
    events.on('routeChangeStart', handleRoute);
    document.addEventListener('keydown', handleKeyEvent, false);

    return () => {
      events.off('routeChangeStart', handleRoute);
      document.removeEventListener('keydown', handleKeyEvent, false);
    };
  }, []);

  if (!(groupedMessagesByDate && Object.keys(groupedMessagesByDate).length)) return null;

  return (
    <Modal visible={isMessageModalVisible}>
      <div className={cx('messageArea')}>
        {/* [D] 모달 타이틀 영역 */}
        <MessageModalTitle />

        {/* [D] 모달 메세지 영역 */}
        <Messages />

        {/* [D] 모달 종료, 삭제 안내 영역 */}
        <Notice />
      </div>
    </Modal>
  );
};

export default MessageModal;
