import React from 'react';

import { FileAttachmentModalType } from '../fileAttachmentModals';

import MessageButton from '../../../../common/button';
import classnames from 'classnames/bind';
import styles from './fileSubmit.module.scss';
const cx = classnames.bind(styles);

interface FileSubmitProps {
  isCompleted: boolean;
  isUploaded: boolean;
  decisionDateTime: string | null;
  setFileAttachmentModalState: (fileAttachmentModalState: FileAttachmentModalType | null) => void;
  openYn: boolean;
  deleteYn: boolean;
}

const FileSubmit = ({
  isCompleted,
  isUploaded,
  decisionDateTime,
  setFileAttachmentModalState,
  openYn,
  deleteYn,
}: FileSubmitProps) => {
  const isDisabled = !isUploaded || !openYn || deleteYn;

  const handleSubmit = () => {
    setFileAttachmentModalState('FILE_CONFIRM');
  };

  if (!isCompleted) {
    return (
      <MessageButton className={cx('btnSubmit')} disabled={isDisabled} onClick={handleSubmit}>
        파일 제출
      </MessageButton>
    );
  }

  return (
    <MessageButton className={cx('complete')} disabled>
      {decisionDateTime} 파일 제출 완료
    </MessageButton>
  );
};

export default FileSubmit;
