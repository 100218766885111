export enum ReviewTypeEnum {
  CASH_REWARD = '물질적 보상',
  COWORKER_RELATIONSHIP = '동료와의 관계',
  WORK_ENVIRONMENT = '업무 환경',
  SOCIAL_REWARD = '사회적 보상',
  LEADER_RELATIONSHIP = '리더와의 관계',
  GROWTH_ENVIRONMENT = '성장 환경',
  MENTAL_REWARD = '정신적 보상',
  WORLD_RELATIONSHIP = '세상과의 관계',
  TOTAL_ENVIRONMENT = '통합 환경',
}

export type ReviewType = keyof typeof ReviewTypeEnum;
