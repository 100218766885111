import React, { FC } from 'react';
import Link from 'next/link';
import Modal from '@common/modal';
import { RouteUrl } from '@utils/routeUtil';

import classnames from 'classnames/bind';
import styles from './noCompleteMissionModal.module.scss';
const cx = classnames.bind(styles);

interface INoCompleteMissionModalProps {
  visible: boolean;
  onClose: () => void;
}

const NoCompleteMissionModal: FC<INoCompleteMissionModalProps> = ({ visible, onClose }) => {
  return (
    <Modal visible={visible} dimmed={true}>
      <div className={cx('layer')}>
        <strong className={cx('title')}>
          기업 추천서비스를 신청하면
          <br />
          확인할 수 있어요.
        </strong>
        <p className={cx('desc')}>취준생을 위한 다양한 무료혜택을 받아보세요!</p>
        <div className={cx('imgArea')} />

        <Link href={RouteUrl.MATCH_APPLY} legacyBehavior>
          <a className={cx('link')} role="button">
            신청하러 가기
          </a>
        </Link>

        <a className={cx('btnClose')} onClick={onClose} role="button">
          <span className={cx('hidden')}>close</span>
        </a>
      </div>
    </Modal>
  );
};

export default NoCompleteMissionModal;
