import React from 'react';
import MatchMessageVO from '@domain/vo/match/message/MatchMessageVO';
import classnames from 'classnames/bind';
import styles from './notice.module.scss';
const cx = classnames.bind(styles);

interface NoticeProps {
  companyMessage: MatchMessageVO;
}

const Notice = ({ companyMessage }: NoticeProps) => {
  const { title, message } = companyMessage;

  return (
    <div className={cx('noticeArea')}>
      <strong className={cx('title')}>{title}</strong>
      <div className={cx('message')}>{message}</div>
    </div>
  );
};

export default Notice;
