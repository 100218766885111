import { useAccordion } from '@components/_atoms/Accordion';
import useOnMount from '@utils/hooks/useOnMount';

interface Props {
  children: React.ReactNode;
  className?: string;
  id: string;
}

const Header = ({ children, className, id }: Props) => {
  const { toggle, registerAccordion } = useAccordion();

  useOnMount(() => {
    registerAccordion(id || '');
  });

  return (
    <div onClick={() => toggle(id)} className={className}>
      {children}
    </div>
  );
};

export default Header;
