import { Children, Fragment, ReactElement, ReactNode, cloneElement, isValidElement, useEffect, useState } from 'react';

import classnames from 'classnames/bind';
import styles from './tab.module.scss';
const cx = classnames.bind(styles);

interface TabItemProps {
  title: ReactNode;
  active?: boolean;
  index: string;
  onClick?: () => void;
  children: ReactNode;
  className?: string;
  activeClassName?: string;
  nonActiveClassName?: string;
  hidden?: boolean;
}

const TabItem = ({
  active,
  onClick,
  title,
  className,
  index: _index,
  children: _children,
  activeClassName,
  nonActiveClassName,
  hidden,
}: TabItemProps) => {
  const activeClassNames = active ? activeClassName ?? 'active' : nonActiveClassName ?? '';
  if (hidden) return null;
  return (
    <a role="button" className={cx(className, activeClassNames)} onClick={onClick}>
      {title}
    </a>
  );
};

interface TabProps {
  active?: string | null;
  className?: string;
  children: ReactNode;
  onTabChange?: (tab?: string) => void;
}

const TabComponent = ({ active, className, children, onTabChange }: TabProps) => {
  const [activeTab, setActiveTab] = useState<string>(() => {
    if (active) {
      return active;
    } else {
      return (Children.toArray(children).filter(isValidElement)[0].props as TabItemProps).index;
    }
  });

  const items = Children.map(children, (child) => {
    const element = child as ReactElement;
    return cloneElement(element, {
      ...element.props,
      className: cx(element.props.className ?? 'tabTitle'),
      active: element.props.index === activeTab,
      onClick: () => {
        setActiveTab(element.props.index);
      },
      key: element.props.index,
    });
  });

  const activeItem = Children.toArray(items)
    .filter(isValidElement)
    .filter((element) => {
      if (!isValidElement(element)) {
        false;
      }

      return (element.props as TabItemProps).index === activeTab;
    })[0];

  useEffect(() => {
    onTabChange?.(activeTab);
  }, [activeTab]);

  useEffect(() => {
    if (active) {
      setActiveTab(active);
      onTabChange?.(active);
    }
  }, [active]);

  return (
    <>
      <div className={cx(className ?? 'tabWrap')}>{items}</div>
      <Fragment key={activeItem?.key}>{(activeItem?.props as TabItemProps)?.children}</Fragment>
    </>
  );
};

const Tab = Object.assign((props: TabProps) => <TabComponent {...props} />, {
  Item: (props: TabItemProps) => <TabItem {...props} />,
});

export default Tab;
