import React, { FC, useState } from 'react';

import classnames from 'classnames/bind';
import styles from './terms.module.scss';
const cx = classnames.bind(styles);

interface TermsProps {
  title: string; // 제목
  contents: string; // 본문
  isChecked: boolean;
  onClick: () => void;
}

const Terms: FC<TermsProps> = ({ title, contents, isChecked, onClick }) => {
  const [contentsVisible, setContentsVisible] = useState(false);

  const handleChangeContentsVisible = () => {
    setContentsVisible(!contentsVisible);
  };

  return (
    <>
      <div className={cx('labelWrap')}>
        <label className={cx('label')}>
          <input
            type="checkbox"
            className={cx('input', 'hidden')}
            checked={isChecked}
            readOnly={true}
            onClick={onClick}
          />
          <span className={cx('mark')} />
          {title}
        </label>
        <a className={cx('btnOpen', { open: contentsVisible })} role="button" onClick={handleChangeContentsVisible} />
      </div>
      <div className={cx('agreementTextArea', { open: contentsVisible })}>
        <div
          className={cx('agreementText')}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: contents,
          }}
        />
      </div>
    </>
  );
};

export default Terms;
