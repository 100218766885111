import { RouteUrl } from '@utils/routeUtil';
import RouterQuery, { UtmOrigin } from '@utils/routeUtil/RouterQuery';

export interface IMenu {
  title: string;
  url: string;
  query?: Record<string, string>;
  isNewWindow?: boolean;
}

export const profileSubMenus: IMenu[] = [
  {
    title: '내 프로필',
    url: RouteUrl.PROFILE,
    query: RouterQuery.getUtmSource(UtmOrigin.Header),
  },
  {
    title: '응시 결과',
    url: RouteUrl.ACCA_RESULTS,
    query: RouterQuery.getUtmSource(UtmOrigin.Header),
  },
  {
    title: '지원 현황',
    url: RouteUrl.MY_PAGE_APPLY,
  },
  {
    title: '매칭 현황',
    url: RouteUrl.MATCH_APPLY,
    query: RouterQuery.getUtmSource(UtmOrigin.Header),
  },
  {
    title: '북마크',
    url: RouteUrl.MY_PAGE_BOOKMARK,
  },
  {
    title: '제휴서비스 신청',
    url: RouteUrl.MY_PAGE_PREMIUM,
  },
  {
    title: '계정 설정',
    url: RouteUrl.MY_PAGE_ACCOUNT,
  },
];

export const eventSubMenus: IMenu[] = [
  {
    title: '잡다 챌린지',
    url: RouteUrl.EVENT_GET_JOB_CHALLENGE,
  },
];
