import Modal from '@components/common/modal';
import Button from '@components/common/button';
import Icon from '@components/common/assets';
import { useToast } from '@components/common/toast';
import { MATCH_LIST_QUERY_KEY, useAcceptCBOMatching } from '@repository/match';
import { useMessageActions } from '@stores/MessageStore';
import { useJD } from '@repository/position/usePosition';
import { LogTypeEnum } from '@/src/logging/LogType';
import { queryClient } from '@utils/queryClient';

import classnames from 'classnames/bind';
import styles from './confirmModal.module.scss';
const cx = classnames.bind(styles);

interface ConfirmModalProps {
  visible: boolean;
  onClose: () => void;
  onAcceptAfter?: () => void;
  optionSn: number;
  positionSn: number;
  handleProfilePreviewClick: () => Promise<void>;
  sendDateTime: string;
}

const ConfirmModal = ({
  visible,
  onClose,
  onAcceptAfter,
  optionSn,
  positionSn,
  handleProfilePreviewClick,
  sendDateTime,
}: ConfirmModalProps) => {
  const { fetchMessagesList } = useMessageActions();
  const { refetch } = useJD(positionSn);
  const Toast = useToast();

  const matchingProfilePreviewStartDate = new Date('2023-11-30T10:00:00');
  const sendDateTimeDate = new Date(sendDateTime);
  const showProfileButton = matchingProfilePreviewStartDate < sendDateTimeDate;

  const { mutate: acceptMatching, isLoading } = useAcceptCBOMatching({
    onSuccess: () => {
      fetchMessagesList(positionSn);
      queryClient.refetchQueries(MATCH_LIST_QUERY_KEY);
      refetch();
      Toast({ type: 'success', iconType: 'info', content: '제안을 수락하셨습니다.' });
      onAcceptAfter?.();
      onClose();
    },
    onError: (err) => {
      let content;
      switch (err?.response?.data.errorCode) {
        case 'B901':
          content = '포지션이 종료되었습니다.';
          break;
        case 'B902':
          content = '포지션이 삭제되었습니다.';
          break;
        case 'B904':
          content = '이미 수락한 제안입니다.';
          break;
        case 'B906':
          content = '응답 기한이 만료되었습니다.';
          break;
        default:
          content = '제안 수락에 실패했습니다.';
          break;
      }
      Toast({ type: 'error', iconType: 'info', content });
      onClose();
    },
  });

  const onClickAcceptButton = () => {
    acceptMatching({ optionSn, positionSn });
  };

  return (
    <Modal visible={visible}>
      <div className={cx('confirmModalArea')}>
        <div className={cx('title')}> 제안에 수락하시겠습니까?</div>
        <div className={cx('description')}>
          <div className={cx('content')}>
            <Icon name="checkLight" width={24} height={24} />
            <div>
              현재 시점의 프로필이 제출되니, <em className={cx('emphasis')}>프로필을 업데이트하고 수락해 주세요.</em>
            </div>
          </div>
          <div className={cx('content', 'center')}>
            <Icon name="checkLight" width={24} height={24} />이 제안은 입사지원 제안으로 수락 시 면접 대상자로 우선
            검토돼요.
          </div>
          <div className={cx('content', 'center')}>
            <Icon name="checkLight" width={24} height={24} />
            <div>수락한 제안은 다시 취소할 수 없어요.</div>
          </div>
        </div>

        <div className={cx('buttons')}>
          {showProfileButton && (
            <Button className={cx('button')} theme="secondary" onClick={handleProfilePreviewClick}>
              프로필 미리보기
            </Button>
          )}

          <Button
            className={cx('button')}
            onClick={onClickAcceptButton}
            isLoading={isLoading}
            id={LogTypeEnum.log_message_cbo_match_accept}
          >
            수락하기
          </Button>
        </div>

        <a className={cx('btnClose')} role="button" onClick={onClose}>
          <span className={cx('hidden')}>close</span>
        </a>
      </div>
    </Modal>
  );
};

export default ConfirmModal;
