import { PstProgressCompleteDto, PstProgressCompleteRs } from '@domain/rs/phs/pst/result/PstProgressCompleteRs';
import { gradeTextSelectMap, GradeType } from '@domain/constant/gradeType';
import { PHSAnalysisType } from '@domain/constant/phsAnalysisType';
import DateFormat from '@utils/date/format';

export class PstProgressCompleteVO {
  sn: number;
  code: string;
  result: string;
  applyEndDateTime: string;
  matchingUseYn: boolean;

  constructor(dto: PstProgressCompleteDto) {
    this.sn = dto.sn;
    this.code = dto.code;
    this.result = getPstResult(dto.analysisType, dto.grade);
    this.applyEndDateTime = DateFormat.format(new Date(dto.applyEndDateTime), 'yyyy.MM.dd HH:mm');
    this.matchingUseYn = dto.matchingUseYn;
  }
}

const getPstResult = (analysisType: PHSAnalysisType, gradeType: GradeType) => {
  if (analysisType === 'FAILED') return '분석 실패';
  else if (analysisType === 'IN_PROGRESS') return '분석중';

  return gradeTextSelectMap[gradeType];
};

export class PstProgressCompleteListVO {
  resultRecords: PstProgressCompleteVO[];

  constructor(rs: PstProgressCompleteRs) {
    this.resultRecords = rs.resultRecords.map((resultRecord) => new PstProgressCompleteVO(resultRecord));
  }
}
