import React, { FC } from 'react';
import Modal from '@common/modal';
import classnames from 'classnames/bind';
import styles from './deletedModal.module.scss';

const cx = classnames.bind(styles);

interface IDeletedModalProps {
  visible: boolean;
  onClose: () => void;
}

const DeletedModal: FC<IDeletedModalProps> = ({ visible, onClose }) => {
  return (
    <Modal visible={visible} isUseScrollHidden={false}>
      <div className={cx('layer')}>
        <strong className={cx('title')}>삭제된 포지션입니다.</strong>
        <p className={cx('desc')}>포지션이 삭제되어 선택이 불가능 합니다.</p>
        <a className={cx('btnConfirm')} role="button" onClick={onClose}>
          확인
        </a>
      </div>
    </Modal>
  );
};

export default DeletedModal;
