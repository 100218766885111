import { AxiosResponse } from 'axios';
import { axios } from '@repository/RequestClient';
import CurationListFilterRq from '@domain/rq/position/list/CurationListFilterRq';
import PositionListRs from '@domain/rs/position/list/PositionListRs';
import CurationFilterListRs from '@domain/rs/position/list/CurationFilterListRs';

const curationPrefix = '/curations';

export default interface CurationRepo {
  //포지션 큐레이션 필터 목록
  fetchCurationFilterList(): Promise<AxiosResponse<CurationFilterListRs>>;

  //큐레이션 포지션 목록
  fetchCurationPositionList(curationSn?: number, rq?: CurationListFilterRq): Promise<AxiosResponse<PositionListRs>>;

  //큐레이션 자동 추천 포지션 목록
  fetchRecommendPositionList(): Promise<AxiosResponse<PositionListRs>>;
}

export class RemoteCurationRepo implements CurationRepo {
  fetchCurationFilterList(): Promise<AxiosResponse<CurationFilterListRs>> {
    return axios.get<CurationFilterListRs>(`${curationPrefix}`);
  }

  fetchCurationPositionList(curationSn?: number, rq?: CurationListFilterRq): Promise<AxiosResponse<PositionListRs>> {
    return axios.get<PositionListRs>(`${curationPrefix}/v2/${curationSn}/positions`, {
      params: rq,
    });
  }

  fetchRecommendPositionList(): Promise<AxiosResponse<PositionListRs>> {
    return axios.get<PositionListRs>(`${curationPrefix}/positions`);
  }
}
