import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { useCompaniesInformation, useCompaniesSearch } from '@repository/companies/useCompanies';
import Search from './search';
import SearchList from './searchList';
import classnames from 'classnames/bind';
import styles from './searchArea.module.scss';
const cx = classnames.bind(styles);

const SearchArea = () => {
  const router = useRouter();
  const { sn } = router.query;
  const { data: companyInfoData } = useCompaniesInformation(Number(sn));
  const [keyword, setKeyword] = useState('');
  const { data } = useCompaniesSearch(keyword);

  const onChange = (keyword: string) => {
    setKeyword(keyword);
  };

  //처음 렌더링시 키워드에 회사명 넣어주는 로직
  useEffect(() => {
    if (!companyInfoData || keyword) return;
    setKeyword(companyInfoData.companyName);
  }, [companyInfoData]);

  return (
    <div className={cx('wrapper')}>
      <Search keyword={keyword} onChange={onChange} />
      <SearchList companyList={data} />
    </div>
  );
};

export default SearchArea;
