import { useEffect, useRef } from 'react';

/**
 * mount 이후에만 effect를 실행하는 useEffect
 * useEffect: 첫 마운트 시, 종속성 배열에 있는 값이 변경될 때마다 실행
 * useUpdateEffect: 첫 마운트 시는 실행 X, 종속성 배열에 있는 값이 변경될 때만 실행
 * @param effect useEffect callback 함수
 * @param deps useEffect 종속성 배열
 */
const useUpdateEffect: typeof useEffect = (effect, deps) => {
  const isFirst = useRef(true);

  useEffect(() => {
    if (!isFirst.current) {
      return effect();
    }

    isFirst.current = false;
  }, deps);
};

export default useUpdateEffect;
